export enum BookingsKey {
  GameScheduleDate = 'gameScheduleDate',
  GameScheduleTime = 'gameScheduleTime',
  FieldName = 'fieldName',
  CompanyName = 'companyName',
  CustomerName = 'customerName',
  PhoneNumber = 'phoneNumber',
  Source = 'source',
  PaymentStatus = 'paymentStatus',
  Presence = 'presence',
  BookingStatus = 'bookingStatus',
}

export type BookingsValues = {
  [BookingsKey.GameScheduleDate]: string;
  [BookingsKey.GameScheduleTime]: string;
  [BookingsKey.FieldName]: string;
  [BookingsKey.CompanyName]: string;
  [BookingsKey.CustomerName]: string;
  [BookingsKey.PhoneNumber]: string;
  [BookingsKey.Source]: string;
  [BookingsKey.PaymentStatus]: string;
  [BookingsKey.Presence]: boolean;
  [BookingsKey.BookingStatus]: string;
};

export enum RecurringBookingsKey {
  Start = 'start',
  End = 'end',
  CompanyName = 'companyName',
  FieldName = 'fieldName',
  CustomerName = 'customerName',
  PhoneNumber = 'phoneNumber',
  Source = 'source',
  BookingType = 'bookingType',
  Count = 'count',
}

export type RecurringBookingsValues = {
  [RecurringBookingsKey.Start]: string;
  [RecurringBookingsKey.End]: string;
  [RecurringBookingsKey.CompanyName]: string;
  [RecurringBookingsKey.FieldName]: string;
  [RecurringBookingsKey.CustomerName]: string;
  [RecurringBookingsKey.PhoneNumber]: string;
  [RecurringBookingsKey.Source]: string;
  [RecurringBookingsKey.BookingType]: string;
  [RecurringBookingsKey.Count]: string;
};
