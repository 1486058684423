import { PaginatedRequestParams } from 'api';
import { Button, ContentTopBar, Loader, Result404 } from 'components';
import {
  DeductModal,
  TopUpModal,
  useGetWalletTransactionsHistory,
  WalletTransactionHistoryTable,
} from 'features/wallet';
import { usePaginationParams } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import { StyledWalletTransactionButtonWrapper } from './wallet-transaction-history.styles';

type WalletTransactionHistoryProps = {
  walletTransactionId: string;
};

export const WalletTransactionHistory = ({
  walletTransactionId,
}: WalletTransactionHistoryProps) => {
  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState(false);
  const [isDeductModalOpen, setIsDeductModalOpen] = useState(false);
  const { t } = useTranslation();
  const { params, updateParams } = usePaginationParams<PaginatedRequestParams>({
    page: 1,
    size: 10,
  });

  const walletTransactionsHistoryResult = useGetWalletTransactionsHistory(
    walletTransactionId,
    params,
    {
      cacheTime: 0, //workaround to invalidate initialValues of client data form
    },
  );

  const {
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
    isFetching: isFetchingCompany,
    data: walletTransactionsHistoryData,
  } = walletTransactionsHistoryResult;

  const toggleTopUpModal = () => setIsTopUpModalOpen(!isTopUpModalOpen);
  const toggleDeductModal = () => setIsDeductModalOpen(!isDeductModalOpen);
  const closeModal = () => {
    setIsDeductModalOpen(false);
    setIsTopUpModalOpen(false);
  };

  return (
    <>
      {isLoadingCompany ? (
        <Loader />
      ) : isErrorCompany ? (
        <Result404 />
      ) : (
        <>
          <ContentTopBar
            title={`${t('wallet.transactionsHistory.title')} (${
              walletTransactionsHistoryData.wallet_owner
            })`}
            hasBackButton={ROUTES.WALLET}
            renderButtons={
              <StyledWalletTransactionButtonWrapper>
                <Button danger onClick={toggleDeductModal}>
                  {t('wallet.buttons.deduct')}
                </Button>
                <Button onClick={toggleTopUpModal}>
                  {t('wallet.buttons.topUp')}
                </Button>
              </StyledWalletTransactionButtonWrapper>
            }
          />

          <WalletTransactionHistoryTable
            data={walletTransactionsHistoryData}
            isLoading={isFetchingCompany}
            tableParams={params}
            updateParams={updateParams}
            walletTransactionId={walletTransactionId}
          />
        </>
      )}

      {isTopUpModalOpen ? (
        <TopUpModal
          walletId={walletTransactionId}
          currentBalance={
            walletTransactionsHistoryData?.items[0]?.balance_after
          }
          onSuccess={closeModal}
          onCancel={closeModal}
        />
      ) : null}

      {isDeductModalOpen ? (
        <DeductModal
          walletId={walletTransactionId}
          currentBalance={
            walletTransactionsHistoryData?.items[0]?.balance_after
          }
          onSuccess={closeModal}
          onCancel={closeModal}
        />
      ) : null}

      <Outlet />
    </>
  );
};
