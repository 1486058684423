import { Spin } from 'antd';
import { RcFile } from 'antd/es/upload';
import { ContentTopBar, Loader, Result404 } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import dayjs from 'dayjs';
import { useGetCompany } from 'features/companies';
import { useGetFacility } from 'features/facilities/use-cases/get-facility';
import {
  FieldDetailsForm,
  FieldDetailsFormValues,
  useGetField,
  useUpdateField,
} from 'features/fields';
import { useDeleteFieldCoverImage } from 'features/fields/use-cases/delete-cover-image';
import { useDeleteFieldGalleryImage } from 'features/fields/use-cases/delete-gallery-image';
import { useUploadFieldCoverImage } from 'features/fields/use-cases/upload-field-image';
import { useUploadFieldGalleryImage } from 'features/fields/use-cases/upload-gallery';
import { useState } from 'react';
import { ROUTES } from 'routes/constants';
import { FieldCategory } from 'types';

type FieldDetailsProps = {
  fieldId: string;
  onSuccess: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatTimeRange = (timerange: any) => {
  const format = 'HH:mm:ss';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const time_ranges = timerange.all_days?.map((range: any) => ({
    start_time: dayjs(range?.all_days_range[0]).second(0).format(format),
    end_time: dayjs(range?.all_days_range[1]).second(0).format(format),
    slot_price: range?.all_days_slot_price,
  }));

  return time_ranges;
};

const formatExcepTimeRange = (timerange: any) => {
  const format = 'HH:mm:ss';

  const response: any = [];
  if (timerange !== undefined && timerange.length > 0) {
    timerange.forEach((value: any) => {
      const exceptionDate = value.exceptionDays;
      const extimeRange = value.exceptionTimeRange?.map((range: any) => ({
        start_time: dayjs(range?.exception_days_range[0])
          .second(0)
          .format(format),
        end_time: dayjs(range?.exception_days_range[1])
          .second(0)
          .format(format),
        slot_price: range?.exception_days_slot_price,
      }));
      const weekDays = value.exceptionWeekDays;
      const exceptionData_ = {
        // start_date: dayjs(exceptionDate ? exceptionDate[0] : ''),
        // end_date: dayjs(exceptionDate ? exceptionDate[1] : ''),
        start_date: dayjs(exceptionDate ? exceptionDate[0] : '').format(
          'YYYY-MM-DD',
        ),
        end_date: dayjs(exceptionDate ? exceptionDate[1] : '').format(
          'YYYY-MM-DD',
        ),
        weekdays: weekDays,
        time_ranges: extimeRange,
      };

      response.push(exceptionData_);
    });
    if (
      Object.keys(response[0].weekdays).length &&
      response[0].weekdays.length > 0
    ) {
      return response;
    } else {
      return [];
    }
  } else {
    return null;
  }
};

const getPayload = (formValues: FieldDetailsFormValues) => {
  const {
    name,
    name_arabic,
    rank,
    max_players,
    sport_ids,
    // opening_hours,
    time_slot,
    time_price,
    free_amenities,
    chargeable_amenities,
    categories_type,
    gender,
    type,
    width,
    length,
    exception_time_price,
  } = formValues;

  const composeCategories = () => {
    const categories = [categories_type];

    if (
      gender === FieldCategory.FemaleOnly ||
      gender === FieldCategory.MaleOnly
    ) {
      return [...categories, gender];
    }

    return categories;
  };

  //const composeAmenities = [...free_amenities, ...chargeable_amenities];

  const composeAmenities = [...free_amenities];
  const chargeableAmenities = [...chargeable_amenities];

  const format = 'HH:mm:ss';

  const firstRangeStartTime = dayjs(
    time_price?.all_days?.[0]?.all_days_range?.[0],
  )
    .second(0)
    .format(format);

  const firstRangeEndTime = dayjs(
    time_price?.all_days?.[0]?.all_days_range?.[1],
  )
    .second(0)
    .format(format);

  const firstRangeSlotPrice = time_price?.all_days?.[0]?.all_days_slot_price;

  let secondRangeStartTime;
  let secondRangeEndTime;
  let secondRangeSlotPrice;

  if (time_price?.all_days?.[1] !== undefined) {
    secondRangeStartTime = dayjs(time_price?.all_days?.[1]?.all_days_range?.[0])
      .second(0)
      .format(format);

    secondRangeEndTime = dayjs(time_price?.all_days?.[1]?.all_days_range?.[1])
      .second(0)
      .format(format);

    secondRangeSlotPrice = time_price?.all_days?.[1]?.all_days_slot_price;
  }

  const time_ranges = formatTimeRange(time_price);
  const exception_ranges = formatExcepTimeRange(exception_time_price);

  return {
    name,
    name_arabic,
    rank,
    categories: composeCategories(),
    max_players,
    time_slot,
    // open_from: dayjs(opening_hours[0]).format(format),
    // open_to: dayjs(opening_hours[1]).format(format),
    open_from: '00:00:00',
    open_to: '23:59:00',
    first_range_start_time: firstRangeStartTime,
    first_range_end_time: firstRangeEndTime,
    first_range_slot_price: firstRangeSlotPrice,
    second_range_start_time: secondRangeStartTime,
    second_range_end_time: secondRangeEndTime,
    second_range_slot_price: secondRangeSlotPrice,
    sport_ids,
    price: Number(firstRangeSlotPrice),
    time_ranges,
    amenity_ids: composeAmenities.length ? composeAmenities : undefined,
    chargeable_amenity_ids: chargeableAmenities.length
      ? chargeableAmenities
      : undefined,
    type,
    width,
    length,
    exception_ranges: exception_ranges || [],
  };
};

export const FieldDetails = ({ fieldId, onSuccess }: FieldDetailsProps) => {
  const { mutate: mutateUpdateField, isLoading: isLoadingUpdateField } =
    useUpdateField();
  const { uploadImage: uploadCoverImage } = useUploadFieldCoverImage();
  const { uploadImage: uploadGalleryImage } = useUploadFieldGalleryImage();
  const {
    mutate: mutateDeleteFieldCoverImage,
    isLoading: isLoadingDeleteFieldCoverImage,
  } = useDeleteFieldCoverImage();
  const {
    mutate: mutateDeleteFieldGalleryImage,
    isLoading: isLoadingDeleteFieldGalleryImage,
  } = useDeleteFieldGalleryImage();
  const fieldQueryResult = useGetField(fieldId, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });
  const [isLoadingUploadCoverImage, setIsLoadingUploadCoverImage] =
    useState(false);
  const [isLoadingUploadGalleryImage, setIsLoadingUploadGalleryImage] =
    useState(false);

  const {
    isLoading: isLoadingField,
    isError: isErrorField,
    isFetching: isFetchingField,
    data: fieldData,
  } = fieldQueryResult;

  const canFetchFacility = Boolean(fieldData?.facility_id);

  const facilityQueryResult = useGetFacility(fieldData?.facility_id as string, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
    enabled: canFetchFacility,
  });

  const {
    isLoading: isLoadingFacility,
    isError: isErrorFacility,
    isFetching: isFetchingFacility,
    data: facilityData,
  } = facilityQueryResult;

  const canFetchCompany = Boolean(facilityData?.company_id);

  const companyQueryResult = useGetCompany(facilityData?.company_id as string, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
    enabled: canFetchCompany,
  });

  const {
    isLoading: isLoadingCompany,
    isError: isErrorCompany,
    isFetching: isFetchingCompany,
    data: companyData,
  } = companyQueryResult;

  const getIsLoading =
    isLoadingUpdateField ||
    isFetchingField ||
    isFetchingFacility ||
    isFetchingCompany ||
    isLoadingDeleteFieldCoverImage ||
    isLoadingUploadCoverImage ||
    isLoadingUploadGalleryImage ||
    isLoadingDeleteFieldGalleryImage;

  const handleUpdate = async (
    formValues: FieldDetailsFormValues,
    fileList: RcFile[],
    galleryFileList: RcFile[],
  ) => {
    const payload = { id: fieldId, ...getPayload(formValues) };
    mutateUpdateField(payload, {
      onSuccess() {
        if (fileList.length) {
          setIsLoadingUploadCoverImage(true);

          const coverImageformData = new FormData();
          coverImageformData.append('file', fileList[0] as RcFile);

          uploadCoverImage(fieldId, coverImageformData).finally(() =>
            setIsLoadingUploadCoverImage(false),
          );
        }

        if (galleryFileList.length) {
          setIsLoadingUploadGalleryImage(true);

          galleryFileList.map(async (galleryImage) => {
            const galleryImageformData = new FormData();
            galleryImageformData.append('file', galleryImage as RcFile);

            return uploadGalleryImage(fieldId, galleryImageformData);
          });

          setIsLoadingUploadGalleryImage(false);
        }

        if (!getIsLoading) onSuccess();
      },
    });
  };

  const handleDeleteImage = () => {
    mutateDeleteFieldCoverImage(fieldId);
  };

  const handleDeleteGalleryImage = (url: string) => {
    mutateDeleteFieldGalleryImage({ id: fieldId, image_url: url });
  };

  return (
    <>
      {isLoadingField || isLoadingFacility || isLoadingCompany ? (
        <Loader />
      ) : isErrorField || isErrorFacility || isErrorCompany ? (
        <Result404 />
      ) : (
        <>
          <Spin spinning={getIsLoading}>
            <ContentTopBar
              title={fieldData.name}
              hasBackButton={ROUTES.FIELDS}
            />

            <StyledContentBackground>
              <FieldDetailsForm
                fieldData={fieldData}
                facilityData={facilityData}
                companyData={companyData}
                handleUpdate={handleUpdate}
                handleDeleteImage={handleDeleteImage}
                handleDeleteGalleryImage={handleDeleteGalleryImage}
                disabled={getIsLoading}
              />
            </StyledContentBackground>
          </Spin>
        </>
      )}
    </>
  );
};
