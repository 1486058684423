import {
  AppleOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Status, StatusVariant } from 'components';
import { BookingStatus, PaymentMethod } from 'types';

const gameStatusMap = {
  [BookingStatus.active]: StatusVariant.success,
  [BookingStatus.cancelled]: StatusVariant.error,
  [BookingStatus.completed]: StatusVariant.default,
};

export const handleRenderStatus = (item: {
  is_cancelled?: boolean;
  end_date: string | Date;
}) => {
  if (item.is_cancelled)
    return (
      <Status variant={gameStatusMap[BookingStatus.cancelled]}>
        <Status.BadgeWithText text={BookingStatus.cancelled} />
      </Status>
    );
  const now = new Date();
  const itemDate = new Date(item?.end_date);

  if (itemDate < now)
    return (
      <Status variant={gameStatusMap[BookingStatus.completed]}>
        <Status.BadgeWithText text={BookingStatus.completed} />
      </Status>
    );

  return (
    <Status variant={gameStatusMap[BookingStatus.active]}>
      <Status.BadgeWithText text={BookingStatus.active} />
    </Status>
  );
};

export const paymentMethodIconMap: { [key in PaymentMethod]: JSX.Element } = {
  [PaymentMethod.Cash]: <DollarCircleOutlined />,
  [PaymentMethod.Knet]: <CreditCardOutlined />,
  [PaymentMethod.Wallet]: <WalletOutlined />,
  [PaymentMethod.Credit]: <CreditCardOutlined />,
  [PaymentMethod.Apple]: <AppleOutlined />,
};
