import { ContentFilters, ContentTopBar } from 'components';
import {
  useGetUsersWithWallet,
  UsersWithWalletFilterForm,
  UsersWithWalletFilterFormPaginated,
  UsersWithWalletFilterFormValues,
  UsersWithWalletTable,
} from 'features/wallet';
import { usePaginationParams } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const initialFilterValues: UsersWithWalletFilterFormValues = {
  full_user_name: undefined,
  phone_number: undefined,
};

export const UsersWithWalletView = () => {
  const { t } = useTranslation();
  const { params, updateParams, resetPage } =
    usePaginationParams<UsersWithWalletFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by_transaction: '-last_transaction_date',
      ...initialFilterValues,
    });

  const { data, isFetching } = useGetUsersWithWallet(params, {
    retry: false,
  });

  const handleFiltersChange = (values: UsersWithWalletFilterFormValues) => {
    updateParams(values);
    resetPage();
  };

  const formId = 'users-with-wallet-filters-form';

  return (
    <>
      <ContentTopBar title={t('wallet.heading')} />

      <ContentFilters formId={formId}>
        <UsersWithWalletFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>

      <UsersWithWalletTable
        isLoading={isFetching}
        data={data}
        tableParams={params}
        updateParams={updateParams}
      />

      <Outlet />
    </>
  );
};
