import { Form, Grid, Input, InputNumber, Modal } from 'antd';
import dayjs from 'dayjs';
import { getUserFullName, useUserContext } from 'features/users';
import {
  StyledAdditionalInfoWrapper,
  StyledLabel,
  StyledTopUpModalWrapper,
} from 'features/wallet';
import { useTopUpWallet } from 'features/wallet/use-cases/top-up-wallet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/currency';
import { formatDate } from 'utils/date';

import { TopUpFormField, TopUpFormValues } from './types';

const { useBreakpoint } = Grid;

type TopUpModalProps = {
  walletId: string;
  currentBalance: number | undefined;
  onSuccess: () => void;
  onCancel: () => void;
};

export const TopUpModal = ({
  walletId,
  currentBalance,
  onSuccess,
  onCancel,
}: TopUpModalProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { user } = useUserContext();

  const { isLoading, mutate } = useTopUpWallet();

  const initialValues = {
    [TopUpFormField.TransactionId]: undefined,
    [TopUpFormField.ReferenceNumber]: undefined,
    [TopUpFormField.AuthId]: undefined,
    [TopUpFormField.Amount]: undefined,
  };

  const handleSubmit = (formValues: TopUpFormValues) => {
    const payload = {
      wallet_id: walletId,
      currency: 'KWD',
      ...formValues,
    };

    mutate(payload, {
      onSuccess,
    });
  };

  return (
    <Modal
      open
      title={t('wallet.modal.topUp')}
      onCancel={onCancel}
      getContainer={false}
      width={screens.lg ? '480px' : '100%'}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{
        form: 'wallet-top-up',
        htmlType: 'submit',
        loading: isLoading,
      }}
      okText={<>{t('wallet.buttons.topUpWallet')}</>}
    >
      <StyledTopUpModalWrapper>
        <Form<TopUpFormValues>
          id='wallet-top-up'
          name='wallet-top-up'
          form={form}
          layout='vertical'
          initialValues={initialValues}
          onFinish={handleSubmit}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          requiredMark='optional'
          disabled={isLoading}
        >
          <Form.Item<TopUpFormField>
            label={t('wallet.formFields.transactionId.label')}
            name={TopUpFormField.TransactionId}
          >
            <Input
              placeholder={t('wallet.formFields.transactionId.placeholder')}
              size='large'
            />
          </Form.Item>

          <Form.Item<TopUpFormField>
            label={t('wallet.formFields.referenceNumber.label')}
            name={TopUpFormField.ReferenceNumber}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input
              placeholder={t('wallet.formFields.referenceNumber.placeholder')}
              size='large'
            />
          </Form.Item>

          <Form.Item<TopUpFormField>
            label={t('wallet.formFields.authId.label')}
            name={TopUpFormField.AuthId}
          >
            <Input
              placeholder={t('wallet.formFields.authId.placeholder')}
              size='large'
            />
          </Form.Item>

          <Form.Item<TopUpFormField>
            label={t('wallet.formFields.amount.label')}
            name={TopUpFormField.Amount}
            rules={[
              {
                required: true,
              },
              {
                pattern: /^[\d]{0,8}$/,
                message: 'The user can only enter a maximum of eight digits.',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder={t('wallet.formFields.amount.placeholder')}
              prefix={'KD'}
              size='large'
            />
          </Form.Item>
        </Form>

        <StyledAdditionalInfoWrapper>
          <div>
            <StyledLabel>{t('wallet.modal.label.currentBalance')}</StyledLabel>
            {formatPrice({
              value: currentBalance || 0,
              currency: 'KWD',
            })}
          </div>

          <div>
            <StyledLabel>{t('wallet.modal.label.comment')}</StyledLabel>
            {t('wallet.modal.value.updatedByAdmin')}
          </div>

          <div>
            <StyledLabel>{t('wallet.modal.label.transactionDate')}</StyledLabel>
            {formatDate({
              date: dayjs(),
              format: 'dateTime',
            })}
          </div>

          <div>
            <StyledLabel>{t('wallet.modal.label.actionBy')}</StyledLabel>
            {user ? getUserFullName(user) : '-'}
          </div>
        </StyledAdditionalInfoWrapper>
      </StyledTopUpModalWrapper>
    </Modal>
  );
};
