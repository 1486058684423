import {
  BankOutlined,
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import { Col, Grid, Row, Space, Typography } from 'antd';
import { ReactComponent as LogoSvg } from 'assets/li3ib-logo.svg';
import { Marker } from 'features/company-info/components/marker/marker';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';

import { ContactTextBox, ContactUsWrapper } from './contact-us.styles';

const { useBreakpoint } = Grid;

export const ContactUsView = () => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const googleMapsSettings = {
    center: {
      lat: 29.37089454064832,
      lng: 47.97781160309592,
      gestureHandling: 'none',
      zoomControl: false,
    },
    zoom: 17,
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  };

  const googleMapsStyle = {
    width: '100%',
    height: screens.lg ? '100%' : '100vw',
  };
  const colFlex = screens.lg ? '1 0' : undefined;

  return (
    <ContactUsWrapper>
      <Row
        justify={'center'}
        style={{
          height: '100%',
        }}
      >
        <Col
          flex={colFlex}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Space
            direction={'horizontal'}
            align={'center'}
            style={{
              padding: '10%',
            }}
          >
            <Row gutter={[24, 48]}>
              <Row gutter={[24, 72]}>
                <Col span={24}>
                  <Space direction={'vertical'}>
                    <LogoSvg title={t('li3ibLogo')} color='#00473A' />
                    <Typography.Text strong>
                      {t('companyInfo.contactUs.questions')}
                    </Typography.Text>
                  </Space>
                </Col>
              </Row>
              <Row gutter={[24, 72]}>
                <Col md={12} span={24}>
                  <Space direction='vertical'>
                    <MailOutlined className='status-icon success' />
                    <ContactTextBox>
                      <Typography.Text strong>Email us</Typography.Text>
                      <Typography.Text type='secondary'>
                        {t('companyInfo.contactUs.help')}
                      </Typography.Text>
                    </ContactTextBox>
                    <a href={'mailto:hello@li3ib.com'}>
                      <Typography.Text type='success'>
                        {t('companyInfo.contactUs.email')}
                      </Typography.Text>
                    </a>
                  </Space>
                </Col>

                <Col md={12} span={24}>
                  <Space direction={'vertical'}>
                    <WhatsAppOutlined className='status-icon success' />
                    <ContactTextBox>
                      <Typography.Text strong>
                        {t('companyInfo.contactUs.chat')}
                      </Typography.Text>
                      <Typography.Text type='secondary'>
                        {t('companyInfo.contactUs.chatHours')}
                      </Typography.Text>
                    </ContactTextBox>
                    <a href='https://wa.me/9651801804'>
                      <Typography.Text type='success'>
                        {t('companyInfo.contactUs.telephone')}
                      </Typography.Text>
                    </a>
                  </Space>
                </Col>

                <Col md={12} span={24}>
                  <Space direction={'vertical'}>
                    <BankOutlined className='status-icon success' />
                    <ContactTextBox>
                      <Typography.Text strong>
                        {t('companyInfo.contactUs.headOffice')}
                      </Typography.Text>
                      <Typography.Text type='secondary'>
                        {t('companyInfo.contactUs.comeVisit')}
                      </Typography.Text>
                    </ContactTextBox>
                    <a
                      href={'https://goo.gl/maps/aWfuDtRDuVBHeiG69'}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <Typography.Text type='success'>
                        {t('companyInfo.contactUs.address')}
                      </Typography.Text>
                    </a>
                  </Space>
                </Col>

                <Col md={12} span={24}>
                  <Space direction={'vertical'}>
                    <PhoneOutlined className='status-icon success' />
                    <ContactTextBox>
                      <Typography.Text strong>
                        {t('companyInfo.contactUs.callUs')}
                      </Typography.Text>
                      <Typography.Text type='secondary'>
                        {t('companyInfo.contactUs.callHours')}
                      </Typography.Text>
                    </ContactTextBox>
                    <a href={'tel:+9651801804'}>
                      <Typography.Text type='success'>
                        {t('companyInfo.contactUs.telephone')}
                      </Typography.Text>
                    </a>
                  </Space>
                </Col>
              </Row>
            </Row>
          </Space>
        </Col>
        <Col flex={colFlex} style={googleMapsStyle}>
          <GoogleMapReact
            defaultCenter={googleMapsSettings.center}
            defaultZoom={googleMapsSettings.zoom}
            bootstrapURLKeys={{
              key: googleMapsSettings.apiKey,
            }}
            options={{
              draggable: false,
              zoomControl: false,
            }}
          >
            <Marker {...googleMapsSettings.center} />
          </GoogleMapReact>
        </Col>
      </Row>
    </ContactUsWrapper>
  );
};
