import { AddBookingModal } from 'features/bookings/components';
import { FieldWithBookings } from 'features/fields';
import { useCallback, useEffect, useState } from 'react';
import { User } from 'types';

import { AvailableSlotData } from './available-time-slot';
import { ConfirmationBar } from './confirmation-bar';
import { FieldSchedule } from './field-schedule';
import { TimelineHours } from './utils';

type TimetableContentProps = {
  fields: FieldWithBookings[];
  date: string;
  user: User;
  timelineHours: TimelineHours;
  filteredSportId?: string;
};

export type SelectedSlots = Map<
  string,
  AvailableSlotData & { fieldId: string; index: number }
>;

export type SelectSlot = (
  fieldId: string,
  index: number,
  value: AvailableSlotData,
) => void;

export const TimetableContent = ({
  fields,
  date,
  user,
  timelineHours,
  filteredSportId,
}: TimetableContentProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState<SelectedSlots>(new Map());

  const currentFieldId =
    selectedSlots.size > 0
      ? Array.from(selectedSlots.values())[0].fieldId
      : null;
  const currentField = fields.find((field) => field.id === currentFieldId);

  const selectSlot = useCallback<SelectSlot>((fieldId, index, value) => {
    const key = `${fieldId}_${index}`;

    setSelectedSlots((prevSlots) => {
      const slots = new Map(prevSlots);

      if (slots.has(key)) {
        slots.delete(key);

        return slots;
      }

      // Clear the collection when user selects a slot from a different field
      if (slots.size > 0 && Array.from(slots.values())[0].fieldId !== fieldId) {
        slots.clear();
      }

      return slots.set(key, { ...value, fieldId, index });
    });
  }, []);

  useEffect(() => {
    clearSelectedSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const clearSelectedSlots = () => {
    setSelectedSlots(() => new Map());
  };

  const toggleAddBookingModal = () => setIsModalOpen(!isModalOpen);

  const handleCloseModal = () => {
    toggleAddBookingModal();
    clearSelectedSlots();
  };

  return (
    <>
      {fields.map((field) => (
        <FieldSchedule
          key={field.id}
          field={field}
          date={date}
          user={user}
          timelineHours={timelineHours}
          selectSlot={selectSlot}
          selectedSlots={selectedSlots}
        />
      ))}

      {selectedSlots.size ? (
        <ConfirmationBar
          onCancel={clearSelectedSlots}
          onConfirm={toggleAddBookingModal}
        />
      ) : null}

      {currentField && isModalOpen ? (
        <AddBookingModal
          selectedSlots={selectedSlots}
          field={currentField}
          filteredSportId={filteredSportId}
          onCancel={handleCloseModal}
          onSuccess={handleCloseModal}
        />
      ) : null}
    </>
  );
};
