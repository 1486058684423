export enum SportType {
  courtBased = 'COURT_BASED',
  laneBased = 'LANE_BASED',
}

export type Sport = {
  id: string;
  name: string;
  name_arabic: string;
  icon: string;
  type: SportType;
  is_active: boolean;
};
