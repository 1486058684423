import i18n from 'locales/i18n';

export enum Weekdays {
  Sunday = 'SU',
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
}

export const weekdaysTranslationsMap = (weekday: Weekdays) => {
  const typeMap: { [key in Weekdays]: string } = {
    [Weekdays.Sunday]: i18n.t('weekday.sunday'),
    [Weekdays.Monday]: i18n.t('weekday.monday'),
    [Weekdays.Tuesday]: i18n.t('weekday.tuesday'),
    [Weekdays.Wednesday]: i18n.t('weekday.wednesday'),
    [Weekdays.Thursday]: i18n.t('weekday.thursday'),
    [Weekdays.Friday]: i18n.t('weekday.friday'),
    [Weekdays.Saturday]: i18n.t('weekday.saturday'),
  };

  return typeMap[weekday];
};
