import { Form, Grid } from 'antd';
import { Select } from 'components';
import { StyledAdvancedFiltersWrapper } from 'components/content-filters/content-filters.styles';
import { FieldsSelect } from 'features/fields/components/fields-select/fields-select';
import { useTranslation } from 'react-i18next';

import { BookingFilterFormField, BookingFilterFormValues } from './types';

const { useBreakpoint } = Grid;

export const BookingAdvancedFilterForm = ({
  openAdvancedFilters,
}: {
  openAdvancedFilters: boolean;
}) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const bookingsTypeOptions = [
    {
      value: true,
      label: t('bookingTypes.cancelled'),
    } as const,
    {
      value: false,
      label: t('bookingTypes.notCancelled'),
    } as const,
  ];

  const renderFormInputs = (
    <>
      <Form.Item<BookingFilterFormValues>
        label={t('bookings.formFields.field.label')}
        name={BookingFilterFormField.Field}
      >
        <FieldsSelect className='filter' customParams={{ is_active: true }} />
      </Form.Item>
      <Form.Item<BookingFilterFormValues>
        label={t('bookings.formFields.bookings.label')}
        name={BookingFilterFormField.Bookings}
      >
        <Select
          placeholder={t('bookings.formFields.bookings.placeholder')}
          bordered
          allowClear
          options={bookingsTypeOptions}
          className='filter'
          dropdownMatchSelectWidth={200}
        />
      </Form.Item>
    </>
  );

  return (
    <>
      {screens.lg ? (
        <StyledAdvancedFiltersWrapper openAdvancedFilters={openAdvancedFilters}>
          {renderFormInputs}
        </StyledAdvancedFiltersWrapper>
      ) : (
        renderFormInputs
      )}
    </>
  );
};
