import { Alert, Button, Col, Grid, Row, Tag } from 'antd';
import { Column, Table } from 'components';
import dayjs from 'dayjs';
import { daysOptions } from 'features/bookings';
import { useWizardContext } from 'features/bookings/hooks';
import { useTranslation } from 'react-i18next';
import { useWizard } from 'react-use-wizard';
import { weekdaysTranslationsMap } from 'types/days';
import { formatDate } from 'utils/date';

import {
  LegendLabel,
  LegendWrapper,
} from './add-fixed-booking-step-two.styles';
import { RecurringBookingsKey } from './types';

const { useBreakpoint } = Grid;

type RecurringBookingListItem = {
  start_date: string;
  end_date: string;
  reason: string;
};

type RecurringBookingList = {
  detail: RecurringBookingListItem[];
};

export const AddFixedBookingStepTwo = () => {
  const { wizardState } = useWizardContext();
  const { goToStep } = useWizard();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const layoutSettings = {
    wideScreen: { span: 12, offset: 6 },
    desktop: { span: 20, offset: 2 },
    mobile: { span: 24 },
  };

  const getLayout = () =>
    screens.xxl
      ? layoutSettings.wideScreen
      : screens.xl
      ? layoutSettings.desktop
      : screens.sm
      ? layoutSettings.mobile
      : undefined;

  const getDay = (date: string) => dayjs(date).startOf('day').day();

  return (
    <>
      <Row>
        <Col
          {...getLayout()}
          style={{
            gap: '48px',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Alert
            message={t('bookings.fixedSlotUnavailable')}
            showIcon
            description={
              <Button onClick={() => goToStep(0)}>
                {t('bookings.buttons.changeSlot')}
              </Button>
            }
            type='error'
          />
          <LegendWrapper>
            <LegendLabel>{t('bookings.legendLabel')}</LegendLabel>
            <Tag color='red'>{t('bookings.legendBooked')}</Tag>
            <Tag>{t('bookings.legendDontExist')}</Tag>
          </LegendWrapper>
          <Table<RecurringBookingList>
            dataSource={wizardState?.prediction.detail}
            rowKey='id'
            pagination={{
              defaultPageSize: 5,
            }}
          >
            <Column<RecurringBookingListItem>
              title={t('bookings.table.date')}
              dataIndex={'start_date'}
              key={RecurringBookingsKey.Date}
              render={(_, record) => (
                <>
                  {formatDate({
                    date: record.start_date,
                    format: 'date',
                  })}
                </>
              )}
            />
            <Column<RecurringBookingListItem>
              title={t('bookings.table.day')}
              dataIndex={'day'}
              key={RecurringBookingsKey.Day}
              render={(_, record) => (
                <>
                  {weekdaysTranslationsMap(
                    daysOptions[getDay(record.start_date)].value,
                  )}
                </>
              )}
            />
            <Column<RecurringBookingListItem>
              title={t('bookings.table.time')}
              dataIndex={'time'}
              key={RecurringBookingsKey.Time}
              render={(_, record) => (
                <Tag color={record.reason === 'booked' ? 'red' : undefined}>
                  {formatDate({
                    date: record.end_date,
                    format: 'time',
                  })}
                </Tag>
              )}
            />
          </Table>
        </Col>
      </Row>
    </>
  );
};
