import { Button } from 'antd';
import styled from 'styled-components';

export const ImageGroupWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const ImageWrapper = styled.div`
  position: relative;

  &:hover {
    .hidden {
      display: inline-block;
    }
  }
`;

export const StyledImageDeleteButton = styled(Button)`
  border-color: red;
  padding: 4px 10px;
  position: absolute;
  right: 6px;
  top: 6px;

  &.hidden {
    display: none;
  }
`;
export const TimePriceInputChip = styled.span`
  background-color: ${({ theme }) => theme.colors.gray3};
  padding: 4px 8px;
  border-radius: 4px;
  user-select: none;
`;

type TimePriceInputWrapperProps = {
  error: boolean;
};
export const TimePriceInputWrapper = styled.div<TimePriceInputWrapperProps>`
  border: 1px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red5 : theme.colors.gray5};
  border-radius: 8px;
  min-height: 38px;
  padding: 4px;
  width: 100%;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;
