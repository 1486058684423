import { useMainFiltersContext } from 'components/main-filters/main-filters.context';
import { useGetFacility } from 'features/facilities/use-cases/get-facility';
import { sportOptionsWithinFacility } from 'features/sports/utils';
import { Option } from 'types';

export const useSportsOptions = (): Option[] => {
  const {
    params: [filters],
  } = useMainFiltersContext();

  const facilityQueryResult = useGetFacility(filters?.facility_id || '', {
    enabled: !!filters.facility_id,
  });

  return sportOptionsWithinFacility(facilityQueryResult?.data?.fields);
};
