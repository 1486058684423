import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  BookingApiService,
  CreateBookingDTO,
  fieldsBookingsQueryKeys,
} from 'features/bookings';
import i18n from 'locales/i18n';

const { createBooking } = BookingApiService();

export const useCreateBooking = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateBookingDTO) => {
      return createBooking(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: fieldsBookingsQueryKeys.all,
        });
        message.success(i18n.t('createBooking.successMessage'));
      },
    },
  );
};
