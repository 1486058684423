import { useMainFiltersContext } from 'components/main-filters/main-filters.context';
import { useGetFacility } from 'features/facilities/use-cases/get-facility';
import { Option } from 'types';

export const useFieldOptions = (selectedSportId?: string): Option[] => {
  const {
    params: [filters],
  } = useMainFiltersContext();

  const facilityQueryResult = useGetFacility(filters?.facility_id || '', {
    enabled: !!filters.facility_id,
  });

  if (!facilityQueryResult?.data?.fields) return [];

  return facilityQueryResult.data.fields
    .filter((field) => {
      if (!selectedSportId) return true;

      const availableSportsForThisField = field.sports.map((sport) => sport.id);

      return availableSportsForThisField.includes(selectedSportId);
    })
    .map((field) => ({
      label: field.name,
      value: field.id,
    }));
};
