import { Menu } from 'antd';
import { ContentTopBar } from 'components';
import { getActiveKeys } from 'components/side-bar/utils';
import i18n from 'locales/i18n';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  &.side-bar-menu {
    background: transparent;

    .ant-menu-submenu-selected:not(.ant-menu-submenu-open) {
      background-color: ${({ theme }) => theme.colors.greenExtraLight};
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .side-bar-menu {
    margin-bottom: 32px;
  }
`;

export const CompanyInfoView = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const activeKeys = useMemo(() => {
    return getActiveKeys(location.pathname);
  }, [location.pathname]);

  return (
    <Wrapper>
      <ContentTopBar title={t('companyInfo.title')} />
      <StyledMenu
        selectedKeys={activeKeys}
        className='side-bar-menu'
        mode='horizontal'
        items={[
          {
            key: ROUTES.CONTACT_US,
            label: (
              <NavLink to={ROUTES.CONTACT_US}>
                {i18n.t('sideBar.menu.companyInfo.contactUs')}
              </NavLink>
            ),
          },
          {
            key: ROUTES.PRIVACY_POLICY,
            label: (
              <NavLink to={ROUTES.PRIVACY_POLICY}>
                {i18n.t('sideBar.menu.companyInfo.privacyPolicy')}
              </NavLink>
            ),
          },
        ]}
      />
      <Outlet />
    </Wrapper>
  );
};
