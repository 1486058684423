import i18n from 'locales/i18n';
import { CompanyType } from 'types';

export const companyTypeTranslationsMap = (companyType: CompanyType) => {
  const typeMap: { [key in CompanyType]: string } = {
    [CompanyType.Li3ibClient]: i18n.t('companyType.li3ibClient'),
    [CompanyType.SuffixManagement]: i18n.t('companyType.suffixManagement'),
  };

  return typeMap[companyType];
};
