import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { Area } from 'types';

export interface AreasParams extends PaginatedRequestParams {
  country_code?: string;
  name?: string;
}

export type AreasResponse = PaginatedResponse<Area>;

export const AreasApiService = () => {
  const getAreas = async (
    params: AreasParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<AreasResponse>({
      ...callConfig,
      endpoint: `/admin/areas`,
      params,
    });

    return response.data;
  };

  return {
    getAreas,
  };
};
