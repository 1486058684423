import styled from 'styled-components';
import { BREAKPOINTS, hideScrollbar } from 'styles';

export const TimetableMetadataWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 32px;
  margin-bottom: 16px;
`;

export const StyledTimetableBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding-top: 16px;

  ${BREAKPOINTS.tablet} {
    padding-top: 24px;
  }
`;

export const TimetableContainer = styled.div<{
  $offset: number;
  status: 'loading' | 'error' | 'empty' | 'success';
  headerHeight: number;
}>`
  display: ${({ status: state }) => {
    switch (state) {
      case 'loading':
      case 'error':
        return 'flex';
      case 'empty':
        return 'block';
      default:
        return 'grid';
    }
  }};
  grid-template-columns: 64px 1fr;
  grid-template-rows: ${({ headerHeight }) => `${headerHeight}px auto`};
  overflow: hidden;
  width: 100%;
  // Fill all the space left by other components, minus bottom content padding
  height: ${({ theme, $offset }) =>
    `calc(100vh - calc(${$offset}px + ${
      theme.constants.smallMobileContentPadding().bottom
    }))}`};

  ${BREAKPOINTS.mobile} {
    height: ${({ theme, $offset }) =>
      `calc(100vh - calc(${$offset}px + ${
        theme.constants.contentPadding().bottom
      }))}`};
  }
`;

export const EmptyStateWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyCell = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.antd.colorBorder}`};
  position: relative;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 12%) 0px -3px 14px 0px;
`;

export const HeaderRow = styled.div`
  ${hideScrollbar}
  display: flex;
  overflow-y: hidden;
  border-bottom: ${({ theme }) => `1px solid ${theme.antd.colorBorder}`};
  padding-right: 16px; // scrollbar width
`;

export const StyledHeadersWrapper = styled.div`
  display: flex;
`;

export const AxisColumn = styled.div`
  ${hideScrollbar}
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 12%) 0px 14px 16px 0px,
    rgb(0 0 0 / 12%) 0px 3px 6px -4px, rgb(0 0 0 / 5%) 0px 9px 28px 8px;
  padding-bottom: 16px; // scrollbar width
`;

export const ScaleHourBlock = styled.div`
  height: ${({ theme }) =>
    `${theme.constants.fieldsTimetable.oneHourSlotHeight}px`};
  text-align: right;
  padding: 0 8px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    right: 8px;
    top: 50px;
    width: 9px;
    height: 1px;
    background-color: ${({ theme }) => theme.antd.colorTextDisabled};
  }
`;

export const StyledTimetableContent = styled.div`
  display: flex;
  overflow-y: auto;
  overflow-x: auto;
`;
