import { App } from 'antd';
import { handleError } from 'api';
import { CompaniesApiService } from 'features/companies';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { companiesQueryKeys } from './query-keys';

const { uploadCompanyImage } = CompaniesApiService();

export const useUploadCompanyImage = () => {
  const { message } = App.useApp();

  const uploadImage = async (id: string, formData: FormData) => {
    await uploadCompanyImage(id, formData)
      .then(() => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: companiesQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: companiesQueryKeys.detail(id),
          }),
        ]);
        message.success(i18n.t('companies.update.successfulImageUpload'));
      })
      .catch((error) => {
        const uiMessage = handleError(error);
        console.error(uiMessage);

        message.warning(i18n.t('companies.update.errorImageUpload'));
      });
  };

  return {
    uploadImage,
  };
};
