import { Grid, TablePaginationConfig, Tag } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Status, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { BookingsResponse } from 'features/bookings';
import { BookingsKey } from 'features/bookings/components/bookings/types';
import { handleRenderStatus } from 'features/bookings/utils';
import { getUserFullName, usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { BookingListItem } from 'types';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';
import { presenceStatusVariant } from 'utils/presence';
import { sourceTranslationsMap } from 'utils/source';
import { paymentStatusMapToStatusVariant } from 'utils/transaction';

const { useBreakpoint } = Grid;

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

type BookingsTableProps = {
  isBookingListLoading: boolean;
  data?: BookingsResponse;
  tableParams: {
    pagination?: TablePaginationConfig;
  };
  setTableParams: Dispatch<SetStateAction<TableParams>>;
};

export const BookingsTable = ({
  data,
  isBookingListLoading,
  tableParams,
  setTableParams,
}: BookingsTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const permissions = usePermissions();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<BookingListItem> | SorterResult<BookingListItem>[],
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });

    return {
      pagination,
      filters,
      ...sorter,
    };
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('bookings.foundBookings')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<BookingListItem>
        loading={isBookingListLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.pagination?.current,
          defaultPageSize: tableParams.pagination?.pageSize,
          total: data?.total,
        }}
        scroll={{ x: 1440 }}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(getRoute(ROUTES.BOOKINGS_EDIT, record.id));
              }
            },
          };
        }}
      >
        <Column<BookingListItem>
          title={t('bookings.table.date')}
          dataIndex={'start_date'}
          key={BookingsKey.GameScheduleDate}
          width={screens.xxl ? 'auto' : 120}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']} // workaround to remove null as an option
          defaultSortOrder={'descend'}
          render={(_, record) => (
            <>
              {formatDate({
                date: record.start_date,
                format: 'date',
                timeZone: DEFAULT_TIMEZONE,
              })}
            </>
          )}
        />
        <Column<BookingListItem>
          title={t('bookings.table.time')}
          dataIndex={'start_date'}
          key={BookingsKey.GameScheduleTime}
          width={screens.xxl ? 'auto' : 100}
          render={(_, record) => (
            <>
              {formatDate({
                date: record.start_date,
                format: 'time',
                timeZone: DEFAULT_TIMEZONE,
              })}
            </>
          )}
        />
        {permissions.company.view ? (
          <Column<BookingListItem>
            title={t('bookings.table.company')}
            dataIndex={['field', 'facility', 'company', 'name']}
            width={screens.xxl ? 'auto' : 200}
          />
        ) : (
          <Column<BookingListItem>
            title={t('bookings.table.facility')}
            dataIndex={['field', 'facility', 'name']}
            width={screens.xxl ? 'auto' : 200}
          />
        )}
        <Column<BookingListItem>
          title={t('bookings.table.field')}
          dataIndex={['field', 'name']}
        />
        <Column<BookingListItem>
          title={t('bookings.table.customerName')}
          dataIndex={'user'}
          width={screens.xxl ? 'auto' : 200}
          render={(_, record) => getUserFullName(record.user)}
        />
        <Column<BookingListItem>
          title={t('bookings.table.phone')}
          dataIndex={['user', 'phone_number']}
        />
        <Column<BookingListItem>
          title={t('bookings.table.source')}
          dataIndex={BookingsKey.Source}
          render={(_, record) => (
            <Tag>{sourceTranslationsMap(record.source, permissions)}</Tag>
          )}
        />
        <Column<BookingListItem>
          title={t('bookings.table.payment')}
          dataIndex={['transaction', 'payment_status']}
          width={120}
          render={(_, record) => (
            <Status
              variant={
                paymentStatusMapToStatusVariant[
                  record.transaction?.payment_status
                ]
              }
            >
              <Status.Icon />
            </Status>
          )}
        />
        <Column<BookingListItem>
          title={t('bookings.table.presence')}
          dataIndex={'client_showed_up'}
          width={120}
          render={(_, record) => (
            <Status variant={presenceStatusVariant(record?.client_showed_up)}>
              <Status.Icon />
            </Status>
          )}
        />
        <Column<BookingListItem>
          title={t('bookings.table.status')}
          width={screens.xxl ? 'auto' : 120}
          render={(_, record) => handleRenderStatus(record)}
        />
      </Table>
    </StyledTableWrapper>
  );
};
