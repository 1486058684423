import dayjs from 'dayjs';
import { EndUsersTopBar } from 'features/users/components/top-bar/end-users-top-bar';
import { EndUsersTable } from 'features/users/components/users/end-users-table';
import {
  EndUserFilterFormValues,
  EndUserFilterFormValuesPaginated,
} from 'features/users/components/users-filter-form/types';
import { useGetUsersList } from 'features/users/use-cases/get-users';
import { usePaginationParams } from 'hooks';
import { omit } from 'lodash';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';

const getInitialFilterValues: EndUserFilterFormValues = {
  date: [undefined, undefined],
  full_user_name: undefined,
  phone_number: undefined,
  email: undefined,
};

const preparePayload = (params: EndUserFilterFormValuesPaginated) => {
  const createdAtFrom = params.date?.[0];
  const createdAtTo = params.date?.[1];

  return {
    ...omit(params, ['date']),
    roles: ['User'],
    created_at_from: createdAtFrom
      ? formatDate({
          date: dayjs(createdAtFrom).startOf('day'),
          format: 'dbDate',
        })
      : undefined,
    created_at_to: createdAtTo
      ? formatDate({
          date: dayjs(createdAtTo).endOf('day'),
          format: 'dbDate',
        })
      : undefined,
  };
};

export const EndUsersView = () => {
  const { params, updateParams, resetPage } =
    usePaginationParams<EndUserFilterFormValuesPaginated>({
      page: 1,
      size: 10,
      order_by: 'name',
      ...getInitialFilterValues,
    });

  const getUsersListQueryResponse = useGetUsersList(preparePayload(params), {
    retry: false,
  });

  const handleFiltersChange = (values: EndUserFilterFormValuesPaginated) => {
    updateParams(values);
    resetPage();
  };

  return (
    <>
      <EndUsersTopBar
        getInitialFilterValues={getInitialFilterValues}
        handleFilter={handleFiltersChange}
      />
      <EndUsersTable
        isUsersListLoading={
          getUsersListQueryResponse.isLoading &&
          getUsersListQueryResponse.isFetching
        }
        usersList={getUsersListQueryResponse.data?.items || []}
        itemsCount={getUsersListQueryResponse.data?.total || 0}
        tableParams={params}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
