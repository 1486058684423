export const BREAKPOINTS_RESOLUTIONS = {
  mobile: 480,
  tablet: 768,
  laptop: 991,
};

export const BREAKPOINTS = {
  mobile: `@media screen and (min-width: ${
    BREAKPOINTS_RESOLUTIONS.mobile + 1
  }px)`,
  tablet: `@media screen and (min-width: ${
    BREAKPOINTS_RESOLUTIONS.tablet + 1
  }px)`,
  laptop: `@media screen and (min-width: ${
    BREAKPOINTS_RESOLUTIONS.laptop + 1
  }px)`,
};
