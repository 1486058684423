export enum AddBookingFormField {
  PhoneNumber = 'phone_number',
  FirstName = 'first_name',
  LastName = 'last_name',
  Sport = 'sport_id',
  Discount = 'discount',
  Comment = 'comment',
}

export type AddBookingFormValues = {
  [AddBookingFormField.PhoneNumber]: string;
  [AddBookingFormField.FirstName]: string;
  [AddBookingFormField.LastName]: string;
  [AddBookingFormField.Sport]: string;
  [AddBookingFormField.Discount]: number | null;
  [AddBookingFormField.Comment]: string;
};
