type RoutesType = typeof ROUTES;
type RouteKeys = keyof RoutesType;
export type RoutePaths = RoutesType[RouteKeys];

export const ROUTES = {
  HOME: '/',
  BOOKINGS: '/bookings',
  DASHBOARD: '/dashboard',
  BOOKINGS_CREATE: `/bookings/create`,
  BOOKINGS_EDIT: `/bookings/:id/edit`,
  FIXED_BOOKINGS: '/fixed-bookings',
  FIXED_BOOKING_DETAILS: '/fixed-bookings/:id/bookings',
  FIXED_BOOKING_DETAILS_COMMENTS: '/fixed-bookings/:id/comments',
  FIXED_BOOKING_DETAILS_INVOICE: '/fixed-bookings/:id/invoice',
  FIXED_BOOKINGS_CREATE: '/fixed-bookings/create',
  FIXED_BOOKINGS_CREATE_INVOICE: '/fixed-bookings/create-invoice',
  FIXED_BOOKINGS_EDIT: '/fixed-bookings/:id/bookings/:booking_id/edit',
  DAY_VIEW: '/day-view',
  WEEK_VIEW: '/week-view',
  USERS: '/users',
  USERS_APP: '/users/app',
  USERS_APP_EDIT: `/users/app/:id/edit`,
  USERS_ADMIN: '/users/admin',
  USERS_ADMIN_EDIT: `/users/admin/:id/edit`,
  COMPANIES_ALL: '/companies-all', // for unique menu key purposes - not a real route
  COMPANIES: '/companies',
  COMPANIES_CREATE: '/companies/create',
  COMPANIES_EDIT: '/companies/:id/edit',
  FACILITIES: '/facilities',
  FACILITIES_CREATE: '/facilities/create',
  FACILITIES_EDIT: '/facilities/:id/edit',
  FIELDS: '/fields',
  FIELDS_CREATE: '/fields/create',
  FIELDS_EDIT: '/fields/:id/edit',
  SPORTS: '/sports',
  AMENITIES: '/amenities',
  WALLET: '/wallet',
  WITHDRAW: '/withdraw',
  WITHDRAW_detail_data: '/withdraw/:id/information',
  WITHDRAW_detail: '/withdraw/:id/detail',
  WITHDRAW_REJECT: '/withdraw/:id/details',
  PACKAGE: '/package',
  PACKAGE_MASTER: '/package/master',
  PACKAGE_EDIT: '/package/:id/edit',
  PACKAGE_CREATE: '/package/create',
  PACKAGE_BOOKINGS: '/package/booking',
  PACKAGE_BOOKING_DETAILS: `/package/booking/:id/detail`,
  CANCELLATION_POLICY: '/package/CancellationPolicy',
  CANCELLATION_POLICY_EDIT: '/package/CancellationPolicy/:id/edit',
  COMMUNITY: '/community',
  COMMUNITY_GROUPS: '/community/groups',
  REPORT_POST: '/community/report',
  REPORT_POST_DETAILS: `/community/report/:id/detail`,
  COMMUNITY_TC: '/community/term',
  COMMUNITY_GROUPS_EDIT: '/community/term/:id/edit',
  WALLET_TRANSACTIONS: '/wallet/:id/transactions',
  WALLET_TRANSACTION_DETAILS: '/wallet/:id/transactions/:transaction_id',
  LOGIN: '/login',
  ACCOUNT_SETTINGS: '/account-settings',
  COMPANY_INFO: '/company-info',
  COMPANY_TC: '/company-info/term',
  COMPANY_TC_EDIT: '/company-info/term/:id/edit',
  CONTACT: '/company-info/contact', // for unique menu key purposes - not a real route
  CONTACT_US: '/company-info/contact-us',
  PRIVACY_POLICY: '/company-info/privacy-policy',
  USER_LEVEL: '/user-level',
  QUESTION: '/question',
  USERS_QUESTION_EDIT: `/question/:id/edit`,
  BADGE: '/user-level/badge',
  BADGE_EDIT: `/user-level/badge/:id/edit`,
  SOCIAL: '/social',
  SOCIAL_LIST: '/social/list',
  SOCIAL_DETAILS: `/social/list/:id/detail`,
  SOCIAL_EDIT: `/social/list/:id/edit`,
  LABEL: '/social/label',
  LABEL_EDIT: '/social/label/:id/edit',
  REPORTS: '/reports',
  NOT_FOUND: '*',
} as const;
