import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Grid, Modal, Space, Typography } from 'antd';
import { useCancelRecurringBooking } from 'features/bookings/use-cases/cancel-recurring-booking';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const { useBreakpoint } = Grid;

type CancelAllModalProps = {
  onSuccess: () => void;
  onCancel: () => void;
};

// antd does not provide icon prop in Modal API. To achieve that, we put exclamation mark icon with css "absolute" property.
const CancelAllStyle = styled.div`
  .ant-modal-content {
    position: relative;
    padding: 32px;
    padding-left: 72px;
  }
`;

const IconStyle = styled.div`
  position: absolute;
  left: 32px;
  top: 32px;

  svg {
    width: 24px;
    height: 24px;
    fill: #faad14;
  }
`;

export const CancelAllModal = ({
  onCancel,
  onSuccess,
}: CancelAllModalProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { id } = useParams();

  const { mutate: mutateCancelBooking, isLoading: isLoadingCancelBooking } =
    useCancelRecurringBooking();

  const handleCancelBooking = () => {
    if (!id) return;
    mutateCancelBooking(id, { onSuccess });
  };

  return (
    <CancelAllStyle>
      <Modal
        open
        title={t('bookings.modal.cancel.title')}
        onCancel={onCancel}
        onOk={handleCancelBooking}
        getContainer={false}
        width={screens.lg ? '480px' : '100%'}
        okButtonProps={{
          htmlType: 'button',
          loading: isLoadingCancelBooking,
          danger: true,
        }}
        okText={<>{t('bookings.buttons.yesCancel')}</>}
        cancelText={<>{t('bookings.buttons.back')}</>}
      >
        <IconStyle>
          <ExclamationCircleOutlined />
        </IconStyle>
        <Space direction={'vertical'}>
          <Typography.Text>
            {t('bookings.modal.cancel.subtitle')}
          </Typography.Text>
          <Typography.Paragraph type={'secondary'}>
            {t('bookings.modal.cancel.description')}
          </Typography.Paragraph>
        </Space>
      </Modal>
    </CancelAllStyle>
  );
};
