import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const Wrapper = styled.div`
  background: white;

  padding: 24px;

  ${BREAKPOINTS.laptop} {
    padding: 64px 0;
  }
`;
