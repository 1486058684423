export enum PackageDetailsField {
  Company = 'company_id',
  Facility = 'facility_id',
  lat = 'latlang',
  lon = 'latlang',
  packagetypeid = 'package_type_id',
  monthsessioncount = 'month_session_count',
  package_name = 'package_name',
  packagenamearabic = 'package_name_arabic',
  aboutpackage = 'about_package',
  about_package_arabic = 'about_package_arabic',
  price = 'price',
  packagedays = 'package_days',
  packagetimeend = 'package_time_end',
  packagetimestart = 'package_time_start',
  Age = 'age',
  Gender = 'gender',
  Sports = 'sport_ids',
  packagecoverimage = 'package_cover_image',
  packageimagelist = 'package_image_list',
  packagestarttime = 'packagestart_time',
  packageend_time = 'packageend_time',
  totalseat = 'total_seat',
  availableseat = 'available_seat',
  isusercancancel = 'is_user_can_cancel',
}
export type PackageDetailsFormValues = {
  [PackageDetailsField.Company]: string;
  [PackageDetailsField.Facility]: string;
};
