import type { TabsProps } from 'antd';
import { Button, Form, Grid, Tabs } from 'antd';
import { Modal } from 'components';
import { TFunction } from 'i18next';
import { useState } from 'react';
import type { ExceptionDays, TimePrice } from 'types';

import { ExceptionDaysTab } from './exception-days-tab';
import { TimePriceModalFields } from './types';
import { exceptionRangeValidator } from './utils';

const { useBreakpoint } = Grid;

type TimePriceModalProps = {
  closeModal: () => void;
  handleSubmit: (formData: ExceptionDays) => void;
  timeSlot: string;
  defaultValue: TimePrice;
  t: TFunction;
  type: number;
};
//openingHours: Date[];
// openingHours,
export const ExceptionTimePriceModal = ({
  closeModal,
  handleSubmit: onFinish,
  timeSlot,
  defaultValue,
  t,
  type,
}: TimePriceModalProps) => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  // const deleteTimeRange(id)

  const getInitialExceptionValues = (defaultValues: any) => {
    if (type === 1) {
      const exceptionDays = [
        ...(defaultValues.exception_days &&
        defaultValues.exception_days.length > 0
          ? defaultValues.exception_days
          : [
              {
                [TimePriceModalFields.ExceptionDaysRange]: undefined,
                [TimePriceModalFields.ExceptionDaysSlot]: undefined,
              },
            ]),
      ];

      const exceptionDaysList =
        defaultValues?.[TimePriceModalFields.ExceptionDaysList] || [];

      return {
        [TimePriceModalFields.ExceptionDays]: exceptionDays,
        [TimePriceModalFields.ExceptionDaysList]: exceptionDaysList,
        [TimePriceModalFields.ExceptionWeekDays]:
          defaultValues.exceptionWeekDays,
        updateIndex: defaultValues.updateIndex,
        isUpdate: defaultValues.isUpdate,
      };
    } else {
      const exceptionDays = [
        {
          [TimePriceModalFields.ExceptionDaysRange]: undefined,
          [TimePriceModalFields.ExceptionDaysSlot]: undefined,
        },
      ];

      return {
        [TimePriceModalFields.ExceptionDays]: exceptionDays,
        [TimePriceModalFields.ExceptionDaysList]: [],
        [TimePriceModalFields.ExceptionWeekDays]: [],
        updateIndex: 0,
        isUpdate: 0,
      };
    }
  };

  const exceptionValidator = async (rule: any, value: any) => {
    const firstRange = 'exception_days.0.exception_days_range';
    const lastItemIndex = form.getFieldValue('exception_days')?.length - 1;
    const lastRange =
      'exception_days.' + lastItemIndex + '.exception_days_range';

    if (rule.field === firstRange) {
      const [startTime, endTime] = value;
      if (
        startTime.isSame(endTime, 'hour') &&
        startTime.isSame(endTime, 'minute')
      ) {
        return Promise.reject('Time should not be of the same');
      }
    } else if (rule.field === lastRange) {
      const firstRangeValue =
        form.getFieldValue('exception_days')?.[0]?.exception_days_range;

      await exceptionRangeValidator(
        rule,
        value,
        timeSlot as any,
        firstRangeValue,
        t,
      );
    }
  };

  // TODO: find the correct type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  const items: TabsProps['items'] = [
    // {
    //   key: '1',
    //   label: t('fields.modal.labels.allDays'),
    //   children: <AllDaysTab t={t} timeSlot={timeSlot} validator={validator} />,
    // },
    {
      key: '1',
      label: t('fields.modal.labels.exceptionDays'),
      children: (
        <ExceptionDaysTab
          t={t}
          timeSlot={timeSlot}
          validator={exceptionValidator}
          form={form}
        />
      ),
    },
  ];

  const onFinishFailed = (errorInfo: any) => {
    //let allDaysHasError = false;
    let exceptionDaysHasError = false;

    for (let i = 0; i < errorInfo?.errorFields.length; i++) {
      // if (errorInfo.errorFields[i].name[0] === 'all_days') {
      //   allDaysHasError = true;
      // }

      if (String(errorInfo.errorFields[i].name[0]).includes('exception_days')) {
        exceptionDaysHasError = true;
      }
    }

    switch (true) {
      case exceptionDaysHasError:
        setActiveTab('1');
        break;
      default:
        break;
    }
  };

  const isFirstInputFilled = () =>
    defaultValue?.all_days?.[0]?.all_days_range.length > 0;

  return (
    <Modal
      open
      onCancel={closeModal}
      title={t('fields.modal.titles.addNewExceptionPriceRange')}
      footer={[
        <Button
          key='submit'
          type='primary'
          htmlType='submit'
          form='exception-time-price-form'
          size={screens.lg ? 'middle' : 'large'}
        >
          {isFirstInputFilled()
            ? t('fields.buttons.updatePriceRange')
            : t('fields.buttons.addNewPriceRange')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        onFinish={onFinish}
        id='exception-time-price-form'
        name='execption-time-price-form'
        onFinishFailed={onFinishFailed}
        initialValues={getInitialExceptionValues(defaultValue)}
      >
        <Tabs
          items={items}
          activeKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        />
      </Form>
    </Modal>
  );
};
