import { DeleteOutlined, DownOutlined, InboxOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  Image,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  Upload,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { RcFile } from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import dayjs from 'dayjs';
import { CompanySelect } from 'features/companies/components/company-select/company-select';
import { FacilitySelect } from 'features/facilities/components';
import { FieldDetailsFormValues } from 'features/fields';
import {
  FieldDetailsPackage,
  week,
} from 'features/package/components/add-company-form/type';
import { SportSelect } from 'features/sports';
import { useUploadProps } from 'hooks/use-upload-props';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Field } from 'types';

import {
  ImageGroupWrapper,
  ImageWrapper,
  StyledImageDeleteButton,
} from './package-details-form.styles';
import { PackageDetailsField } from './type';

const { useBreakpoint } = Grid;
const { Dragger } = Upload;

type FieldDetailsFormProps = {
  packageData: Field;
  disabled: boolean;
  handleDeleteImage: () => void;
  handleDeleteGalleryImage: (url: string) => void;
  handleUpdate: (
    object: FieldDetailsFormValues,
    FileList: RcFile[],
    galleryFileList: RcFile[],
  ) => void;
};

// type chipStateT = {
//   label: string;
// };

export const PackageDetailsForm = ({
  packageData,
  disabled,
  handleDeleteImage,
  handleDeleteGalleryImage,
  handleUpdate,
}: FieldDetailsFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);

  const [form] = Form.useForm<FieldDetailsFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { fileList, uploadProps } = useUploadProps();
  const companyId = Form.useWatch(FieldDetailsPackage.Company, form);
  const { fileList: galleryFileList, uploadProps: uploadGalleryProps } =
    useUploadProps();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const getGalleryImagesLength = packageData?.package_image_list?.length || 0;
  const start_time = dayjs(packageData.package_time_start);
  const end_time = dayjs(packageData.package_time_end);
  const start = dayjs(packageData.package_time_start);
  const end = dayjs(packageData.package_time_end);

  // dayjs(packageData.package_time_end, 'HH:mm:ss'),
  // }

  const getInitialValues = {
    [PackageDetailsField.Company]: packageData.company_id,
    [PackageDetailsField.Facility]: packageData.facility_id,
    [PackageDetailsField.lat]: `${packageData.lat}, ${packageData.lon}`,
    [PackageDetailsField.packagetypeid]: packageData.package_type_id,
    [PackageDetailsField.monthsessioncount]: packageData.month_session_count,
    [PackageDetailsField.package_name]: packageData.package_name,
    [PackageDetailsField.packagenamearabic]: packageData.package_name_arabic,
    [PackageDetailsField.aboutpackage]: packageData.about_package,
    [PackageDetailsField.Sports]: packageData.package_sports_list.map(
      (res: any) => res.sport_id,
    ),
    [PackageDetailsField.about_package_arabic]:
      packageData.about_package_arabic,
    [PackageDetailsField.price]: packageData.price,
    [PackageDetailsField.packagedays]: packageData.package_days.map(
      (day: any) => {
        const foundDay = week.find((item) => item.label === day);

        return foundDay ? foundDay.value : undefined;
      },
    ),
    [PackageDetailsField.packagetimestart]: start,
    [PackageDetailsField.packagetimeend]: end,
    [PackageDetailsField.packagestarttime]: start_time,
    [PackageDetailsField.packageend_time]: end_time,
    [PackageDetailsField.Age]: packageData.age,
    [PackageDetailsField.totalseat]: packageData.total_seat,
    [PackageDetailsField.availableseat]: packageData.available_seat,
    [PackageDetailsField.Gender]: packageData.gender,
    [PackageDetailsField.isusercancancel]: packageData.is_user_can_cancel,
    [PackageDetailsField.packagecoverimage]: packageData.package_cover_image,
  };

  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;
  const [endDate, setEndDate] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date && endDate && date.isAfter(endDate, 'day')) {
      setEndDate(null); // Reset end date if it is before the new start date
    }
  };
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    if (date && startDate && date.isBefore(startDate, 'day')) {
      setStartDate(null); // Reset start date if it is after the new end date
    }
  };
  const disabledStartDate = (current: any) => {
    // Disable all dates before today and dates before or equal to the end date
    return (
      current &&
      (current < dayjs().startOf('day') ||
        (endDate && current <= endDate.endOf('day')))
    );
  };

  const disabledEndDate = (current: any) => {
    // Disable all dates before today and dates before or equal to the start date
    return (
      current &&
      (current < dayjs().startOf('day') ||
        (startDate && current <= startDate.endOf('day')))
    );
  };
  const handleSubmit = (formValues: FieldDetailsFormValues) => {
    setIsFormDirty(false);
    handleUpdate(formValues, fileList as RcFile[], galleryFileList as RcFile[]);
  };

  return (
    <>
      <div className='packForm'>
        <div className='alart_wrapp'>
          {packageData.total_seat > packageData.available_seat && (
            <Alert
              message='You can not edit this package information because some seats of this package already booked'
              type='warning'
              showIcon
            />
          )}
        </div>
        <div className='warp_inner'>
          <Form
            {...formItemLayout}
            id='company-details-form'
            name='company-details-form'
            form={form}
            layout={screens.lg ? 'horizontal' : 'vertical'}
            onFinish={(values) => handleSubmit(values)}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            initialValues={getInitialValues}
            validateTrigger={validateTrigger}
            disabled={disabled}
          >
            <Row>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={t('fields.formFields.company.label')}
                  name={FieldDetailsPackage.Company}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <CompanySelect
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                    allowClear={false}
                    size='large'
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={t('fields.formFields.facility.label')}
                  name={FieldDetailsPackage.Facility}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <FacilitySelect
                    allowClear={false}
                    allowPullAll={false}
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                    companyId={companyId ? companyId : undefined}
                    size='large'
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={t('fields.formFields.sport.label')}
                  name={FieldDetailsPackage.Sports}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <SportSelect
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                    mode='multiple'
                    size='large'
                    customParams={{ is_active: true }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Google Map location' name='latlang'>
                  <Input
                    placeholder='Latitude,Longitude'
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={'Package Type'}
                  name={FieldDetailsPackage.Packagetypeid}
                  // rules={[
                  //   {
                  //     required: true,
                  //     whitespace: true,
                  //   },
                  // ]}
                >
                  <Select
                    style={{ width: '100%' }}
                    options={[
                      { value: 1, label: 'Monthly' },
                      { value: 2, label: 'Session' },
                    ]}
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name='month_session_count' label='Number'>
                  <Input
                    placeholder='Enter Number'
                    onKeyPress={(e) => {
                      const isNumber = /^[0-9]$/.test(e.key);
                      const isBackspace = e.key === 'Backspace';
                      if (!isNumber && !isBackspace) {
                        e.preventDefault();
                      }
                    }}
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                {/* <Form.Item<FieldDetailsPackage>
                  label='Total Seat'
                  name={'total_seat'}
                >
                  <Input
                    placeholder='Enter Seat'
                    onKeyPress={(e) => {
                      const isNumber = /^[0-9]$/.test(e.key);
                      const isBackspace = e.key === 'Backspace';
                      if (!isNumber && !isBackspace) {
                        e.preventDefault();
                      }
                    }}
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item> */}
                {packageData.total_seat !== packageData.available_seat ? (
                  <Form.Item<FieldDetailsPackage>
                    label='Total Seat'
                    name={'available_seat'}
                  >
                    <Input
                      placeholder='Enter Seat'
                      onKeyPress={(e) => {
                        const isNumber = /^[0-9]$/.test(e.key);
                        const isBackspace = e.key === 'Backspace';
                        if (!isNumber && !isBackspace) {
                          e.preventDefault();
                        }
                      }}
                      disabled={
                        !(packageData.total_seat === packageData.available_seat)
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item<FieldDetailsPackage>
                    label='Total Seat'
                    name={'total_seat'}
                  >
                    <Input
                      placeholder='Enter Seat'
                      onKeyPress={(e) => {
                        const isNumber = /^[0-9]$/.test(e.key);
                        const isBackspace = e.key === 'Backspace';
                        if (!isNumber && !isBackspace) {
                          e.preventDefault();
                        }
                      }}
                      disabled={
                        !(packageData.total_seat === packageData.available_seat)
                      }
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label='Package Name'
                  name={FieldDetailsPackage.PackageName}
                >
                  <Input
                    placeholder='Enter Package Name'
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label='Package Name Arabic'
                  name={FieldDetailsPackage.Packagenamearabic}
                >
                  <Input
                    placeholder='Enter Package Name'
                    dir='rtl'
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Price' name='price'>
                  <Input
                    placeholder='Enter Price'
                    prefix={'KD'}
                    onKeyPress={(e) => {
                      const isNumber = /^[0-9]$/.test(e.key);
                      const isBackspace = e.key === 'Backspace';
                      if (!isNumber && !isBackspace) {
                        e.preventDefault();
                      }
                    }}
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={'Age'}
                  name={'age'}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder='Enter age'
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={'Gender'}
                  name={'gender'}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    placeholder='Select Gender'
                    style={{ width: '100%' }}
                    options={[
                      { value: 'All', label: 'All' },
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                    ]}
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={'Days'}
                  name={'package_days'}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    className='has-prefix-icon'
                    placeholder={t('bookings.formFields.days.placeholder')}
                    options={week}
                    mode='multiple'
                    allowClear
                    size='large'
                    suffixIcon={
                      <>
                        <DownOutlined className='suffix-icon' />
                      </>
                    }
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <label className='lab_bx' htmlFor='Package Start Date & Time'>
                  Package Start Date & Time
                </label>
                <div className='pack_date'>
                  <Form.Item name='package_time_start'>
                    <DatePicker
                      disabled={
                        !(packageData.total_seat === packageData.available_seat)
                      }
                      disabledDate={disabledStartDate}
                      onChange={handleStartDateChange}
                      value={startDate}
                    />
                  </Form.Item>
                  <Form.Item name='packagestart_time'>
                    <TimePicker
                      disabled={
                        !(packageData.total_seat === packageData.available_seat)
                      }
                      use12Hours
                      format='h:mm a'
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <label className='lab_bx' htmlFor='Package End Date & Time'>
                  Package End Date & Time
                </label>
                <div className='pack_date'>
                  <Form.Item name='package_time_end'>
                    <DatePicker
                      disabled={
                        !(packageData.total_seat === packageData.available_seat)
                      }
                      disabledDate={disabledEndDate}
                      onChange={handleEndDateChange}
                      value={endDate}
                    />
                  </Form.Item>
                  <Form.Item name='packageend_time'>
                    <TimePicker
                      disabled={
                        !(packageData.total_seat === packageData.available_seat)
                      }
                      use12Hours
                      format='h:mm a'
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <label>Package Cancellation</label>
                <Form.Item name='is_user_can_cancel'>
                  <Radio.Group>
                    <Radio
                      disabled={
                        !(packageData.total_seat === packageData.available_seat)
                      }
                      value={true}
                    >
                      Yes
                    </Radio>
                    <Radio
                      disabled={
                        !(packageData.total_seat === packageData.available_seat)
                      }
                      value={false}
                    >
                      No
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item<FieldDetailsPackage>
                  label='About Package'
                  name={'about_package'}
                >
                  <TextArea
                    placeholder='Decribe Package'
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item<FieldDetailsPackage>
                  label='About Package Arabic'
                  name={'about_package_arabic'}
                >
                  <TextArea
                    placeholder='Decribe Package'
                    dir='rtl'
                    disabled={
                      !(packageData.total_seat === packageData.available_seat)
                    }
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t('fields.formFields.coverImage.label')}
                  getValueFromEvent={(event) => event?.fileList}
                >
                  {packageData.package_cover_image ? (
                    <div style={{ display: 'flex' }}>
                      <Image
                        style={{ borderRadius: '6px' }}
                        src={packageData.package_cover_image}
                        placeholder={true}
                        preview={false}
                      />
                      {packageData.total_seat &&
                        packageData.available_seat ===
                          packageData.total_seat && (
                          <StyledImageDeleteButton
                            danger
                            onClick={handleDeleteImage}
                          >
                            <DeleteOutlined />
                          </StyledImageDeleteButton>
                        )}
                    </div>
                  ) : (
                    <>
                      {fileList.length ? (
                        <Upload
                          {...uploadProps}
                          name='file'
                          accept='.png, .jpg, .jpeg'
                          maxCount={1}
                          listType='picture-card'
                          disabled={
                            !(
                              packageData.total_seat ===
                              packageData.available_seat
                            )
                          }
                        />
                      ) : (
                        <ImgCrop rotationSlider aspect={2}>
                          <Dragger
                            {...uploadProps}
                            multiple={false}
                            disabled={
                              !(
                                packageData.total_seat ===
                                packageData.available_seat
                              )
                            }
                          >
                            <p className='ant-upload-drag-icon'>
                              <InboxOutlined />
                            </p>
                            <p className='ant-upload-text'>
                              {t('upload.dragOrClick')}
                            </p>
                            <p className='ant-upload-hint'>
                              <Trans
                                i18nKey='upload.hint'
                                components={{ br: <br /> }}
                              />
                            </p>
                          </Dragger>
                        </ImgCrop>
                      )}
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  label={t('fields.formFields.gallery.label')}
                  getValueFromEvent={(event) => event?.fileList}
                >
                  <Image.PreviewGroup>
                    <ImageGroupWrapper>
                      {packageData?.package_image_list?.length
                        ? packageData.package_image_list.map(
                            (galleryImage: any, index: number) => (
                              <ImageWrapper key={index}>
                                <Image
                                  style={{
                                    borderRadius: '8px',
                                    objectFit: 'cover',
                                  }}
                                  key={index}
                                  width={102}
                                  height={102}
                                  src={galleryImage.image_url}
                                />
                                {packageData.total_seat &&
                                  packageData.available_seat ===
                                    packageData.total_seat && (
                                    <StyledImageDeleteButton
                                      danger
                                      onClick={() =>
                                        handleDeleteGalleryImage(
                                          galleryImage.image_url,
                                        )
                                      }
                                      className='hidden'
                                    >
                                      <DeleteOutlined />
                                    </StyledImageDeleteButton>
                                  )}
                              </ImageWrapper>
                            ),
                          )
                        : null}
                      <ImageWrapper key={'uploader'}>
                        <ImgCrop rotationSlider aspect={2}>
                          <Upload
                            {...uploadGalleryProps}
                            name='file'
                            accept='.png, .jpg, .jpeg'
                            maxCount={12}
                            listType='picture-card'
                            disabled={
                              !(
                                packageData.total_seat ===
                                packageData.available_seat
                              )
                            }
                          >
                            {getGalleryImagesLength + galleryFileList.length <
                              12 && '+ Upload'}
                          </Upload>
                        </ImgCrop>
                      </ImageWrapper>
                    </ImageGroupWrapper>
                  </Image.PreviewGroup>
                </Form.Item>
              </Col>
              {packageData.total_seat &&
                packageData.available_seat === packageData.total_seat && (
                  <Col span={24}>
                    <Button
                      disabled={disabled}
                      type='primary'
                      htmlType='submit'
                    >
                      Update Package
                    </Button>
                  </Col>
                )}
            </Row>
          </Form>
        </div>
      </div>

      <RouteLeavingGuard blocked={isFormDirty} />
    </>
  );
};
