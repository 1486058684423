import styled from 'styled-components';

export const StyledTopUpModalWrapper = styled.div`
  padding: 24px 0;
`;

export const StyledAdditionalInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;
`;

export const StyledLabel = styled.div`
  color: ${({ theme }) => theme.antd.colorTextDescription};
  font-size: 1.4rem;
  margin-bottom: 8px;
`;
