import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, TimePicker, Typography } from 'antd';
import { TFunction } from 'i18next';
import { getDisabledMinutes } from 'utils/date';

import { TimePriceModalFields } from './types';

const { Title } = Typography;

const { RangePicker } = TimePicker;

interface AllDaysTabProps {
  t: TFunction;
  timeSlot: string;
  // TODO: find the correct type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validator: (rule: any, value: Date[]) => void;
}

export const AllDaysTab = ({ t, timeSlot, validator }: AllDaysTabProps) => {
  return (
    <Form.List name={TimePriceModalFields.AllDays}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Card
              key={field.key}
              style={{ marginBottom: '10px' }}
              extra={
                index !== 0 && (
                  <CloseOutlined onClick={() => remove(field.name)} />
                )
              }
              title={`${t('fields.modal.titles.range')} ${index + 1}`}
            >
              <Title level={5}>{t('fields.modal.titles.defaultPrice')}</Title>
              <Form.Item<TimePriceModalFields>
                label={t('fields.formFields.timeRange.label')}
                name={[field.name, TimePriceModalFields.AllDaysRange]}
                rules={[
                  {
                    required: true,
                    validator,
                  },
                ]}
                shouldUpdate
                required
              >
                <RangePicker
                  format='h mm A'
                  style={{ width: '100%' }}
                  size='large'
                  showSecond={false}
                  hideDisabledOptions={true}
                  disabledTime={getDisabledMinutes}
                />
              </Form.Item>
              <Form.Item<TimePriceModalFields>
                label={
                  t('fields.formFields.slotPrice.label') + ` (${timeSlot} min)`
                }
                name={[field.name, TimePriceModalFields.AllDaysSlot]}
                rules={[
                  { required: true },
                  () => ({
                    validator(rule, value) {
                      if (value < 0 || value === '0') {
                        return Promise.reject('Please enter valid amount.'); // The validator should always return a promise on both success and error
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
                required
              >
                <Input
                  allowClear
                  size='large'
                  placeholder={t('fields.formFields.slotPrice.placeholder')}
                  prefix={'KD'}
                  type='number'
                  // min={1}
                  // defaultValue='1'
                />
              </Form.Item>
            </Card>
          ))}

          <Button
            type='dashed'
            onClick={() => add()}
            block
            disabled={fields?.length >= 10 || false}
          >
            {t('fields.buttons.addTimeRange')}
          </Button>
        </>
      )}
    </Form.List>
  );
};
