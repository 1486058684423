import { Form, Grid } from 'antd';
import { Select } from 'components';
import { StyledAdvancedFiltersWrapper } from 'components/content-filters/content-filters.styles';
import { FieldsSelect } from 'features/fields/components/fields-select/fields-select';
import { useTranslation } from 'react-i18next';
import { BookingType } from 'types';

import {
  RecurringBookingFilterFormField,
  RecurringBookingFilterFormValues,
} from './types';

const { useBreakpoint } = Grid;

type RecurringBookingAdvancedFilterFormProps = {
  openAdvancedFilters: boolean;
};

export const RecurringBookingAdvancedFilterForm = ({
  openAdvancedFilters,
}: RecurringBookingAdvancedFilterFormProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const bookingsTypeOptions = [
    {
      value: BookingType.Fixed,
      label: t('bookingTypes.fixed'),
    } as const,
    {
      value: BookingType.Maintenance,
      label: t('bookingTypes.maintenance'),
    } as const,
    {
      value: BookingType.Closed,
      label: t('bookingTypes.closed'),
    } as const,
  ];

  const renderFormInputs = (
    <>
      <Form.Item<RecurringBookingFilterFormValues>
        label={t('bookings.formFields.field.label')}
        name={RecurringBookingFilterFormField.Field}
      >
        <FieldsSelect className='filter' customParams={{ is_active: true }} />
      </Form.Item>
      <Form.Item<RecurringBookingFilterFormValues>
        label={t('bookings.formFields.bookings.label')}
        name={RecurringBookingFilterFormField.Bookings}
      >
        <Select
          placeholder={t('bookings.formFields.bookings.placeholder')}
          bordered
          allowClear
          options={bookingsTypeOptions}
          className='filter'
          dropdownMatchSelectWidth={200}
        />
      </Form.Item>
    </>
  );

  return (
    <>
      {screens.lg ? (
        <StyledAdvancedFiltersWrapper openAdvancedFilters={openAdvancedFilters}>
          {renderFormInputs}
        </StyledAdvancedFiltersWrapper>
      ) : (
        renderFormInputs
      )}
    </>
  );
};
