/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';
import dayjs from 'dayjs';
import { useDeleteTimeRange } from 'features/fields/use-cases';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AllDays, TimePrice } from 'types';

import { TimePriceModal } from './time-price-modal';
import {
  StyledWrapper,
  TimePriceButton,
  TimePriceInputChip,
  TimePriceInputWrapper,
} from './time-price-styles';
import { TimePriceModalFields } from './types';

type TimePriceInputProps = {
  value?: TimePrice;
  onChange?: (formData: AllDays) => void;
  error: boolean;
  timeSlot: string;
  openingHours: Date[];
};

type chipState = {
  label: string;
};

export const TimePriceInput = ({
  value = {
    [TimePriceModalFields.AllDays]: [],
    [TimePriceModalFields.ExceptionDays]: [],
    [TimePriceModalFields.ExceptionDaysList]: [],
  },
  onChange = () => {
    // alert('dddd');
    // default fallback function
  },
  error,
  timeSlot,
  openingHours,
}: TimePriceInputProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [btnLabel, setBtnLabel] = useState<string>(t('fields.buttons.add'));
  const [chips, setChips] = useState<chipState[] | undefined>(undefined);
  // const [exchips, setExchips] = useState<chipState[] | undefined>(undefined);
  // const [exchipDate, setExchipDate] = useState('');
  const [previousValues] = useState<any>(value);
  const { mutate } = useDeleteTimeRange();

  const handleChange = () => {
    {
      timeSlot && Object.keys(timeSlot)?.length > 0
        ? handleOpenModal()
        : message.error('Please select a Time slot size');
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  function findRemovedTimeRangeIds(defaultArray: any, modifiedArray: any) {
    const modifiedIds = new Set(
      modifiedArray.map((item: any) => item.timeRangeId),
    );
    const removedIds = defaultArray
      .filter((item: any) => !modifiedIds.has(item.timeRangeId))
      .map((item: any) => item.timeRangeId);

    return removedIds;
  }

  const handleSubmit = (formData: any) => {
    const removedIds = findRemovedTimeRangeIds(
      previousValues?.all_days,
      formData?.all_days,
    );

    if (removedIds?.length > 0) {
      removedIds?.map((id: string) => mutate(id));
    }

    const { exception_days_list: exceptionDay } = formData;
    const { exception_days_list: exceptionDayValue } = value;

    if (exceptionDay !== undefined && Object.keys(exceptionDay).length === 0) {
      delete formData['exception_days_list'];
      delete formData['exception_days'];
    } else if (
      !('exception_days_list' in formData) &&
      exceptionDayValue !== undefined &&
      Object.keys(exceptionDayValue).length !== 0
    ) {
      formData.exception_days = value.exception_days;
      formData.exception_days_list = value.exception_days_list;
    }

    if (formData) {
      onChange(formData);
      handleCloseModal();
    }
  };

  const updateChips = useCallback(() => {
    if (!value) return;
    const { all_days: daysRangeArr } = value;
    if (!daysRangeArr || !daysRangeArr.length) return;
    const chipsValues = daysRangeArr.map(
      ({ all_days_range, all_days_slot_price }) => ({
        label: `${dayjs(all_days_range[0]).format('hh:mm A')} - ${dayjs(
          all_days_range[1],
        ).format('hh:mm A')} | ${all_days_slot_price} KWD`,
      }),
    );

    // const { exception_days: exceptionDaysRange } = value;
    // const { exception_days_list: exceptionDaysList } = value;
    // if(exceptionDaysList){
    //   const exceptionDayList = `${dayjs(exceptionDaysList[0]).format('YYYY-MM-DD')} - ${dayjs(
    //     exceptionDaysList[1],).format('YYYY-MM-DD')}` ;
    //     setExchipDate(exceptionDayList);
    // }

    // if (exceptionDaysRange){
    //   const chipsExcepValues = exceptionDaysRange.map(
    //       ({ exception_days_range, exception_days_slot_price }) => ({
    //         label: `${dayjs(exception_days_range[0]).format('hh:mm A')} - ${dayjs(
    //           exception_days_range[1],
    //         ).format('hh:mm A')} | ${exception_days_slot_price} KWD`,
    //       }),
    //     );
    //     setExchips(chipsExcepValues);
    // }

    setChips(chipsValues);
  }, [value]);

  useEffect(() => {
    updateChips();
    if (value && value?.all_days?.length > 0) {
      setBtnLabel(t('fields.buttons.edit'));
    }
  }, [value, updateChips, t]);

  return (
    <>
      <StyledWrapper>
        <TimePriceInputWrapper error={error}>
          {chips && chips?.length > 0
            ? chips.map((chip: chipState) => (
                <TimePriceInputChip key={chip?.label}>
                  {chip?.label}
                </TimePriceInputChip>
              ))
            : null}
        </TimePriceInputWrapper>

        <TimePriceButton type='primary' onClick={handleChange}>
          {btnLabel}
        </TimePriceButton>
      </StyledWrapper>

      {isModalOpen && (
        <TimePriceModal
          closeModal={handleCloseModal}
          handleSubmit={handleSubmit}
          timeSlot={timeSlot}
          defaultValue={value}
          openingHours={openingHours}
          t={t}
        />
      )}
    </>
  );
};
