import { SelectProps, Spin } from 'antd';
import { Select } from 'components';
import { FieldsParams, useGetFieldsInfiniteQuery } from 'features/fields';
import { usePaginationParams } from 'hooks';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Field } from 'types';

const initialParams = {
  page: 1,
  size: 10,
  order_by: 'name',
};

type FieldsSelectProps = {
  customParams?: FieldsParams;
};

export const FieldsSelect = ({
  customParams,
  ...props
}: SelectProps & FieldsSelectProps) => {
  const { t } = useTranslation();

  const { params, setParams } =
    usePaginationParams<FieldsParams>(initialParams);

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetFieldsInfiniteQuery(
      { ...params, ...customParams },
      {
        keepPreviousData: true,
        cacheTime: 2000,
      },
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    debounce(
      (fieldName?: string) =>
        setParams((prevParams) => ({
          ...prevParams,
          ...{ field_name: fieldName },
        })),
      500,
    ),
    [],
  );

  const handleClear = () => setParams(initialParams);

  const getOptions = () => {
    const options = data?.pages;

    return Array.isArray(options) ? options : [];
  };

  return (
    <Select<Field>
      placeholder={t('fields.formFields.field.placeholder')}
      onSearch={debouncedOnSearch}
      onClear={handleClear}
      loading={isFetching}
      dropdownMatchSelectWidth={200}
      notFoundContent={
        isFetching ? (
          <Spin
            size='small'
            aria-label={t('fields.formFields.field.loading')}
          />
        ) : null
      }
      options={getOptions()
        .map((page) =>
          page.items.map((fieldItem) => ({
            value: fieldItem.id,
            label: fieldItem.name,
          })),
        )
        .flat()}
      onPopupScroll={(e) => {
        if (!e.target) return;

        const target = e.target as HTMLDivElement;
        const isScrolledToBottom =
          target.scrollTop + target.offsetHeight === target.scrollHeight;

        if (!isScrolledToBottom) return;

        if (hasNextPage || !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
      allowClear
      {...props}
    />
  );
};
