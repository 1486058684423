import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { uniq } from 'lodash';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BookingType, Facility, PaymentMethod } from 'types';
import { Weekdays } from 'types/days';

export const daysOptions = [
  {
    value: Weekdays.Sunday,
    label: t('weekday.sunday'),
  } as const,
  {
    value: Weekdays.Monday,
    label: t('weekday.monday'),
  } as const,
  {
    value: Weekdays.Tuesday,
    label: t('weekday.tuesday'),
  } as const,
  {
    value: Weekdays.Wednesday,
    label: t('weekday.wednesday'),
  } as const,
  {
    value: Weekdays.Thursday,
    label: t('weekday.thursday'),
  } as const,
  {
    value: Weekdays.Friday,
    label: t('weekday.friday'),
  } as const,
  {
    value: Weekdays.Saturday,
    label: t('weekday.saturday'),
  } as const,
];

export const typeOptions = [
  {
    value: BookingType.Fixed,
    label: t('bookingTypes.fixed'),
  } as const,
  {
    value: BookingType.Closed,
    label: t('bookingTypes.closed'),
  } as const,
  {
    value: BookingType.Maintenance,
    label: t('bookingTypes.maintenance'),
  } as const,
];

export const paymentOptions = [
  {
    value: PaymentMethod.Cash,
    label: t('paymentMethods.cash'),
  } as const,
];

export const getDaysOfWeekBetweenDates = (
  sDate?: string | Dayjs,
  eDate?: string | Dayjs,
) => {
  let startDate = dayjs(sDate);
  const endDate = dayjs(eDate).add(1, 'day');

  const daysOfWeek = [];

  const difference = dayjs(endDate).diff(startDate, 'day');

  let i = 0;

  while (i < difference && startDate < endDate) {
    daysOfWeek.push(startDate.day());
    startDate = startDate.add(1, 'day');
    i++;
  }

  return uniq(daysOfWeek).sort();
};

export const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }

  return result;
};
// TODO: rewrite this function with the new time ranges
/* export const getFacilityDisabledTimeRange = (facilityData?: Facility) => {
  if (facilityData) {
    const openFromHour = Number(facilityData?.open_from.slice(0, 2));
    const openUntilHour = Number(facilityData?.open_to.slice(0, 2));

    return [...range(0, openFromHour), ...range(openUntilHour, 24)];
  }

  return range(0, 24);
}; */

export const getDaysOptions = (
  date: [Dayjs | undefined, Dayjs | undefined] | string | null,
) => {
  if (date)
    return getDaysOfWeekBetweenDates(date[0], date[1])?.map(
      (daysOfWeek: number) => daysOptions[daysOfWeek],
    );
};
