import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AreasApiService,
  AreasParams,
  AreasResponse,
} from 'features/areas/api/areas-api.service';

import { areasQueryKeys } from './query-keys';

const { getAreas } = AreasApiService();
export const useGetAreasInfiniteQuery = (
  params: AreasParams,
  options?: UseInfiniteQueryOptions<AreasResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: areasQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getAreas(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
