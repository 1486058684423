export enum ProfileField {
  FirstName = 'first_name',
  LastName = 'last_name',
  Email = 'email',
  PhoneNumber = 'phone_number',
  Company = 'company',
}

export type ProfileFormValues = {
  [ProfileField.FirstName]: string;
  [ProfileField.LastName]: string;
  [ProfileField.Email]: string;
  [ProfileField.PhoneNumber]: string;
  [ProfileField.Company]: string;
};
