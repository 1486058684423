import {
  ApartmentOutlined,
  DownOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  SmileOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, FormInstance, Input } from 'antd';
import { Select } from 'components';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import {
  UserFormField,
  UserFormFieldData,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useCompanyOptions } from 'features/users/hooks/use-company-options';
import { useGenderOptions } from 'features/users/hooks/use-gender-options';
import { useRoleOptions } from 'features/users/hooks/use-role-options';
import { useTranslation } from 'react-i18next';
import { phoneNumberPattern } from 'utils/validations';

export const UserInfoFormItems = ({
  form,
}: {
  form: FormInstance<UserFormValues>;
}) => {
  const { t } = useTranslation();
  const companyOptions = useCompanyOptions();
  const roleOptions = useRoleOptions(
    Form.useWatch(UserFormField.Company, form),
  );
  const genderOptions = useGenderOptions();

  return (
    <FormSectionsSeparator title={t('adminUsers.userInfo')}>
      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.company.label')}
        name={UserFormField.Company}
        rules={[
          {
            required: true,
            whitespace: false,
            validator: (_, value) => {
              if (value === undefined) {
                return Promise.reject(t('validateMessages.required'));
              }

              return Promise.resolve();
            },
          },
        ]}
      >
        <Select
          onChange={() => form.setFieldValue(UserFormField.Role, null)}
          options={companyOptions}
          defaultValue={!UserFormFieldData.user ?? 'Li3ib'}
          placeholder={t('adminUsers.formFields.company.placeholder')}
          className='has-prefix-icon'
          suffixIcon={
            <>
              <ApartmentOutlined className='prefix-icon' />
              <DownOutlined className='suffix-icon' />
            </>
          }
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.role.label')}
        name={UserFormField.Role}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Select
          options={roleOptions}
          placeholder={t('adminUsers.formFields.role.placeholder')}
          className='has-prefix-icon'
          suffixIcon={
            <>
              <IdcardOutlined className='prefix-icon' />
              <DownOutlined className='suffix-icon' />
            </>
          }
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.firstName.label')}
        name={UserFormField.FirstName}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('adminUsers.formFields.firstName.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
        />
      </Form.Item>
      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.lastName.label')}
        name={UserFormField.LastName}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('adminUsers.formFields.lastName.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.email.label')}
        name={UserFormField.Email}
        rules={[
          {
            required: false,
            whitespace: true,
          },
          {
            type: 'email',
            message: t('validateMessages.custom.email'),
          },
        ]}
      >
        <Input
          placeholder={t('adminUsers.formFields.email.placeholder')}
          prefix={<MailOutlined className='input-prefix-icon' />}
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.phoneNumber.label')}
        name={UserFormField.PhoneNumber}
        rules={[
          {
            required: true,
          },
          {
            pattern: phoneNumberPattern,
            message: t('validateMessages.custom.phoneNumber'),
          },
        ]}
      >
        <Input
          type='tel'
          placeholder={t('adminUsers.formFields.phoneNumber.placeholder')}
          prefix={<PhoneOutlined className='input-prefix-icon' />}
          maxLength={17}
          onKeyPress={(e) => {
            // Check if the entered key is a number, "+", or Backspace (to allow deletion)
            const isNumberOrPlus = /^[0-9+]*$/.test(e.key);
            const isBackspace = e.key === 'Backspace';

            // Check if the total length after the new character insertion will be <= 15
            const inputValue = (e.target as HTMLInputElement).value || '';
            const totalLength = inputValue.length + (isBackspace ? -1 : 1);

            if (!isNumberOrPlus || totalLength > 17) {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>

      <Form.Item<UserFormField>
        label={t('adminUsers.formFields.gender.label')}
        name={UserFormField.Gender}
        rules={[
          {
            required: false,
            whitespace: true,
          },
        ]}
      >
        <Select
          options={genderOptions}
          placeholder={t('adminUsers.formFields.gender.placeholder')}
          className='has-prefix-icon'
          suffixIcon={
            <>
              <SmileOutlined className='prefix-icon' />
              <DownOutlined className='suffix-icon' />
            </>
          }
        />
      </Form.Item>
    </FormSectionsSeparator>
  );
};
