import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {
  AccessTokenExpiredEvent,
  AuthApiService,
  AuthStorageKey,
  getAccessToken,
  setAccessToken,
  setRefreshToken,
} from 'features/auth';

const axiosInstance = axios.create({
  withCredentials: true,
});

axiosInstance.interceptors.request.use((request) => {
  if (request.headers) {
    const accessToken = getAccessToken();

    if (accessToken) {
      request.headers['Authorization'] = `Bearer ${accessToken}`;
    }
  }

  return request;
});

createAuthRefreshInterceptor(axiosInstance, async () => {
  const persistedRefreshToken = localStorage.getItem(
    AuthStorageKey.RefreshToken,
  );
  const sessionRefreshToken = sessionStorage.getItem(
    AuthStorageKey.RefreshToken,
  );
  const token = persistedRefreshToken || sessionRefreshToken;

  try {
    if (!token) {
      throw new Error('Refresh token is missing');
    }

    const response = await AuthApiService().refreshToken({
      grant_type: 'refresh_token',
      token,
    });

    // if the token was previously persisted in local storage, store the new tokens the same way
    setAccessToken(response.access_token, !!persistedRefreshToken);
    setRefreshToken(response.refresh_token, !!persistedRefreshToken);

    // retry the request with new setup
    return Promise.resolve();
  } catch (refreshError) {
    window.dispatchEvent(new AccessTokenExpiredEvent());
  }
});

export { axiosInstance };
