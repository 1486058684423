import dayjs from 'dayjs';
// import tz from 'dayjs/plugin/timezone';
// import isBetween from  'dayjs/plugin/isBetween';
// import isSameOrAfter from  'dayjs/plugin/isSameOrAfter';
// import isSameOrBefore from  'dayjs/plugin/isSameOrBefore';
import { FieldBooking, FieldWithBookings } from 'features/fields';
import range from 'lodash/range';
import { BookingType, CalendarSlot, Time } from 'types';
import {
  DEFAULT_TIMEZONE,
  formatDate,
  //, getDuration
} from 'utils/date';

import { AvailableSlotData } from './available-time-slot';
import { BookingSlotData } from './booking-time-slot';
import { ClosedSlotData } from './closed-time-slot';
import { EmptySlotData } from './empty-time-slot';
import { MaintenanceSlotData } from './maintenance-time-slot';
import { SelectedSlots } from './timetable-content';

// dayjs.extend(tz)
// dayjs.extend(isBetween)
// dayjs.extend(isSameOrAfter)
// dayjs.extend(isSameOrBefore)
// anil
export const getTimelineHours = (fields: FieldWithBookings[]) => {
  const fieldOpenedEarliest = fields.reduce((prev, curr) =>
    prev.open_from < curr.open_from ? prev : curr,
  );
  const fieldClosedLatest = fields.reduce((prev, curr) =>
    prev.open_to > curr.open_to ? prev : curr,
  );

  return {
    startTime: fieldOpenedEarliest.open_from,
    endTime: fieldClosedLatest.open_to,
  };
};

/**
 * @returns array of hour indexes based on `HH:mm:ss` start and end times
 */
export const getTimeRange = ({
  startTime,
  endTime,
}: {
  startTime: string;
  endTime: string;
}) => {
  const [startHour] = (startTime ?? '').split(':');
  const [endHour, endMinutes] = (endTime ?? '').split(':');
  const endHourNumber = Number(endHour);
  const roundedEndHour =
    Number(endMinutes) > 0 ? endHourNumber + 1 : endHourNumber;

  return range(Number(startHour), roundedEndHour);
};

export const getFieldData = (item: FieldWithBookings) => {
  const { bookings, facility, ...rest } = item;

  return rest;
};

export const getBookedTimeSlotProps = (
  data: CalendarSlot,
): BookingSlotData | ClosedSlotData | MaintenanceSlotData => {
  if (
    // (data.Booked && data.BookingInfo.Type === null && data.BookingInfo.Id === "00000000-0000-0000-0000-000000000000") ||
    data.BookingInfo.Type === BookingType.Maintenance ||
    data.BookingInfo.Type === BookingType.Closed
  ) {
    return {
      id: data.BookingInfo.Id,
      type: data.BookingInfo.Type,

      // type: ( data.BookingInfo.Type === null ? BookingType.Closed:data.BookingInfo.Type ),//data.BookingInfo.Type,
      startDate: data.StartTime.split('+')[0],
      endDate: data.EndTime.split('+')[0],
    };
  }

  return {
    id: data.BookingInfo?.Id,
    type: data.BookingInfo?.Type,
    price: data?.TransactionInfo?.TotalCost - data?.TransactionInfo?.Discount,
    currency: data.TransactionInfo?.Currency,
    discount: data.TransactionInfo?.Discount,
    startDate: data.StartTime?.split('+')[0],
    endDate: data.EndTime?.split('+')[0],
    firstName: data.BookingInfo?.FirstName,
    lastName: data.BookingInfo?.LastName,
    source: data.BookingInfo?.Source,
    paymentMethod: data.TransactionInfo?.PaymentMethod,
  };
};

export const getBookedTimeSlotPropsOld = (
  data: FieldBooking,
): BookingSlotData | ClosedSlotData | MaintenanceSlotData => {
  if (
    data.type === BookingType.Maintenance ||
    data.type === BookingType.Closed
  ) {
    return {
      id: data.id,
      type: data.type,
      startDate: formatDate({
        date: data.start_date,
        format: 'dbDate',
        timeZone: DEFAULT_TIMEZONE,
      }),
      endDate: formatDate({
        date: data.end_date,
        format: 'dbDate',
        timeZone: DEFAULT_TIMEZONE,
      }),
    };
  }

  return {
    id: data.id,
    type: data.type,
    price: data.transaction[0].total_cost - data.transaction[0].discount,
    currency: data.transaction[0].currency,
    discount: data.transaction[0].discount,
    startDate: formatDate({
      date: data.start_date,
      format: 'dbDate',
      timeZone: DEFAULT_TIMEZONE,
    }),
    endDate: formatDate({
      date: data.end_date,
      format: 'dbDate',
      timeZone: DEFAULT_TIMEZONE,
    }),
    firstName: data.client.first_name,
    lastName: data.client.last_name,
    source: data.source,
    paymentMethod: data.transaction[0].payment_method,
  };
};

export type TimelineHours = { startTime: Time; endTime: Time };
export type Slot =
  | AvailableSlotData
  | EmptySlotData
  | BookingSlotData
  | ClosedSlotData
  | MaintenanceSlotData;

// /**
//  * Find the closest 30-minute slot for the given slot.
//  */
// const findClosest30MinuteSlot = (slot: any, thirtyMinuteSlots: any[]): any => {
//   let closestSlot: Slot | null = null;
//   let smallestDiff = Infinity;

//   for (const thirtyMinuteSlot of thirtyMinuteSlots) {
//     const startDiff = Math.abs(dayjs(thirtyMinuteSlot.startTime).diff(dayjs(slot.startTime), 'minutes'));
//     const endDiff = Math.abs(dayjs(thirtyMinuteSlot.endTime).diff(dayjs(slot.endTime), 'minutes'));
//     const totalDiff = Math.max(startDiff, endDiff);

//     if (totalDiff < smallestDiff) {
//       smallestDiff = totalDiff;
//       closestSlot = thirtyMinuteSlot;
//     }
//   }

//   return closestSlot || null;
// };

// /**
//  * Match and separate slots from the two given lists.
//  */
// const matchSlots = (thirtyMinuteSlots: any[], ninetyMinuteSlots: any[]): [any[], any[]] => {
//   const matchedSlots: any[] = [];
//   const unmatchedThirtyMinuteSlots: any[] = [];

//   for (let ninetyMinuteSlot of ninetyMinuteSlots) {

//     ninetyMinuteSlot = {
//       "startTime": ninetyMinuteSlot.StartTime.split("+")[0],
//       "endTime": ninetyMinuteSlot.EndTime.split("+")[0]
//     };

//     const closestSlot = findClosest30MinuteSlot(ninetyMinuteSlot, thirtyMinuteSlots);

//     if (closestSlot && closestSlot.startTime === ninetyMinuteSlot.startTime && closestSlot.endTime === ninetyMinuteSlot.endTime) {
//       matchedSlots.push(closestSlot);
//     } else {
//       unmatchedThirtyMinuteSlots.push(closestSlot || thirtyMinuteSlots.find((slot) => slot.startTime === ninetyMinuteSlot.startTime || slot.endTime === ninetyMinuteSlot.endTime) || thirtyMinuteSlots[0]);
//     }
//   }

//   return [matchedSlots, unmatchedThirtyMinuteSlots];
// };
/**
 * Generates array of items to be displayed within a given time period
 */
export const generateSlots = (
  field: FieldWithBookings,
  chosenDate: string,
  timelineHours: TimelineHours,
) => {
  const {
    // bookings,

    time_slot: timeSlotSize,
    // time_ranges: time_rangess,
    // exception_ranges: exception_rangess,
    field_Slots: fieldSlotsList,
  } = field;

  //exception_rangess: exception_rangess

  // const timeRange: any = [];
  // time_rangess.forEach((val: any) => {
  //   timeRange.push({
  //     openFrom: val.start_time,
  //     openTo: val.end_time,
  //     price: val.slot_price,
  //   });
  // });

  //   exception_rangess.forEach((item: any) => {
  // if( item.start_date && item.end_date){
  //     item.time_ranges.forEach((element: any) => {
  //       timeRange.forEach((Time: any) => {
  //         if (Time.start_time === element.start_time && Time.end_time === element.end_time) {
  //                     Time.price = element.slot_price;
  //         }
  //       });
  //     });
  //   }
  //   })

  // if (secondRangeStartTime !== null) {
  //   timeRange.push({
  //     openFrom: secondRangeStartTime,
  //     openTo: secondRangeEndTime,
  //     price: secondRangePrice,
  //   });
  // }

  // Example usage:

  const slots: Slot[] = [];

  fieldSlotsList?.forEach((dateSlots) => {
    const slotsDateOnly = dateSlots.Date.split('T')[0]; //dateSlots.Date.substring(0,dateSlots.Date.indexOf("T"));

    if (chosenDate === slotsDateOnly) {
      // alert(timeSlotSize);
      const slotSize = Number(timeSlotSize);

      const timeLineSlots = generateMinutesDurationTimeSlots(
        slotsDateOnly,
        slotSize,
        timelineHours,
        dateSlots.Slots,
      );

      timeLineSlots.forEach((staticTimeSlot) => {
        const matchedSlotIndex = getAPISlotByTime(
          staticTimeSlot,
          dateSlots.Slots,
        );

        if (matchedSlotIndex > -1) {
          const slot = dateSlots.Slots[matchedSlotIndex];

          if (
            slot.Booked &&
            slot.BookingInfo.Type !== null &&
            slot.BookingInfo.Id !== '00000000-0000-0000-0000-000000000000'
          ) {
            const bookingData = getBookedTimeSlotProps(slot);

            slots.push(bookingData);
          } else {
            slots.push({
              type: 'available',
              startDate: slot.StartTime,
              endDate: slot.EndTime,
              price: slot.Price,
            });
          }
        } else {
          slots.push({
            type: 'empty',
            startDate: staticTimeSlot.startTime,
            endDate: staticTimeSlot.endTime,
          });
        }
      });
    }
  });

  // for (let i = 0; i < timeRange.length; i++) {
  //   const openFromDate = dayjs.tz(
  //     `${chosenDate}T${timeRange[i]?.openFrom}`,
  //     DEFAULT_TIMEZONE,
  //   );
  //   const openToDate = dayjs.tz(
  //     `${chosenDate}T${timeRange[i]?.openTo}`,
  //     DEFAULT_TIMEZONE,
  //   );

  //   let slotStartDate = openFromDate;
  //   const slotSize = Number(timeSlotSize);

  //   const getBookingByStartDate = (startDate: dayjs.Dayjs) => {
  //     return bookings.find((item) =>
  //       startDate.isSame(dayjs(item.start_date).tz(DEFAULT_TIMEZONE), 'minute'),
  //     );
  //   };

  //   while (slotStartDate.isBefore(openToDate)) {
  //     const booking = getBookingByStartDate(slotStartDate);
  //     if (booking) {
  //       const bookingData = getBookedTimeSlotProps(booking);
  //       const duration = getDuration(s
  //         bookingData.startDate,
  //         bookingData.endDate,
  //       );
  //       slots.push(bookingData);
  //       slotStartDate = slotStartDate.add(duration, 'minute');
  //     } else {
  //       const slotEndDate = slotStartDate.add(slotSize, 'minute');
  //       if (!slotEndDate.isAfter(openToDate)) {
  //         slots.push({
  //           type: 'available',
  //           startDate: slotStartDate.format(),
  //           endDate: slotEndDate.format(),
  //           price: timeRange[i]?.price,
  //         });
  //       } else if (openToDate.toString().includes('59')) {
  //         slots.push({
  //           type: 'available',
  //           startDate: slotStartDate.format(),
  //           endDate: slotEndDate.subtract(1, 'minute').format(),
  //           price: timeRange[i]?.price,
  //         });
  //       }

  //       slotStartDate = slotEndDate;
  //     }
  // }

  //   if (i === 0) {
  //     if (timelineStartDate.isBefore(openFromDate)) {
  //       slots.unshift({
  //         type: 'empty',
  //         startDate: timelineStartDate.format(),
  //         endDate: openFromDate.format(),
  //       });
  //     }

  //     const rangeDuration = openToDate.diff(openFromDate, 'minute');
  //     if (rangeDuration % slotSize !== 0) {
  //       slots.push({
  //         type: 'empty',
  //         startDate: dayjs(slots?.[slots.length - 1]?.endDate).format(),
  //         endDate: openToDate.format(),
  //       });
  //     }
  //   }

  //   if (timeRange[i + 1]) {
  //     const nextRangeStartDate = dayjs.tz(
  //       `${chosenDate}T${timeRange[i + 1]?.openFrom}`,
  //       DEFAULT_TIMEZONE,
  //     );

  //     slots.push({
  //       type: 'empty',
  //       startDate: openToDate.format(),
  //       endDate: nextRangeStartDate.format(),
  //     });
  //   }

  //   if (i === timeRange.length - 1) {
  //     if (timelineEndDate.isAfter(openToDate)) {
  //       slots.push({
  //         type: 'empty',
  //         startDate: openToDate.format(),
  //         endDate: timelineEndDate.format(),
  //       });
  //     }
  //   }
  // }
  // // // time_rangess?.forEach((timeRanges, i) => {
  // // //   // {"start_time":"01:00:00","end_time":"02:30:00","slot_price":12}
  // // //   const openFromDate = dayjs.tz(
  // // //     `${chosenDate}T${timeRanges.start_time}`,
  // // //     DEFAULT_TIMEZONE,
  // // //   );
  // // //   const openToDate = dayjs.tz(
  // // //     `${chosenDate}T${timeRanges.end_time}`,
  // // //     DEFAULT_TIMEZONE,
  // // //   );

  // // //   let slotStartDate = openFromDate;
  // // //   const slotSize = Number(timeSlotSize);

  // // //   const getBookingByStartDate = (startDate: dayjs.Dayjs) => {
  // // //     return bookings.find((item) =>
  // // //       startDate.isSame(dayjs(item.start_date).tz(DEFAULT_TIMEZONE), 'minute'),
  // // //     );
  // // //   };

  // // //   while (slotStartDate.isBefore(openToDate)) {
  // // //     const booking = getBookingByStartDate(slotStartDate);
  // // //     if (booking) {
  // // //       const bookingData = getBookedTimeSlotProps(booking);
  // // //       const duration = getDuration(
  // // //         bookingData.startDate,
  // // //         bookingData.endDate,
  // // //       );

  // // //       slots.push(bookingData);
  // // //       slotStartDate = slotStartDate.add(duration, 'minute');
  // // //     } else {
  // // //       const slotEndDate = slotStartDate.add(slotSize, 'minute');
  // // //       if (!slotEndDate.isAfter(openToDate)) {
  // // //         slots.push({
  // // //           type: 'available',
  // // //           startDate: slotStartDate.format(),
  // // //           endDate: slotEndDate.format(),
  // // //           price: timeRanges.slot_price,
  // // //         });
  // // //       } else if (openToDate.toString().includes('59')) {
  // // //         slots.push({
  // // //           type: 'available',
  // // //           startDate: slotStartDate.format(),
  // // //           endDate: slotEndDate.subtract(1, 'minute').format(),
  // // //           price: timeRanges.slot_price,
  // // //         });
  // // //       }
  // // //       slotStartDate = slotEndDate;
  // // //     }
  // // //   }

  // // //   const timelineStartDate = dayjs.tz(
  // // //     `${chosenDate}T${timelineHours.startTime}`,
  // // //     DEFAULT_TIMEZONE,
  // // //   );

  // // //   const timelineEndDate = dayjs.tz(
  // // //     `${chosenDate}T${timelineHours.endTime}`,
  // // //     DEFAULT_TIMEZONE,
  // // //   );

  // // //   if (i === 0) {
  // // //     if (timelineStartDate.isBefore(openFromDate)) {
  // // //       slots.unshift({
  // // //         type: 'empty',
  // // //         startDate: timelineStartDate.format(),
  // // //         endDate: openFromDate.format(),
  // // //       });
  // // //     }
  // // //     const rangeDuration = openToDate.diff(openFromDate, 'minute');
  // // //     if (rangeDuration % slotSize !== 0) {
  // // //       slots.push({
  // // //         type: 'empty',
  // // //         startDate: dayjs(slots?.[slots.length - 1]?.endDate).format(),
  // // //         endDate: openToDate.format(),
  // // //       });
  // // //     }
  // // //   }

  // // //   //SSSS
  // // //   if (time_rangess[i + 1]) {
  // // //     const nextRangeStartDate = dayjs.tz(
  // // //       `${chosenDate}T${time_rangess[i + 1]?.start_time}`,
  // // //       DEFAULT_TIMEZONE,
  // // //     );

  // // //     slots.push({
  // // //       type: 'empty',
  // // //       startDate: openToDate.format(),
  // // //       endDate: nextRangeStartDate.format(),
  // // //     });
  // // //   }

  // // //   if (i === time_rangess.length - 1) {
  // // //     if (timelineEndDate.isAfter(openToDate)) {
  // // //       slots.push({
  // // //         type: 'empty',
  // // //         startDate: openToDate.format(),
  // // //         endDate: timelineEndDate.format(),
  // // //       });
  // // //     }
  // // //   }
  // // // });

  // // // // dayjs.extend(tz)
  // // // /* Check the slots for exceptional prices...*/
  // // // const requestedDate = //`${chosenDate}T00:00:00`; // We only need to check on full days
  // // //   new Date(`${chosenDate}`);
  // // // //dayjs(`${chosenDate}`).tz(DEFAULT_TIMEZONE, true);

  // // // // dayjs(`${chosenDate}T00:00:00`);
  // // // // dayjs.tz(`${chosenDate}`, DEFAULT_TIMEZONE); // '2013-11-18T11:55:20-05:00'

  // // // // //const openFromDate23 = dayjs.tz(`2013-11-18T11:55:20`,DEFAULT_TIMEZONE,);

  // // // // // dayjs.extend(utc)
  // // // // dayjs.extend(tz)

  // // // // dayjs.tz("2014-06-01 12:00", "America/New_York")

  // // // // dayjs("2014-06-01 12:00").tz("America/New_York")

  // // // // const timeZone = dayjs.tz.guess()

  // // // // const openFromDate = dayjs.tz(
  // // // //   `${chosenDate}T${timeRanges.start_time}`,
  // // // //   DEFAULT_TIMEZONE,
  // // // // );

  // // // // const openToDate = dayjs.tz(
  // // // //   `${chosenDate}T${timeRanges.end_time}`,
  // // // //   DEFAULT_TIMEZONE,
  // // // // );

  // // // // dayjs.tz(
  // // // //   `${chosenDate}T00:00:00`,
  // // // //   DEFAULT_TIMEZONE,
  // // // // );

  // // // slots.forEach((slot: any) => {
  // // //   if (slot.type === 'available') {
  // // //     // // slot.startDate = requestedDate.add(parseTime(slot.startDate),
  // // //     // // const  startDateMoment = dayjs(`${requestedDate.format('YYYY-MM-DD'))
  // // //     // // const sSlotTime = dayjs(`${slot.startDate}`).format('HH:mm:ss');
  // // //     // // const eSlotTime = dayjs(`${slot.endDate}`).format('HH:mm:ss');
  // // //     // const sSlotDate = dayjs(`${slot.startDate}`);
  // // //     // const eSlotDate = dayjs(`${slot.endDate}`);

  // // //     // const startDateWithoutOffset = slot.startDate;//.substr(0,19);
  // // //     // const endDateWithoutOffset = slot.endDate;//.substr(0,19);
  // // //     // // const date  = new Date(startDateWithoutOffset);//new Date(slot.startDate);

  // // //     const sSlotDate = parseDateTimeOffset(slot.startDate); //dayjs(`${startDateWithoutOffset}`);//,DEFAULT_TIMEZONE
  // // //     const eSlotDate = parseDateTimeOffset(slot.endDate); //dayjs(`${endDateWithoutOffset}`);//,DEFAULT_TIMEZONE
  // // //     // const sSlotDate = dayjs.tz(`${slot.startDate}`,DEFAULT_TIMEZONE,);
  // // //     // const eSlotDate = dayjs.tz(`${slot.endDate}`,DEFAULT_TIMEZONE,);

  // // //     // const sSlotTime = slot.startDate.substr(11,8);
  // // //     // const eSlotTime = slot.endDate.substr(11,8);

  // // //     exception_rangess?.forEach((exceptionDay: any) => {
  // // //       const sExceptionDate = new Date(`${exceptionDay.start_date}`); //dayjs(`${exceptionDay.start_date}`);
  // // //       const eExceptionDate = new Date(`${exceptionDay.end_date}`); //dayjs(`${exceptionDay.end_date}`).add(23, 'hours').add(59, 'minutes').add(59, 'seconds');

  // // //       /* Verify if the requested date falls under the exceptional dates..*/
  // // //       if (
  // // //         sExceptionDate <= requestedDate &&
  // // //         eExceptionDate >= requestedDate
  // // //       ) {
  // // //         exceptionDay.time_ranges.forEach((element: any) => {
  // // //           const exceptionDayStartTime = new Date(
  // // //             `${chosenDate}T${element.start_time}`,
  // // //           ); // dayjs(`${chosenDate}${ element.start_time}`).tz(DEFAULT_TIMEZONE, true);
  // // //           const exceptionDayEndTime = new Date(
  // // //             `${chosenDate}T${element.end_time}`,
  // // //           ); //  dayjs(`${chosenDate}${ element.end_time}`).tz(DEFAULT_TIMEZONE, true);
  // // //           //       // dayjs.extend(isBetween)
  // // //           if (
  // // //             isWithinDatetimeRange(
  // // //               sSlotDate,
  // // //               exceptionDayStartTime,
  // // //               exceptionDayEndTime,
  // // //             ) &&
  // // //             isWithinDatetimeRange(
  // // //               eSlotDate,
  // // //               exceptionDayStartTime,
  // // //               exceptionDayEndTime,
  // // //             )
  // // //             // sSlotDate.isBetween(exceptionDayStartTime, exceptionDayEndTime, 'm')
  // // //             // // (exceptionDayStartTime.isBefore(sSlotDate) || exceptionDayStartTime.isSame(sSlotDate) )
  // // //             // &&
  // // //             // // exceptionDayStartTime <= sSlotDate &&

  // // //             // eSlotDate.isBetween(exceptionDayStartTime, exceptionDayEndTime, 'm')
  // // //             // // (exceptionDayEndTime.isAfter(eSlotDate) || exceptionDayEndTime.isSame(eSlotDate) )
  // // //             // // exceptionDayEndTime >= eSlotDate
  // // //           ) {
  // // //             // if (
  // // //             //   element.start_time <= sSlotDate &&
  // // //             //   element.end_time >= eSlotDate
  // // //             // ) {
  // // //             slot.price = element.slot_price;
  // // //           }
  // // //         });
  // // //       }
  // // //     });
  // // //   }
  // // // });

  // // // //
  //console.clear();

  /* Convert Continuous Booked Slots of a single Booking Transaction into a single Slot... */
  let lastBookingId: string;
  lastBookingId = '';
  const itemIndexesToRemove: number[] = [];

  for (let i = 0; i < slots.length; i++) {
    // AvailableSlotData
    // | EmptySlotData
    // | BookingSlotData
    // | ClosedSlotData
    // | MaintenanceSlotData

    const slot = slots[i];

    // Type guard to check if the value is TypeA
    const isTypeBookingSlotData = (val: Slot): val is BookingSlotData => {
      return (val as BookingSlotData).id !== undefined;
    };

    if (isTypeBookingSlotData(slot)) {
      if (slot.id !== lastBookingId) {
        const [_firstMatchedSlot, _lastMatchedSlot] = findFirstAndLastMatching(
          slots,
          slot.id,
        );
        slot.startDate = _firstMatchedSlot.startDate;
        slot.endDate = _lastMatchedSlot.endDate;
        slots[i] = slot;
        lastBookingId = slot.id;
      } else {
        itemIndexesToRemove.push(i);
      }
    }
  }

  /* Remove duplicate items from array... */

  let itemsRemoved: number;
  itemsRemoved = 0;
  itemIndexesToRemove.forEach((element) => {
    element = element - itemsRemoved;
    slots.splice(element, 1);
    itemsRemoved += +1;
  });

  // console.log(slots)

  return slots;
};

// // Function to check if a date is within the range
// const isWithinRange = (date: dayjs.ConfigType, start: dayjs.Dayjs, end: dayjs.Dayjs) => {
//   const currentDate = dayjs(date);

//   return currentDate.isSameOrAfter(start, 'day') && currentDate.isSameOrBefore(end, 'day');
// }

// // Function to check if a datetime is within the range
// const isWithinDatetimeRange = (
//   datetime: Date,
//   start: Date,
//   end: Date,
// ): boolean => {
//   const currentDatetime = datetime;

//   return (
//     currentDatetime.getTime() >= start.getTime() &&
//     currentDatetime.getTime() <= end.getTime()
//   );
//   // return currentDatetime.isSameOrAfter(start,'minute') && currentDatetime.isSameOrBefore(end,'minute');
// };

// // Function to check if a datetime is within the range
// const isWithinDatetimeRange = (
//   datetime: dayjs.ConfigType,
//   start: dayjs.Dayjs,
//   end: dayjs.Dayjs
// ): boolean => {
//   const currentDatetime = dayjs(datetime);
//   return currentDatetime.isSameOrAfter(start,'minute') && currentDatetime.isSameOrBefore(end,'minute');
// };

// const convertTZ = (date: string, tzString: string): any => {
//   return new Date(
//     (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
//       timeZone: tzString,
//     }),
//   );
// };

// // Function to parse DateTimeOffset string and convert to dayjs object
// const parseDateTimeOffset = (dateTimeOffsetString: string): any => {
//   const dateTime = convertTZ(dateTimeOffsetString, DEFAULT_TIMEZONE);

//   return dateTime;
// };

// // Function to parse DateTimeOffset string and convert to dayjs object
// const parseDateTimeOffset = (dateTimeOffsetString: string): dayjs.Dayjs => {
//   // Split the DateTimeOffset string into datetime and offset parts
//   const [dateTimePart, offsetPart] = dateTimeOffsetString.split('+');

//   if (dateTimePart===undefined || null)
//   {
//    const [dateTimePartN, offsetPartN] = dateTimeOffsetString.split('-');
//     // Parse the datetime part and adjust time zone offset
//     const dateTimeN = dayjs(dateTimePartN).utcOffset(offsetPartN);

//     return dateTimeN;
//   }
//   // Parse the datetime part and adjust time zone offset
//   const dateTime = dayjs(dateTimePart).utcOffset(offsetPart);

//   return dateTime;

// };

export const getSortedSlots = (selectedSlots: SelectedSlots) => {
  return Array.from(selectedSlots.values()).sort((a, b) =>
    a.index < b.index ? -1 : 1,
  );
};

//  // Define start and end times
//  const startDateTime = dayjs().set('hour', 0).set('minute', 0); // Start from midnight
//  const endDateTime = dayjs().set('hour', 23).set('minute', 59); // End at 11:59 PM

// Generate time slots

export const generateMinutesDurationTimeSlots = (
  choosenDate: string,
  slotDuration: number,
  timelineHours: TimelineHours,
  apiTimeSlots: CalendarSlot[],
) => {
  // const slots = [];

  // console.log(apiTimeSlots);
  // console.log("-------------hera-----------")

  const thirtyMinutesSlots = generate30MinutesDurationTimeSlotsForWholeDay(
    choosenDate,
    timelineHours,
  );

  // console.log(matched);
  // console.log(unmathed);

  const matchedTimes: any[] = [];
  const unmatchedTimes: any[] = [];
  thirtyMinutesSlots.forEach((slot30MinuteObj) => {
    const slot30MinuteStartTime = new Date(slot30MinuteObj.startTime);
    const slot30MinuteEndTime = new Date(slot30MinuteObj.endTime);
    // console.log("***30 Minute Slot ---"+ slot30MinuteStartTime+"-----"+slot30MinuteEndTime);

    let current30MinutesSlotMatched = false;
    apiTimeSlots.forEach((apiSlotObj) => {
      const apiSlotStartTime = new Date(apiSlotObj.StartTime.split('+')[0]);
      const apiSlotEndTime = new Date(apiSlotObj.EndTime.split('+')[0]);
      if (
        slot30MinuteStartTime >= apiSlotStartTime &&
        slot30MinuteEndTime <= apiSlotEndTime
      ) {
        current30MinutesSlotMatched = true;
        matchedTimes.push({
          startTime: slot30MinuteObj.startTime,
          endTime: slot30MinuteObj.endTime,
          apiSlotStartTime: apiSlotObj.StartTime,
          apiSlotEndTime: apiSlotObj.EndTime,
        });
      }
    });

    if (!current30MinutesSlotMatched) {
      // Add to Unmatched List

      unmatchedTimes.push({
        startTime: slot30MinuteObj.startTime,
        endTime: slot30MinuteObj.endTime,
      });
    }
  });
  // console.log(matchedTimes);
  // console.log(findDistinctItems(matchedTimes));

  const findDistinctApiSlots = findDistinctItems(matchedTimes);
  findDistinctApiSlots.forEach((time) => {
    unmatchedTimes.push({
      startTime: time.apiSlotStartTime.split('+')[0],
      endTime: time.apiSlotEndTime.split('+')[0],
    });
  });
  sortSlots(unmatchedTimes);
  // console.log(unmatchedTimes);

  return unmatchedTimes;
  //

  // const startOpeningHoursDateTimeString = `${choosenDate}T${timelineHours.startTime}`;
  // const endOpeningHoursDateTimeString = `${choosenDate}T${timelineHours.endTime}`;

  // const startOpeningHoursDateTime = dayjs.tz(`${choosenDate}T${timelineHours.startTime}`, DEFAULT_TIMEZONE,);
  // const endOpeningHoursDateTime = dayjs.tz(`${choosenDate}T${timelineHours.endTime}`, DEFAULT_TIMEZONE,);

  // // console.log(startDateTime)
  // // console.log(endDateTime)

  // let currentSlotStartTime = startOpeningHoursDateTime.clone();

  // // currentSlotStartTime
  // console.log(currentSlotStartTime)

  // if (apiTimeSlots[0].StartTime.split("+")[0] !== startOpeningHoursDateTimeString)//
  // {
  //   console.log("same");

  // }

  // let currentSlotEndTime = currentSlotStartTime; //startDateTime.clone();
  // // let lastStep = false;

  // while (currentSlotStartTime.isBefore(endOpeningHoursDateTime)) {
  //   // || lastStep) {
  //   // slots.push(currentSlotStartTime.format('yyyy-MM-ddTHH:mm:00'));

  //   currentSlotEndTime = currentSlotStartTime.add(slotDuration, 'minute');

  //   if (endOpeningHoursDateTime.isBefore(currentSlotStartTime)) {
  //     break;
  //   } else {

  //     const slot = {
  //       startTime: currentSlotStartTime.format('YYYY-MM-DDTHH:mm:00'), //.toISOString().split(".")[0],//`${choosenDate}T${startTime}`, //new Date().toISOString(),
  //       endTime: currentSlotEndTime.format('YYYY-MM-DDTHH:mm:00'), //.toISOString().split(".")[0]
  //       // endTime === '24:00:00'
  //       //   ? nextDayDateTime
  //       //   : `${choosenDate}T${endTime}`, //new Date().toISOString()
  //     };

  //     currentSlotStartTime = currentSlotEndTime; //currentSlotStartTime.add(slotDuration, 'minute');

  //     ////////////////////////////////////

  //     // if (endDateTime.isBefore(currentSlotEndTime) && !lastStep) {
  //     //   lastStep = true;
  //     // }
  //     // else {
  //     //   lastStep = false;
  //     // }
  //     // ////////////////////////////////////

  //     slots.push(slot);
  //   }
  // }

  // //////////////////////////////////////////

  // // console.log(slots);
  // return slots;
};

/**
 * Sorts an array of Slot objects in ascending order based on start and end time properties.
 */
const sortSlots = (slots: any[]): any[] => {
  return slots.sort((a, b) => {
    const startTimeA = dayjs(a.startTime);
    const endTimeA = dayjs(a.endTime);
    const startTimeB = dayjs(b.startTime);
    const endTimeB = dayjs(b.endTime);

    if (startTimeA.isBefore(startTimeB)) {
      return -1;
    }
    if (startTimeA.isAfter(startTimeB)) {
      return 1;
    }
    if (endTimeA.isBefore(endTimeB)) {
      return -1;
    }
    if (endTimeA.isAfter(endTimeB)) {
      return 1;
    }

    return 0;
  });
};

/**
 * Returns the distinct items based on the start and end time properties from an array.
 */
const findDistinctItems = (items: any[]) => {
  const uniqueStartEndTimes = new Set<string>();

  return items.filter((item) => {
    const startEndTimeKey = `${item.apiSlotStartTime}-${item.apiSlotEndTime}`;
    if (!uniqueStartEndTimes.has(startEndTimeKey)) {
      uniqueStartEndTimes.add(startEndTimeKey);

      return true;
    }

    return false;
  });
};

export const generateMinutesDurationTimeSlots_Old2 = (
  choosenDate: string,
  slotDuration: number,
  timelineHours: TimelineHours,
) => {
  const slots = [];

  const startDateTime = dayjs.tz(
    `${choosenDate}T${timelineHours.startTime}`,
    DEFAULT_TIMEZONE,
  );
  const endDateTime = dayjs.tz(
    `${choosenDate}T${timelineHours.endTime}`,
    DEFAULT_TIMEZONE,
  );

  // console.log(startDateTime)
  // console.log(endDateTime)

  let currentSlotStartTime = startDateTime.clone();
  let currentSlotEndTime = currentSlotStartTime; //startDateTime.clone();
  // let lastStep = false;

  while (currentSlotStartTime.isBefore(endDateTime)) {
    // || lastStep) {
    // slots.push(currentSlotStartTime.format('yyyy-MM-ddTHH:mm:00'));

    currentSlotEndTime = currentSlotStartTime.add(slotDuration, 'minute');

    if (endDateTime.isBefore(currentSlotStartTime)) {
      break;
    } else {
      // console.log(currentSlotStartTime.toISOString())

      const slot = {
        startTime: currentSlotStartTime.format('YYYY-MM-DDTHH:mm:00'), //.toISOString().split(".")[0],//`${choosenDate}T${startTime}`, //new Date().toISOString(),
        endTime: currentSlotEndTime.format('YYYY-MM-DDTHH:mm:00'), //.toISOString().split(".")[0]
        // endTime === '24:00:00'
        //   ? nextDayDateTime
        //   : `${choosenDate}T${endTime}`, //new Date().toISOString()
      };

      currentSlotStartTime = currentSlotEndTime; //currentSlotStartTime.add(slotDuration, 'minute');

      ////////////////////////////////////

      // if (endDateTime.isBefore(currentSlotEndTime) && !lastStep) {
      //   lastStep = true;
      // }
      // else {
      //   lastStep = false;
      // }
      // ////////////////////////////////////

      slots.push(slot);
    }
  }

  // console.log(slots);

  //   return slots;
  // };

  //////////////////////////////////////////

  // const startHour = parseInt(timelineHours.startTime.substring(0, 2)); // 0; // Start hour (0 for midnight)
  // let endHour = parseInt(timelineHours.endTime.substring(0, 2)); // 23; // End hour (23 for 11:30 PM)
  // const startMinute = parseInt(timelineHours.endTime.substring(3, 5));

  // endHour = startMinute > 0 ? endHour + 1 : endHour;

  // const slotDurationInMinutes = slotDuration; // Duration of each time slot in minutes

  // for (let hour = startHour; hour < endHour; hour++) {
  //   for (let minute = 0; minute < 60; minute += slotDurationInMinutes) {
  //     // Format hour and minute strings (e.g., "09:00")
  //     let formattedHour = hour.toString().padStart(2, '0');
  //     let formattedMinute = minute.toString().padStart(2, '0');

  //     // Create time slot string (e.g., "09:00 - 09:30")
  //     const startTime = `${formattedHour}:${formattedMinute}:00`;
  //     if (minute + slotDurationInMinutes === 60) {
  //       formattedHour = (hour + 1).toString().padStart(2, '0');
  //       formattedMinute = '00';
  //     } else {
  //       formattedMinute = `${(minute + slotDurationInMinutes)
  //         .toString()
  //         .padStart(2, '0')}`;
  //     }
  //     const endTime = `${formattedHour}:${formattedMinute}:00`;

  //     const nextDay = new Date(`${choosenDate}T${'00:00:00'}`);
  //     nextDay.setDate(nextDay.getDate() + 1);

  //     const nextDayDateTime =
  //       nextDay.getFullYear() +
  //       '-' +
  //       (nextDay.getMonth() + 1).toString().padStart(2, '0') +
  //       '-' +
  //       nextDay.getDate().toString().padStart(2, '0') +
  //       'T00:00:00';

  //     const slot = {
  //       startTime: `${choosenDate}T${startTime}`, //new Date().toISOString(),
  //       endTime:
  //         endTime === '24:00:00'
  //           ? nextDayDateTime
  //           : `${choosenDate}T${endTime}`, //new Date().toISOString(),
  //       // endTime: `${date}T${(endTime === "24:00:00" ? "23:59:59" : endTime)}`,//new Date().toISOString(),
  //     };
  //     slots.push(slot);
  //   }
  // }

  return slots;
};

export const generate30MinutesDurationTimeSlotsForWholeDay = (
  choosenDate: string,
  timelineHours: TimelineHours,
) => {
  const slots = [];
  const slotDuration = 30;
  const startHour = parseInt(timelineHours?.startTime?.substring(0, 2)); // 0; // Start hour (0 for midnight)
  let endHour = parseInt(timelineHours?.endTime?.substring(0, 2)); // 23; // End hour (23 for 11:30 PM)
  const startMinute = parseInt(timelineHours?.endTime?.substring(3, 5));

  endHour = startMinute > 0 ? endHour + 1 : endHour;

  const slotDurationInMinutes = slotDuration; // Duration of each time slot in minutes

  for (let hour = startHour; hour < endHour; hour++) {
    for (let minute = 0; minute < 60; minute += slotDurationInMinutes) {
      // Format hour and minute strings (e.g., "09:00")
      let formattedHour = hour.toString().padStart(2, '0');
      let formattedMinute = minute.toString().padStart(2, '0');

      // Create time slot string (e.g., "09:00 - 09:30")
      const startTime = `${formattedHour}:${formattedMinute}:00`;
      if (minute + slotDurationInMinutes === 60) {
        formattedHour = (hour + 1).toString().padStart(2, '0');
        formattedMinute = '00';
      } else {
        formattedMinute = `${(minute + slotDurationInMinutes)
          .toString()
          .padStart(2, '0')}`;
      }
      const endTime = `${formattedHour}:${formattedMinute}:00`;

      const nextDay = new Date(`${choosenDate}T${'00:00:00'}`);
      nextDay.setDate(nextDay.getDate() + 1);

      const nextDayDateTime =
        nextDay.getFullYear() +
        '-' +
        (nextDay.getMonth() + 1).toString().padStart(2, '0') +
        '-' +
        nextDay.getDate().toString().padStart(2, '0') +
        'T00:00:00';

      const slot = {
        startTime: `${choosenDate}T${startTime}`, //new Date().toISOString(),
        endTime:
          endTime === '24:00:00'
            ? nextDayDateTime
            : `${choosenDate}T${endTime}`, //new Date().toISOString(),
        // endTime: `${date}T${(endTime === "24:00:00" ? "23:59:59" : endTime)}`,//new Date().toISOString(),
      };
      slots.push(slot);
    }
  }

  return slots;
};

export const getAPISlotByTime = (staticSlot: any, apiSlots: CalendarSlot[]) => {
  let matchingSlotIndex = -1;

  apiSlots.some((slot, index) => {
    if (
      staticSlot.startTime === slot.StartTime.split('+')[0] &&
      staticSlot.endTime === slot.EndTime.split('+')[0]
    ) {
      // cslot=slot;
      matchingSlotIndex = index;

      //return matchingSlotIndex;
    }
  });

  return matchingSlotIndex;
};

export const findFirstAndLastMatching = (items: any[], targetValue: string) => {
  // let firstMatch: any;
  let lastMatch: any;

  // Find first matching item
  const firstMatch = items.find((item) => item.id === targetValue);

  // Find last matching item
  for (let i = items.length - 1; i >= 0; i--) {
    if (items[i].id === targetValue) {
      lastMatch = items[i];
      break;
    }
  }

  return [firstMatch, lastMatch];
};
