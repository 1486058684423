import { Typography } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS, hideScrollbar } from 'styles';

export const StyledDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${BREAKPOINTS.laptop} {
    flex-direction: row;
    gap: 64px;
  }
`;

export const StyledDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 48px;

  ${BREAKPOINTS.laptop} {
    ${hideScrollbar}
    width: 50%;
    gap: 16px;
    max-height: 70vh;
    overflow: auto;
    padding-bottom: 24px;
  }
`;

export const StyledSeparator = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.antd.colorBorderSecondary};
`;

export const CommentListWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CommentAdditionalDetails = styled.div`
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.antd.colorTextDescription};
`;

export const StyledText = styled(Typography.Text)`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;

  ::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background: ${({ theme }) => theme.antd.colorTextDescription};
    border-radius: 50%;
    margin: 0 8px 2px;
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  color: ${({ theme }) => theme.antd.colorPrimary};
  display: flex;
  width: 100%;
  padding: 48px 0 24px;
  justify-content: center;

  & > * {
    color: inherit;
    font-weight: 500;
  }
`;
