import { StatusVariant } from 'components';

import { exhaustiveCheck } from './lib';

export const presenceStatusVariant = (status: boolean | null) => {
  switch (status) {
    case null:
      return StatusVariant.default;
    case true:
      return StatusVariant.success;
    case false:
      return StatusVariant.error;
    default:
      return exhaustiveCheck(status, 'Status');
  }
};
