import { PaginatedRequestParams } from 'api';

export const walletQueryKeys = {
  all: ['wallet'] as const,
  lists: () => [...walletQueryKeys.all, 'list'] as const,
  list: (filters: object) => [...walletQueryKeys.lists(), filters] as const,
  details: () => [...walletQueryKeys.all, 'detail'] as const,
  detail: (id: string, params?: PaginatedRequestParams) =>
    [...walletQueryKeys.details(), id, params] as const,
};
