import { ClockCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/currency';
import { formatDateRange, getDuration } from 'utils/date';

import {
  BookingSlot,
  DurationRow,
  DurationWrapper,
  SlotContent,
  SlotFooter,
  StyledSlotWrapper,
} from './time-slot.styles';
import { SelectSlot } from './timetable-content';
export type AvailableSlotData = {
  type: 'available';
  price: number;
  startDate: string;
  endDate: string;
};

type AvailableSlotProps = {
  data: AvailableSlotData;
  index: number;
  fieldId: string;
  selectSlot: SelectSlot;
  isSelectable: boolean;
  isSelected: boolean;
};

export const AvailableTimeSlot = ({
  data,
  index,
  fieldId,
  selectSlot,
  isSelectable,
  isSelected,
}: AvailableSlotProps) => {
  const { t } = useTranslation();

  const { startDate, endDate, price } = data;
  const duration = getDuration(startDate, endDate);
  const isCompact = duration <= 45;
  const specifiedValues = [59, 89, 119];
  let valuedata;
  if (specifiedValues.includes(duration) && duration % 30 === 29) {
    valuedata = duration + 1;
  } else {
    valuedata = duration;
  }
  const renderPrice = () => {
    return (
      <Typography.Text
        strong
        ellipsis={{
          tooltip: true,
        }}
      >
        {formatPrice({
          value: price,
          currency: 'KWD',
        })}
      </Typography.Text>
    );
  };

  const handleClick = () => {
    if (isSelectable) {
      selectSlot(fieldId, index, data);
    }
  };

  return (
    <StyledSlotWrapper
      duration={duration}
      onClick={handleClick}
      className={`${duration === 30 ? 'halfHours' : 'halfHours'}`}
    >
      <BookingSlot
        $color={isSelected ? 'lightGreen' : 'white'}
        isSelectable={isSelectable}
      >
        <Typography.Text strong>
          {formatDateRange({ startDate, endDate })}
        </Typography.Text>
        <SlotContent>
          <DurationRow>
            <DurationWrapper>
              <Typography.Text type='secondary'>
                <ClockCircleOutlined />{' '}
                {t('common.minutes.short', { value: valuedata })}
                {/* {t('common.minutes.short', { value:  duration  })} */}
              </Typography.Text>
            </DurationWrapper>

            {isCompact ? renderPrice() : null}
          </DurationRow>

          {!isCompact ? <SlotFooter>{renderPrice()}</SlotFooter> : null}
        </SlotContent>
      </BookingSlot>
    </StyledSlotWrapper>
  );
};

export const MemoizedAvailableTimeSlot = React.memo(AvailableTimeSlot);
