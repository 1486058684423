import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import {
  FieldsFilterForm,
  FieldsFilterFormPaginated,
  FieldsFilterFormValues,
  FieldsTable,
  useGetFields,
} from 'features/fields';
import { usePermissions } from 'features/users';
import { usePaginationParams } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

const { useBreakpoint } = Grid;

const initialFilterValues: FieldsFilterFormValues = {
  field_name: undefined,
};

export const FieldsView = () => {
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const permissions = usePermissions();
  const { params, updateParams, resetPage } =
    usePaginationParams<FieldsFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by: '-rank',
      ...initialFilterValues,
    });

  const { data, isFetching } = useGetFields(params, { retry: false });

  const shouldHandleFieldCreation = permissions?.fields.add;

  const handleFiltersChange = (values: FieldsFilterFormValues) => {
    updateParams(values);
    resetPage();
  };

  const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
    updateParams(mainParams);
    resetPage();
  };

  const formId = 'fields-filters-form';

  return (
    <>
      <ContentTopBar
        title={t('fields.heading')}
        renderHeadingContent={
          <MainFilters onChange={handleMainFiltersChange} />
        }
        renderButtons={
          <>
            {shouldHandleFieldCreation ? (
              <Button
                icon={!screens.xs ? <PlusOutlined /> : undefined}
                href={ROUTES.FIELDS_CREATE}
              >
                {t('fields.buttons.addField')}
              </Button>
            ) : null}
          </>
        }
      />

      <ContentFilters formId={formId}>
        <FieldsFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>

      <FieldsTable
        data={data}
        isLoading={isFetching}
        tableParams={params}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
