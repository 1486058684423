import { Typography } from 'antd';
import { Avatar } from 'components';
import { ReactNode } from 'react';

import { StyledWrapper } from './client-info.styles';

export const ClientInfo = ({ children }: { children: ReactNode }) => {
  const avatarSize = 64;

  return (
    <StyledWrapper>
      <Avatar size={avatarSize} className='avatar' shape='square' />
      <Typography.Title level={5} className='name' ellipsis={{ tooltip: true }}>
        {children}
      </Typography.Title>
    </StyledWrapper>
  );
};
