import { Button, DatePicker, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

import { EndUserFilterFormField, EndUserFilterFormValues } from './types';

const { RangePicker } = DatePicker;

const { useBreakpoint } = Grid;

type UsersFilterFormProps = {
  formId: string;
  handleFilter: (values: EndUserFilterFormValues) => void;
  initialValues: EndUserFilterFormValues;
  closeModal?: () => void;
};

export const EndUsersFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<EndUserFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (formValues: EndUserFilterFormValues) => {
    handleFilter(formValues);
    closeModal?.();
  };

  return (
    <Form<EndUserFilterFormValues>
      id={formId}
      name={formId}
      className='filters-form'
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<EndUserFilterFormValues>
            label={t('adminUsers.formFields.date.label')}
            name={EndUserFilterFormField.Date}
          >
            <RangePicker
              allowEmpty={[true, true]}
              format={DATE_FORMATS.date}
              presets={[
                {
                  label: t('form.formFields.date.presets.today'),
                  value: [dayjs(), dayjs()],
                },
                {
                  label: t('form.formFields.date.presets.fromTomorrow'),
                  value: [dayjs().add(1, 'day'), null],
                },
                {
                  label: t('form.formFields.date.presets.untilYesterday'),
                  value: [null, dayjs().add(-1, 'day')],
                },
              ]}
            />
          </Form.Item>
          <Form.Item<EndUserFilterFormValues>
            label={t('adminUsers.formFields.userName.label')}
            name={EndUserFilterFormField.FullUserName}
          >
            <Input
              placeholder={t('adminUsers.formFields.userName.placeholder')}
            />
          </Form.Item>
          <Form.Item<EndUserFilterFormValues>
            label={t('adminUsers.formFields.phoneNumber.label')}
            name={EndUserFilterFormField.PhoneNumber}
          >
            <Input
              placeholder={t('adminUsers.formFields.phoneNumber.placeholder')}
            />
          </Form.Item>
          <Form.Item<EndUserFilterFormValues>
            label={t('adminUsers.formFields.email.label')}
            name={EndUserFilterFormField.Email}
          >
            <Input placeholder={t('adminUsers.formFields.email.placeholder')} />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('common.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('common.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
