import { TableProps } from 'antd';

import { StyledTable } from './table.styled';

export const Table = <T extends object>({
  children,
  dataSource,
  onChange,
  ...props
}: TableProps<T>) => {
  return (
    <StyledTable<T> dataSource={dataSource} onChange={onChange} {...props}>
      {children}
    </StyledTable>
  );
};
