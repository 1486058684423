import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { WalletApiService } from 'features/wallet';
import { WalletTransactionDetails } from 'types';

import { walletQueryKeys } from './query-keys';

const { getWalletTransactionDetails } = WalletApiService();

const getWalletTransactionDetailsQuery = (
  wallet_id: string,
  transaction_id: string,
  options?: UseQueryOptions<WalletTransactionDetails>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<WalletTransactionDetails>;
  options?: UseQueryOptions<WalletTransactionDetails>;
} => ({
  queryKey: walletQueryKeys.detail(transaction_id),
  queryFn: ({ signal }) =>
    getWalletTransactionDetails(wallet_id, transaction_id, {
      config: { signal },
    }),
  ...options,
});

export const useGetWalletTransactionDetails = (
  wallet_id: string,
  transaction_id: string,
  options?: UseQueryOptions<WalletTransactionDetails>,
) => {
  const { message } = App.useApp();

  return useQuery<WalletTransactionDetails, Error>(
    getWalletTransactionDetailsQuery(wallet_id, transaction_id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
