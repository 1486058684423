import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { Sport, SportType } from 'types';

export type GetSportsParams = {
  name?: string;
  is_active?: boolean;
} & PaginatedRequestParams;

export type UpdateSportDTO = {
  id: string;
  name?: string;
  name_arabic?: string;
  icon?: string;
  type?: SportType;
  is_active?: boolean;
};

export type SportsResponse = PaginatedResponse<Sport>;

export const SportsApiService = () => {
  const getSports = async (
    params: GetSportsParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<SportsResponse>({
      ...callConfig,
      endpoint: `/admin/sports`,
      params,
    });

    return response.data;
  };

  const updateSport = async (
    payload: UpdateSportDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Sport>({
      ...callConfig,
      endpoint: `/admin/sports/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  return {
    getSports,
    updateSport,
  };
};
