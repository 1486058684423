import {
  LogoutOutlined,
  MenuOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Dropdown, Grid, Space } from 'antd';
import { ReactComponent as LogoSvg } from 'assets/li3ib-logo.svg';
import { Avatar } from 'components';
import { useSessionContext } from 'features/auth';
import { useUserContext } from 'features/users';
import { getUserFullName, getUserRole } from 'features/users/utils';
import { useSideBar } from 'providers/side-bar-provider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

import {
  StyledDropdownIcon,
  StyledHeader,
  StyledLogoWrapper,
  StyledUserInfo,
  StyledUserMenuButton,
} from './header.styles';

const { useBreakpoint } = Grid;

export const Header = () => {
  const { collapsed, toggleCollapsed } = useSideBar();
  const { logOut } = useSessionContext();
  const { user } = useUserContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const avatarSize = 24;

  if (!user) return null;

  return (
    <StyledHeader className='app-header'>
      <Space
        role='button'
        aria-controls='sideBar'
        aria-expanded={!collapsed}
        onClick={toggleCollapsed}
        className='app-header-logo-wrapper'
        aria-label={t('sideBar.toggle')}
      >
        <MenuOutlined className='app-header-hamburger-mobile' />
        <StyledLogoWrapper>
          <LogoSvg title={t('li3ibLogo')} color='#f9d1d9' />
        </StyledLogoWrapper>
      </Space>

      <Space className='app-header-user-wrapper'>
        <Dropdown
          menu={{
            items: [
              {
                key: 'accountSettings',
                label: t('userMenu.accountSettings'),
                icon: <SettingOutlined />,
                onClick: () => navigate(ROUTES.ACCOUNT_SETTINGS),
              },
              {
                key: 'logout',
                label: t('userMenu.logOut'),
                icon: <LogoutOutlined />,
                onClick: logOut,
              },
            ],
          }}
          trigger={['click']}
          // required for keyboard accessibility, see: https://github.com/ant-design/ant-design/issues/21357
          getPopupContainer={(triggerNode) =>
            triggerNode.parentNode as HTMLElement
          }
        >
          <StyledUserMenuButton
            className='app-header-user'
            ghost
            $avatarSize={avatarSize}
            aria-label={t('userMenu.toggle')}
          >
            <Avatar size={avatarSize} className='app-header-avatar' />
            <Space className='app-header-space'>
              <StyledUserInfo ellipsis={{ tooltip: true }}>
                {getUserFullName(user)}
              </StyledUserInfo>
              <StyledUserInfo
                className='app-header-user-role'
                ellipsis={{ tooltip: true }}
              >
                {getUserRole(user.role)}
              </StyledUserInfo>
            </Space>
            {screens.lg ? (
              <StyledDropdownIcon style={{ marginLeft: '12px' }} />
            ) : null}
          </StyledUserMenuButton>
        </Dropdown>
      </Space>
    </StyledHeader>
  );
};
