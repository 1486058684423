import { Country } from './country';
import { Coordinates } from './geolocation';

export enum CompanyType {
  Li3ibClient = 'Li3ibClient',
  SuffixManagement = 'SuffixManagement',
}

export type Company = {
  id: string;
  name: string;
  name_arabic: string;
  address: string;
  is_active: boolean;
  country: Country;
  geolocation: Coordinates;
  type: CompanyType;
  phone_number: string;
  email: string;
  image_url: string;
};
