import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BookingTransaction, PaymentMethod, PaymentStatus } from 'types';
import { formatDate } from 'utils/date';

import {
  ItemLabel,
  ItemValue,
  TransactionDetailsItem,
  Wrapper,
} from './booking-details-transaction-details.styles';

type BookingDetailsTransactionDetailsProps = {
  transaction: BookingTransaction;
};

export const BookingDetailsTransactionDetails = ({
  transaction,
}: BookingDetailsTransactionDetailsProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <>
      {transaction && transaction.payment_status === PaymentStatus.Paid ? (
        <Wrapper>
          <TransactionDetailsItem>
            <ItemLabel>{t('bookings.details.transactionDate')}</ItemLabel>
            <ItemValue>
              <Typography.Text>
                {formatDate({
                  date: transaction.created_at,
                  format: 'dateTime',
                })}
              </Typography.Text>
            </ItemValue>
            <ItemLabel>{t('bookings.details.transactionId')}</ItemLabel>
            <Typography.Text>
              {transaction?.ottu_transaction?.id}
            </Typography.Text>
            <ItemLabel>{t('bookings.details.OrderNo')}</ItemLabel>
            <Typography.Text>{id}</Typography.Text>
          </TransactionDetailsItem>

          {transaction.payment_method === PaymentMethod.Knet &&
          transaction.ottu_transaction.ref ? (
            <TransactionDetailsItem>
              <ItemLabel>{t('bookings.details.referenceNumber')}</ItemLabel>
              <ItemValue>
                <Typography.Text>
                  {transaction.ottu_transaction.ref}
                </Typography.Text>
              </ItemValue>
            </TransactionDetailsItem>
          ) : null}
        </Wrapper>
      ) : null}
    </>
  );
};
