import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'components';
import { StyledTableWrapper } from 'components/table/table.styled';
import { usePermissions } from 'features/users';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';

type BadgeTableProps = {
  isLoading: boolean;
  data?: any;
};
export const CancellationTermViewTable = ({
  isLoading,
  data,
}: BadgeTableProps) => {
  const permissions = usePermissions();
  const navigate = useNavigate();
  if (!permissions) return null;
  const columns = [
    {
      title: 'Content in English',
      dataIndex: 'content_english',
      key: 'title',
      render: (text: any) => {
        const stripHtmlTags = (html: any) => {
          if (!html) return '';

          return html.replace(/<[^>]*>?/gm, '');
        };
        const strippedText = stripHtmlTags(text);

        return (
          <span>
            {strippedText.length > 20
              ? strippedText.slice(0, 20) + '...'
              : strippedText}
          </span>
        );
      },
    },
    {
      title: 'Content in Arabic',
      dataIndex: 'content_arabic',
      key: 'title',
      render: (text: any) => {
        const stripHtmlTags = (html: any) => {
          if (!html) return '';

          return html.replace(/<[^>]*>?/gm, '');
        };

        const strippedText = stripHtmlTags(text);

        return (
          <span>
            {strippedText.length > 20
              ? strippedText.slice(0, 20) + '...'
              : strippedText}
          </span>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'description',
      render: () => (
        <Button
          type='primary'
          // onClick={() => navigate(getRoute(ROUTES.CANCELLATION_POLICY_EDIT, record.id))}
        >
          <EditOutlined />
        </Button>
      ),
    },
  ];

  return (
    <StyledTableWrapper>
      <Table
        className='badge-page'
        dataSource={[data]}
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(getRoute(ROUTES.CANCELLATION_POLICY_EDIT, record.id));
            },
          };
        }}
        loading={isLoading}
        columns={columns}
      />
    </StyledTableWrapper>
  );
};
