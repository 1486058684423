import { InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Grid,
  Input,
  Radio,
  Row,
  Select,
  Upload,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import {
  CompanyDetailsField,
  CompanyDetailsFormValues,
} from 'features/companies';
import { useUploadProps } from 'hooks/use-upload-props';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { CompanyType, CountryCode } from 'types';
import { geolocationPattern, phoneNumberPattern } from 'utils/validations';

const { useBreakpoint } = Grid;
const { Dragger } = Upload;

type AddCompanyFormProps = {
  handleUpdate: (object: CompanyDetailsFormValues, FileList: RcFile[]) => void;
  disabled: boolean;
};

export const AddCompanyForm = ({
  handleUpdate,
  disabled,
}: AddCompanyFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CompanyDetailsFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [isFormDirty, setIsFormDirty] = useState(false);

  const { fileList, uploadProps } = useUploadProps();

  const onFinish = (formValues: CompanyDetailsFormValues) => {
    setIsFormDirty(false);
    handleUpdate(formValues, fileList as RcFile[]);
  };

  useEffect(() => {
    if (fileList.length) setIsFormDirty(true);
  }, [fileList]);

  const getInitialValues = {
    [CompanyDetailsField.Name]: '',
    [CompanyDetailsField.NameArabic]: '',
    [CompanyDetailsField.Address]: '',
    [CompanyDetailsField.Country]: CountryCode.KW,
    [CompanyDetailsField.PhoneNumber]: '',
    [CompanyDetailsField.Email]: '',
    [CompanyDetailsField.Geolocation]: '',
    [CompanyDetailsField.Type]: '',
  };

  const clientTypeOptions = [
    { value: CompanyType.Li3ibClient, label: 'Li3ib' } as const,
    {
      value: CompanyType.SuffixManagement,
      label: 'Suffix Management',
    } as const,
  ];

  const countryOptions = [
    { value: CountryCode.KW, label: 'Kuwait' } as const,
    // currently we've only one market
    // add SA when new market is available
  ];

  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;

  return (
    <>
      <Form
        {...formItemLayout}
        id='company-details-form'
        name='company-details-form'
        form={form}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={getInitialValues}
        onFinish={(values) => onFinish(values)}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        onFieldsChange={() => setIsFormDirty(true)}
        validateTrigger={validateTrigger}
        disabled={disabled}
      >
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.name.label')}
          name={CompanyDetailsField.Name}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.name.placeholder')}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.nameArabic.label')}
          name={CompanyDetailsField.NameArabic}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.nameArabic.placeholder')}
            dir='rtl'
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.address.label')}
          name={CompanyDetailsField.Address}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.address.placeholder')}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.country.label')}
          name={CompanyDetailsField.Country}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Select
            placeholder={t('companies.formFields.country.placeholder')}
            size='large'
            options={countryOptions}
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.phoneNumber.label')}
          name={CompanyDetailsField.PhoneNumber}
          rules={[
            {
              required: true,
              whitespace: true,
            },
            {
              pattern: phoneNumberPattern,
              message: t('validateMessages.custom.phoneNumber'),
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.phoneNumber.placeholder')}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.email.label')}
          name={CompanyDetailsField.Email}
          rules={[
            {
              required: true,
              whitespace: true,
            },
            {
              type: 'email',
              message: t('validateMessages.custom.email'),
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.email.placeholder')}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.geolocation.label')}
          name={CompanyDetailsField.Geolocation}
          rules={[
            {
              required: true,
            },
            {
              pattern: geolocationPattern,
              message: t('validateMessages.custom.geolocation'),
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.geolocation.placeholder')}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.type.label')}
          name={CompanyDetailsField.Type}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Radio.Group>
            {clientTypeOptions.map((option) => (
              <Radio value={option.value} key={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t('companies.formFields.logo.label')}
          getValueFromEvent={(event) => event?.fileList}
          rules={[
            {
              required: true,
            },
          ]}
        >
          {fileList.length ? (
            <Upload
              {...uploadProps}
              name='file'
              accept='.png, .jpg, .jpeg'
              maxCount={1}
              listType='picture-card'
            />
          ) : (
            <ImgCrop rotationSlider>
              <Dragger {...uploadProps} multiple={false}>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>{t('upload.dragOrClick')}</p>
                <p className='ant-upload-hint'>
                  <Trans i18nKey='upload.hint' components={{ br: <br /> }} />
                </p>
              </Dragger>
            </ImgCrop>
          )}
        </Form.Item>

        <Col span={screens.lg ? 10 : 'auto'} offset={screens.lg ? 7 : 0}>
          <Row justify='end'>
            <Button disabled={disabled} type='primary' htmlType='submit'>
              {t('companies.buttons.addCompany')}
            </Button>
          </Row>
        </Col>
      </Form>

      <RouteLeavingGuard blocked={isFormDirty} />
    </>
  );
};
