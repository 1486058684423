import { GetRecurringBookingSingleBookingsParams } from 'features/bookings/api/bookings-api.service';
import { RecurringBookingDetailsTable } from 'features/bookings/components/bookings/recurring-booking-details-table';
import { useGetRecurringBookingSingleBookings } from 'features/bookings/use-cases';
import { usePaginationParams } from 'hooks';
import { Outlet, useParams } from 'react-router-dom';

export const RecurringBookingBookings = () => {
  const { id } = useParams();

  const { params, updateParams } =
    usePaginationParams<GetRecurringBookingSingleBookingsParams>({
      page: 1,
      size: 10,
    });

  const { data: bookingsData, isFetching: isFetchingBookings } =
    useGetRecurringBookingSingleBookings(
      {
        fixed_booking_id: id,
        ...params,
      },
      {
        enabled: Boolean(id),
      },
    );

  return (
    <>
      <RecurringBookingDetailsTable
        data={bookingsData}
        isBookingListLoading={isFetchingBookings}
        tableParams={params}
        updateParams={updateParams}
        fixedBookingId={id}
      />

      <Outlet />
    </>
  );
};
