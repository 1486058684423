import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Grid,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Upload,
} from 'antd';
import { RcFile } from 'antd/es/upload';
import ImgCrop from 'antd-img-crop';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import {
  canEditCompany,
  getUserPermissions,
  useUserContext,
} from 'features/users';
import { useUploadProps } from 'hooks/use-upload-props';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Company, CompanyType, CountryCode } from 'types';
import { geolocationPattern, phoneNumberPattern } from 'utils/validations';

import { CompanyDetailsField, CompanyDetailsFormValues } from './types';

type CompanyDetailsFormProps = {
  companyData: Company;
  handleUpdate: (object: CompanyDetailsFormValues, FileList: RcFile[]) => void;
  handleDeleteImage: () => void;
  isLoading: boolean;
};

const { useBreakpoint } = Grid;
const { Dragger } = Upload;

export const CompanyDetailsForm = ({
  companyData,
  handleUpdate,
  handleDeleteImage,
  isLoading,
}: CompanyDetailsFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CompanyDetailsFormValues>();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const screens = useBreakpoint();
  const { fileList, uploadProps } = useUploadProps();

  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    if (fileList.length) setIsFormDirty(true);
  }, [fileList]);

  if (!user) return null;

  const onFinish = (formValues: CompanyDetailsFormValues) => {
    setIsFormDirty(false);
    handleUpdate(formValues, fileList as RcFile[]);
  };

  const { company } = getUserPermissions(user);

  const companyCountry = companyData?.country?.country_code || CountryCode.KW;
  const companyGeolocation = companyData.geolocation
    ? `${companyData.geolocation.lat}, ${companyData.geolocation.lon}`
    : '';

  const getInitialValues = {
    [CompanyDetailsField.Name]: companyData.name,
    [CompanyDetailsField.NameArabic]: companyData.name_arabic,
    [CompanyDetailsField.Address]: companyData.address,
    [CompanyDetailsField.Country]: companyCountry,
    [CompanyDetailsField.PhoneNumber]: companyData.phone_number,
    [CompanyDetailsField.Email]: companyData.email,
    [CompanyDetailsField.Geolocation]: companyGeolocation,
    [CompanyDetailsField.Type]: companyData.type,
  };

  const canEdit = canEditCompany(user);

  const clientTypeOptions = [
    { value: CompanyType.Li3ibClient, label: 'Li3ib' } as const,
    {
      value: CompanyType.SuffixManagement,
      label: 'Suffix Management',
    } as const,
  ];

  const countryOptions = [
    { value: CountryCode.KW, label: 'Kuwait' } as const,
    //currently we've only one market
    // add SA when new market is available
  ];

  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;

  return (
    <>
      <Form
        {...formItemLayout}
        id='company-details-form'
        name='company-details-form'
        form={form}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={getInitialValues}
        onFinish={onFinish}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        onFieldsChange={() => setIsFormDirty(true)}
        validateTrigger={validateTrigger}
      >
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.name.label')}
          name={CompanyDetailsField.Name}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.name.placeholder')}
            disabled={!canEdit.name || isLoading}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.nameArabic.label')}
          name={CompanyDetailsField.NameArabic}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.nameArabic.placeholder')}
            disabled={!canEdit.name_arabic || isLoading}
            dir='rtl'
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.address.label')}
          name={CompanyDetailsField.Address}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.address.placeholder')}
            disabled={!canEdit.address || isLoading}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.country.label')}
          name={CompanyDetailsField.Country}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Select
            placeholder={t('companies.formFields.country.placeholder')}
            disabled={!canEdit.country || isLoading}
            size='large'
            options={countryOptions}
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.phoneNumber.label')}
          name={CompanyDetailsField.PhoneNumber}
          rules={[
            {
              required: true,
              whitespace: true,
            },
            {
              pattern: phoneNumberPattern,
              message: t('validateMessages.custom.phoneNumber'),
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.phoneNumber.placeholder')}
            disabled={!canEdit.phone_number || isLoading}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.email.label')}
          name={CompanyDetailsField.Email}
          rules={[
            {
              required: true,
              whitespace: true,
            },
            {
              type: 'email',
              message: t('validateMessages.custom.email'),
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.email.placeholder')}
            disabled={!canEdit.email || isLoading}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.geolocation.label')}
          name={CompanyDetailsField.Geolocation}
          rules={[
            {
              required: true,
            },
            {
              pattern: geolocationPattern,
              message: t('validateMessages.custom.geolocation'),
            },
          ]}
        >
          <Input
            placeholder={t('companies.formFields.geolocation.placeholder')}
            disabled={!canEdit.geolocation || isLoading}
            size='large'
          />
        </Form.Item>
        <Form.Item<CompanyDetailsField>
          label={t('companies.formFields.type.label')}
          name={CompanyDetailsField.Type}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <Radio.Group disabled={!canEdit.type || isLoading}>
            {clientTypeOptions.map((option) => (
              <Radio value={option.value} key={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t('companies.formFields.logo.label')}
          getValueFromEvent={(event) => event?.fileList}
        >
          {companyData.image_url ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Image
                width={200}
                style={{ borderRadius: '6px' }}
                src={companyData.image_url}
                placeholder={true}
                preview={false}
              />
              <Button
                danger
                onClick={handleDeleteImage}
                style={{ borderColor: 'red', padding: '4px 10px' }}
              >
                <DeleteOutlined />
              </Button>
            </div>
          ) : (
            <>
              {fileList.length ? (
                <Upload
                  {...uploadProps}
                  name='file'
                  accept='.png, .jpg, .jpeg'
                  maxCount={1}
                  listType='picture-card'
                />
              ) : (
                <ImgCrop rotationSlider>
                  <Dragger {...uploadProps} multiple={false}>
                    <p className='ant-upload-drag-icon'>
                      <InboxOutlined />
                    </p>
                    <p className='ant-upload-text'>{t('upload.dragOrClick')}</p>
                    <p className='ant-upload-hint'>
                      <Trans
                        i18nKey='upload.hint'
                        components={{ br: <br /> }}
                      />
                    </p>
                  </Dragger>
                </ImgCrop>
              )}
            </>
          )}
        </Form.Item>

        <Col span={screens.lg ? 10 : 'auto'} offset={screens.lg ? 7 : 0}>
          <Row justify='end'>
            <Button
              disabled={!company.edit || isLoading}
              type='primary'
              htmlType='submit'
            >
              {t('companies.buttons.update')}
            </Button>
          </Row>
        </Col>
      </Form>

      <RouteLeavingGuard blocked={isFormDirty} />
    </>
  );
};
