import { CompanyDetails } from 'features/companies/components';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const CompanyDetailsView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const navigateToList = () => {
    navigate(ROUTES.COMPANIES);
  };

  return <CompanyDetails companyId={id} onSuccess={navigateToList} />;
};
