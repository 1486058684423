import { App, Grid, Input, Switch, Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import {
  FieldsFilterFormPaginated,
  FieldsResponse,
  useUpdateField,
} from 'features/fields';
import { usePermissions } from 'features/users';
import { Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Field } from 'types';

const { useBreakpoint } = Grid;

type FieldsTableProps = {
  isLoading: boolean;
  data?: FieldsResponse;
  tableParams: FieldsFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<FieldsFilterFormPaginated>>;
};

export const FieldsTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: FieldsTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { message } = App.useApp();
  const navigate = useNavigate();
  const permissions = usePermissions();
  const [editingId, setEditingId] = useState('');

  const { mutate: mutateUpdateField, isLoading: isLoadingUpdateField } =
    useUpdateField();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Field> | SorterResult<Field>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };

  const isEditing = (id: string) => {
    return editingId === id;
  };

  const handleRankChange = async (
    id: string,
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    const newValue = +(event.target as HTMLInputElement).value;
    if (newValue <= 0) {
      message.error(t('facilities.formFields.rank.errorLessThanOne'));
    } else {
      await mutateUpdateField({
        id,
        rank: +(event.target as HTMLInputElement).value,
      });
      setEditingId('');
    }
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('fields.foundCompanies')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Field>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        scroll={tableWidth}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(getRoute(ROUTES.FIELDS_EDIT, record.id));
              }
            },
          };
        }}
      >
        <Column<Field>
          title={t('fields.table.rank')}
          dataIndex={'rank'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']}
          defaultSortOrder={'descend'}
          width={screens.xxl ? 'auto' : 240}
          ellipsis
          onCell={(record) => {
            return {
              onClick: (event) => {
                event.stopPropagation();
                setEditingId(record?.id);
              },
            };
          }}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          render={(value: any, record: any) => {
            const editable = isEditing(record?.id);

            return editable ? (
              <Input
                defaultValue={value}
                onPressEnter={(e) => handleRankChange(record?.id, e)}
              />
            ) : (
              <div>{record?.rank}</div>
            );
          }}
        />
        <Column<Field>
          title={t('fields.table.fieldName')}
          dataIndex={'name'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']} // workaround to remove null as an option
          defaultSortOrder={'ascend'}
          width={screens.xxl ? 'auto' : 240}
          ellipsis
        />
        <Column<Field>
          title={t('fields.table.area')}
          dataIndex={['facility', 'area', 'name']}
          width={screens.xxl ? 'auto' : 200}
          className='cell-direction-rtl'
          ellipsis
        />
        <Column<Field>
          title={t('fields.table.facility')}
          dataIndex={['facility', 'name']}
          width={screens.xxl ? 'auto' : 240}
          ellipsis
        />
        <Column<Field>
          title={t('fields.table.company')}
          dataIndex={['facility', 'company', 'name']}
          width={screens.xxl ? 'auto' : 240}
        />
        <Column<Field>
          title={t('fields.table.enableDisable')}
          width={screens.xxl ? 'auto' : 162}
          dataIndex={'is_active'}
          render={(_, record) => (
            <Switch
              defaultChecked={record.is_active}
              checked={record.is_active}
              loading={isLoadingUpdateField}
              onChange={(isChecked) =>
                mutateUpdateField({
                  id: record.id,
                  is_active: isChecked,
                })
              }
              onClick={(checked, event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
