import { AxiosError, isAxiosError } from 'axios';
import i18n from 'locales/i18n';

import { HTTPValidationError } from './types';

export { isAxiosError };

export const isServerError = (error: AxiosError) => {
  if (!error.response) {
    return false;
  }
  const { status } = error.response;

  return status >= 500;
};

export const isObject = (value: unknown): value is object => {
  return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const hasProperty = <T extends string>(
  value: unknown,
  property: T,
): value is Record<T, unknown> => {
  return isObject(value) && property in value;
};

export const hasServerErrorDetails = (
  error: AxiosError,
): error is AxiosError<HTTPValidationError> => {
  return (
    error.response !== undefined && hasProperty(error.response.data, 'detail')
  );
};

export const parseServerErrorDetails = (error: AxiosError): string => {
  if (error.response?.data) {
    if (hasServerErrorDetails(error)) {
      const errorDetail = error.response.data.detail;

      if (typeof errorDetail === 'string') {
        return errorDetail;
      }

      if (Array.isArray(errorDetail)) {
        return errorDetail[0].msg;
      }
    }
  }

  return i18n.t('errors.unexpectedError');
};

export const handleError = (error: unknown): string => {
  let uiMessage;

  if (isAxiosError(error)) {
    if (isServerError(error)) {
      uiMessage = i18n.t('errors.systemFailure');
      console.error(error, { error: error.response?.data });
    } else {
      uiMessage = parseServerErrorDetails(error);
    }
  } else {
    uiMessage = i18n.t('errors.unexpectedError');
    console.error(error);
  }

  return uiMessage;
};
