import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { FacilitiesApiService } from 'features/facilities/api/facilities-api.service';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { facilitiesQueryKeys } from './query-keys';

const { deleteFacilityImage } = FacilitiesApiService();

export const useDeleteFacilityImage = () => {
  const { message } = App.useApp();

  return useMutation(
    (id: string) => {
      return deleteFacilityImage(id);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        console.error(uiMessage);
        message.error(i18n.t('facilities.update.errorImageDelete'));
      },
      onSuccess: (data, id) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: facilitiesQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: facilitiesQueryKeys.detail(id),
          }),
        ]);
        message.success(i18n.t('facilities.update.successfulImageDelete'));
      },
    },
  );
};
