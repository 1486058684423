import { useWindowSize } from 'hooks';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type SideBarContextValue = {
  collapsed: boolean;
  toggleCollapsed: () => void;
};

const SideBarContext = createContext<SideBarContextValue | null>(null);

export const SideBarProvider = ({ children }: { children: ReactNode }) => {
  const { isLaptop } = useWindowSize();

  const shouldBeCollapsed = () => {
    if (isLaptop) return false;

    return true;
  };

  const [collapsed, setCollapsed] = useState(() => shouldBeCollapsed());

  const toggleCollapsed = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  return (
    <SideBarContext.Provider
      value={{
        collapsed,
        toggleCollapsed,
      }}
    >
      {children}
    </SideBarContext.Provider>
  );
};

export const useSideBar = () => {
  const ctx = useContext(SideBarContext);

  if (!ctx) {
    throw new Error('Error: Side Bar Context not available');
  }

  return ctx;
};
