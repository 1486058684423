import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const ContentWrapper = styled.div`
  max-width: 404px;
  width: 100%;
  margin: 0 auto;
`;

export const ContentHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;
  font-size: 1.6rem;
`;

export const StyledButton = styled(Button)`
  display: block;
  margin: 48px 0 0 auto;
  width: 100%;

  ${BREAKPOINTS.tablet} {
    width: initial;
  }
`;

export const CommentListWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CommentAdditionalDetails = styled.div`
  display: flex;
  flex-wrap: nowrap;
  font-size: 1.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.antd.colorTextDescription};
`;

export const StyledText = styled(Typography.Text)`
  font-size: inherit;
  font-weight: inherit;
  color: inherit;

  ::before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background: ${({ theme }) => theme.antd.colorTextDescription};
    border-radius: 50%;
    margin: 0 8px 2px;
  }
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonWrapper = styled.div`
  color: ${({ theme }) => theme.antd.colorPrimary};
  display: flex;
  width: 100%;
  padding: 48px 0 24px;
  justify-content: center;

  & > * {
    color: inherit;
    font-weight: 500;
  }
`;
