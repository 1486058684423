/**
 * Keeps values used among timetable components.
 * These need to be changed at the same time in order to retain the layout and functionalities
 */

export const oneHourSlotHeight = 100;
export const columnWidth = '192px';
export const columnMargin = '8px';
export const columnPadding = '8px';
export const columnBorderRadius = '8px';
export const oneandhelfHourSlotHeight = 150;
