import { SelectProps, Spin } from 'antd';
import { Select } from 'components';
import { GetFacilitiesParams } from 'features/facilities/api/facilities-api.service';
import { useGetFacilitiesInfiniteQuery } from 'features/facilities/use-cases';
import { usePaginationParams } from 'hooks';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Facility } from 'types';

const initialParams = {
  page: 1,
  size: 10,
};

type FacilitySelectProps = {
  companyId?: string;
  disabled?: boolean;
  allowPullAll?: boolean;
  customParams?: GetFacilitiesParams;
};

export const FacilitySelect = ({
  companyId,
  allowPullAll = true,
  customParams,
  ...props
}: SelectProps & FacilitySelectProps) => {
  const { t } = useTranslation();

  const { params, setParams } =
    usePaginationParams<GetFacilitiesParams>(initialParams);

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetFacilitiesInfiniteQuery(
      { ...params, company_id: companyId, ...customParams },
      {
        enabled: allowPullAll ? allowPullAll : !!companyId,
        keepPreviousData: true,
        cacheTime: 2000,
      },
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    debounce(
      (facilityName?: string) =>
        setParams((prevParams) => ({
          ...prevParams,
          ...{ facility_name: facilityName },
        })),
      500,
    ),
    [],
  );

  const handleClear = () => setParams(initialParams);

  return (
    <Select<Facility>
      placeholder={t('facilities.formFields.facilityName.placeholder')}
      onSearch={debouncedOnSearch}
      onClear={handleClear}
      loading={isFetching}
      dropdownMatchSelectWidth={200}
      notFoundContent={
        isFetching ? (
          <Spin
            size='small'
            aria-label={t('facilities.formFields.facilityName.loading')}
          />
        ) : null
      }
      options={data?.pages
        .map((page) =>
          page.items.map((facilityItem) => ({
            value: facilityItem.id,
            label: facilityItem.name,
          })),
        )
        .flat()}
      onPopupScroll={(e) => {
        if (!e.target) return;

        const target = e.target as HTMLDivElement;
        const isScrolledToBottom =
          target.scrollTop + target.offsetHeight === target.scrollHeight;

        if (!isScrolledToBottom) return;

        if (hasNextPage || !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
      allowClear
      {...props}
    />
  );
};
