import { BookingDetailsModal } from 'features/bookings/components';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';

export const FixedBookingSingleBookingDetailsView = () => {
  const navigate = useNavigate();
  const { id, booking_id } = useParams();

  if (!id) return null;
  if (!booking_id) return null;

  const closeModal = () => {
    navigate(getRoute(ROUTES.FIXED_BOOKING_DETAILS, id));
  };

  return (
    <BookingDetailsModal
      bookingId={booking_id}
      onCancel={closeModal}
      onSuccess={closeModal}
    />
  );
};
