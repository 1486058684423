/* eslint-disable @typescript-eslint/no-explicit-any */
import { DeleteOutlined,EditOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { AllDays } from 'types';

import { ExceptionTimePriceModal } from './exception-time-price-modal';
import {
  StyledWrapper,
  TimePriceButton,
  TimePriceInputChip,
  TimePriceInputWrapper,
} from './time-price-styles';
import { TimePriceModalFields } from './types';

type ExceptionDays = {
  exception_days_range: Dayjs[];
  exception_days_slot_price: string;
};
enum Weekdays {
  Sunday = 'SU',
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
}
type ExceptionTimePrice = {
  all_days: AllDays[];
  exception_days_list: Dayjs[];
  exception_days: ExceptionDays[];
  exceptionWeekDays: Weekdays[];
  updateIndex: number;
  isUpdate: number;
};

type TimePriceInputProps = {
  value?: ExceptionTimePrice;
  onChange?: (formData: AllDays) => void;
  error: boolean;
  timeSlot: string;
  setMulitExcepTimeRange: React.Dispatch<
    React.SetStateAction<multiPleExceptionalTimeRange[]>
  >;
  mulitExcepTimeRange: multiPleExceptionalTimeRange[];
};
//setMulitExcepTimeRange_:React.Dispatch<React.SetStateAction<multiPleExceptionalTimeRange[]>>;
type chipState = {
  label: string;
};

type chipStateExcep = {
  label: string;
  chipsExcepValues: [];
};

type multiPleExceptionalTimeRange = {
  exceptionDays: Dayjs[];
  exceptionTimeRange: ExceptionDays[];
  exceptionWeekDays: Weekdays[];
};

export const ExceptionTimePriceInput = ({
  value = {
    [TimePriceModalFields.AllDays]: [],
    [TimePriceModalFields.ExceptionDays]: [],
    [TimePriceModalFields.ExceptionDaysList]: [],
    exceptionWeekDays: [],
    updateIndex: 0,
    isUpdate: 0,
  },
  onChange = () => {
    // default fallback function
  },
  error,
  timeSlot,
  setMulitExcepTimeRange,
  mulitExcepTimeRange,
}: TimePriceInputProps) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  //const [btnLabel, setBtnLabel] = useState<string>(t('fields.buttons.add'));
  //const [chips, setChips] = useState<chipState[] | undefined>(undefined);
  // const [exchips, setExchips] = useState<chipState[] | undefined>(undefined);
  const [exchipDate, setExchipDate] = useState<chipStateExcep[] | undefined>(
    undefined,
  );
  //const [previousValues] = useState<any>(value);
  const [modalType, setModalType] = useState(0);
  const multiExceptionalValue = [
    {
      exceptionDays: [],
      exceptionTimeRange: [],
      exceptionWeekDays: [],
    },
  ];
  // const [mulitExcepTimeRange,setMulitExcepTimeRange] = useState<multiPleExceptionalTimeRange[]>(multiExceptionalValue);
  //const { mutate } = useDeleteTimeRange();

  const handleChange = () => {
    setModalType(0);
    handleOpenModal();
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // function findRemovedTimeRangeIds(defaultArray: any, modifiedArray: any) {
  //   const modifiedIds = new Set(
  //     modifiedArray.map((item: any) => item.timeRangeId),
  //   );
  //   const removedIds = defaultArray
  //     .filter((item: any) => !modifiedIds.has(item.timeRangeId))
  //     .map((item: any) => item.timeRangeId);

  //   return removedIds;
  // }

  useEffect(() => {
    updateChipsWithMultiException();
    // setMulitExcepTimeRange_([multiExceptionalValue]);
  }, [mulitExcepTimeRange]);

  const exceptionHandleSubmit = (formData: any) => {
    // const { exception_days_list: exceptionDay } = formData;
    // const { exception_days_list: exceptionDayValue } = value;
    const { updateIndex: updateIndex_ } = formData;
    const { isUpdate: isUpdate_ } = formData;

    if (isUpdate_ === 1) {
      mulitExcepTimeRange[updateIndex_] = {
        exceptionDays: formData.exception_days_list,
        exceptionTimeRange: formData.exception_days,
        exceptionWeekDays: formData.exception_week_days,
      };
      setMulitExcepTimeRange(mulitExcepTimeRange);
      updateChipsWithMultiException();
    } else {
      // if (exceptionDay !== undefined && Object.keys(exceptionDay).length === 0) {
      //   delete formData['exception_days_list'];
      //   delete formData['exception_days'];
      // } else if (
      //   !('exception_days_list' in formData) &&
      //   exceptionDayValue !== undefined &&
      //   Object.keys(exceptionDayValue).length !== 0
      // ) {

      //  formData.exception_days = value.exception_days;
      //   formData.exception_days_list = value.exception_days_list;

      // }

      if (
        Object.keys(mulitExcepTimeRange).length !== 0 &&
        mulitExcepTimeRange !== null &&
        mulitExcepTimeRange[0].exceptionDays.length !== 0 &&
        mulitExcepTimeRange.length > 0
      ) {
        setMulitExcepTimeRange([
          ...mulitExcepTimeRange,
          {
            exceptionDays: formData.exception_days_list,
            exceptionTimeRange: formData.exception_days,
            exceptionWeekDays: formData.exception_week_days,
          },
        ]);
      } else {
        setMulitExcepTimeRange([
          {
            exceptionDays: formData.exception_days_list,
            exceptionTimeRange: formData.exception_days,
            exceptionWeekDays: formData.exception_week_days,
          },
        ]);
      }
    }

    if (formData) {
      onChange(formData);
      handleCloseModal();
    }
  };

  const updateChipsWithMultiException = () => {
    if (
      Object.keys(mulitExcepTimeRange).length !== 0 &&
      mulitExcepTimeRange[0].exceptionDays !== undefined &&
      mulitExcepTimeRange[0].exceptionDays.length === 0
    )
      return;

    //debugger ;
    const exceptionDayList: any = [];
    mulitExcepTimeRange.forEach(({ exceptionDays, exceptionTimeRange }) => {
      if (exceptionTimeRange) {
        const chipsExcepValues = exceptionTimeRange.map(
          ({ exception_days_range, exception_days_slot_price }) => ({
            label: `${dayjs(exception_days_range[0]).format(
              'hh:mm A',
            )} - ${dayjs(exception_days_range[1]).format(
              'hh:mm A',
            )} | ${exception_days_slot_price} KWD`,
          }),
        );
        // setExchips(chipsExcepValues);
        exceptionDayList.push({
          label: `${dayjs(exceptionDays[0]).format('YYYY-MM-DD')} - ${dayjs(
            exceptionDays[1],
          ).format('YYYY-MM-DD')}`,
          chipsExcepValues: chipsExcepValues,
        });
      }
    });
    setExchipDate(exceptionDayList);
  };

  // useEffect(() => {
  //   updateChips();
  //   if (value && value?.exception_days?.length > 0) {
  //     //setBtnLabel(t('fields.buttons.edit'));
  //     setBtnLabel('Add more');
  //   }
  // }, [value, updateChips, t]);

  const editExceptionForm = (index: number) => {
    setModalType(1);
    handleOpenModal();
    value.exception_days_list = mulitExcepTimeRange[index].exceptionDays;
    value.exceptionWeekDays = mulitExcepTimeRange[index].exceptionWeekDays;
    value.exception_days = mulitExcepTimeRange[index].exceptionTimeRange;
    value.updateIndex = index;
    value.isUpdate = 1;
    //console.log('mulitExcepTimeRange'+JSON.stringify(mulitExcepTimeRange[index]));
  };

  const deleteExceptionForm = (index: number) => {
    if (mulitExcepTimeRange.length === 1) {
      setMulitExcepTimeRange(mulitExcepTimeRange.filter((o, i) => index !== i));
      setMulitExcepTimeRange(multiExceptionalValue);
      setExchipDate([]);
    } else {
      setMulitExcepTimeRange(mulitExcepTimeRange.filter((o, i) => index !== i));
    }
  };

  return (
    <>
      <StyledWrapper>
        <TimePriceInputWrapper error={error}>
          {exchipDate && exchipDate?.length > 0
            ? exchipDate.map((chip: chipStateExcep, index: number) => (
                <>
                  {index !== 0 ? <Divider /> : null}
                  <TimePriceInputChip key={chip?.label}>
                    {chip?.label}
                  </TimePriceInputChip>
                  <TimePriceButton
                    type='primary'
                    onClick={() => editExceptionForm(index)}
                  >
                    <EditOutlined />
                  </TimePriceButton>
                  <TimePriceButton
                    type='primary'
                    onClick={() => deleteExceptionForm(index)}
                    danger
                  >
                    <DeleteOutlined />
                  </TimePriceButton>
                  {chip.chipsExcepValues && chip.chipsExcepValues?.length > 0
                    ? chip.chipsExcepValues.map((chips: chipState) => (
                        <TimePriceInputChip key={chips?.label}>
                          {chips?.label}
                        </TimePriceInputChip>
                      ))
                    : null}
                </>
              ))
            : null}
        </TimePriceInputWrapper>

        <TimePriceButton type='primary' onClick={handleChange}>
          {exchipDate && exchipDate?.length > 0 ? 'Add More' : 'Add'}
          {/* {btnLabel} */}
        </TimePriceButton>
      </StyledWrapper>
      {/* openingHours={openingHours} */}
      {isModalOpen && (
        <ExceptionTimePriceModal
          closeModal={handleCloseModal}
          handleSubmit={exceptionHandleSubmit}
          timeSlot={timeSlot}
          defaultValue={value}
          t={t}
          type={modalType}
        />
      )}
    </>
  );
};
