import { PermissionKey, usePermissions } from 'features/users';
import get from 'lodash/get';
import { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type RestrictedRouteProps = {
  children: ReactElement;
  requiredPermission: PermissionKey;
  redirectPath: string;
};

export const RestrictedRoute = ({
  children,
  redirectPath,
  requiredPermission,
}: RestrictedRouteProps) => {
  const permissions = usePermissions();

  if (!permissions) return null;

  const isAllowed = get(permissions, requiredPermission);

  if (isAllowed === true) {
    return children;
  }

  return <Navigate to={redirectPath} replace />;
};
