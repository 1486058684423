import { Empty } from 'antd';
import { ContentFilters, ContentTopBar, Loader } from 'components';
import { ResultError } from 'components/errors';
import {
  MainFilters,
  MainFiltersOptions,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import { useMainFiltersContext } from 'components/main-filters/main-filters.context';
import dayjs from 'dayjs';
import {
  EmptyStateWrapper,
  StyledTimetableBackground,
  TimetableContainer,
  WeekViewFiltersForm,
  WeekViewFiltersFormValues,
} from 'features/bookings/components';
import { WeekTimetable } from 'features/bookings/components/fields-timetable/week-timetable';
import { SportList } from 'features/bookings/components/sport-list/sport-list';
import { useGetFieldBookings } from 'features/bookings/use-cases/get-field-booking';
import { FieldBookingsParams } from 'features/fields';
import { usePermissions } from 'features/users';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';

export type WeekViewParams = FieldBookingsParams & MainFiltersParams;
const initialParams: WeekViewParams = {
  date: formatDate({
    date: dayjs().weekday(0),
    format: 'isoDate',
  }),
  id: undefined,
  sport_id: undefined,
  field_id: undefined,
};

export const WeekView = () => {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const [selectedFieldTitle, setSelectedFieldTitle] = useState('');

  const timetableRef = useRef<HTMLDivElement>(null);
  const [componentTopOffset, setComponentTopOffset] = useState(0);

  const {
    clientTypeOptions: [, setClientType],
    facilityOptions: [, setFacilityOptions],
    companyOptions: [, setCompanyOptions],
  } = useMainFiltersContext();

  const [params, setParams] = useState(initialParams);
  const handleFiltersChange = useCallback(
    (values: WeekViewFiltersFormValues) => {
      setParams((prevParams) => ({
        ...prevParams,
        ...values,
        date: formatDate({
          date: values.date || prevParams.date,
          format: 'isoDate',
        }),
        //date:"2024-03-05",
      }));
    },
    [],
    // (values: WeekViewFiltersFormValues) => {
    //   console.log(values, "values");
    //   // Add one day to the default date if it exists
    //   const defaultDate = values.date ? dayjs(values.date).add(1, 'day') : null;

    //   setParams((prevParams) => ({
    //     ...prevParams,
    //     ...values,
    //     date: formatDate({
    //       date: defaultDate || prevParams.date, // Use the modified date or previous date
    //       format: 'isoDate',
    //     }),
    //   }));
    // },
    // [],
  );

  const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
    setParams((prevParams) => ({
      ...prevParams,
      company_type: permissions?.companyType.select
        ? mainParams?.company_type
        : undefined,
      facility_id: permissions?.facility.select
        ? mainParams?.facility_id
        : undefined,
      company_id: permissions?.company.select
        ? mainParams?.company_id
        : undefined,
      page: 1,
    }));
  };

  const handleMainFiltersOptionChange = useMemo(() => {
    return (mainFiltersOptions: MainFiltersOptions) => {
      if (permissions?.companyType.select && mainFiltersOptions?.company_type) {
        setClientType(mainFiltersOptions?.company_type);
      }
      if (permissions?.facility.select && mainFiltersOptions?.facility_id) {
        setFacilityOptions(mainFiltersOptions?.facility_id);
      }

      if (permissions?.company.select && mainFiltersOptions?.company_id) {
        setCompanyOptions(mainFiltersOptions?.company_id);
      }
    };
  }, [
    permissions?.company.select,
    permissions?.companyType.select,
    permissions?.facility.select,
    setClientType,
    setCompanyOptions,
    setFacilityOptions,
  ]);

  useLayoutEffect(() => {
    if (timetableRef.current) {
      setComponentTopOffset(timetableRef.current.offsetTop);
    }
  }, []);

  const formId = 'week-view-filters-form';

  const payload = {
    id: params.id,
    sport_id: params.sport_id,
    field_id: params.field_id,
    date: params.date,
  };
  const { data, isError, isFetching, isLoading, status, fetchStatus } =
    useGetFieldBookings(payload, { enabled: !!params.id });

  useEffect(() => {
    if (data) {
      setSelectedFieldTitle(`${data?.name} (${data?.facility?.name})`);
    }
  }, [data]);

  const showEmptyState =
    (isLoading && fetchStatus !== 'idle') || !data || !params.id;

  return (
    <>
      <ContentTopBar
        title={t('weekView.heading')}
        ctFilter={
          <ContentFilters formId={formId}>
            <WeekViewFiltersForm
              formId={formId}
              handleFilter={handleFiltersChange}
              initialValues={initialParams}
              facilityId={params.facility_id}
            />
          </ContentFilters>
        }
        renderHeadingContent={
          <MainFilters
            onChange={handleMainFiltersChange}
            onOptionsChange={handleMainFiltersOptionChange}
          />
        }
      />

      {/* <ContentFilters formId={formId}>
        <WeekViewFiltersForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialParams}
          facilityId={params.facility_id}
        />
      </ContentFilters> */}

      {selectedFieldTitle && !showEmptyState && (
        <SportList label={selectedFieldTitle} sports={data?.sports} />
      )}

      <StyledTimetableBackground className='slot_wrap week_view_clg'>
        <TimetableContainer
          className='slot_cell'
          ref={timetableRef}
          $offset={componentTopOffset}
          status={!data ? 'empty' : isFetching ? 'loading' : status}
          headerHeight={50}
        >
          {isFetching ? (
            <Loader />
          ) : isError ? (
            <ResultError />
          ) : showEmptyState ? (
            <EmptyStateWrapper>
              <Empty description={<>{t('weekView.empty')}</>} />
            </EmptyStateWrapper>
          ) : (
            <WeekTimetable
              data={data}
              date={formatDate({ date: params.date, format: 'isoDate' })}
              filteredSportId={params.sport_id}
            />
          )}
        </TimetableContainer>
      </StyledTimetableBackground>
    </>
  );
};
