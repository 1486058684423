import jwtDecode from 'jwt-decode';

import { getAccessToken } from './auth-storage';
import { DecodedAccessToken, SessionData } from './types';

export function decodeAccessToken(): SessionData | null {
  const storedData = getAccessToken();

  if (!storedData) {
    return null;
  }

  try {
    const decodedData = jwtDecode<DecodedAccessToken>(storedData);

    return {
      userId: decodedData.sub,
    };
  } catch (error) {
    console.error('Access token malformed', { error });

    return null;
  }
}
