import React from 'react';
import { useLocation } from 'react-router-dom';
import { getDuration } from 'utils/date';

import { StyledSlotWrapper } from './time-slot.styles';

export type EmptySlotData = {
  type: 'empty';
  startDate: string;
  endDate: string;
};

export const EmptyTimeSlot = ({ data }: { data: EmptySlotData }) => {
  const { startDate, endDate } = data;
  const loction = useLocation();
  const duration = getDuration(startDate, endDate);

  return (
    <StyledSlotWrapper
      className={`${
        loction.pathname == '/week-view' ? 'emptycell' : 'dayEmpty'
      }`}
      duration={duration}
    />
  );
};

export const MemoizedEmptyTimeSlot = React.memo(EmptyTimeSlot);
