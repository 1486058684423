import { FieldDetails } from 'features/fields/components';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const FieldDetailsView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const navigateToList = () => {
    navigate(ROUTES.FIELDS);
  };

  return <FieldDetails fieldId={id} onSuccess={navigateToList} />;
};
