import { Permissions, USER_ROLES } from 'features/users';
import i18n from 'locales/i18n';
import { DefaultTheme } from 'styled-components';
import { Source, User } from 'types';

import { exhaustiveCheck } from './lib';

export type SourceColor =
  | 'white'
  | 'lightGreen'
  | 'green'
  | 'darkGreen'
  | 'blue';

export const sourceTranslationsMap = (
  source: Source,
  permissions: Permissions,
) => {
  const sourceMap: { [key in Source]: string } = {
    [Source.ExternalAdminPortal]: i18n.t('sources.externalAdminPortal'),
    [Source.InternalAdminPortal]: permissions.source.view
      ? i18n.t('sources.internalAdminPortal.li3ib')
      : i18n.t('sources.internalAdminPortal.client'),
    [Source.MobileApp]: permissions.source.view
      ? i18n.t('sources.mobileApp.li3ib')
      : i18n.t('sources.mobileApp.client'),
  };

  return sourceMap[source];
};

export const getSourceColor = (
  source: Source,
  role: User['role'],
): SourceColor => {
  if (USER_ROLES.clientRoles.includes(role)) {
    switch (source) {
      case Source.ExternalAdminPortal:
        return 'darkGreen';
      case Source.InternalAdminPortal:
      case Source.MobileApp:
        return 'green';
      default:
        exhaustiveCheck(source, 'Source');
    }
  }

  if (USER_ROLES.li3ibRoles.includes(role)) {
    switch (source) {
      case Source.ExternalAdminPortal:
        return 'darkGreen';
      case Source.InternalAdminPortal:
        return 'blue';
      case Source.MobileApp:
        return 'green';
      default:
        exhaustiveCheck(source, 'Source');
    }
  }

  return 'white';
};

export const mapSourceColorToToken = (
  theme: DefaultTheme,
  color: SourceColor,
) => {
  switch (color) {
    case 'white':
      return theme.colors.pureWhite;
    case 'lightGreen':
      return theme.antd.colorPrimaryBg;
    case 'green':
      return theme.colors.colorPrimary;
    case 'darkGreen':
      return theme.colors.greenDark;
    case 'blue':
      return theme.antd.geekblue;
    default:
      exhaustiveCheck(color, 'SourceColor');
  }
};
