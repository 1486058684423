import { LeftOutlined } from '@ant-design/icons';
import { Grid, Typography } from 'antd';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  StyledBackButton,
  StyledHeadingContent,
  StyledHeadingWrapper,
  StyledTitleWrapper,
  StyledWrapper,
} from './top-content-bar.styles';

interface TopContentBarProps {
  title: string;
  renderHeadingContent?: ReactNode;
  renderButtons?: ReactNode;
  hasBackButton?: string;
  ctFilter?: ReactNode;
}

const { useBreakpoint } = Grid;

export const ContentTopBar = ({
  title,
  renderHeadingContent,
  renderButtons,
  hasBackButton = '',
  ctFilter,
}: TopContentBarProps) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <StyledWrapper
      className={
        location.pathname == '/companies'
          ? 'cmpny_head'
          : 'cstmhead' && location.pathname === '/bookings'
          ? 'bknghead'
          : 'cstmhead'
      }
    >
      <StyledHeadingWrapper className='day_v_head'>
        <StyledTitleWrapper>
          {hasBackButton.length ? (
            <StyledBackButton
              type='link'
              onClick={() => navigate(hasBackButton)}
            >
              <LeftOutlined width={24} height={24} />
            </StyledBackButton>
          ) : null}
          <Typography.Title
            level={3}
            ellipsis={{ tooltip: title }}
            data-testid='page-heading'
          >
            {title}
          </Typography.Title>
        </StyledTitleWrapper>

        {renderButtons && !screens.lg && (
          <div className='mltyplbtn'>{renderButtons}</div>
        )}
        {renderHeadingContent && (
          <StyledHeadingContent>{renderHeadingContent}</StyledHeadingContent>
        )}

        {ctFilter && <StyledHeadingContent>{ctFilter}</StyledHeadingContent>}
      </StyledHeadingWrapper>
      {renderButtons && screens.lg && (
        <div className='mltyplbtn'>{renderButtons}</div>
      )}
    </StyledWrapper>
  );
};
