import { Grid, Table, TablePaginationConfig } from 'antd';
import { Column, RecordCountBadge, Status } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import {
  GetRecurringBookingSingleBookingsParams,
  RecurringBookingSingleBookingsResponse,
} from 'features/bookings/api/bookings-api.service';
import { StyledAdditionalItemText } from 'features/bookings/components/booking-details/booking-details-info.styles';
import { handleRenderStatus } from 'features/bookings/utils';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePaths, ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { RecurringBookingSingleBookingsListItem } from 'types';
import { formatPrice } from 'utils/currency';
import { DEFAULT_TIMEZONE, formatDate, getDuration } from 'utils/date';
import { presenceStatusVariant } from 'utils/presence';
import { paymentStatusMapToStatusVariant } from 'utils/transaction';

import { BookingsKey } from './types';

const { useBreakpoint } = Grid;

type BookingsTableProps = {
  isBookingListLoading: boolean;
  data?: RecurringBookingSingleBookingsResponse;
  tableParams: GetRecurringBookingSingleBookingsParams;
  updateParams: Dispatch<
    SetStateAction<GetRecurringBookingSingleBookingsParams>
  >;
  fixedBookingId?: string;
};

export const RecurringBookingDetailsTable = ({
  data,
  isBookingListLoading,
  tableParams,
  updateParams,
  fixedBookingId,
}: BookingsTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const permissions = usePermissions();
  const navigate = useNavigate();

  const handleTableChange = (pagination: TablePaginationConfig) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
    });
  };

  if (!fixedBookingId) return null;
  if (!permissions) return null;

  const getDetailsRoute = (
    route: RoutePaths,
    id: string | number,
    singleBookingId: string | number,
  ) => {
    return getRoute(route, id).replace(
      `:booking_id`,
      singleBookingId.toString(),
    );
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('bookings.foundBookings')}
          count={data?.total}
        />
      </StyledTableHeader>
      <Table<RecurringBookingSingleBookingsListItem>
        loading={isBookingListLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        scroll={{ x: 1440 }}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(
                  getDetailsRoute(
                    ROUTES.FIXED_BOOKINGS_EDIT,
                    fixedBookingId,
                    record.id,
                  ),
                );
              }
            },
          };
        }}
      >
        <Column<RecurringBookingSingleBookingsListItem>
          title={t('bookings.table.date')}
          dataIndex={'start_date'}
          key={BookingsKey.GameScheduleDate}
          width={screens.xxl ? 'auto' : 120}
          render={(_, record) => (
            <>
              {formatDate({
                date: record.start_date,
                format: 'date',
                timeZone: DEFAULT_TIMEZONE,
              })}
            </>
          )}
        />
        <Column<RecurringBookingSingleBookingsListItem>
          title={t('bookings.table.time')}
          dataIndex={'start_date'}
          key={BookingsKey.GameScheduleTime}
          width={screens.xxl ? 'auto' : 100}
          render={(_, record) => {
            const duration = getDuration(record.start_date, record.end_date);

            return (
              <>
                {formatDate({
                  date: record.start_date,
                  format: 'time',
                  timeZone: DEFAULT_TIMEZONE,
                })}
                <StyledAdditionalItemText type='secondary'>
                  {t('common.minutes.short', { value: duration })}
                </StyledAdditionalItemText>
              </>
            );
          }}
        />
        <Column<RecurringBookingSingleBookingsListItem>
          title={t('bookings.table.id')}
          dataIndex={'id'}
        />
        <Column<RecurringBookingSingleBookingsListItem>
          title={t('bookings.table.price')}
          dataIndex={'price'}
          width={screens.xxl ? 'auto' : 200}
          render={(_, record) => {
            if (!record.transaction[0]) return t('errors.noData');

            return (
              <>
                {formatPrice({
                  value:
                    record.transaction[0]?.total_cost -
                    record.transaction[0]?.discount,
                  currency: 'KWD',
                })}
              </>
            );
          }}
        />
        <Column<RecurringBookingSingleBookingsListItem>
          title={t('bookings.table.discount')}
          dataIndex={['transaction', 'discount']}
          render={(_, record) => {
            if (!record.transaction[0]) return t('errors.noData');

            return (
              <>
                {formatPrice({
                  value: record.transaction[0]?.discount,
                  currency: 'KWD',
                })}
              </>
            );
          }}
        />
        <Column<RecurringBookingSingleBookingsListItem>
          title={t('bookings.table.payment')}
          render={(_, record) => {
            const variant =
              paymentStatusMapToStatusVariant[
                record.transaction[0]?.payment_status
              ];

            if (!variant) return t('errors.noData');

            return (
              <Status variant={variant}>
                <Status.Icon />
              </Status>
            );
          }}
        />
        <Column<RecurringBookingSingleBookingsListItem>
          title={t('bookings.table.presence')}
          render={(_, record) => (
            <Status variant={presenceStatusVariant(record?.client_showed_up)}>
              <Status.Icon />
            </Status>
          )}
        />
        <Column<RecurringBookingSingleBookingsListItem>
          title={t('bookings.table.status')}
          render={(_, record) => handleRenderStatus(record)}
        />
      </Table>
    </StyledTableWrapper>
  );
};
