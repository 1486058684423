import { Gender } from 'types';
import { GenderTranslationsMap } from 'utils/gender';

export const useGenderOptions = () => {
  return [
    {
      label: GenderTranslationsMap[Gender.NotSpecified],
      value: Gender.NotSpecified,
    },
    {
      label: GenderTranslationsMap[Gender.Male],
      value: Gender.Male,
    },
    {
      label: GenderTranslationsMap[Gender.Female],
      value: Gender.Female,
    },
  ];
};
