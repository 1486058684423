import { Result } from 'antd';
import styled from 'styled-components';

export const StyledResult = styled(Result)`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;

  & > *:not(.ant-result-image) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;
