import styled from 'styled-components';
import { colors } from 'styles';

export const StyledTransactionDateTime = styled.p`
  color: ${({ theme }) => theme.antd.colorTextDescription};
`;

type WalletTransactionAmountProps = {
  $color?: string;
};

export const WalletTransactionAmount = styled.div<WalletTransactionAmountProps>`
  ${({ $color }) =>
    $color && $color === 'red'
      ? `color: ${colors.redBright}`
      : $color === 'green'
      ? `color: ${colors.colorPrimary}`
      : $color === 'black'
      ? `color: ${colors.colorPrimaryText}`
      : null}
`;
