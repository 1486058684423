/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface WizardContextState {
  wizardState: Record<any, any>;
  setWizardState: Dispatch<SetStateAction<Record<any, any>>>;
}

const WizardContext = createContext<WizardContextState | undefined>(undefined);

interface WizardContextProviderProps {
  children: ReactNode;
}

const WizardContextProvider = ({ children }: WizardContextProviderProps) => {
  const [wizardState, setWizardState] = useState({});

  return (
    <WizardContext.Provider value={{ wizardState, setWizardState }}>
      {children}
    </WizardContext.Provider>
  );
};

const useWizardContext = () => {
  const context = useContext(WizardContext);

  if (!context) {
    throw new Error('Wizard context must be used within a Provider!');
  }

  return context;
};

export { useWizardContext, WizardContextProvider };
