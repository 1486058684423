import { StatusVariant } from 'components';
import i18n from 'locales/i18n';
import { PaymentMethod, PaymentStatus } from 'types';

export const paymentMethodTranslationsMap: { [key in PaymentMethod]: string } =
  {
    [PaymentMethod.Cash]: i18n.t('paymentMethods.cash'),
    [PaymentMethod.Knet]: i18n.t('paymentMethods.knet'),
    [PaymentMethod.Wallet]: i18n.t('paymentMethods.wallet'),
    [PaymentMethod.Credit]: i18n.t('paymentMethods.credit'),
    [PaymentMethod.Apple]: i18n.t('paymentMethods.apple'),
  };

export const paymentStatusMapToStatusVariant = {
  [PaymentStatus.Paid]: StatusVariant.success,
  [PaymentStatus.Unpaid]: StatusVariant.error,
  [PaymentStatus.Created]: StatusVariant.default,
};
