import { Button, DatePicker, Form, Grid } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { ContentFilters, Select } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

import { useFieldOptions } from './hooks/use-field-options';
import { useInitialLoad } from './hooks/use-initial-load';
import { useSportsOptions } from './hooks/use-sports-options';

type WeekViewFiltersFormProps = {
  formId: string;
  handleFilter: (values: WeekViewFiltersFormValues) => void;
  initialValues: Partial<WeekViewFiltersFormValues>;
  closeModal?: () => void;
  facilityId?: string;
};

export enum WeekViewFiltersFormField {
  Date = 'date',
  Sport = 'sport_id',
  Field = 'id',
}

export type WeekViewFiltersFormValues = {
  [WeekViewFiltersFormField.Date]?: Dayjs | string;
  [WeekViewFiltersFormField.Sport]?: string | undefined;
  [WeekViewFiltersFormField.Field]?: string | undefined;
};

const { useBreakpoint } = Grid;

export const WeekViewFiltersForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
  facilityId,
}: WeekViewFiltersFormProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const [form] = Form.useForm<WeekViewFiltersFormValues>();
  const selectedSport = Form.useWatch(WeekViewFiltersFormField.Sport, form);
  //console.log(initialValues,"initialValues");
  const sportOptions = useSportsOptions();
  const fieldOptions = useFieldOptions(selectedSport);
  useInitialLoad(form, fieldOptions, handleFilter);

  useEffect(() => {
    if (!facilityId) {
      form.setFieldValue(WeekViewFiltersFormField.Field, undefined);
      form.setFieldValue(WeekViewFiltersFormField.Sport, undefined);
    }
  }, [facilityId, form]);

  const handleOnFinish = (values: WeekViewFiltersFormValues) => {
    handleFilter({
      [WeekViewFiltersFormField.Field]: values[WeekViewFiltersFormField.Field],
      [WeekViewFiltersFormField.Date]: dayjs(
        values[WeekViewFiltersFormField.Date],
      ),
      [WeekViewFiltersFormField.Sport]: values[WeekViewFiltersFormField.Sport],
    });
    closeModal?.();
  };

  const onReset = () => {
    form.resetFields();
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && dayjs(current).day() !== 0;
  };

  return (
    <Form<WeekViewFiltersFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish}
      initialValues={{ ...initialValues, date: dayjs(initialValues.date) }}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<WeekViewFiltersFormValues>
            label={t('weekView.formFields.date.label')}
            name={WeekViewFiltersFormField.Date}
          >
            <DatePicker
              allowClear={false}
              format={DATE_FORMATS.date}
              disabledDate={disabledDate}
            />
          </Form.Item>

          <Form.Item<WeekViewFiltersFormValues>
            label={t('weekView.formFields.sport.label')}
            name={WeekViewFiltersFormField.Sport}
          >
            <Select
              placeholder={t('weekView.formFields.sport.placeholder')}
              bordered
              className='filter'
              allowClear
              options={sportOptions}
            />
          </Form.Item>

          <Form.Item<WeekViewFiltersFormValues>
            label={t('weekView.formFields.field.label')}
            name={WeekViewFiltersFormField.Field}
          >
            <Select
              placeholder={t('weekView.formFields.field.placeholder')}
              dropdownMatchSelectWidth={200}
              bordered
              className='filter'
              allowClear
              options={fieldOptions}
            />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('bookings.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('bookings.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
