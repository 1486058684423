import { Form, Grid } from 'antd';
import { Modal } from 'components';
import { UserInfoFormItems } from 'features/users/components/modals/user-info-form-items';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import {
  UserFormField,
  UserFormValues,
} from 'features/users/components/users-filter-form';
import { useCreateUser } from 'features/users/use-cases/create-user';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordFormItems } from './password-form-items';
const { useBreakpoint } = Grid;

export const AddAdminUserModal = ({ onClose }: { onClose: () => void }) => {
  const formName = 'add-user-form';
  const screens = useBreakpoint();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UserFormValues>();
  const { t } = useTranslation();

  const { isLoading, mutate } = useCreateUser();

  const getInitialValues = {
    [UserFormField.FirstName]: undefined,
    [UserFormField.LastName]: undefined,
    [UserFormField.PhoneNumber]: undefined,
    [UserFormField.Company]: undefined,
    [UserFormField.Role]: undefined,
    [UserFormField.Email]: undefined,
    [UserFormField.Gender]: undefined,
  };

  const handleUpdate = (formData: UserFormValues) => {
    mutate(formData, { onSuccess: onClose });
  };

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        title={t('adminUsers.addNewUser')}
        onCancel={onClose}
        getContainer={false}
        onOk={form.submit}
        okText={t('adminUsers.addNewUser')}
        cancelButtonProps={{ style: { display: 'none' } }}
        width={screens.lg ? '520px' : '100%'}
        okButtonProps={{
          form: formName,
        }}
      >
        <Form<UserFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={getInitialValues}
          onFinish={handleUpdate}
          requiredMark={'optional'}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <UserInfoFormItems form={form} />
          <PasswordFormItems />
        </Form>
      </Modal>
    </StyledUserModalWrapper>
  );
};
