/* eslint-disable @typescript-eslint/no-shadow */
import { Dayjs } from 'dayjs';
import { FieldCategory, SportType, TimePrice } from 'types';

export enum FieldDetailsField {
  Company = 'company',
  Facility = 'facility_id',
  Area = 'area',
  Name = 'name',
  NameArabic = 'name_arabic',
  Rank = 'rank',
  Geolocation = 'geolocation',
  TimeSlot = 'time_slot',
  OpeningHours = 'opening_hours',
  TimePrice = 'time_price',
  Price = 'price',
  TeamSize = 'max_players',
  Sports = 'sport_ids',
  Gender = 'gender',
  FreeAmenities = 'free_amenities',
  ChargeableAmenities = 'chargeable_amenities',
  CategoriesType = 'categories_type',
  Type = 'type',
  Width = 'width',
  Length = 'length',
  ExceptionTimePrice = 'exception_time_price',
}

type ExceptionDays = {
  exception_days_range: Dayjs[];
  exception_days_slot_price: string;
};
export enum Weekdays {
  Sunday = 'SU',
  Monday = 'MO',
  Tuesday = 'TU',
  Wednesday = 'WE',
  Thursday = 'TH',
  Friday = 'FR',
  Saturday = 'SA',
}

export type multiPleExceptionalTimeRange = {
  exceptionDays: Dayjs[];
  exceptionTimeRange: ExceptionDays[];
  exceptionWeekDays: Weekdays[];
};

export type FieldDetailsFormValues = {
  [FieldDetailsField.Company]: string;
  [FieldDetailsField.Facility]: string;
  [FieldDetailsField.Area]: string;
  [FieldDetailsField.Name]: string;
  [FieldDetailsField.NameArabic]: string;
  [FieldDetailsField.Rank]: number;
  [FieldDetailsField.Geolocation]: string;
  [FieldDetailsField.TimeSlot]: string;
  [FieldDetailsField.OpeningHours]: [];
  [FieldDetailsField.TimePrice]: TimePrice;
  [FieldDetailsField.TeamSize]: number;
  [FieldDetailsField.Sports]: string[];
  [FieldDetailsField.Gender]: FieldCategory;
  [FieldDetailsField.FreeAmenities]: string[];
  [FieldDetailsField.ChargeableAmenities]: string[];
  [FieldDetailsField.CategoriesType]: FieldCategory;
  [FieldDetailsField.Type]: SportType;
  [FieldDetailsField.Width]: number;
  [FieldDetailsField.Length]: number;
  [FieldDetailsField.Length]: number;
  [FieldDetailsField.ExceptionTimePrice]: multiPleExceptionalTimeRange[];
};
