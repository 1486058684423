import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS, colors } from 'styles';

export const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.antd.colorBgLayout};
  color: ${({ theme }) => theme.colors.pureBlack};
  padding: 16px;
  border-radius: 8px;
  font-size: 1.6rem;
  line-height: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  ${BREAKPOINTS.laptop} {
    width: fit-content;
  }
`;

export const StyledConfirmationItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  font-weight: 500;

  & > span {
    font-size: inherit;
  }

  ${BREAKPOINTS.laptop} {
    height: 32px;
    gap: 20px;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  gap: 14px;
`;

interface StyledButtonProps extends ButtonProps {
  $isActive?: boolean;
}

export const StyledButton = styled(Button)<StyledButtonProps>`
  &.confirmation-button {
    display: flex;
    background: none;
    border: none;
    box-shadow: none;
    width: 42px;
    height: 42px;

    ${BREAKPOINTS.laptop} {
      width: 28px;
      height: 28px;
    }

    color: ${({ theme }) => theme.antd.colorIcon};

    &.confirmation-positive {
      &:hover {
        color: ${({ theme }) => theme.colors.colorPrimary};
      }
      &:active {
        color: ${({ theme }) => theme.colors.greenDark};
      }
    }

    &.confirmation-negative {
      &:hover {
        color: ${({ theme }) => theme.colors.redBright};
      }
      &:active {
        color: ${({ theme }) => theme.colors.pureBlack};
      }
    }

    &.confirmation-negative,
    &.confirmation-positive {
      &:disabled {
        color: ${({ theme }) => theme.antd.colorTextDisabled};
      }
    }

    ${({ $isActive }) =>
      $isActive &&
      `
      &.confirmation-positive {
        color: ${colors.colorPrimary}!important;
      }
  
      &.confirmation-negative {
        color: ${colors.redBright}!important;
      }
    `}

    .confirmation-icon,
    .ant-btn-loading-icon {
      align-self: center;
      transform: scale(1); // reset icon scaling

      font-size: 4.2rem;
      color: inherit;

      ${BREAKPOINTS.laptop} {
        font-size: 2.8rem;
      }
    }
  }
`;
