import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const StyledFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  ${BREAKPOINTS.laptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: wrap;
  gap: 16px;

  ${BREAKPOINTS.mobile} {
    flex-flow: initial;
    gap: 8px;
  }

  ${BREAKPOINTS.laptop} {
    width: 320px;
    margin-bottom: 24px;
    margin-top: 30px;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .modal-footer-button {
    width: 100%;
    margin: 0 !important;

    ${BREAKPOINTS.laptop} {
      width: initial;
    }
  }
  .advanced-filters-button {
    color: ${({ theme }) => theme.antd.colorPrimary};
  }
`;

export const StyledFiltersWrapper = styled.div`
  padding: 16px 16px 0;
  width: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.pureWhite};

  form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }
`;

export const StyledFiltersModalWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledFormItemsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  & .ant-picker {
    width: 100%;
  }

  ${BREAKPOINTS.laptop} {
    gap: initial;
    align-items: flex-start;

    & > * {
      min-width: 160px;
    }
  }
`;

export const StandardItemsWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;

  ${BREAKPOINTS.laptop} {
    flex-direction: row;
  }
`;

type StyledAdvancedFiltersWrapperProps = {
  openAdvancedFilters: boolean;
};

export const StyledAdvancedFiltersWrapper = styled.div<StyledAdvancedFiltersWrapperProps>`
  display: none;
  height: 0;

  ${({ openAdvancedFilters }) =>
    openAdvancedFilters &&
    `
    display: flex;
    flex-wrap: wrap;
    height: auto;
    gap: 16px;
  `}
`;
