import { Typography } from 'antd';
import { formatMaxPlayersToTeamSize } from 'features/fields';
import { useTranslation } from 'react-i18next';
import { BookingType } from 'types';
import { Weekdays, weekdaysTranslationsMap } from 'types/days';
import { bookingTypeTranslationsMap } from 'utils/bookings';
//import { formatDate, formatDateRange } from 'utils/date';
import { formatDate } from 'utils/date';

import { SectionLabel, Wrapper } from './fixed-booking-invoice.styles';
import {
  InvoiceGridColumn,
  InvoiceGridItem,
  InvoiceGridWrapper,
} from './invoice-grid';

type InvoiceBookingDetailsProps = {
  data: {
    company_name: string;
    facility_name: string;
    field_name: string;
    date_from: string;
    date_to: string;
    weekdays_list: Weekdays[];
    weekday?: Weekdays;
    start_time: string;
    end_time: string;
    max_players: number;
    bookings_count: number;
    type: Exclude<BookingType, 'STANDARD'>;
  };
};

export const InvoiceBookingDetails = ({ data }: InvoiceBookingDetailsProps) => {
  const { t } = useTranslation();
  let weekDaywithmap = '';
  const getWeekDay = () => {
    if (data.weekdays_list !== undefined && data.weekdays_list.length > 0) {
      data.weekdays_list.map((i) => {
        if (weekDaywithmap !== '') {
          weekDaywithmap = weekDaywithmap + ',' + weekdaysTranslationsMap(i);
        } else {
          weekDaywithmap = weekdaysTranslationsMap(i);
        }

        return weekDaywithmap;
      });
    } else {
      const weekD = data.weekday?.split(',');
      weekD?.map((i: any) => {
        if (weekDaywithmap !== '') {
          weekDaywithmap = weekDaywithmap + ',' + weekdaysTranslationsMap(i);
        } else {
          weekDaywithmap = weekdaysTranslationsMap(i);
        }

        return weekDaywithmap;
      });
    }
  };
  getWeekDay();

  return (
    <Wrapper>
      <SectionLabel>
        <Typography.Text strong>
          {t('bookings.invoice.labels.bookingDetails')}
        </Typography.Text>
      </SectionLabel>

      <InvoiceGridWrapper>
        <InvoiceGridColumn>
          <InvoiceGridItem
            label={t('bookings.invoice.labels.companyName')}
            value={data.company_name}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.facilityName')}
            value={data.facility_name}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.fieldName')}
            value={data.field_name}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.dateStart')}
            value={formatDate({
              date: data.date_from,
              format: 'date',
            })}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.dateEnd')}
            value={formatDate({
              date: data.date_to,
              format: 'date',
            })}
          />
        </InvoiceGridColumn>
        <InvoiceGridColumn>
          <InvoiceGridItem
            label={t('bookings.invoice.labels.days')}
            // value={data?.weekday ? weekdaysTranslationsMap(data.weekday) : ''}
            value={
              data?.weekday
                ? weekDaywithmap
                : data?.weekdays_list
                ? weekDaywithmap
                : ''
            }
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.time')}
            value={
              formatTimestampToAMPM(data.start_time) +
              ' - ' +
              formatTimestampToAMPM(data.end_time)
            }
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.maxTeamSize')}
            value={formatMaxPlayersToTeamSize(data.max_players)}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.totalBookings')}
            value={data.bookings_count}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.bookingType')}
            value={bookingTypeTranslationsMap(data.type)}
          />
        </InvoiceGridColumn>
      </InvoiceGridWrapper>
    </Wrapper>
  );

  function formatTimestampToAMPM(timestamp: any) {
    if (isNaN(timestamp)) {
      return timestamp;
    } else {
      const date = new Date(timestamp);
      const hours = date.getHours();
      const minutes = date.getMinutes();

      return `${hours % 12 || 12}:${minutes < 10 ? '0' : ''}${minutes} ${
        hours >= 12 ? 'PM' : 'AM'
      }`;
    }
  }
};
