import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  SportsApiService,
  sportsQueryKeys,
  UpdateSportDTO,
} from 'features/sports';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

const { updateSport } = SportsApiService();

export const useUpdateSport = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateSportDTO) => {
      return updateSport(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: sportsQueryKeys.lists(),
          }),
        ]);
        message.success(
          i18n.t('sports.details.successfulUpdate', {
            sport: data.name,
          }),
        );
      },
    },
  );
};
