import { EditAdminUserModal } from 'features/users/components/modals/edit-admin-user-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const AdminUserUpdateView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.USERS_ADMIN);
  };

  return (
    <EditAdminUserModal id={id} onClose={closeModal} onSuccess={closeModal} />
  );
};
