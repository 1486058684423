import { PaginatedRequestParams } from 'api';

export enum SportsFilterFormField {
  SportName = 'name',
}

export type SportsFilterFormValues = {
  [SportsFilterFormField.SportName]: string | undefined;
};

export type SportsFilterFormPaginated = SportsFilterFormValues &
  PaginatedRequestParams;
