import { Typography } from 'antd';
import styled from 'styled-components';
import { Sport } from 'types';

import { SportIconsList } from './sport-icons-list';

type SportListProps = {
  label: string;
  sports?: Pick<Sport, 'id' | 'name' | 'icon'>[];
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;

  margin-top: 32px;
  margin-bottom: 16px;

  background: transparent;
`;

export const SportList = ({ label, sports = [] }: SportListProps) => {
  return (
    <Wrapper>
      <Typography.Text strong>{label}</Typography.Text>
      {sports?.length > 0 && <SportIconsList sports={sports} />}
    </Wrapper>
  );
};
