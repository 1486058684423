import {
  DoubleRightOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, Input } from 'antd';
import { useUserContext } from 'features/users';
import { canEditBooking, canViewPhoneNumber } from 'features/users/permissions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Booking } from 'types';
import { phoneNumberPattern } from 'utils/validations';

import { BookingDetailsField, FilterFormValues } from './types';

type BookingDetailsFormProps = {
  bookingData: Booking;
  handleUpdate: (object: FilterFormValues) => void;
};

export const BookingDetailsForm = ({
  bookingData,
  handleUpdate,
}: BookingDetailsFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<FilterFormValues>();
  const { t } = useTranslation();
  const { user } = useUserContext();

  if (!user) return null;

  const getInitialValues = {
    [BookingDetailsField.FirstName]: bookingData.user.first_name,
    [BookingDetailsField.LastName]: bookingData.user.last_name,
    [BookingDetailsField.PhoneNumber]: bookingData.user.phone_number,
    [BookingDetailsField.Discount]: bookingData.transaction.discount,
  };

  const canEdit = canEditBooking(user, bookingData);

  return (
    <Form<FilterFormValues>
      id='booking-details-client-form'
      name='booking-details-client-form'
      form={form}
      layout='vertical'
      initialValues={getInitialValues}
      onFinish={handleUpdate}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
      requiredMark='optional'
    >
      {canViewPhoneNumber(user, bookingData) ? (
        <Form.Item<BookingDetailsField>
          label={t('bookings.formFields.phoneNumber.label')}
          name={BookingDetailsField.PhoneNumber}
          rules={[
            {
              required: true,
            },
            {
              pattern: phoneNumberPattern,
              message: t('validateMessages.custom.phoneNumber'),
            },
          ]}
        >
          <Input
            type='tel'
            placeholder={t('bookings.formFields.phoneNumber.placeholder')}
            prefix={<PhoneOutlined className='input-prefix-icon' />}
            disabled={!canEdit.phoneNumber}
            size='large'
            maxLength={17}
            onKeyPress={(e) => {
              // Check if the entered key is a number, "+", or Backspace (to allow deletion)
              const isNumberOrPlus = /^[0-9+]*$/.test(e.key);
              const isBackspace = e.key === 'Backspace';

              // Check if the total length after the new character insertion will be <= 15
              const inputValue = (e.target as HTMLInputElement).value || '';
              const totalLength = inputValue.length + (isBackspace ? -1 : 1);

              if (!isNumberOrPlus || totalLength > 17) {
                e.preventDefault();
              }
            }}
          />
        </Form.Item>
      ) : null}

      <Form.Item<BookingDetailsField>
        label={t('bookings.formFields.firstName.label')}
        name={BookingDetailsField.FirstName}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('bookings.formFields.firstName.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
          disabled={!canEdit.firstName}
          size='large'
        />
      </Form.Item>
      <Form.Item<BookingDetailsField>
        label={t('bookings.formFields.lastName.label')}
        name={BookingDetailsField.LastName}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('bookings.formFields.lastName.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
          disabled={!canEdit.lastName}
          size='large'
        />
      </Form.Item>
      <Form.Item<BookingDetailsField>
        label={t('bookings.formFields.discount.label')}
        name={BookingDetailsField.Discount}
      >
        <Input
          placeholder={t('bookings.formFields.discount.placeholder')}
          prefix={
            <DoubleRightOutlined rotate={90} className='input-prefix-icon' />
          }
          size='large'
          disabled={!canEdit.discount}
        />
      </Form.Item>
      <Form.Item<BookingDetailsField>
        label={t('bookings.formFields.comment.label')}
        name={BookingDetailsField.Comment}
      >
        <Input.TextArea
          showCount
          rows={2}
          placeholder={`${t('bookings.formFields.comment.placeholder')}`}
          maxLength={1000}
          size='large'
        />
      </Form.Item>
    </Form>
  );
};
