import type { TabsProps } from 'antd';
import { Button, Form, Grid, Tabs } from 'antd';
import { Modal } from 'components';
import { TFunction } from 'i18next';
import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import { useState } from 'react';
import type { AllDays, TimePrice } from 'types';

import { AllDaysTab } from './all-days-tab';
//import { ExceptionDaysTab } from './exception-days-tab';
import { TimePriceModalFields } from './types';
import { firstRangeValidator, rangeValidator } from './utils';
const { useBreakpoint } = Grid;

type TimePriceModalProps = {
  closeModal: () => void;
  handleSubmit: (formData: AllDays) => void;
  timeSlot: string;
  defaultValue: TimePrice;
  openingHours: Date[];
  t: TFunction;
};

export const TimePriceModal = ({
  closeModal,
  handleSubmit: onFinish,
  timeSlot,
  defaultValue,
  openingHours,
  t,
}: TimePriceModalProps) => {
  const [activeTab, setActiveTab] = useState<string>('1');
  const screens = useBreakpoint();
  const [form] = Form.useForm();

  // const deleteTimeRange(id)

  const getInitialValues = (defaultValues: TimePrice) => {
    const allDays = [
      ...(defaultValues.all_days && defaultValues.all_days.length >= 1
        ? defaultValues.all_days
        : [
            {
              [TimePriceModalFields.AllDaysRange]: undefined,
              [TimePriceModalFields.AllDaysSlot]: undefined,
            },
          ]),
    ];

    const exceptionDays = [
      ...(defaultValues.exception_days &&
      defaultValues.exception_days.length > 0
        ? defaultValues.exception_days
        : [
            {
              [TimePriceModalFields.ExceptionDaysRange]: undefined,
              [TimePriceModalFields.ExceptionDaysSlot]: undefined,
            },
          ]),
    ];

    const exceptionDaysList =
      defaultValues?.[TimePriceModalFields.ExceptionDaysList] || [];

    return {
      [TimePriceModalFields.AllDays]: allDays,
      [TimePriceModalFields.ExceptionDays]: exceptionDays,
      [TimePriceModalFields.ExceptionDaysList]: exceptionDaysList,
    };
  };

  // const exceptionValidator = async (rule: any, value: any) => {
  //   debugger ;

  //   const firstRange = 'exception_days.0.exception_days_range';
  //   const lastItemIndex = form.getFieldValue('exception_days')?.length - 1;
  //   const lastRange =
  //     'exception_days.' + lastItemIndex + '.exception_days_range';

  //   if (rule.field === firstRange) {
  //     const [startTime, endTime] = value;
  //     if (
  //       startTime.isSame(endTime, 'hour') &&
  //       startTime.isSame(endTime, 'minute')
  //     ) {
  //       return Promise.reject('Time should not be of the same');
  //     }
  //   } else if (rule.field === lastRange) {
  //     const firstRangeValue =
  //       form.getFieldValue('exception_days')?.[0]?.exception_days_range;

  //     await exceptionRangeValidator(
  //       rule,
  //       value,
  //       Number(timeSlot),
  //       firstRangeValue,
  //       t,
  //     );
  //   }
  // };

  // TODO: find the correct type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validator = async (rule: any, value: Date[]) => {
    const firstRange = 'all_days.0.all_days_range';
    // const secondRange = 'all_days.1.all_days_range';
    const lastItemIndex = form.getFieldValue('all_days')?.length - 1;
    const lastRange = 'all_days.' + lastItemIndex + '.all_days_range';

    if (rule.field === firstRange) {
      await firstRangeValidator(rule, value, openingHours, Number(timeSlot), t);
    }
    /////// Modified By : Anil Ghildiyal
    /////// Commented to force check and consider the second range as a end of series..
    //     else if (rule.field === secondRange) {
    //       // const firstRangeValue =
    //       //   form.getFieldValue('all_days')?.[0]?.all_days_range;
    //       // await rangeValidator(
    //       //   rule,
    //       //   value,
    //       //   openingHours,
    //       //   Number(timeSlot),
    //       //   firstRangeValue,
    //       //   t,
    //       // );
    //     }
    else if (rule.field === lastRange) {
      /////// Modified By : Anil Ghildiyal
      /////// Checking if the price is filled then only check for last item end time should be coincide
      /////// with the closing time of the field.
      /////// This condition added to avoid last item checking at the time of the time filling. Now this
      /////// validation checks only on the final Add/Update Price Range button click.
      const allranged =
        form.getFieldValue('all_days')?.[lastItemIndex]?.all_days_slot_price;

      if (allranged !== undefined) {
        const firstRangeValue =
          form.getFieldValue('all_days')?.[0]?.all_days_range;
        await rangeValidator(
          rule,
          value,
          openingHours,
          Number(timeSlot),
          firstRangeValue,
          t,
        );
      }
    }
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('fields.modal.labels.allDays'),
      children: <AllDaysTab t={t} timeSlot={timeSlot} validator={validator} />,
    },
    // {
    //   key: '2',
    //   label: t('fields.modal.labels.exceptionDays'),
    //   children: (
    //     <ExceptionDaysTab
    //       t={t}
    //       timeSlot={timeSlot}
    //       validator={exceptionValidator}
    //     />
    //   ),
    // },
  ];

  const onFinishFailed = (errorInfo: ValidateErrorEntity<AllDays>) => {
    let allDaysHasError = false;
    let exceptionDaysHasError = false;

    for (let i = 0; i < errorInfo?.errorFields.length; i++) {
      if (errorInfo.errorFields[i].name[0] === 'all_days') {
        allDaysHasError = true;
      }

      if (String(errorInfo.errorFields[i].name[0]).includes('exception_days')) {
        exceptionDaysHasError = true;
      }
    }

    switch (true) {
      case allDaysHasError && exceptionDaysHasError:
        setActiveTab('1');
        break;
      case allDaysHasError:
        setActiveTab('1');
        break;
      case exceptionDaysHasError:
        setActiveTab('2');
        break;
      default:
        break;
    }
  };

  const isFirstInputFilled = () =>
    defaultValue?.all_days?.[0]?.all_days_range.length > 0;

  return (
    <Modal
      open
      onCancel={closeModal}
      title={t('fields.modal.titles.addNewPriceRange')}
      footer={[
        <Button
          key='submit'
          type='primary'
          htmlType='submit'
          form='time-price-form'
          size={screens.lg ? 'middle' : 'large'}
        >
          {isFirstInputFilled()
            ? t('fields.buttons.updatePriceRange')
            : t('fields.buttons.addNewPriceRange')}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        autoComplete='off'
        onFinish={onFinish}
        id='time-price-form'
        name='time-price-form'
        onFinishFailed={onFinishFailed}
        initialValues={getInitialValues(defaultValue)}
      >
        <Tabs
          items={items}
          activeKey={activeTab}
          onChange={(activeKey) => setActiveTab(activeKey)}
        />
      </Form>
    </Modal>
  );
};
