import { Avatar } from 'components/avatar/avatar';
import styled from 'styled-components';

const StyledFallbackAvatar = styled(Avatar)`
  color: ${({ theme }) => theme.antd.colorText};
  border: 0;
`;

export const FallbackIcon = ({ label }: { label: string }) => {
  return (
    <StyledFallbackAvatar size={24} aria-label={label}>
      {label.charAt(0).toUpperCase()}
    </StyledFallbackAvatar>
  );
};
