import i18n from 'locales/i18n';
import { BookingType } from 'types';

export const bookingTypeTranslationsMap = (type: BookingType) => {
  switch (type) {
    case BookingType.Fixed:
      return i18n.t('bookingTypes.fixed');
    case BookingType.Closed:
      return i18n.t('bookingTypes.closed');
    case BookingType.Maintenance:
      return i18n.t('bookingTypes.maintenance');
    default:
      return type;
  }
};
