import { Modal as AntModal } from 'antd';
import styled, { css } from 'styled-components';

import { ModalProps } from './modal';

export const StyledModal = styled(AntModal)<ModalProps>`
  &.ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        padding-bottom: 16px;
        margin-bottom: 24px;
        border-bottom: 1px solid
          ${({ theme }) => theme.antd.colorBorderSecondary};

        .ant-modal-title {
          font-size: 2.4rem;
        }
      }

      .ant-modal-close {
        top: 28px;
        inset-inline-end: 24px;

        & .anticon {
          font-size: 2.1rem;
        }
      }

      .ant-modal-footer {
        margin-top: 24px;
        padding-top: 24px;
        border-top: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary};
      }
    }

    &.modal-mobile {
      ${({ isFullScreenOnMobile }) =>
        isFullScreenOnMobile
          ? css`
              max-width: 100vw;
              height: 100vh;
              top: 0;
              padding: 0;
            `
          : undefined}

      .ant-modal-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: ${({ isFullScreenOnMobile }) =>
          isFullScreenOnMobile ? 0 : undefined};
        overflow: auto;

        .ant-modal-footer {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          gap: 16px;

          & > button {
            width: 100%;
          }
        }
      }
    }
  }
`;
