import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  TopUpWalletDTO,
  WalletApiService,
  walletQueryKeys,
} from 'features/wallet';
import i18n from 'locales/i18n';

const { topUpWallet } = WalletApiService();

export const useTopUpWallet = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: TopUpWalletDTO) => {
      return topUpWallet(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: walletQueryKeys.all,
        });
        message.success(i18n.t('wallet.topUp.successMessage'));
      },
    },
  );
};
