import { Dayjs } from 'dayjs';
import { BookingTransaction, PaymentStatus, User } from 'types';

import { Weekdays } from './days';

/**
 * Duration in minutes
 */
export enum Duration {
  duration30 = '30',
  duration45 = '45',
  duration60 = '60',
  duration90 = '90',
  duration120 = '120',
}

export enum BookingCommentResourceType {
  Booking = 'booking',
  FixedBooking = 'fixed_booking',
}

export enum BookingStatus {
  active = 'Active',
  cancelled = 'Cancelled',
  completed = 'Completed',
}

export enum BookingType {
  Closed = 'CLOSED',
  Fixed = 'FIXED',
  Maintenance = 'MAINTENANCE',
  Standard = 'STANDARD',
}

export enum Source {
  ExternalAdminPortal = 'ExternalAdminPortal',
  InternalAdminPortal = 'InternalAdminPortal',
  MobileApp = 'MobileApp',
}

export type RecurringBookingListItem = {
  id: string;
  date_from: string;
  date_to: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  field: {
    id: string;
    name: string;
    facility: {
      id: string;
      name: string;
      company: {
        id: string;
        name: string;
      };
    };
  };
  source: Source;
  bookings_count: number;
  type: Exclude<BookingType, 'STANDARD'>;
};

export type BookingListItem = {
  id: string;
  user: Pick<User, 'id' | 'first_name' | 'last_name' | 'phone_number'>;
  start_date: string;
  end_date: string;
  client_showed_up: boolean | null;
  is_cancelled: boolean;
  source: Source;
  field: {
    id: string;
    name: string;
    facility: {
      id: string;
      name: string;
      company: {
        id: string;
        name: string;
      };
    };
  };
  transaction: {
    payment_status: PaymentStatus;
  };
};

export type RecurringBookingSingleBookingsListItem = {
  id: string;
  start_date: string;
  end_date: string;
  is_cancelled: boolean;
  client_showed_up: boolean | null;
  transaction: [
    {
      id: string;
      total_cost: number;
      discount: number;
      payment_status: PaymentStatus;
    },
  ];
};

export interface Booking extends Omit<BookingListItem, 'transaction'> {
  transaction: BookingTransaction;
  creator_id: string;
  created_at: string;
  cancelled_at: string;
  cancelled_by_id: string;
  cancellation_source: Source;
}

export type BookingComment = {
  id: string;
  created_at: string;
  resource_id: string;
  resource_type: BookingCommentResourceType;
  creator_id: string;
  creator: {
    fullname: string;
    id: string;
  };
  company_id: string;
  text: string;
};
export interface RecurringBooking
  extends Omit<RecurringBookingListItem, 'field'> {
  created_at: string | Dayjs;
  weekdays_list: Weekdays[];
  start_time: string;
  end_time: string;
  field: {
    id: string;
    name: string;
    max_players: number;
    facility: {
      id: string;
      name: string;
      company: {
        id: string;
        name: string;
        image_url: string;
      };
    };
  };
  total_cost: number;
  discount_per_booking?: number;
}

export type RecurringBookingSingleBookingListItem = {
  id: string;
  start_date: string;
  end_date: string;
  is_cancelled: boolean;
  client_showed_up: boolean | null;
  transaction: {
    id: string;
    total_cost: number;
    discount: number;
    payment_status: PaymentStatus;
  }[];
  total_cost?: number;
};
