import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum CompaniesFilterFormField {
  CompanyName = 'company_name',
  PhoneNumber = 'phone_number',
}

export type CompaniesFilterFormValues = {
  [CompaniesFilterFormField.CompanyName]: string | undefined;
  [CompaniesFilterFormField.PhoneNumber]: string | undefined;
};

export type CompaniesFilterFormPaginated = CompaniesFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
