import {
  CalendarOutlined,
  CloseOutlined,
  DownOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  TimePicker,
  Typography,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { Select } from 'components';
import dayjs from 'dayjs';
import { getDaysOptions } from 'features/bookings';
//import { MultipleDatePicker } from 'components';
import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { DATE_FORMATS, getDisabledMinutes } from 'utils/date';

import { TimePriceModalFields } from './types';

const { Title } = Typography;

const { RangePicker } = TimePicker;

interface ExceptionDaysTabProps {
  t: TFunction;
  timeSlot: string;
  validator: (rule: any, value: Date[]) => void;
  form: any;
}

// type exceptionalDateRange = {
//   startDate:string,
//   endDate:string
// }
export const ExceptionDaysTab = ({
  t,
  timeSlot,
  validator,
  form,
}: ExceptionDaysTabProps) => {
  //  const [areDaysRequired, setAreDaysRequired] = useState(false);
  const [isRangeRequired, setRangeRequired] = useState(false);

  //const [AreDaysRequired, setAreDaysRequired] = useState(true);
  //const [isRangeRequired] = useState(true);
  const default_ = {
    startDate: undefined,
    endDate: undefined,
  };

  useEffect(() => {
    const formValue = form.getFieldsValue();
    const isUpdate = formValue.isUpdate;
    if (isUpdate === 1) {
      setSelectedDateRange(formValue.exception_days_list);
    } else {
      setSelectedDateRange('');
    }
  }, []);

  const [selectedDates, setSelectedDates] = useState(default_);
  const [selectedDateRange, setSelectedDateRange] = useState('');

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today
    return current.subtract(-1, 'day') < dayjs().endOf('day');
  };

  const handleChange = (date: any, dateArray: any) => {
    setSelectedDateRange(date);
    setSelectedDates({ startDate: dateArray[0], endDate: dateArray[1] });
    setRangeRequired(true);
    form.setFieldsValue({
      [TimePriceModalFields.ExceptionWeekDays]: undefined,
    });
  };

  return (
    <>
      <Form.Item label='updateIndex' name='updateIndex' noStyle>
        <Input type='hidden' />
      </Form.Item>
      <Form.Item label='isUpdate' name='isUpdate' noStyle>
        <Input type='hidden' />
      </Form.Item>

      <Title level={5}>{t('fields.modal.titles.defaultPrice')}</Title>
      <Form.Item
        label={t('bookings.formFields.date.label')}
        name={TimePriceModalFields.ExceptionDaysList}
        rules={[
          {
            required: isRangeRequired,
            message: 'Please select date range!',
            validator,
          },
        ]}
        shouldUpdate
      >
        <DatePicker.RangePicker
          defaultValue={[
            dayjs(selectedDates.startDate, 'YYYY-MM-DD'),
            dayjs(selectedDates.endDate, 'YYYY-MM-DD'),
          ]}
          allowEmpty={[true, true]}
          format={DATE_FORMATS.date}
          onChange={handleChange}
          disabledDate={disabledDate}
        />
      </Form.Item>
      <Form.Item
        label={t('bookings.formFields.days.label')}
        name={TimePriceModalFields.ExceptionWeekDays}
        rules={[
          {
            required: true,
          },
        ]}
        shouldUpdate
      >
        <Select
          className='has-prefix-icon'
          placeholder={t('bookings.formFields.days.placeholder')}
          options={getDaysOptions(selectedDateRange)}
          mode='multiple'
          allowClear
          size='large'
          suffixIcon={
            <>
              <CalendarOutlined className='prefix-icon' />
              <DownOutlined className='suffix-icon' />
            </>
          }
        />
      </Form.Item>
      {/* <Form.Item
        label={t('fields.modal.labels.exceptionDays')}
        rules={[
          {
            required: areDaysRequired,
          },
        ]}
        name={TimePriceModalFields.ExceptionDaysList}
      >
        <MultipleDatePicker setRangeRequired={setRangeRequired} />
      </Form.Item> */}
      {/* 
extra={

<CloseOutlined
  onClick={() => {
    remove(field.name);
    if (index === 0) {
      setAreDaysRequired(false);
    }
  }}
/>
} */}
      <Form.List name={TimePriceModalFields.ExceptionDays}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => {
              return (
                <Card
                  key={field.key}
                  style={{ marginBottom: '10px' }}
                  extra={
                    index !== 0 && (
                      <CloseOutlined onClick={() => remove(field.name)} />
                    )
                  }
                  title={`${t('fields.modal.titles.range')} ${index + 1}`}
                >
                  {/* <Title level={5}>
                    {t('fields.modal.titles.defaultPrice')}
                  </Title> */}

                  <Form.Item<TimePriceModalFields>
                    label={t('fields.formFields.timeRange.label')}
                    name={[field.name, TimePriceModalFields.ExceptionDaysRange]}
                    rules={[{ required: isRangeRequired }]}
                    shouldUpdate
                    required
                  >
                    <RangePicker
                      format='h mm A'
                      style={{ width: '100%' }}
                      size='large'
                      showSecond={false}
                      hideDisabledOptions={true}
                      disabledTime={getDisabledMinutes}
                      order={true}
                    />
                  </Form.Item>
                  <Form.Item<TimePriceModalFields>
                    label={
                      t('fields.formFields.slotPrice.label') +
                      ` (${timeSlot} min)`
                    }
                    name={[field.name, TimePriceModalFields.ExceptionDaysSlot]}
                    rules={[
                      { required: isRangeRequired },
                      () => ({
                        validator(rule, value) {
                          if (value < 0 || value === '0') {
                            return Promise.reject('Please enter valid amount.'); // The validator should always return a promise on both success and error
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                    required
                  >
                    <Input
                      allowClear
                      size='large'
                      placeholder={t('fields.formFields.slotPrice.placeholder')}
                      prefix={'KD'}
                    />
                  </Form.Item>
                </Card>
              );
            })}
            <Button
              type='dashed'
              onClick={() => {
                add();
                //setAreDaysRequired(true);
              }}
              block
              disabled={fields?.length >= 10 || false}
            >
              {t('fields.buttons.addTimeRange')}
            </Button>
          </>
        )}
      </Form.List>
    </>
  );
};
