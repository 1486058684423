import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import {
  UserWithWallet,
  WalletTransactionDetails,
  WalletTransactionsHistoryItem,
} from 'types';

export type UsersWithWalletResponse = PaginatedResponse<UserWithWallet>;

export type UsersWithWalletParams = {
  phone_number?: string;
  user_name?: string;
} & PaginatedRequestParams;

export type WalletTransactionsHistoryResponse = {
  wallet_owner: string;
} & PaginatedResponse<WalletTransactionsHistoryItem>;

export type TopUpWalletDTO = {
  wallet_id: string;
};

export type deductWalletDTO = {
  wallet_id: string;
  amount: number;
  currency: string;
};

export const WalletApiService = () => {
  const getUsersWithWallet = async (
    params: UsersWithWalletParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<UsersWithWalletResponse>({
      ...callConfig,
      endpoint: `/admin/wallets`,
      params,
    });

    return response.data;
  };

  const getWalletTransactionsHistory = async (
    id: string,
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<WalletTransactionsHistoryResponse>({
      ...callConfig,
      endpoint: `/admin/wallets/${id}/wallet-transactions`,
      params,
    });

    return response.data;
  };

  const topUpWallet = async (
    payload: TopUpWalletDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { wallet_id, ...data } = payload;

    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/wallets/${wallet_id}/topup`,
      method: 'POST',
      data,
    });

    return response.data;
  };

  const deductWallet = async (
    payload: deductWalletDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { wallet_id, ...data } = payload;

    const response = await apiClient({
      ...callConfig,
      endpoint: `/admin/wallets/${wallet_id}/refund`,
      method: 'POST',
      data,
    });

    return response.data;
  };

  const getWalletTransactionDetails = async (
    wallet_id: string,
    wallet_transaction_id: string,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<WalletTransactionDetails>({
      ...callConfig,
      endpoint: `/admin/wallets/${wallet_id}/wallet-transactions/${wallet_transaction_id}`,
    });

    return response.data;
  };

  return {
    getUsersWithWallet,
    getWalletTransactionsHistory,
    topUpWallet,
    deductWallet,
    getWalletTransactionDetails,
  };
};
