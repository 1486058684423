import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import {
  RecurringBookingFilterFormValues,
  RecurringBookingsFilterForm,
} from 'features/bookings/components/recurring-bookings-filter-form';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/constants';

const { useBreakpoint } = Grid;

type BookingTopBar = {
  setQueryParams: Dispatch<SetStateAction<MainFiltersParams | undefined>>;
  setQueryFilters: Dispatch<SetStateAction<RecurringBookingFilterFormValues>>;
  initialFilterValues: RecurringBookingFilterFormValues;
  resetTableCurrentPage: () => void;
};

export const RecurringBookingsTopBar = ({
  setQueryParams,
  setQueryFilters,
  initialFilterValues,
  resetTableCurrentPage,
}: BookingTopBar) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const formId = 'recurring-booking-filters-form';

  const handleParams = ({
    company_type,
    company_id,
    facility_id,
  }: MainFiltersParams) => {
    resetTableCurrentPage();
    setQueryParams({
      company_type,
      company_id,
      facility_id,
    });
  };

  const handleFilter = (formData: RecurringBookingFilterFormValues) => {
    resetTableCurrentPage();
    setQueryFilters(formData);
  };

  return (
    <>
      <ContentTopBar
        title={t('bookings.heading.fixedBookings')}
        renderHeadingContent={<MainFilters onChange={handleParams} />}
        ctFilter={
          <ContentFilters formId={formId}>
            <RecurringBookingsFilterForm
              formId={formId}
              handleFilter={handleFilter}
              initialValues={initialFilterValues}
            />
          </ContentFilters>
        }
        renderButtons={
          <Button
            icon={!screens.xs ? <PlusOutlined /> : undefined}
            href={ROUTES.FIXED_BOOKINGS_CREATE}
          >
            {t('bookings.buttons.addFixedBooking')}
          </Button>
        }
      />
    </>
  );
};
