import { Result } from 'antd';
import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const Result404 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Result
      status='404'
      title={t('errors.404.title')}
      subTitle={t('errors.404.subtitle')}
      extra={
        <Button type='primary' onClick={() => navigate(ROUTES.HOME)}>
          {t('errors.404.action')}
        </Button>
      }
    />
  );
};
