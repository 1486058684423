import { ReactElement, useEffect, useState } from 'react';

type DynamicImportIconProps = {
  filePath: string;
  label: string;
  fallback?: ReactElement;
};

export const DynamicImportIcon = ({
  filePath,
  label,
  fallback,
}: DynamicImportIconProps) => {
  const [icon, setIcon] = useState('');
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>(
    'loading',
  );

  useEffect(() => {
    const importIcon = async () => {
      try {
        const importedIcon = await import(`assets/${filePath}`);
        setIcon(importedIcon.default);
        setStatus('success');
      } catch (error) {
        console.error(`Couldn't load icon for file path: "${filePath}"`);
        setStatus('error');
      }
    };

    importIcon();
  }, [filePath]);

  if (status === 'loading') return null;

  if (status === 'error') return fallback || <>{label}</>;

  return <img alt={label} src={icon} title={label} />;
};
