import { SelectProps } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';

import { StyledSelect } from './select.styles';

export const Select = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ValueType = any,
  OptionType extends BaseOptionType | DefaultOptionType = DefaultOptionType,
>({
  options,
  placeholder,
  onChange,
  ...props
}: SelectProps<ValueType, OptionType>) => {
  return (
    <StyledSelect<ValueType, OptionType>
      showSearch
      options={options}
      optionFilterProp='children'
      filterOption={(input, option) =>
        (option?.label ?? '')
          .toString()
          .toLowerCase()
          .includes(input.toLowerCase())
      }
      placeholder={placeholder}
      onChange={onChange}
      {...props}
    />
  );
};
