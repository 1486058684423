import styled from 'styled-components';

export const MarkerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  padding: 12px 16px;
  border-radius: 8px;

  background: ${({ theme }) => theme.colors.pureWhite};
  gap: 8px;
  transform: translate(-50%, calc(-100% - 70px));

  box-shadow: 0 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
`;

export const MarkerIconStyle = styled.div`
  position: absolute;
  bottom: -55px;

  svg {
    width: 33px;
    height: 44px;
  }
`;

export const MarkerTriangleStyle = styled.div`
  position: relative;
  bottom: -20px;
`;
