import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  BookingApiService,
  BookingCommentResponse,
  GetBookingCommentParams,
} from 'features/bookings';

import { bookingCommentsQueryKeys } from './query-keys';

const { getBookingComments } = BookingApiService();

const getBookingCommentsQuery = (
  params: GetBookingCommentParams,
  options?: UseQueryOptions<BookingCommentResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<BookingCommentResponse>;
  options?: UseQueryOptions<BookingCommentResponse>;
} => ({
  queryKey: bookingCommentsQueryKeys.list(params),
  queryFn: ({ signal }) => getBookingComments(params, { config: { signal } }),
  ...options,
});

export const useGetBookingComments = (
  params: GetBookingCommentParams,
  options?: UseQueryOptions<BookingCommentResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<BookingCommentResponse, Error>(
    getBookingCommentsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

export const useGetBookingCommentsInfiniteQuery = (
  params: GetBookingCommentParams,
  options?: UseInfiniteQueryOptions<BookingCommentResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: bookingCommentsQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getBookingComments(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
