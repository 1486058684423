import { Typography } from 'antd';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .anticon {
    font-size: 2rem;
    margin-top: 4px;
    color: ${({ theme }) => theme.antd.colorIcon};
  }

  .booking-details-price-badge {
    .ant-badge-count {
      height: 24px;
      font-size: 1.6rem;
      line-height: 22px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.pureBlack};
    }
  }
`;

export const StyledDetailsItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`;

export const StyledTruncationWrapper = styled.div`
  max-width: calc(100% - 24px);
`;

export const StyledItemText = styled(Typography.Text)`
  font-size: 1.6rem;
`;

export const StyledAdditionalItemText = styled(Typography.Text)`
  display: block !important;
  font-size: 1.2rem;
  line-height: 20px;
`;
