import { PaginatedRequestParams } from 'api';

export enum AmenitiesFilterFormField {
  AmenityName = 'name',
  Chargeable = 'chargeable',
}

export type AmenitiesFilterFormValues = {
  [AmenitiesFilterFormField.AmenityName]: string | undefined;
  [AmenitiesFilterFormField.Chargeable]: boolean | undefined;
};

export type AmenitiesFilterFormPaginated = AmenitiesFilterFormValues &
  PaginatedRequestParams;
