import { Button } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS, hideScrollbar } from 'styles';

export const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const StyledHeadingWrapper = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  row-gap: 16px;
  justify-content: space-between;
  width: 100%;

  ${BREAKPOINTS.laptop} {
    gap: 16px;
    justify-content: initial;
    align-items: center;
    width: 75%;
  }
`;

export const StyledHeadingContent = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  overflow: auto;
  ${hideScrollbar}

  ${BREAKPOINTS.laptop} {
    width: initial;
  }
`;

export const StyledBackButton = styled(Button)`
  font-size: 2.4rem;
  color: ${({ theme }) => theme.colors.colorPrimaryText};
  padding: 0;
  margin: 0;
  display: flex;
`;

export const StyledTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  max-width: 100%;
`;
