import { Role, User } from 'types';
import { RoleTranslationsMap } from 'utils/role';

export const getUserFullName = (
  user: User | Pick<User, 'first_name' | 'last_name'>,
): string => {
  if (user.first_name === null && user.last_name === null) {
    user.first_name = '';
    user.last_name = '';
  }

  return `${user.first_name} ${user.last_name}`;
};

export const getUserRole = (role: Role): string => RoleTranslationsMap[role];
