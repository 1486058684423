import { Button, Form, Grid } from 'antd';
import { Modal } from 'components';
import { useCreateBooking } from 'features/bookings';
import {
  StyledDetailsContainer,
  StyledDetailsWrapper,
  StyledSeparator,
} from 'features/bookings/components';
import {
  getSortedSlots,
  SelectedSlots,
} from 'features/bookings/components/fields-timetable';
import { FieldWithBookings } from 'features/fields';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'types';
import { formatDate, zeroOutSeconds } from 'utils/date';

import { AddBookingClientInfo } from './add-booking-client-info';
import { AddBookingDetailsInfo } from './add-booking-details-info';
import { AddBookingForm } from './add-booking-form';
import { AddBookingFormValues } from './types';

const { useBreakpoint } = Grid;

type AddBookingModalProps = {
  selectedSlots: SelectedSlots;
  field: FieldWithBookings;
  filteredSportId?: string;
  onCancel: () => void;
  onSuccess: () => void;
};

export type slotsData = {
  startDate: string;
  endDate: string;
  totalCost: number;
};

const getPayload = (
  data: slotsData,
  fieldId: string,
  formValues: AddBookingFormValues,
) => {
  const { first_name, last_name, phone_number, sport_id, discount, comment } =
    formValues;

  return {
    first_name,
    last_name,
    phone_number,
    start_date: data.startDate,
    end_date: data.endDate,
    sport_id,
    field_id: fieldId,
    payment_method: PaymentMethod.Cash,
    total_cost: data.totalCost,
    discount: typeof discount === 'number' ? discount : 0,
    text: comment.length ? comment : undefined,
  };
};

export const AddBookingModal = ({
  selectedSlots,
  field,
  filteredSportId,
  onCancel,
  onSuccess,
}: AddBookingModalProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [form] = Form.useForm<AddBookingFormValues>();

  const slots = getSortedSlots(selectedSlots);

  const slotsData: slotsData = {
    startDate: formatDate({
      date: zeroOutSeconds(slots[0].startDate),
      format: 'dbDate',
    }),
    endDate: formatDate({
      date: zeroOutSeconds(slots[slots.length - 1].endDate),
      format: 'dbDate',
    }),
    totalCost: slots.reduce((acc, slot) => acc + slot.price, 0),
  };

  const { isLoading, mutate } = useCreateBooking();

  const handleSubmit = (formValues: AddBookingFormValues) => {
    const payload = getPayload(slotsData, field.id, formValues);

    mutate(payload, {
      onSuccess,
    });
  };

  return (
    <Modal
      open
      title={t('createBooking.title')}
      onCancel={onCancel}
      getContainer={false}
      width={screens.lg ? '920px' : '100%'}
      footer={[
        <>
          <Button
            form='add-booking-form'
            htmlType='submit'
            size={screens.lg ? 'middle' : 'large'}
            type='primary'
            loading={isLoading}
          >
            {t('createBooking.submitButton')}
          </Button>
        </>,
      ]}
    >
      <StyledDetailsWrapper>
        <StyledDetailsContainer>
          <AddBookingClientInfo form={form} />

          <AddBookingDetailsInfo form={form} data={slotsData} field={field} />
        </StyledDetailsContainer>

        {!screens.lg && <StyledSeparator />}

        <StyledDetailsContainer className='no-gap'>
          <AddBookingForm
            form={form}
            sports={field.sports}
            filteredSportId={filteredSportId}
            disabled={isLoading}
            totalCost={slotsData.totalCost}
            onSubmit={handleSubmit}
          />
        </StyledDetailsContainer>
      </StyledDetailsWrapper>
    </Modal>
  );
};
