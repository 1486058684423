import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Switch, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { getUserFullName, usePermissions } from 'features/users';
import { UserFilterFormValuesPaginated } from 'features/users/components/users-filter-form';
import { useUpdateUser } from 'features/users/use-cases/update-user';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { User } from 'types';
import { companyTranslationsMap } from 'utils/company';

import { RoleCell } from './role-cell';

type UsersTableProps = {
  isUsersListLoading: boolean;
  usersList: User[];
  itemsCount: number;
  tableParams: UserFilterFormValuesPaginated;
  updateParams: Dispatch<SetStateAction<UserFilterFormValuesPaginated>>;
};

export const UsersTable = ({
  usersList,
  isUsersListLoading,
  itemsCount,
  tableParams,
  updateParams,
}: UsersTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const navigate = useNavigate();

  const { mutate: mutateUpdateUser, isLoading: isLoadingUpdateUser } =
    useUpdateUser();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('adminUsers.foundUsers')}
          count={itemsCount}
        />
      </StyledTableHeader>
      <Table<User>
        loading={isUsersListLoading}
        dataSource={usersList}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: itemsCount,
        }}
        scroll={{ x: 1440 }}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(getRoute(ROUTES.USERS_ADMIN_EDIT, record.id));
              }
            },
          };
        }}
      >
        <Column<User>
          title={t('adminUsers.table.customerName')}
          dataIndex={'customerName'}
          render={(_, record) => getUserFullName(record)}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']}
          defaultSortOrder={'ascend'}
        />
        <Column<User>
          title={t('adminUsers.table.role')}
          dataIndex={'user'}
          render={(_, record) => <RoleCell user={record} />}
        />
        <Column<User>
          title={t('adminUsers.table.company')}
          dataIndex={'user'}
          render={(_, record: any) =>
            record.role === 'User'
              ? 'N/A'
              : companyTranslationsMap(record.company_name)
          }
        />
        <Column<User>
          title={t('adminUsers.table.email')}
          dataIndex={'user'}
          render={(_, record) => record.email}
        />
        <Column<User>
          title={t('adminUsers.table.phoneNumber')}
          dataIndex={'user'}
          render={(_, record) => record.phone_number}
        />
        <Column<User>
          title={t('adminUsers.table.enableDisable')}
          dataIndex={'user'}
          render={(_, record) => (
            <Switch
              defaultChecked={record.is_active}
              checked={record.is_active}
              loading={isLoadingUpdateUser}
              onChange={(isChecked) =>
                mutateUpdateUser({
                  id: record.id,
                  is_active: isChecked,
                })
              }
              onClick={(checked, event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
