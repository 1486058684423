import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { en } from './en';

// eslint-disable-next-line import/no-named-as-default-member
i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en,
    },
    lng: 'en',
    fallbackLng: 'en',
    defaultNS: 'translation',
    interpolation: {
      // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      escapeValue: false,
    },
    // debug: process.env.NODE_ENV === 'development',
  });

export default i18next;
