import { Image } from 'antd';

export const InvoiceCompanyLogo = ({ imageUrl }: { imageUrl: string }) => (
  <Image
    src={imageUrl}
    placeholder={true}
    preview={false}
    style={{ objectFit: 'contain', width: 'auto' }}
    height={80}
  />
);
