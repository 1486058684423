import { ContentFilters, ContentTopBar } from 'components';
import { EndUsersFilterForm } from 'features/users/components/users-filter-form/end-users-filter-form';
import { EndUserFilterFormValues } from 'features/users/components/users-filter-form/types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

type UsersTopBarProps = {
  getInitialFilterValues: EndUserFilterFormValues;
  handleFilter: (values: EndUserFilterFormValues) => void;
};

export const EndUsersTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const formId = 'end-users-filters-form';

  return (
    <>
      <ContentTopBar title={t('adminUsers.heading.li3ib')} />
      <ContentFilters formId={formId}>
        <EndUsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
