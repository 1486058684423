import { hasClientRole, hasLi3ibRole } from 'features/users/permissions';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { User } from 'types';
import { RoleTranslationsMap } from 'utils/role';

const RoleStyles = styled.div`
  color: ${({ theme }) => theme.colors.gray7};
`;

export const RoleCell = ({ user }: { user: User }) => {
  const { t } = useTranslation();

  let roleType = '';
  if (hasClientRole(user)) roleType = t('adminUsers.client');
  if (hasLi3ibRole(user)) roleType = t('adminUsers.li3ib');

  return (
    <>
      {roleType && <RoleStyles>{roleType}</RoleStyles>}
      <div>{RoleTranslationsMap[user.role]}</div>
    </>
  );
};
