import { Button, Grid, Modal } from 'antd';
import {
  getUserFullName,
  usePermissions,
  useUserContext,
} from 'features/users';
// import { canCancelBooking } from 'features/users/permissions';
import { Trans, useTranslation } from 'react-i18next';
import { Booking, User } from 'types';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';
import { sourceTranslationsMap } from 'utils/source';

import {
  StyledAdditionalInfo,
  StyledAdditionalInfoText,
  StyledButtonsWrapper,
  StyledIsCancelledInfo,
  StyledTag,
  StyledWrapper,
} from './booking-details-footer.styles';

const { useBreakpoint } = Grid;

type BookingDetailsFooterProps = {
  bookingData?: Booking;
  creatorData?: User;
  cancellerData?: User;
  handleBookingCancellation: () => void;
  isLoading: {
    loading: boolean;
    isLoadingUpdateBooking: boolean;
    isLoadingCancelBooking: boolean;
  };
};

export const BookingDetailsFooter = ({
  bookingData,
  creatorData,
  cancellerData,
  handleBookingCancellation,
  isLoading,
}: BookingDetailsFooterProps) => {
  const [modal, contextHolder] = Modal.useModal();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const permissions = usePermissions();
  const { user } = useUserContext();

  if (!permissions || !user || !bookingData) return null;

  const renderAdditionalInfo = () => {
    const date =
      bookingData &&
      formatDate({
        date: bookingData.created_at,
        format: 'dateTime',
        timeZone: DEFAULT_TIMEZONE,
      });
    const cancelledDate =
      bookingData &&
      bookingData.cancelled_at &&
      formatDate({
        date: bookingData.cancelled_at,
        format: 'dateTime',
        timeZone: DEFAULT_TIMEZONE,
      });
    const userFullName = creatorData ? getUserFullName(creatorData) : null;
    const cancellationAuthor = cancellerData
      ? getUserFullName(cancellerData)
      : sourceTranslationsMap(bookingData.cancellation_source, permissions);

    return (
      <StyledAdditionalInfo>
        {bookingData && (
          <>
            <StyledTag>
              {sourceTranslationsMap(bookingData.source, permissions)}
            </StyledTag>
            <StyledAdditionalInfoText>
              <div>
                {userFullName ? (
                  <Trans
                    i18nKey='bookings.details.footer.createdShowUserName'
                    values={{ date, userFullName }}
                    components={{
                      span: <span />,
                      hiddenOnMobile: <span className='hide-on-mobile' />,
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey='bookings.details.footer.created'
                    values={{ date }}
                    components={{
                      span: <span />,
                      hiddenOnMobile: <span className='hide-on-mobile' />,
                    }}
                  />
                )}
              </div>

              {bookingData.is_cancelled && (
                <StyledIsCancelledInfo>
                  <Trans
                    i18nKey='bookings.details.footer.cancelled'
                    values={{ cancelledDate, cancellationAuthor }}
                    components={{ span: <span /> }}
                  />
                </StyledIsCancelledInfo>
              )}
            </StyledAdditionalInfoText>
          </>
        )}
      </StyledAdditionalInfo>
    );
  };

  return (
    <StyledWrapper>
      {screens.lg && renderAdditionalInfo()}
      <StyledButtonsWrapper>
        {/* {canCancelBooking(user, bookingData) ? ( */}
        <Button
          size={screens.lg ? 'middle' : 'large'}
          danger
          className='booking-details-footer-button'
          disabled={isLoading.loading}
          loading={isLoading.isLoadingCancelBooking}
          onClick={() =>
            modal.confirm({
              centered: true,
              title: t('confirmModal.confirmTitle'),
              content: <>{t('confirmModal.booking.cancel.text')}</>,
              okText: t('confirmModal.booking.cancel.button'),
              cancelText: t('bookings.buttons.back'),
              okButtonProps: {
                danger: true,
              },
              onOk: handleBookingCancellation,
            })
          }
        >
          {t('bookings.buttons.cancelBooking')}
        </Button>
        {/* ) : null} */}
        {bookingData?.transaction && (
          <Button
            form='booking-details-client-form'
            key='booking-details-submit'
            htmlType='submit'
            size={screens.lg ? 'middle' : 'large'}
            type='primary'
            className='booking-details-footer-button'
            disabled={isLoading.loading}
            loading={isLoading.isLoadingUpdateBooking}
          >
            {t('bookings.buttons.updateBooking')}
          </Button>
        )}
        {contextHolder}
      </StyledButtonsWrapper>
      {!screens.lg && renderAdditionalInfo()}
    </StyledWrapper>
  );
};
