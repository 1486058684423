import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

import { RecurringBookingAdvancedFilterForm } from './recurring-advanced-filter-form';
import {
  RecurringBookingFilterFormField,
  RecurringBookingFilterFormValues,
} from './types';

const { useBreakpoint } = Grid;
const { RangePicker } = DatePicker;

type RecurringBookingsFilterFormProps = {
  formId: string;
  handleFilter: (values: RecurringBookingFilterFormValues) => void;
  initialValues: RecurringBookingFilterFormValues;
  closeModal?: () => void;
};

export const RecurringBookingsFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: RecurringBookingsFilterFormProps) => {
  const [openAdvancedFilters, setOpenAdvancedFilters] = useState(false);
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<RecurringBookingFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
  };

  const handleOnFinish = (values: RecurringBookingFilterFormValues) => {
    handleFilter(values);
    closeModal?.();
  };

  const toggleAdvancedFilters = () => {
    setOpenAdvancedFilters(!openAdvancedFilters);
  };

  return (
    <Form<RecurringBookingFilterFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<RecurringBookingFilterFormValues>
            label={t('bookings.formFields.date.label')}
            name={RecurringBookingFilterFormField.Date}
          >
            <RangePicker
              allowEmpty={[true, true]}
              format={DATE_FORMATS.date}
              presets={[
                {
                  label: t('form.formFields.date.presets.today'),
                  value: [dayjs(), dayjs()],
                },
                {
                  label: t('form.formFields.date.presets.fromTomorrow'),
                  value: [dayjs().add(1, 'day'), null],
                },
                {
                  label: t('form.formFields.date.presets.untilYesterday'),
                  value: [null, dayjs().add(-1, 'day')],
                },
              ]}
            />
          </Form.Item>
          <Form.Item<RecurringBookingFilterFormValues>
            label={t('bookings.formFields.customerName.label')}
            name={RecurringBookingFilterFormField.FullUserName}
          >
            <Input
              placeholder={t('bookings.formFields.customerName.placeholder')}
            />
          </Form.Item>
          <Form.Item<RecurringBookingFilterFormValues>
            label={t('bookings.formFields.phoneNumber.label')}
            name={RecurringBookingFilterFormField.PhoneNumber}
          >
            <Input
              type='tel'
              placeholder={t('bookings.formFields.phoneNumber.placeholder')}
            />
          </Form.Item>
        </StandardItemsWrapper>
        <RecurringBookingAdvancedFilterForm
          openAdvancedFilters={openAdvancedFilters}
        />
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('bookings.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('bookings.buttons.reset')}</Button>
          <Button
            type='dashed'
            className='advanced-filters-button'
            onClick={toggleAdvancedFilters}
          >
            {t('bookings.buttons.advancedFilters')}
            {openAdvancedFilters ? <UpOutlined /> : <DownOutlined />}
          </Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
