import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { GroupTermQueryKeys } from 'features/community/use-cases/query.keys';
import { CompaniesApiService } from 'features/companies/api/companies-api.service';
import { UpdateBadgeDTO } from 'features/user-level/api/questions-api.service';
import { queryClient } from 'providers';
// ../api/companies-api.service

const { updateTermComapny } = CompaniesApiService();
export const useUpdateTermComapny = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateBadgeDTO) => {
      return updateTermComapny(payload as any);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: GroupTermQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: GroupTermQueryKeys.detail(variables.id as any),
          }),
        ]);
        message.success('Udated Successfully');
      },
    },
  );
};
