import GraphikArabicMedium from 'assets/fonts/graphik-arabic-medium.woff2';
import GraphikArabicRegular from 'assets/fonts/graphik-arabic-regular.woff2';
import GraphikMedium from 'assets/fonts/graphik-medium.woff2';
import GraphikRegular from 'assets/fonts/graphik-regular.woff2';
import { dropdownMenu } from 'components/dropdown/dropdown.styles';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: 'Graphik', 'GraphikArabic', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: baseline;
  }

  /* setting font size to 10px for easier rem calculations */
  html { font-size: 62.5%; };

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .ant-typography strong {
    font-weight: 500;
  }

  .prflbx{
        img{
          max-width: 125px;
        }
  }
  {}
  @font-face {
    font-family: 'Graphik';
    font-weight: 400;
    src: url(${GraphikRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 500;
    src: url(${GraphikMedium}) format('woff2');
  }

  @font-face {
    font-family: 'GraphikArabic';
    font-weight: 400;
    src: url(${GraphikArabicRegular}) format('woff2');
  }


  @font-face {
    font-family: 'GraphikArabic';
    font-weight: 500;
    src: url(${GraphikArabicMedium}) format('woff2');
  }

  .ant-picker-dropdown {
    .ant-picker-today-btn {
      color: ${({ theme }) => theme.colors.greenLight};

      &:hover {
        color: ${({ theme }) => theme.colors.greenDark};
      }
    }
  }


  .day_v_head .sc-kDvujY.kdHSeD {
    gap: 10px !important;

    // flex-wrap:wrap;
}
  .filter_day_v {
    // background: #fff; padding: 4px 6px; border-radius: 6px; border: 1px solid #00473A;

    .ant-select-selector {
      padding: 0px !important;

      .ant-select-selection-placeholder {
        padding-inline-end: 30px;
      }
    }
}


 @media(max-width:900px){

  .global_slots{
    .slot_mbl{
      .halfHours{
        .sc-ckEbSK{
          display: flex; align-items: center;
          span.ant-typography{
            display:none;
          }
          .sc-GhhNo{
            width: 100%;
            span{
              display:inline-block;
            }
          }
        }
      }
    }
  }

  .day_v_head .sc-kDvujY{ display:block !important; }
  .filter_day_v{ min-width:100% !important; width:100%; margin-bottom: 6px !important; }
  .filter_day_v span.ant-select-clear {
    opacity: 1; width: 25px; height: 25px; background: #edeff6; display: flex; align-items: center; justify-content: center; 
    font-size: 30px; border-radius: 50%; top: 13px; right: 3px;
  }
  .stats_detls .row{ flex-wrap:wrap;  }
  .stats_detls .row .col:nth-child(2) { padding-right: 0;}
  .stats_detls .row .col { max-width: 50% !important ; width: 50% !important; padding-right: 15px; margin-bottom: 15px; }
  .stats_detls .row .col + .col{ padding-left:0px !important; }
  .stats_detls .row .col:last-child{ padding-right:0px; } 
  .ant-space-item .ant-modal-content .ant-modal-footer .sc-hLBbgP button.ant-btn { position: static; }
  .ant-space-item .sc-pyfCe .day_v_head a { right: 0 ;}
  .ant-row.fixed_bk_in{
    .ant-col{
      .ant-row.booking_dtls{
        .ant-col{
          .ant-row{
            .ant-col{max-width: 170px;width: auto;
            }
          }
        }
      }
    }
  }

  .ant-space-item .sc-pyfCe{
    &.cmpny_head{
      .day_v_head{
        .sc-csuSiG{
          .ant-typography{
            // overflow: hidden; text-overflow: ellipsis; display: inline-block; width: 61%;
          }
        }
      }
    }    
  }
  .ant-modal-wrap .ant-modal-content .ant-modal-body .ant-form .sc-gKPRtg .sc-iBYQkv .ant-form-item .ant-col.ant-form-item-control .ant-form-item-control-input .ant-picker span.ant-picker-clear {
    opacity: 1 !important; inset-inline-end: 11px !important;
  }

  .ant-picker-dropdown{
    left: 10px !important; margin: 0px; padding: 0px; right: 10px;
    .ant-picker-range-wrapper{
      padding: 15px; width: 100%;
      .ant-picker-panel-container{
        .ant-picker-panel-layout{
          display:block;
          .ant-picker-presets{
            width: 100%; min-width: 100%; max-width: 100%; display: block; border-bottom: 1px solid #ccc;
            ul{
              height: auto; display: flex; align-items: center; justify-content: space-between;
              li{
                font-size:14px;
                & + li{
                  margin:0px;
                }
              }
            }
          } 
  
          .ant-picker-panels{
            overflow: auto; width: 100%;
            // display:block;
            .ant-picker-panel{
              width:100%;
              .ant-picker-date-panel{
                width:100%;
                .ant-picker-body{
                  .ant-picker-content{
                    width:100%;
                  }
                }
              }
            }
          }
  
        }
      }
    }
  }

}
 


  // Remove the flex gap from component

  .no-gap {
     gap: 0;
  }

  .ant-space-item{
    .sc-idXgbr.cdZltE{
      margin-top:15px;
    }
  }

  .stats_detls {
    margin-bottom: 15px;
    .row {
      display: flex; align-items: center;
      .col {
        max-width: 33.3%; width: 33.3%;
        &:nth-child(1) .stats_bx{
          background:#4E7E4C; 
          // span{color:#df9c5a;}
          // h4{ color:#975e26;}
        }
        &:nth-child(2) .stats_bx{
          background:#009EDB; 
          // span{color:#6b9d9d;}
          // h4{ color:#2e7070;}
        }
        &:nth-child(3) .stats_bx{
          background:#015B97; 
          // span{color:#8666b5;}
          // h4{ color:#5e359b;}
        }
        &:nth-child(4) .stats_bx{
          background:#CD7640; 
          // span{color:#5fa978;}
          // h4{ color:#31874e;}
        }
        
        .stats_bx {
          background: #fff; padding: 10px; border-radius: 5px; border: 1px solid #dbdbdb;height: 70px;position:relative;
          span {
            font-size: 14px; color: #4f5051; font-weight: 500; color:#fff;
          }
          h4 {
            font-size: 20px; color: #00473a; font-weight: 500; text-align:right; color:#fff;
          }
          &:hover{
            box-shadow:0px 1px 6px #00473a40; 
          }         
        }
        & + .col{
          padding-left:15px;
        }

      }
    }
  }
 
  
   


  .loader {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #00473a;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: absolute;
    right: 13px;
    bottom: 11px;
}
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }








  /* 24-02-2024 All Booking Page / Vikas / */


 

.ant-space-item{

  .sc-pyfCe{
    position: relative;    
    .sc-jrcTuL{    
      width: 100%; display: block; grid-template-columns: auto auto auto; gap: 0px 10px; align-items: start;           
      .sc-csuSiG{
        width: 100%;  grid-column: 1 / span 3; margin-bottom: 10px;
        h3{
          margin:0px;
        }
      }
      
      .sc-kDvujY:nth-child(2){
        display: inline-block; vertical-align: top;
        .sc-kgTSHT {
          min-width: 110px; margin: 0px; padding: 0px;
          .ant-select-selector{
            .ant-select-selection-item{
              padding-inline-end: 25px; 
            }
            .ant-select-selection-placeholder{
              padding-inline-end: 25px; font-size:14px;
            }
          }
        }
      }
      
      .sc-kDvujY:nth-child(3){     
        display: inline-block;
        
        .sc-eDvSVe{
          padding:0px; background: transparent;
          .ant-form{
            align-items: center; gap:10px;
            .sc-gKPRtg{
              flex-direction: row;
              .sc-iBYQkv{
                gap:10px;
                .ant-form-item {
                  margin:0px;                  
                  
                  &:nth-child(3){ width: 135px; }
                  &:nth-child(2){ width: 115px; }

                  &:nth-child(1){
                    .ant-row{
                      flex-direction: unset;
                      .ant-col{
                        .ant-form-item-control-input{
                          .ant-form-item-control-input-content{
                            .ant-picker{
                              max-width: 195px; padding: 4px 7px;
                              .ant-picker-input{}
                              .ant-picker-range-separator{
                                display:none;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &:nth-child(2){}
                  &:nth-child(3){}

                  .ant-row{
                    flex-direction: unset;
                    .ant-col{
                      label{
                        display:none;
                      }
                    }
                  }
                }
              }
              .sc-ftTHYK{
                display: flex; height: auto; padding-left:10px;
                .ant-form-item{
                  margin:0px;
                  & + .ant-form-item{
                    margin-left:10px;
                  }
                  .ant-row{
                    flex-direction: unset;
                    .ant-col{
                      label{
                        display:none
                      }
                    }
                  }
                }
              }
            }
            .sc-hLBbgP{
              width: auto; margin: 0px;
              .ant-btn{
                position: relative;
                

                &:nth-child(2){}
                &:nth-child(3){
                  display:none;
                }
              }
            }
          }
        }
      }
    }
  }
}








  @media (min-width: 1024px) and (max-width: 1450px) {

    .ant-space-item{
      .sc-dkKxlM{
        margin:0px 0px 10px 0px;
      }

    .sc-pyfCe{
      // display: block; 
      position: relative; margin-bottom: 10px;      
        .ant-btn.ant-btn-default {
          padding: 5px 10px; font-size: 12px; height: auto;border-color: #d9d9d9; color: rgba(0, 0, 0, 0.88) ;
        }  
      .sc-jrcTuL{    
        width: 100%; display: block; grid-template-columns: auto auto auto; gap: 0px 10px; align-items: start;           
          .sc-csuSiG{
            width: 100%;  grid-column: 1 / span 3; margin-bottom: 10px;
            h3{
              margin:0px;
            }
          }
          .sc-kDvujY:nth-child(2){
            display: inline-block; vertical-align: top;
            // width: 29%;
              .sc-kgTSHT {
                min-width: auto; 
                .ant-select-selector{
                  .ant-select-selection-item{
                    padding-inline-end: 25px; font-size:12px;
                  }
                  .ant-select-selection-placeholder{
                    padding-inline-end: 25px; font-size:12px;
                  }
                }
              }
          }
        .sc-kDvujY:nth-child(3){     
          display: inline-block; 
          // width: 71%;
          
          .sc-eDvSVe{
            padding:0px; background: transparent;
            .ant-form{
              align-items: center; gap:10px;
              .sc-gKPRtg{
                flex-direction: row;
                .sc-iBYQkv{
                  gap:5px;
                  .ant-form-item {
                    margin:0px;     
                    
                    .ant-row{
                      .ant-col{
                        .ant-form-item-control-input{
                          .ant-form-item-control-input-content{
                            .ant-input{
                              font-size:12px; padding-left: 7px; padding-right: 7px;
                            }
                          }
                        }
                      }
                    }
                    
                    &:nth-child(3){ width: 115px; }
                    &:nth-child(2){ width: 115px; }
  
                    &:nth-child(1){
                      .ant-row{
                        flex-direction: unset;
                        .ant-col{
                          .ant-form-item-control-input{
                            .ant-form-item-control-input-content{
                              .ant-picker{
                                max-width: 170px; padding: 4px 5px;                             
                                .ant-picker-input{
                                  input{
                                    font-size:12px;
                                  }
                                }
                                .ant-picker-range-separator{
                                  display:none;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  
                   
  
                    .ant-row{
                      flex-direction: unset;
                      .ant-col{
                        label{
                          display:none;
                        }
                      }
                    }
                  }
                }
                .sc-ftTHYK{
                  display: flex; height: auto; padding-left:10px;
                  .ant-form-item{
                    margin:0px;
                    & + .ant-form-item{
                      margin-left:10px;
                    }
                    .ant-row{
                      flex-direction: unset;
                      .ant-col{
                        label{
                          display:none
                        }
                        .ant-form-item-control-input{
                          .ant-form-item-control-input-content{
                            .ant-select{
                              .ant-select-selector{
                                padding: 0 7px; height:28px;
                                .ant-select-selection-search{
                                  .ant-select-selection-search-input{
                                    font-size:12px; height:28px;
                                  }
                                }
                                .ant-select-selection-item{
                                  display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
                                  font-size: 12px; width: 95px;
                                }
                                .ant-select-selection-placeholder{
                                  font-size:12px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              .sc-hLBbgP{
                width: auto; margin: 0px;
                .ant-btn{
                  font-size: 12px; height:28px; padding-top: 4px;
                  
                  &:nth-child(3){
                    display:none;
                  }

                }
              }
            }
          }
        }
      }      
    }        
  }  
}



  .sc-idXgbr{
    margin-top: 20px;
  }
  .ant-space-item .sc-khsqcC {
    margin-top: 16px !important;
}


#booking-filters-form .ant-form-item .ant-col.ant-form-item-control .ant-picker span.ant-picker-clear {
  inset-inline-end: 5px;
}
 

.ant-space-item .sc-pyfCe .sc-bRlCZA .ant-btn.ant-btn-default.ant-btn-dangerous { 
 color: #ff4d4f; border-color: #ff4d4f;
  span{
    color: #ff4d4f;
  }
}



span.ant-typography.ant-typography-secondary.css-inhf9z { font-size: 13px;}


.day_view_clg{
  .sc-jfTVlA{
    height: calc(100vh - calc(267px + 32px)); overflow: auto; overflow: auto;
    .sc-hiDMwi{ overflow:visible;}
  }
  .sc-lbVpMG{ position: sticky; top: 0px; z-index: 3; background: #fff; left: 0px;}
  .sc-iOeugr{ position: sticky; top: 0px; z-index: 2; overflow: visible; padding-right:0px;}
  .sc-eJDSGI{ position: sticky; left:0px; overflow: visible; background: #fff; }
}


.bknghead{
  .day_v_head{}
  .mltyplbtn{
    position: absolute; right: 0; top: 0;
    .ant-btn.ant-btn-default{}
  }
}

.cstmhead{
  display:flex;
  .day_v_head{}
  .mltyplbtn{
    .ant-space-item{
      & + .ant-space-item{
        .ant-btn.ant-btn-default{}
      }
    }
    .ant-btn.ant-btn-default{}
  }
}

.cstmtablecs{
  .ant-table-wrapper{
    .ant-spin-container{
      .ant-table{
        .ant-table-container{
          .ant-table-content{
            table{
              width:100%;
              tr{
                th{
                  &:last-child{
                    width:160px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.sidebarl{
  .ant-layout-sider{
    .ant-layout-sider-children{
      .ant-menu{
        padding-bottom: 25px;
      }
    }
  }
}

@media screen and (max-width: 980px) {  
  
  .anticon.anticon-menu.app-header-hamburger-mobile{ display:inline-flex; }

  .ant-layout-sider-below.side-bar-sider{    
    &:after{
      content: ''; background: #00473a14; left: 0px; right: 0px; top: 0px; bottom: 0px; position: fixed; z-index: -1;
    }
    &.ant-layout-sider-collapsed.ant-layout-sider-zero-width, &.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
      &:after{
        display: none;
      }  
    }
  }

  .cmpny_head{
    .day_v_head{
      .mltyplbtn{
        position: unset; right: 0; top: 0;        
      }
    }
  }
 

  .cstmhead{    
    .day_v_head{
      .sc-csuSiG{
        gap: 5px;
        .ant-typography{
          white-space:normal; font-size: 20px;
        }
      }
      .mltyplbtn{
        position: unset; right: 0; top: 0;
      }
    }     
  }



  .day_view_clg{
    .sc-jfTVlA{
      grid-template-rows: 50px auto;
      .sc-iOeugr{
        .sc-jfvxQR{
          .field_mbl{
            .ant-typography.ant-typography-ellipsis{
              font-size:16px;
            }
            .sc-cOxWqc{display:none;}
            .sc-dPWrhe{display:none;}
            .sc-jOiSOi{display:none;}
          }
        }
      }
    }
    .slot_mbl{
      .halfHours{
        .sc-ckEbSK{
          display: flex; align-items: unset; line-height: 42px;
          span.ant-typography{
            display:none;
          }
          .sc-GhhNo{
            width: 100%;
            span{
              display:inline-block; line-height: 42px;
            }
          }
        }
      }
    }
  }  
}
    
  
  
}



.week_view_clg{
  .slot_cell{
    overflow:auto;
    .timdbx{overflow:visible;}
    .global_slots{overflow:visible;}
  }
}





  ${dropdownMenu};

  .input-prefix-icon { color: ${({ theme }) =>
    theme.antd.colorIcon}; margin-right: 8px; };



  // social match feature

  .ques_card {
    border: 1px solid #f0f0f0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: 'Graphik', 'GraphikArabic';
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .ques_card .head_bx {
    position: relative;
  }
  
  .ques_card .head_bx .icon {
    position: absolute;
    right: -5px;
    background: #000;
    border-radius: 100px;
    font-size: 12px;
    color: #fff;
    padding: 3px;
    top: -6px;
    width: 18px;
    height: 18px;
  }
  
  .ques_card .head_bx .ant-row {
    justify-content: end;
  }
  
  .ques_card .option_item {
    padding: 10px;
  }
  .ques_card .option_item .ant-form-item {
    margin: 0;
  }
  ul.list_user {}
  
  ul.list_user li {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.88);
      text-transform: capitalize;
  }
  
  ul.list_user li span:first-child {
      display: inline-block;
      width: 100%;
      max-width: 19%;
      margin-bottom: 10px;
  }
  
  ul.list_user li span:last-child {
      font-weight: 500;
  }
  
  .btn_rgt{    margin-left: auto;
    display: block;}
  
  
  
    .ant-modal.right-modal {
      padding-bottom: 0;
      float: right;
      height: 100%;
      top: 0;
      background: #fff;
  }
  
  .ant-modal.right-modal .ant-modal-content {
      border-radius: 8px 0px 0px 8px;
      height: auto;
      box-shadow: none !important;
      padding: 0px;
  }
  .ant-modal.right-modal .ant-modal-header {
    background: #76B584;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0 !important;
    padding: 10px 20px;
  }
  .user_avtar .media {
    width: 60px;
    height: 60px;
  }
  
  .user_avtar {
    display: flex;
    align-items: center;
  }
  
  .user_avtar .media img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
    overflow: hidden;
  }
  .plr_list_edit .cell .cellsub .media img{ object-fit: cover;
    object-position: center;}
  .user_avtar .ant-typography {
    font-size: 18px;
    color: #000;
    margin-left: 10px;
    text-transform: capitalize;
  }
  .ant-modal.right-modal .ant-modal-header .ant-modal-title {
    color: #fff;
    font-size: 20px !important;
  }
  .list_user {
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }
  .ant-modal.right-modal .ant-modal-content .ant-modal-body {
    padding: 0px 12px;
    margin-top: 0px;
  }
  .modal_social .ant-modal-body {
    overflow: initial;
  }
  .ant-collapse-header-text .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  
  .inti_user01 {
    position: absolute;
    top: 49%;
    width: 100%;
    max-width: 50%;
    right: 0;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  
  .inti_user02 {
    position: absolute;
    width: 100%;
    max-width: 50%;
    right: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    bottom: 5%;
  }
  
  .inti_user02 .ant-form-item-control-input {
    min-height: 60px;
  }
  
  .inti_user02 .ant-input,.inti_user01  .ant-input{
    height: 70px;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
  }
  .question_ans .ad {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-bottom: 15px;
    background: #FFF;
    -moz-box-shadow: 0 1px 5px -1px rgba(0, 0, 0, .13);
    -webkit-box-shadow: 0 1px 5px -1px rgba(0,0,0,.13);
    box-shadow: 0 1px 5px -1px rgba(0,0,0,.13);
    border-bottom: 1px solid #dedede;
    position: relative;border-radius:7px;
  }
  .question_ans .ad .ques_no {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .question_ans .ad .ques_no h3 {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
  
  .question_ans .ad .ques_no .btn_edit {
    display: flex;
    align-items: center;
  }
  
  
  .question_ans .ad .ques_no .btn_edit .anticon-edit {}
  
  .question_ans .ad .ques_no .btn_edit .ant-switch-checked {
    margin-left: 10px;
  }
  ul.ans_list {padding: 7px 15px;
    display: grid;
    align-items: center;
    grid-template-columns: repeat(3,1fr);
  }
  
  ul.ans_list li {
    display: inline-block;
    margin-top: 15px;
    font-size: 16px;
    font-weight: 400;
  }
  
  
  .ant-modal.right-modal .ant-modal-header {
    background: #00473A!important;
  }
  .question_ans .ad .ques_no {
    background: #f0f0f0;
    border-bottom: 1px solid #d9d9d9;
    padding: 7px 15px;
  }
  
  .option_ques {
    display: flex;
  }
  
  .option_ques .option_item {
    width: 50%;
  }
  .right-modal button.ant-modal-close {
    color: #fff;
    top: 14px!important;
  }
  .plsection .plr_list .cell { align-items: center;}
  .plsection .plr_list .cell .add_bydef {
    width:70px;
    height: 70px;
    border: 2px dashed #53a869;
    margin-left: 20px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    color: #53a869;
    font-size: 20px;
    justify-content: center;
  }
  .text_Quill .ql-container.ql-snow {
    height: calc(100vh - 200px);
  }
    ${dropdownMenu};
   
    .input-prefix-icon { color: ${({ theme }) =>
      theme.antd.colorIcon}; margin-right: 8px; };
  
  
  
    .sm_pndl{
      margin-top: 30px;
      text-align:center;
      .smpnm{
        display: flex; align-items: center; justify-content: center; margin-bottom: 7px;
        .media{
          img{ width: 100%; max-width: 28px;}
        }
        .smttl{ margin-left: 7px; font-size: 16px; color: #000; font-weight: 500;}
      }
  
      .sm_mttl{
        display: flex; align-items: center; justify-content: center; margin-bottom: 5px;
        .media{
          img{width:100%; max-width:50px;}
        }
        .smpnttl{
          text-align:left; margin-left:10px;
          h3{margin: 0px; color: #1F1F1F; font-size: 18px; font-weight: 500;}
          p{font-size:14px; margin:0px; color:#5B6683}
        }
      }
  
      .sm_dtl{      
        text-align:center;
        p{
          color:#5B6683; font-size:14px;
          & + p{margin-top:10px;}
          .ttlp_lv{
            color: #53A869; position: relative; display: inline-block; font-weight: 500; padding-left: 10px;
            &:after{
              content: ''; width: 7px; height: 7px; background: #53A869; position: absolute; left: 0px; top: 7px; border-radius: 50%;
            }
          }
          .ttlp_wr{color:#FF6100;display: inline-block; font-weight: 500;}
        }
      }
  
    }
  
  
    .plsection{
      .head{      
        display: flex; align-items: center; justify-content: space-between; margin-bottom: 10px;
        h3{
          font-size: 16px; font-weight: 500;
        }
        label{
          background: #FF6100; color: #fff; padding: 2px 7px; display: inline-block; border-radius: 3px;
        }
      }
      .plr_list{
        display:flex; position: relative;
        &:after{
          content:''; background: #969CA6; position: absolute; left: 0px; right: 0px; margin: auto; top: 0px; bottom: 0px; width: 1px;
      }
        .cell{
          width: 50%; display: grid; grid-template-columns: 1fr 1fr;
          &.cell:nth-child(1){
            // .cellsub{text-align:left;}
            
          }
  
          .cellsub{
            text-align:center;
            .media{
              width:70px; margin: auto; height:70px; overflow:hidden; border-radius:50px;
              img{
                width:100%;     object-fit: cover;
                object-position: top; height:100%;
              }
            }
            h3{
              color:#5B6683;
            }
          }
        }
      }
    }
    .badge-page .ant-pagination {
      display: none;
  }
    .plr_list_edit{
      display:flex; position: relative;padding-bottom:20px;margin-bottom:20px;border-bottom:1px solid #ccc;
      
  
      .cell{
        width: 50%; display: grid; grid-template-columns: 1fr 1fr;
        
          .cellsub{
            text-align:center; position: relative;
            .media{
              width:75px; margin: auto;height:75px; overflow:hidden; border-radius:50px;
              img{
                width:100%; height:100%;
              }
            }
  
            h3{
              color:#5B6683;
            }
            .trfyimg{
              position: absolute; right: 0px; top: -10px; width: 30px;
            }
          }
         
  
        }
  
        .score_bx {
          width: 50%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
  
      
  
      .ant-form-item-row{
        margin-left: 10px;
  
        input{
          width: 38px;
          height: 50px;
        }
      }
        }
      }
  
  
    
  
      .plr_list_edit:last-child{
        border:0px;
      }
  
  
      .mtch_dtls_modal{
        .ant-modal-content{height:100%;
          .ant-modal-close{    inset-inline-end: 10px !important; top: 12px !important;}
          .ant-modal-body{max-height:100% !important;}
          .ant-modal-footer{display:flex; justify-content:end;}
        }
      }


      .dwlnuf.right-modal{
        .ant-modal-content{
          .ant-modal-body{
            height: calc(100vh - 130px); overflow: auto;
           
            
            .ant-spin-container{
              .ques_card{
                .head_bx{
                  .ant-row{
                    top:6px; right:6px;
                  }
                }
              }
            }
            
          }
          
          .ant-modal-footer{
            padding: 15px; margin: 10px 0px 0px 0px; text-align: left;
          }
        }
      }



      .plsection .plr_list.plr_list02 .cell .cellsub .media img {
        object-fit: cover;
        object-position: top;
        max-width: 70px;
    }
    
    .plsection .plr_list.plr_list02 .cell .cellsub .media {
        width: 70px;
        height: 70px;
    }
    
    .plsection .plr_list.plr_list02 .cell {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .plsection .plr_list.plr_list02 .cell .add_bydef {
        width: 70px;
        height: 70px;
        margin: 0;
    }
    .sm_pndl .sm_mttl .media {
      width: 60px;    height: 75px;
      display: flex;
      align-items: center;
  }
  
  .sm_pndl .sm_mttl .media img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .text_Quill .ql-container.ql-snow {
    height: 105px!important;
}
.option_ques_edit .option_item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 0 -5px;
}

.option_ques_edit .option_item .ant-form-item {
  padding: 0 5px;
}
.question_ans .ad .ques_no h3 +h3 {
  margin-top: 5px;
  margin-left: 20px;
}
.table_list_dta table {}

.table_list_dta table tr {}

.table_list_dta table tr th:nth-child(2) {
    width: 20%;
}

.table_list_dta table tr th:nth-child(4) {
    width: 10%;
}

.table_list_dta table tr th:last-child {
    width: 15%;
}

.table_list_dta .ant-space-item .ant-btn {
    height: 22px;
    line-height: 14px;
    font-size: 12px;
    border-radius: 4px;
}
.dot_icon_drop .anticon-more {}

.dot_icon_drop .anticon-more svg {
    width: 40px;
    height: 20px;
    color: #000;
}
.dot_icon_drop .anticon-more {}

.dot_icon_drop .anticon-more svg {
    width: 40px;
    height: 20px;
    color: #000;
}

.wd_dta_modal {}

.wd_dta_modal p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

.wd_dta_modal p span {}

.wd_dta_modal p span:last-child {
    font-weight: 500;
    margin-left: 7px;
}

.wd_dta_modal .frm_dta .ant-row {
    display: block;
    text-align: left;
}

.wd_dta_modal .frm_dta .ant-row label {
    display: block;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    height: auto;
}

.wd_dta_modal .btn_grp {
    text-align: right;
}

.wd_dta_modal .btn_grp .ant-btn {
    color: #fff;
    background-color: #53a869;
    box-shadow: 0 2px 0 rgba(8, 102, 22, 0.15);
    width: 110px;
}

.wd_dta_modal .btn_grp .ant-btn +.ant-btn {
    margin-left: 15px;
    background: #dd3838;
    color: #fff;
}
.wd_dta_modal p span:first-child {
  width: 30%;
  display: inline-block;
}

.wd_dta_modal p {
  margin-bottom: 10px;
}
.clickable-row {
  cursor: pointer;
}



.frm_StartTime {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-width: 70%;
}

.frm_time {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 70%;
}

.frm_StartTime .ant-picker-input >input {
  width: 100%;
}

.frm_StartTime label {
  margin-bottom: 21px;
  margin-right: 12px;
}

.frm_time .ant-col {
  max-width: 68%;
}

.frm_StartTime .ant-picker {
  width: 100%;
}

.time_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 60%;
  justify-content: center;
  margin: auto;
  gap: 10px;

  .time_bx{
    .ant-col{max-width: 100%;
      .ant-picker{    width: 100%;}
    }
  }
  .time_bx_2{
    .ant-col{max-width: 80%;
      .ant-picker{    width: 100%;}
    }
  }
}



.packForm .warp_inner {}

.packForm .warp_inner .ant-row .ant-row {
    display: block;
    text-align: left;
    margin: 0;
}

.packForm .warp_inner .ant-col .ant-col {
    max-width: 100%;
    padding: 0 15px;
}

.packForm .warp_inner .ant-row {
    margin: 0 -15px;
}

.packForm .warp_inner .ant-row .ant-row label {
    text-align: left;
    width: 100%;
}



.packForm .warp_inner .ant-select-selector {
    height: 32px!important;
    border-radius: 3px;
}

.packForm .warp_inner .ant-select-selector .ant-select-selection-search-input {
    height: 28px!important;
}

.packForm .warp_inner span.ant-select-selection-placeholder {
    line-height: 31px!important;
}

.packForm .warp_inner .ant-form-item {
    margin-bottom: 15px;
}
.packForm .warp_inner .pack_date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: end;
    gap: 15px;
    padding: 0 15px;
}

.packForm .warp_inner 
 .pack_date .ant-col {
    padding: 0;
}
.packForm .warp_inner .ant-btn {
    margin: 0 15px;
    float: right;
}
.packForm .warp_inner .lab_bx {
    padding: 0 15px;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    display: inline-flex;
}
    .book_pack_wrapp p {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-bottom: 10px;
}

.book_pack_wrapp p span:last-child {
    font-weight: 500;
}
.book_pack_wrapp{margin-top:30px;}
.book_pack_wrapp p span:first-child {
    width: 45%;
    display: inline-block;
}
    .packForm .alart_wrapp {
    position: absolute;
    width: 100%;
    left: 0;
    top: -50px;
}

.packForm {
    position: relative;
}
.packForm .warp_inner .ant-select-multiple .ant-select-selector {
    height: auto!important;
}
    .packForm textarea {
    min-height: 130px;
}
.packForm .ant-radio-group {
    display: flex;
    margin-top: 18px;
}
.pckg_wrapp .sc-iBYQkv {
    display: flex;
   flex-wrap:wrap;
    gap: 0 15px;
}
.packForm .ant-radio-group label {width: 70px!important;}
.sc-iBYQkv.ewamOi {}

.pckg_wrapp .sc-iBYQkv {
    gap: 0 15px;
}

.card_pk_details {
    border: 1px solid #f0f0f0;
    margin-top: 20px;
    padding: 15px;
    position: relative;
}

.card_pk_details h3 {
    position: absolute;
    top: -11px;
    z-index: 2;
    font-size: 14px;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
    font-weight: 500;
}

.card_pk_details .bk_inner {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 10px;
}

.card_pk_details .bk_inner p {
    font-size: 13px;
}
.table_pckge .ant-table-cell{
    white-space: nowrap;
}

.table_pckge .ant-table-content {
    overflow: auto;
}
.card_pk_details .bk_inner p span:last-child {display: block;font-weight: 500;}



.quill.editor-large .ql-container .ql-editor{min-height:150px;}
.grpview-table .ant-table-cell .ant-space {
    display: block;
}

.rept_table thead {}

.rept_table thead th {
    white-space: nowrap;
}

.rept_table tbody td .ant-space {
    display: block;
}

.rept_table tbody td:first-child .ant-space .ant-space-item:first-child {
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 22px;
    text-overflow: ellipsis;
    margin-bottom: 10px;
}

.rept_table thead th:first-child {
    width: 30%;
}

.rept_table tbody td:first-child .ant-space .ant-space-item {
}

.rept_table .ant-table-cell .mediagrp .ant-image {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 8px;
    margin: 6px;
}
.rept_table .ant-table-cell .mediagrp {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2,1fr);
    margin: -6px;
}



.rept_table thead th:nth-child(2) {
    width: 10%;
}

.rept_table thead th:nth-child(3) {
    width: 15%;
}
.rept_view_table .ant-space {
    display: block;
}
.rept_table thead th:last-child {
    width: 85px;
}
    .btn_rpt {
    width: 47px;
    margin: 0 auto;
    background: #f9d1d9;
    text-align: center;
    border-radius: 12px;
    color: #000;
}
  
.editorrrr .ql-editor {
    text-align: right;
}
    .pckg_wrapp .sc-iBYQkv .ant-form-item {
    width: 23.6%;
}

.pckg_wrapp .sc-iBYQkv .ant-form-item:nth-child(6) {
    width: 30%;
}

.table_pckge .ant-table-thead th:nth-child(5),.table_pckge td:nth-child(5) {
  text-align: center;
}
.cancelled {
    color: #FF0000; /* Red color for cancelled status */
}

.booked {
    color: #76B584; /* Green color for booked status */
}
    .ql-editor {
    height: 180px !important;
}
    .editer_text .ant-modal-footer {
    padding: 0px 30px!important;
    border: 0px!important;
    margin: 0px!important;
    display: flex;
    justify-content: end;
}
    ul.bk_dta li {
    color: rgba(0, 0, 0, 0.88);
    font-size: 1.5rem;
}

ul.bk_dta li:first-child span {
    font-size: 1.6rem;
    font-weight: 500;
    color: #000000;
    background: rgb(245, 245, 245);
    padding: 6px 12PX;
    line-height: normal;
    border-radius: 100PX;
    display: flex;
    align-items: center;
}

ul.bk_dta {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
}
.bk_wrp {
    display: flex;
    align-items: center;
}

.bk_wrp span.anticon {
    margin-bottom: 20px;
    margin-right: 15px;
}
    .crd_list {
    justify-content: flex-start;
    gap: 20px
}

.crd_list .ant-card {}

.crd_list .ant-card .ant-card-body {
    padding: 10px;
}

.crd_list .ant-card .ant-card-body span + span.ant-typography {
    margin-top: 14px;
}
.arr_frm .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    right: -20px;
}
.crd_list .ant-card .ant-card-body .reprt_btn {
    margin-top: 14px;
}
.card_row{width:100%;
.op_cl{width:100%;
h4{font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;}
.op_cl_bx{
    display: flex;
    width: 100%;
    align-items: center;

    .card_wrap{
    background: #fff;
    padding: 14px;
    width: 25%;
        border-radius: 5px;
    box-shadow: 1px 1px 14px #e3e1e1;

    & + .card_wrap{
    margin-left:10px;
    }

    .card_bx{
    span{font-size:13px; font-weight:500; margin-bottom:5px; display:block; color:#fff;}
    h3{font-size: 16px;font-weight: 500; color:#fff;}
    }
    }
}
}
}


.card_row .ant-space-item {
    margin-bottom: 10px;
}

.op_cl h4 {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
}

.card_bx {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card_bx .bx_icon {}

.card_bx .bx_icon img {
    width: 40px;
}


`;
