import styled from 'styled-components';

export const ContactTextBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactUsWrapper = styled.section`
  background: white;
  height: 100%;

  .status-icon {
    font-size: 21px;

    &.success {
      color: ${({ theme }) => theme.antd.colorPrimary};
    }
    &.error {
      color: ${({ theme }) => theme.colors.red5};
    }
    &.default {
      color: ${({ theme }) => theme.antd.colorIcon};
    }
  }
`;
