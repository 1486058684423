import { App } from 'antd';
import { queryClient } from 'providers';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  addStorageEventListener,
  AuthStorageEventType,
  clearStorage,
  getAccessToken,
  removeStorageEventListener,
} from './auth-storage';
import { SessionData } from './types';
import { decodeAccessToken } from './utils';

interface ContextValue {
  data: SessionData | null;
  isLoggedIn: boolean;
  logOut: () => void;
}

export const SessionContext = createContext<ContextValue | null>(null);

export const useSessionContext = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error(
      'You tried to use SessionContext outside the scope of the SessionContextProvider.',
    );
  }

  return context;
};

const logOut = () => {
  clearStorage();
  queryClient.clear();
};

export function SessionContextProvider({ children }: { children: ReactNode }) {
  const [sessionData, setSessionData] = useState<SessionData | null>(() =>
    decodeAccessToken(),
  );
  const { message } = App.useApp();
  const { t } = useTranslation();

  const isLoggedIn = !!sessionData?.userId;

  useEffect(() => {
    const updateEventCallback = () => {
      setSessionData(decodeAccessToken());
    };
    const expireEventCallback = () => {
      const wasLoggedIn = getAccessToken();

      if (wasLoggedIn) {
        message.warning(t('errors.401'));
      }

      logOut();
    };
    const userDataFetchFailedCallback = () => {
      const loggedIn = getAccessToken();

      if (loggedIn) {
        message.error(t('errors.loginUserFetchFailed'), 5);

        logOut();
      }
    };

    addStorageEventListener();
    window.addEventListener(
      AuthStorageEventType.AccessTokenUpdated,
      updateEventCallback,
    );
    window.addEventListener(
      AuthStorageEventType.AccessTokenExpired,
      expireEventCallback,
    );
    window.addEventListener(
      AuthStorageEventType.UserDataFetchFailed,
      userDataFetchFailedCallback,
    );

    return () => {
      window.removeEventListener(
        AuthStorageEventType.AccessTokenUpdated,
        updateEventCallback,
      );
      window.removeEventListener(
        AuthStorageEventType.AccessTokenExpired,
        expireEventCallback,
      );
      window.removeEventListener(
        AuthStorageEventType.UserDataFetchFailed,
        userDataFetchFailedCallback,
      );
      removeStorageEventListener();
    };
  }, [message, t]);

  return (
    <SessionContext.Provider value={{ data: sessionData, isLoggedIn, logOut }}>
      {children}
    </SessionContext.Provider>
  );
}
