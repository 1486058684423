import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  BookingApiService,
  GetRecurringBookingSingleBookingsParams,
  RecurringBookingSingleBookingsResponse,
} from 'features/bookings';

import { recurringBookingsQueryKeys } from './query-keys';

const { getRecurringBookingSingleBookings } = BookingApiService();

const getRecurringBookingSingleBookingsQuery = (
  params: GetRecurringBookingSingleBookingsParams,
  options?: UseQueryOptions<RecurringBookingSingleBookingsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<RecurringBookingSingleBookingsResponse>;
  options?: UseQueryOptions<RecurringBookingSingleBookingsResponse>;
} => ({
  queryKey: recurringBookingsQueryKeys.list(params),
  queryFn: ({ signal }) =>
    getRecurringBookingSingleBookings(params, { config: { signal } }),
  ...options,
});

export const useGetRecurringBookingSingleBookings = (
  params: GetRecurringBookingSingleBookingsParams,
  options?: UseQueryOptions<RecurringBookingSingleBookingsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<RecurringBookingSingleBookingsResponse, Error>(
    getRecurringBookingSingleBookingsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
