import { SelectProps, Spin } from 'antd';
import {
  facilitiesFilterOptionsMap,
  FacilitiesParams,
  useGetFacilitiesInfiniteQuery,
} from 'features/facilities';
import { usePaginationParams } from 'hooks';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Facility } from 'types';

import { OnOptionsChange } from './main-filters';
import { getSelectStyle } from './utils';

interface FacilitySelectProps extends SelectProps {
  companyId: string | undefined;
  theme: 'standard' | 'minimal';
  onOptionsChange?: OnOptionsChange;
  onParamsChange?: (params: object) => void;
}

const initialParams = {
  page: 1,
  size: 10,
  is_active: true,
  order_by: 'name',
};

export const FacilitySelect = ({
  companyId,
  theme,
  onOptionsChange,
  ...props
}: FacilitySelectProps) => {
  const { t } = useTranslation();

  const { params, setParams } =
    usePaginationParams<FacilitiesParams>(initialParams);

  useEffect(() => {
    return setParams({
      ...initialParams,
      company_id: companyId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetFacilitiesInfiniteQuery(params, {
      enabled: !!companyId,
      keepPreviousData: true,
      cacheTime: 2000,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    debounce(
      (facilityName?: string) =>
        setParams((prevParams) => ({
          ...prevParams,
          ...{ facility_name: facilityName },
        })),
      500,
    ),
    [],
  );

  const facilitiesFilterOptions = useMemo(() => {
    return facilitiesFilterOptionsMap(
      companyId ? data?.pages.map((page) => page.items).flat() : [],
    );
  }, [companyId, data?.pages]);

  useEffect(() => {
    onOptionsChange &&
      onOptionsChange({ facility_id: facilitiesFilterOptions });
  }, [facilitiesFilterOptions, onOptionsChange]);

  const handleClear = () => setParams(initialParams);

  const SelectWithStyles = getSelectStyle(theme);

  return (
    <SelectWithStyles<Facility>
      placeholder={t('bookings.allFacilities')}
      onSearch={debouncedOnSearch}
      onClear={handleClear}
      loading={isFetching}
      notFoundContent={
        isFetching ? (
          <Spin size='small' aria-label={t('common.loading')} />
        ) : null
      }
      options={facilitiesFilterOptions}
      onPopupScroll={(e) => {
        if (!e.target) return;

        const target = e.target as HTMLDivElement;
        const isScrolledToBottom =
          target.scrollTop + target.offsetHeight === target.scrollHeight;

        if (!isScrolledToBottom) return;

        if (hasNextPage || !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
      allowClear
      bordered={false}
      {...props}
    />
  );
};
