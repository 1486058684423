import styled from 'styled-components';

export const StyledStatus = styled.div`
  width: fit-content;
  height: fit-content;

  &.center {
    margin: auto;
  }

  .status-icon {
    font-size: 21px; //svg icon width and height

    &.success {
      color: ${({ theme }) => theme.antd.colorPrimary};
    }
    &.error {
      color: ${({ theme }) => theme.colors.red5};
    }
    &.default {
      color: ${({ theme }) => theme.antd.colorIcon};
    }
  }
`;
