import { Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { getUserFullName, usePermissions } from 'features/users';
import { useTranslation } from 'react-i18next';
import { Source } from 'types';
import { formatDate } from 'utils/date';
import { sourceTranslationsMap } from 'utils/source';

import { SectionLabel, Wrapper } from './fixed-booking-invoice.styles';
import {
  InvoiceGridColumn,
  InvoiceGridItem,
  InvoiceGridWrapper,
} from './invoice-grid';

type InvoiceCustomerBookingInfoProps = {
  data: {
    created_at: string | Dayjs;
    first_name: string;
    last_name: string;
    phone_number: string;
    source: Source;
  };
};

export const InvoiceCustomerBookingInfo = ({
  data,
}: InvoiceCustomerBookingInfoProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();

  if (!permissions) return null;

  const getClientName = () => {
    if (data.first_name?.length && data.last_name?.length) {
      return getUserFullName(data);
    }

    return t('notApplicable');
  };

  return (
    <Wrapper>
      <SectionLabel>
        <Typography.Text strong>
          {t('bookings.invoice.labels.customerAndBookingInfo')}
        </Typography.Text>
      </SectionLabel>
      <InvoiceGridWrapper>
        <InvoiceGridColumn>
          <InvoiceGridItem
            label={t('bookings.invoice.labels.createDate')}
            value={formatDate({
              date: data.created_at,
              format: 'date',
            })}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.bookingSource')}
            value={sourceTranslationsMap(data.source, permissions)}
          />
        </InvoiceGridColumn>
        <InvoiceGridColumn>
          <InvoiceGridItem
            label={t('bookings.invoice.labels.customerName')}
            value={getClientName()}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.customerPhone')}
            value={data.phone_number}
          />
        </InvoiceGridColumn>
      </InvoiceGridWrapper>
    </Wrapper>
  );
};
