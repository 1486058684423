import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { MainFiltersParams } from 'components/main-filters/main-filters';
import dayjs from 'dayjs';
import { useGetBookings } from 'features/bookings';
import {
  BookingFilterFormValues,
  BookingsTable,
  BookingsTopBar,
} from 'features/bookings/components';
import { usePermissions } from 'features/users';
import { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { formatDate } from 'utils/date';

interface TableParams {
  pagination?: TablePaginationConfig;
  field?: string;
  order?: string;
  filters?: Record<string, FilterValue>;
}

const initialFilterValues: BookingFilterFormValues = {
  full_user_name: undefined,
  phone_number: undefined,
  date: [dayjs(), dayjs()],
  field: undefined,
  bookings: undefined,
};

export const StandardBookingsView = () => {
  const [queryParams, setQueryParams] = useState<MainFiltersParams>();
  const [queryFilters, setQueryFilters] =
    useState<BookingFilterFormValues>(initialFilterValues);
  const fromDate = queryFilters.date?.[0];
  const toDate = queryFilters.date?.[1];
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const permissions = usePermissions();

  const { data, isFetching } = useGetBookings(
    {
      from_date: fromDate
        ? formatDate({
            date: dayjs(fromDate).startOf('day'),
            format: 'dbDate',
          })
        : undefined,
      to_date: toDate
        ? formatDate({
            date: dayjs(toDate).endOf('day'),
            format: 'dbDate',
          })
        : undefined,
      full_user_name: queryFilters.full_user_name,
      phone_number: queryFilters.phone_number,
      company_type: permissions?.companyType.select
        ? queryParams?.company_type
        : undefined,
      facility_id: permissions?.facility.select
        ? queryParams?.facility_id
        : undefined,
      company_id: permissions?.company.select
        ? queryParams?.company_id
        : undefined,
      field_id: queryFilters.field,
      size: tableParams.pagination?.pageSize,
      page: tableParams.pagination?.current,
      order_by: tableParams.order === 'ascend' ? 'date' : '-date',
      is_cancelled: queryFilters?.bookings,
    },
    {
      retry: false,
    },
  );

  const resetTableCurrentPage = useCallback(() => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        current: 1,
      },
    }));
  }, []);

  return (
    <>
      <BookingsTopBar
        setQueryParams={setQueryParams}
        setQueryFilters={setQueryFilters}
        initialFilterValues={initialFilterValues}
        resetTableCurrentPage={resetTableCurrentPage}
      />
      <BookingsTable
        isBookingListLoading={isFetching}
        data={data}
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
      <Outlet />
    </>
  );
};
