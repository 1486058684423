import { Col, Row, Space, Typography } from 'antd';
import KuwaitFlag from 'assets/flags/kw.svg';
import { ReactComponent as LogoSvg } from 'assets/li3ib-logo.svg';
import { LoginForm } from 'features/auth/components';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';

import { StyledBackground, StyledCard, StyledRow } from './login.styles';

export const LoginView = () => {
  const { t } = useTranslation();

  return (
    <StyledBackground align='middle' justify='center'>
      <Col xs={24} sm={18} md={12} lg={8}>
        <StyledCard>
          <StyledRow justify='center'>
            <Col>
              <LogoSvg
                title={t('li3ibLogo')}
                color={colors.redBright}
                width={126}
                height={39}
              />
            </Col>
          </StyledRow>

          <StyledRow>
            <Col xs={24}>
              <LoginForm />
            </Col>
          </StyledRow>

          <Row justify='center'>
            <Col>
              <Space direction='vertical' align='center'>
                <img src={KuwaitFlag} alt={t('login.flagDescription')} />
                <Typography.Text type='secondary'>
                  {t('login.countryDescription')}
                </Typography.Text>
              </Space>
            </Col>
          </Row>
        </StyledCard>
      </Col>
    </StyledBackground>
  );
};
