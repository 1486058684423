export enum BookingDetailsField {
  PhoneNumber = 'phone_number',
  FirstName = 'first_name',
  LastName = 'last_name',
  Discount = 'discount',
  Comment = 'comment',
}

export type FilterFormValues = {
  [BookingDetailsField.PhoneNumber]: string;
  [BookingDetailsField.FirstName]: string;
  [BookingDetailsField.LastName]: string;
  [BookingDetailsField.Discount]: number;
  [BookingDetailsField.Comment]: string;
};
