import { TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, Table } from 'components';
import { useTranslation } from 'react-i18next';
import { RecurringBookingSingleBookingListItem } from 'types';
import { formatPrice } from 'utils/currency';
import { formatDate } from 'utils/date';

import { PaginatedRecurringBookings } from './fixed-booking-invoice';
import { RecurringBookingsKey } from './types';

type InvoiceBookingListProps = {
  bookings?: PaginatedRecurringBookings;
};

export const InvoiceBookingList = ({ bookings }: InvoiceBookingListProps) => {
  const { t } = useTranslation();

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter:
      | SorterResult<RecurringBookingSingleBookingListItem>
      | SorterResult<RecurringBookingSingleBookingListItem>[],
  ) => {
    return {
      pagination,
      filters,
      ...sorter,
    };
  };

  const amountAfterDiscount = (totalPrice: number, discount: number) =>
    totalPrice - discount;

  return (
    <Table<RecurringBookingSingleBookingListItem>
      dataSource={bookings?.items}
      rowKey='id'
      onChange={handleTableChange}
      pagination={{
        defaultPageSize: 5,
      }}
    >
      <Column<RecurringBookingSingleBookingListItem>
        title={t('bookings.table.date')}
        dataIndex={'start_date'}
        key={RecurringBookingsKey.GameScheduleDate}
        render={(_, record) => (
          <>
            {formatDate({
              date: record.start_date,
              format: 'date',
              timeZone: 'Asia/Kuwait',
            })}
          </>
        )}
      />
      <Column<RecurringBookingSingleBookingListItem>
        title={t('bookings.table.startTime')}
        dataIndex={'start_time'}
        key={RecurringBookingsKey.GameScheduleTime}
        render={(_, record) => (
          <>
            {formatDate({
              date: record.start_date,
              format: 'time',
              timeZone: 'Asia/Kuwait',
            })}
          </>
        )}
      />
      <Column<RecurringBookingSingleBookingListItem>
        title={t('bookings.table.price')}
        dataIndex={'price'}
        key={RecurringBookingsKey.Price}
        render={(_, record) => (
          <>
            {record.transaction[0]?.total_cost !== undefined &&
            record.transaction[0]?.discount !== undefined
              ? formatPrice({
                  value: amountAfterDiscount(
                    record.transaction[0].total_cost,
                    record.transaction[0].discount,
                  ),
                  currency: 'KWD',
                })
              : null}
          </>
        )}
      />
    </Table>
  );
};
