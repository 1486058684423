import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { AxiosError } from 'axios';
import {
  BookingApiService,
  CreateRecurringBookingDTO,
  recurringBookingsQueryKeys,
} from 'features/bookings';
import i18n from 'locales/i18n';

const { createRecurringBooking } = BookingApiService();

export const useCreateRecurringBooking = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateRecurringBookingDTO) => {
      return createRecurringBooking(payload);
    },
    {
      onError: (error: AxiosError) => {
        const uiMessage = handleError(error);

        if (uiMessage) message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: recurringBookingsQueryKeys.all,
        });
        message.success(i18n.t('createBooking.successMessage'));
      },
    },
  );
};
