import { SelectProps, Spin } from 'antd';
import {
  CompaniesParams,
  useGetCompaniesInfiniteQuery,
} from 'features/companies';
import { companiesFilterOptionsMap } from 'features/companies/utils';
import { usePaginationParams } from 'hooks';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Company, CompanyType, Option } from 'types';

import { OnOptionsChange } from './main-filters';
import { getSelectStyle } from './utils';

interface CompanySelectProps extends SelectProps {
  companyType: CompanyType | undefined;
  theme: 'standard' | 'minimal';
  predefinedOptions?: Option[];
  onOptionsChange?: OnOptionsChange;
}

const initialParams = {
  page: 1,
  size: 10,
  is_active: true,
  order_by: 'name',
};

const emptyOptions: Option[] = [];

export const CompanySelect = ({
  companyType,
  theme,
  onOptionsChange,
  predefinedOptions = emptyOptions,
  ...props
}: CompanySelectProps) => {
  const { t } = useTranslation();

  const { params, setParams } =
    usePaginationParams<CompaniesParams>(initialParams);

  useEffect(() => {
    return setParams({
      ...initialParams,
      company_type: companyType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyType]);

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetCompaniesInfiniteQuery(params, {
      keepPreviousData: true,
      cacheTime: 2000,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    debounce(
      (companyName?: string) =>
        setParams((prevParams) => ({
          ...prevParams,
          ...{ company_name: companyName },
        })),
      500,
    ),
    [],
  );

  const companiesFilterOptions = useMemo(() => {
    return companiesFilterOptionsMap(
      data?.pages.map((page) => page.items).flat(),
      [...predefinedOptions],
    );
  }, [data?.pages, predefinedOptions]);

  useEffect(() => {
    onOptionsChange && onOptionsChange({ company_id: companiesFilterOptions });
  }, [companiesFilterOptions, onOptionsChange]);

  const handleClear = () => setParams(initialParams);

  const SelectWithStyles = getSelectStyle(theme);

  return (
    <SelectWithStyles<Company>
      placeholder={t('bookings.allCompanies')}
      onSearch={debouncedOnSearch}
      onClear={handleClear}
      loading={isFetching}
      notFoundContent={
        isFetching ? (
          <Spin size='small' aria-label={t('common.loading')} />
        ) : null
      }
      options={companiesFilterOptions}
      onPopupScroll={(e) => {
        if (!e.target) return;

        const target = e.target as HTMLDivElement;
        const isScrolledToBottom =
          target.scrollTop + target.offsetHeight === target.scrollHeight;

        if (!isScrolledToBottom) return;

        if (hasNextPage || !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
      allowClear
      bordered={false}
      {...props}
    />
  );
};
