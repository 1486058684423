import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  BookingApiService,
  bookingCommentsQueryKeys,
  bookingsQueryKeys,
  fieldsBookingsQueryKeys,
  UpdateBookingDTO,
} from 'features/bookings';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

const { updateBooking } = BookingApiService();

export const useUpdateBooking = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateBookingDTO) => {
      return updateBooking(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: bookingsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: bookingsQueryKeys.detail(variables.id),
          }),
          queryClient.invalidateQueries({
            queryKey: fieldsBookingsQueryKeys.all,
          }),
          queryClient.invalidateQueries({
            queryKey: bookingCommentsQueryKeys.lists(),
          }),
        ]);
        message.success(i18n.t('bookings.details.successfulUpdate'));
      },
    },
  );
};
