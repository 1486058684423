import { ReactNode } from 'react';
import styled from 'styled-components';

const TitleStyle = styled.p`
  margin-bottom: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
`;

export const FormSectionsSeparator = ({
  children,
  title,
}: {
  children: ReactNode;
  title: string;
}) => (
  <>
    <TitleStyle>{title}</TitleStyle>
    {children}
  </>
);
