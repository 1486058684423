import { PaginatedRequestParams } from 'api';

export enum UsersWithWalletFilterFormField {
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
}

export type UsersWithWalletFilterFormValues = {
  [UsersWithWalletFilterFormField.FullUserName]: string | undefined;
  [UsersWithWalletFilterFormField.PhoneNumber]: string | undefined;
};

type UsersWithWalletSort = {
  order_by_transaction?: string;
  order_by_balance?: string;
};

export type UsersWithWalletFilterFormPaginated =
  UsersWithWalletFilterFormValues &
    UsersWithWalletSort &
    PaginatedRequestParams;
