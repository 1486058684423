import { EditTermCompanyModal } from 'features/companies/components/modal/edit-term-comapny-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
// ../components/modal/edit-term-comapny-modal
// ../components/modal/edit-term-modal
export const ComapnyTermViewEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.COMPANY_TC);
  };

  return (
    <EditTermCompanyModal id={id} onClose={closeModal} onSuccess={closeModal} />
  );
};
