import { Select } from 'components/select/select';
import styled, { css } from 'styled-components';

const SharedStyles = css`
  padding: 4px 0;
  margin-bottom: 0.5em;
  align-items: center;

  .ant-select-arrow {
    color: ${({ theme }) => theme.colors.greenDark};
  }
`;

export const StyledSelect = styled(Select)`
  ${SharedStyles}
` as typeof Select;

export const StyledMinimalSelect = styled(Select)`
  min-width: 120px;
  max-width: 200px;
  font-weight: 500;

  .ant-select-selection-item {
    font-weight: 500;
  }

  ${SharedStyles}
` as typeof Select;
