import { WalletTransactionDetails } from 'features/wallet';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';

export const WalletTransactionDetailsView = () => {
  const navigate = useNavigate();
  const { id, transaction_id } = useParams();

  if (!transaction_id || !id) return null;

  const closeModal = () => {
    navigate(getRoute(ROUTES.WALLET_TRANSACTIONS, id));
  };

  return (
    <WalletTransactionDetails
      onCancel={closeModal}
      walletId={id}
      transactionId={transaction_id}
    />
  );
};
