import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  BookingApiService,
  BookingsResponse,
  GetBookingsParams,
} from 'features/bookings';

import { bookingsQueryKeys } from './query-keys';

const { getBookings } = BookingApiService();

const getBookingsQuery = (
  params: GetBookingsParams,
  options?: UseQueryOptions<BookingsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<BookingsResponse>;
  options?: UseQueryOptions<BookingsResponse>;
} => ({
  queryKey: bookingsQueryKeys.list(params),
  queryFn: ({ signal }) => getBookings(params, { config: { signal } }),
  ...options,
});

export const useGetBookings = (
  params: GetBookingsParams,
  options?: UseQueryOptions<BookingsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<BookingsResponse, Error>(
    getBookingsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
