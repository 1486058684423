import { DoubleRightOutlined, RetweetOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import { Modal } from 'components';
import { usePermissions, useUserContext } from 'features/users';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Source } from 'types';
import { getSourceColor, sourceTranslationsMap } from 'utils/source';

import {
  LegendListItem,
  SourceColorBlock,
  StyledInfoIcon,
} from './timetable-legend.styles';

export const TimetableLegend = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUserContext();
  const permissions = usePermissions();
  const { t } = useTranslation();

  const toggleModal = () => setIsOpen(!isOpen);

  if (!permissions || !user) return null;

  return (
    <>
      <Button
        type='text'
        onClick={toggleModal}
        aria-label={t('timetableLegend.triggerLabel')}
      >
        <StyledInfoIcon />
      </Button>

      {isOpen ? (
        <Modal
          open
          title={t('timetableLegend.title')}
          onCancel={toggleModal}
          footer={null}
          centered
          width={undefined}
          isFullScreenOnMobile={false}
        >
          <Row>
            <Col xs={24} sm={12}>
              <Space>
                <ul>
                  <LegendListItem>
                    <RetweetOutlined />
                    {t('timetableLegend.fixedBooking')}
                  </LegendListItem>
                  <LegendListItem>
                    <DoubleRightOutlined rotate={90} />
                    {t('timetableLegend.discounted')}
                  </LegendListItem>
                </ul>
              </Space>
            </Col>

            <Col xs={24} sm={12}>
              <Space>
                <ul>
                  {Object.values(Source).map((source) => {
                    return (
                      <LegendListItem key={source}>
                        <SourceColorBlock
                          $color={getSourceColor(source, user.role)}
                        />
                        {sourceTranslationsMap(source, permissions)}
                      </LegendListItem>
                    );
                  })}
                </ul>
              </Space>
            </Col>
          </Row>
        </Modal>
      ) : null}
    </>
  );
};
