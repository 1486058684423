import { SelectProps, Spin } from 'antd';
import { Select } from 'components';
import {
  CompaniesParams,
  useGetCompaniesInfiniteQuery,
} from 'features/companies';
import { usePaginationParams } from 'hooks';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Company, CompanyType, Option } from 'types';

const initialParams = {
  page: 1,
  size: 10,
  order_by: 'name',
  is_active: true,
};

type CompanySelectProps = {
  companyType?: CompanyType;
  predefinedOptions?: Option[];
  customParams?: CompaniesParams;
};

export const CompanySelect = ({
  predefinedOptions = [],
  companyType,
  customParams,
  ...props
}: SelectProps & CompanySelectProps) => {
  const { t } = useTranslation();

  const { params, setParams } =
    usePaginationParams<CompaniesParams>(initialParams);

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetCompaniesInfiniteQuery(
      { ...params, company_type: companyType, ...customParams },
      {
        keepPreviousData: true,
        cacheTime: 2000,
      },
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    debounce(
      (companyName?: string) =>
        setParams((prevParams) => ({
          ...prevParams,
          ...{ company_name: companyName },
        })),
      500,
    ),
    [],
  );

  const getOptions = () => {
    const options = data?.pages;

    return Array.isArray(options) ? options : [];
  };

  const fetchedOptions =
    getOptions()
      .map((page) =>
        page.items.map((companyItem) => ({
          value: companyItem.id,
          label: companyItem.name,
        })),
      )
      .flat() || [];

  const options = [...predefinedOptions, ...fetchedOptions];

  const handleClear = () => setParams(initialParams);

  return (
    <Select<Company>
      placeholder={t('companies.formFields.companyName.placeholder')}
      onSearch={debouncedOnSearch}
      onClear={handleClear}
      loading={isFetching}
      dropdownMatchSelectWidth={200}
      notFoundContent={
        isFetching ? (
          <Spin
            size='small'
            aria-label={t('companies.formFields.companyName.loading')}
          />
        ) : null
      }
      options={options}
      onPopupScroll={(e) => {
        if (!e.target) return;

        const target = e.target as HTMLDivElement;
        const isScrolledToBottom =
          target.scrollTop + target.offsetHeight === target.scrollHeight;

        if (!isScrolledToBottom) return;

        if (hasNextPage || !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
      allowClear
      {...props}
    />
  );
};
