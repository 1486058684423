import { Grid, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { getUserFullName, usePermissions } from 'features/users';
import {
  UsersWithWalletFilterFormPaginated,
  UsersWithWalletResponse,
} from 'features/wallet';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { UserWithWallet } from 'types/wallet';
import { formatPrice } from 'utils/currency';
import { formatDate } from 'utils/date';

const { useBreakpoint } = Grid;

type UsersWithWalletTableProps = {
  isLoading: boolean;
  data?: UsersWithWalletResponse;
  tableParams: UsersWithWalletFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<UsersWithWalletFilterFormPaginated>>;
};

export const UsersWithWalletTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: UsersWithWalletTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const permissions = usePermissions();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<UserWithWallet> | SorterResult<UserWithWallet>[],
  ) => {
    const sortBy = () => {
      if (Array.isArray(sorter)) return;

      const sortOrder =
        sorter.order === 'ascend' ? `${sorter.field}` : `-${sorter.field}`;

      if (sorter?.field === 'last_transaction_date') {
        return {
          order_by_balance: undefined,
          order_by_transaction: sortOrder,
        };
      }

      if (sorter?.field === 'balance') {
        return {
          order_by_balance: sortOrder,
          order_by_transaction: undefined,
        };
      }
    };

    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      ...sortBy(),
    });
  };

  const tableWidth = screens.xxl ? { x: 1440 } : { x: 1100 };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('wallet.foundUsers')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<UserWithWallet>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        scroll={tableWidth}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(getRoute(ROUTES.WALLET_TRANSACTIONS, record.id));
              }
            },
          };
        }}
      >
        <Column<UserWithWallet>
          title={t('wallet.table.userName')}
          dataIndex={'userName'}
          width={screens.xxl ? 'auto' : 170}
          render={(_, record) => getUserFullName(record.user)}
          ellipsis
        />
        <Column<UserWithWallet>
          title={t('wallet.table.email')}
          dataIndex={['user', 'email']}
          width={screens.xxl ? 'auto' : 170}
          ellipsis
        />
        <Column<UserWithWallet>
          title={t('wallet.table.phone')}
          dataIndex={['user', 'phone_number']}
          width={screens.xxl ? 'auto' : 170}
          ellipsis
        />
        <Column<UserWithWallet>
          title={t('wallet.table.walletBalance')}
          dataIndex={'balance'}
          sorter={true}
          sortDirections={['descend', 'ascend', 'descend']} // workaround to remove null as an option
          width={screens.xxl ? 'auto' : 130}
          render={(_, record) =>
            formatPrice({
              value: record.balance,
              currency: record.currency,
            })
          }
          ellipsis
        />
        <Column<UserWithWallet>
          title={t('wallet.table.transactionDate')}
          dataIndex={'last_transaction_date'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']} // workaround to remove null as an option
          defaultSortOrder={'descend'}
          width={screens.xxl ? 'auto' : 130}
          render={(_, record) =>
            record.last_transaction_date
              ? formatDate({
                  date: record.last_transaction_date,
                  format: 'date',
                })
              : null
          }
          ellipsis
        />
      </Table>
    </StyledTableWrapper>
  );
};
