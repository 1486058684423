import { Dayjs } from 'dayjs';

export enum BookingFilterFormField {
  Date = 'date',
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
  Field = 'field',
  Bookings = 'bookings',
}

export type BookingFilterFormValues = {
  [BookingFilterFormField.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
  [BookingFilterFormField.FullUserName]: string | undefined;
  [BookingFilterFormField.PhoneNumber]: string | undefined;
  [BookingFilterFormField.Field]: string | undefined;
  [BookingFilterFormField.Bookings]: boolean | undefined;
};
