import { PaginatedRequestParams } from 'api';
import { MainFiltersParams } from 'components/main-filters/main-filters';

export enum FieldsFilterFormField {
  FieldName = 'field_name',
}

export type FieldsFilterFormValues = {
  [FieldsFilterFormField.FieldName]: string | undefined;
};

export type FieldsFilterFormPaginated = FieldsFilterFormValues &
  MainFiltersParams &
  PaginatedRequestParams;
