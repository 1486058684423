export enum TopUpFormField {
  TransactionId = 'transaction_id',
  ReferenceNumber = 'reference_number',
  AuthId = 'auth_id',
  Amount = 'amount',
}

export type TopUpFormValues = {
  [TopUpFormField.TransactionId]: string;
  [TopUpFormField.ReferenceNumber]: string;
  [TopUpFormField.AuthId]: string;
  [TopUpFormField.Amount]: string;
};
