import { BorderOutlined, InfoCircleOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components';
import { mapSourceColorToToken, SourceColor } from 'utils/source';

export const StyledInfoIcon = styled(InfoCircleOutlined)`
  color: ${({ theme }) => theme.antd.colorIcon};
`;

export const SourceColorBlock = styled(BorderOutlined)<{
  $color: SourceColor;
}>`
  ${({ theme, $color }) => {
    const color = mapSourceColorToToken(theme, $color);

    return css`
      background-color: ${color};
      color: ${color};
    `;
  }}
`;

export const LegendListItem = styled.li`
  display: flex;
  font-size: 1.6rem;
  margin-bottom: 16px;

  .anticon {
    margin-right: 12px;
    font-size: 2.4rem;

    &:not(${SourceColorBlock}) {
      color: ${({ theme }) => theme.antd.colorIcon};
    }
  }
`;
