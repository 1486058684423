import { colors } from './colors';
import * as distances from './distances';
import * as fieldsTimetable from './fields-timetable';

export const theme = {
  colors,
  constants: {
    ...distances,
    fieldsTimetable,
  },
};
