import { RcFile } from 'antd/es/upload';
import { Button, ContentTopBar, Loader, Result404 } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { UpdateFacilityDTO } from 'features/facilities/api/facilities-api.service';
import {
  useUpdateFacility,
  useUploadFacilityImage,
} from 'features/facilities/use-cases';
import { useDeleteFacilityImage } from 'features/facilities/use-cases/delete-facility-image';
import { useGetFacility } from 'features/facilities/use-cases/get-facility';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Facility } from 'types';

import { UpdateFacilityFormValues } from './types';
import { UpdateFacilityForm } from './update-facility-form';

const getPayload = (
  formValues: UpdateFacilityFormValues,
  id: string,
): UpdateFacilityDTO => {
  const {
    area,
    facility_name,
    facility_name_in_arabic,
    geolocation,
    payment,
    rank,
    is_split_payment,
  } = formValues;

  const getGeolocationArray = geolocation.split(',');

  return {
    name: facility_name,
    address: '',
    payment_methods: payment,
    lat: Number(getGeolocationArray[0].trim()),
    lon: Number(getGeolocationArray[1].trim()),
    name_arabic: facility_name_in_arabic,
    id,
    area_id: area,
    rank,
    is_split_payment,
  };
};

export const UpdateFacility = ({
  facilityId,
  onSuccess,
  onCancel,
}: {
  facilityId: string;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const { t } = useTranslation();
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState(false);
  const { mutate: mutateUpdateFacility, isLoading: isLoadingUpdateFacility } =
    useUpdateFacility();
  const { uploadImage } = useUploadFacilityImage();
  const { mutate: mutateDeleteFacilityImage } = useDeleteFacilityImage();

  const facilityQueryResult = useGetFacility(facilityId, {
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });

  const {
    isLoading: isLoadingFacility,
    isError: isErrorFacility,
    isFetching: isFetchingFacility,
    data: facilityData,
  } = facilityQueryResult;

  const handleUpdate = async (
    formValues: UpdateFacilityFormValues,
    fileList: RcFile[],
  ) => {
    const payload = getPayload(formValues, facilityId);

    mutateUpdateFacility(payload, {
      onSuccess(data) {
        const { id } = data as Facility;

        if (fileList.length) {
          const formData = new FormData();
          formData.append('file', fileList[0] as RcFile);

          setIsLoadingUploadImage(true);

          uploadImage(id, formData).finally(() => {
            setIsLoadingUploadImage(false);

            return onSuccess();
          });
        } else {
          onSuccess();
        }
      },
    });
  };

  const handleDeleteImage = () => {
    mutateDeleteFacilityImage(facilityId);
  };

  return (
    <>
      {isLoadingFacility ? (
        <Loader />
      ) : isErrorFacility ? (
        <Result404 />
      ) : (
        <>
          <ContentTopBar
            title={facilityData.name}
            renderButtons={
              <Button onClick={onCancel}>
                {t('facilities.buttons.cancel')}
              </Button>
            }
          />

          <StyledContentBackground>
            <UpdateFacilityForm
              data={facilityData}
              handleUpdate={handleUpdate}
              handleDeleteImage={handleDeleteImage}
              disabled={
                isLoadingUpdateFacility ||
                isFetchingFacility ||
                isLoadingUploadImage
              }
            />
          </StyledContentBackground>
        </>
      )}
    </>
  );
};
