export const getEnvVariable = (variableName: string) => {
  const variable = process.env[variableName];

  if (!variable) {
    throw Error(
      `There is no environment variable named: '${variableName}'. Check .env file or CI workflow and secrets.`,
    );
  }

  return variable;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};
