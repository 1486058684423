import { USER_ROLES } from 'features/users/permissions';
import { useUserContext } from 'features/users/user-context';
import { useMemo } from 'react';
import { Role } from 'types';
import { RoleTranslationsMap } from 'utils/role';

export const useRoleOptions = (selectedCompany: undefined | null | string) => {
  const { user } = useUserContext();

  return useMemo(() => {
    if (!user) return [];

    const showAllRoles = selectedCompany === undefined;
    const showLi3ibRoles = selectedCompany === null;
    const showClientRoles = !!selectedCompany;

    let roles: Role[] = [];
    if (showAllRoles) {
      roles = USER_ROLES.adminPortalRoles;
    }
    if (showLi3ibRoles) {
      roles = USER_ROLES.li3ibRoles;
    }
    if (showClientRoles) {
      roles = USER_ROLES.clientRoles;
    }

    const canCreateSuperAdmin = user.role === Role.SuperAdmin;

    if (!canCreateSuperAdmin) {
      roles = roles.filter((role) => role !== Role.SuperAdmin);
    }

    return roles.map((role) => ({
      label: RoleTranslationsMap[role],
      value: role,
    }));
  }, [selectedCompany, user]);
};
