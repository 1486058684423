import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .avatar {
    flex-shrink: 0;
  }

  .name {
    margin: 0;
  }
`;
