export function getActiveKeys(locationPath: string): string[] {
  if (locationPath === '/') return ['/'];

  const pathSegments = locationPath.split('/').filter((segment) => !!segment);

  const keys = pathSegments.reduce<string[]>((acc, segment) => {
    const previousSegmentPath = acc[acc.length - 1];
    const segmentPath = previousSegmentPath
      ? `${previousSegmentPath}/${segment}`
      : `/${segment}`;

    acc.push(segmentPath);

    return acc;
  }, []);

  return keys;
}
