import { FormInstance } from 'antd';
import { useMainFiltersContext } from 'components/main-filters/main-filters.context';
import {
  WeekViewFiltersFormField,
  WeekViewFiltersFormValues,
} from 'features/bookings/components/week-view-filters-form/week-view-filters-form';
import { usePermissions } from 'features/users';
import { useEffect, useState } from 'react';
import { Option } from 'types';

export const useInitialLoad = (
  form: FormInstance<WeekViewFiltersFormValues>,
  fieldOptions: Option[],
  callback: (values: WeekViewFiltersFormValues) => void,
) => {
  const [isInitialLoadCompleted, setIsInitialLoadCompleted] = useState(false);

  const {
    params: [filters, setFilters],
    facilityOptions: [facilityOptions],
    companyOptions: [companyOptions],
  } = useMainFiltersContext();
  const permissions = usePermissions();

  const shouldHandleCompanies = Boolean(permissions?.company.select);
  const shouldHandleFacilities = Boolean(permissions?.facility.select);

  useEffect(() => {
    if (isInitialLoadCompleted || !shouldHandleCompanies) return;

    if (!filters.company_type && companyOptions.length > 0) {
      setFilters((prevState) => {
        return {
          ...prevState,
          company_id: companyOptions[0]?.value || undefined,
        };
      });
      form.setFieldValue(WeekViewFiltersFormField.Sport, undefined);
      form.setFieldValue(WeekViewFiltersFormField.Field, undefined);
    }
  }, [
    companyOptions,
    filters.company_type,
    form,
    isInitialLoadCompleted,
    setFilters,
    shouldHandleCompanies,
  ]);

  useEffect(() => {
    if (isInitialLoadCompleted || !shouldHandleFacilities) return;

    if (!filters.facility_id && facilityOptions.length > 0) {
      setFilters((prevState) => {
        return {
          ...prevState,
          facility_id: facilityOptions[0]?.value || undefined,
        };
      });
      form.setFieldValue(WeekViewFiltersFormField.Sport, undefined);
      form.setFieldValue(WeekViewFiltersFormField.Field, undefined);
    }
  }, [
    facilityOptions,
    filters.facility_id,
    form,
    isInitialLoadCompleted,
    setFilters,
    shouldHandleFacilities,
  ]);

  useEffect(() => {
    if (isInitialLoadCompleted) return;

    if (
      !form.getFieldValue(WeekViewFiltersFormField.Field) &&
      fieldOptions.length >= 1
    ) {
      form.setFieldValue(WeekViewFiltersFormField.Field, fieldOptions[0].value);
      callback({
        [WeekViewFiltersFormField.Field]: fieldOptions[0].value as string,
      });
      setIsInitialLoadCompleted(true);
    }
  }, [callback, fieldOptions, form, isInitialLoadCompleted]);
};
