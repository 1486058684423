import { SelectProps, Spin } from 'antd';
import { Select } from 'components';
import { AreasParams } from 'features/areas/api/areas-api.service';
import { useGetAreasInfiniteQuery } from 'features/areas/use-cases/get-areas';
import { usePaginationParams } from 'hooks';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Area } from 'types';

const initialParams = {
  page: 1,
  size: 10,
};

export const AreaSelect = ({
  countryCode,
  name,
  ...props
}: SelectProps & { countryCode?: string; name?: string }) => {
  const { t } = useTranslation();

  const { params, setParams } = usePaginationParams<AreasParams>(initialParams);

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetAreasInfiniteQuery(
      { ...params, country_code: countryCode, name },
      {
        keepPreviousData: true,
        cacheTime: 2000,
      },
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    debounce(
      (areaName?: string) =>
        setParams((prevParams) => ({
          ...prevParams,
          ...{ name: areaName },
        })),
      500,
    ),
    [],
  );

  const handleClear = () => setParams(initialParams);

  return (
    <Select<Area>
      placeholder={t('areas.formFields.areaName.placeholder')}
      onSearch={debouncedOnSearch}
      onClear={handleClear}
      loading={isFetching}
      dropdownMatchSelectWidth={200}
      notFoundContent={
        isFetching ? (
          <Spin
            size='small'
            aria-label={t('areas.formFields.areaName.loading')}
          />
        ) : null
      }
      options={data?.pages
        .map((page) =>
          page.items.map((areaItem) => ({
            value: areaItem.id,
            label: areaItem.name,
          })),
        )
        .flat()}
      onPopupScroll={(e) => {
        if (!e.target) return;

        const target = e.target as HTMLDivElement;
        const isScrolledToBottom =
          target.scrollTop + target.offsetHeight === target.scrollHeight;

        if (!isScrolledToBottom) return;

        if (hasNextPage || !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
      allowClear
      {...props}
    />
  );
};
