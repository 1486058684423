import { Tag } from 'antd';
import styled from 'styled-components';

export const StyledDate = styled.div`
  background: ${({ theme }) => theme.colors.colorPrimary};
  color: white;
  margin: 3px;
  border-radius: 8px;
`;

export const StyledTag = styled(Tag)`
  user-select: none;
  cursor: pointer;
  padding: 2px 5px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.colorPrimary};
    color: white;
  }
`;
