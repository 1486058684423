import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  FieldBookingsParams,
  FieldBookingsResponse,
  FieldsApiService,
} from 'features/fields';

import { fieldsBookingsQueryKeys } from './query-keys';

const { getFieldBookings } = FieldsApiService();

const getFieldBookingsQuery = (
  params: FieldBookingsParams,
  options?: UseQueryOptions<FieldBookingsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<FieldBookingsResponse>;
  options?: UseQueryOptions<FieldBookingsResponse>;
} => ({
  queryKey: fieldsBookingsQueryKeys.list(params),
  queryFn: ({ signal }) => getFieldBookings(params, { config: { signal } }),
  ...options,
});

export const useGetFieldBookings = (
  params: FieldBookingsParams,
  options?: UseQueryOptions<FieldBookingsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<FieldBookingsResponse, Error>(
    getFieldBookingsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
