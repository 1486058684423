import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  GetSportsParams,
  SportsApiService,
  SportsResponse,
} from 'features/sports';

import { sportsQueryKeys } from './query-keys';

const { getSports } = SportsApiService();

const getSportsQuery = (
  params: GetSportsParams,
  options?: UseQueryOptions<SportsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<SportsResponse>;
  options?: UseQueryOptions<SportsResponse>;
} => ({
  queryKey: sportsQueryKeys.list(params),
  queryFn: ({ signal }) => getSports(params, { config: { signal } }),
  ...options,
});

export const useGetSports = (
  params: GetSportsParams,
  options?: UseQueryOptions<SportsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<SportsResponse, Error>(
    getSportsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

export const useGetSportsInfiniteQuery = (
  params: GetSportsParams,
  options?: UseInfiniteQueryOptions<SportsResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: sportsQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getSports(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
