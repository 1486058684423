import { Dayjs } from 'dayjs';
import { BookingType } from 'types';

export enum RecurringBookingFilterFormField {
  Date = 'date',
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
  Field = 'field',
  Bookings = 'bookings',
}

export type RecurringBookingFilterFormValues = {
  [RecurringBookingFilterFormField.Date]:
    | [Dayjs | undefined, Dayjs | undefined]
    | null;
  [RecurringBookingFilterFormField.FullUserName]: string | undefined;
  [RecurringBookingFilterFormField.PhoneNumber]: string | undefined;
  [RecurringBookingFilterFormField.Field]: string | undefined;
  [RecurringBookingFilterFormField.Bookings]: BookingType | undefined;
};
