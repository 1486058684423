import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AddBookingCommentDTO,
  BookingApiService,
  bookingCommentsQueryKeys,
} from 'features/bookings';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

const { addBookingComment } = BookingApiService();

export const useAddBookingComment = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: AddBookingCommentDTO) => {
      return addBookingComment(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: bookingCommentsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: bookingCommentsQueryKeys.details(),
          }),
        ]);
        message.success(i18n.t('bookings.details.successfulCommentUpdate'));
      },
    },
  );
};
