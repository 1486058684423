import { Weekdays } from 'types/days';

export enum RecurringBookingsKey {
  Date = 'date',
  Day = 'day',
  Time = 'time',
}

export type RecurringBookingsValues = {
  [RecurringBookingsKey.Date]: string;
  [RecurringBookingsKey.Day]: Weekdays;
  [RecurringBookingsKey.Time]: string;
};
