import { Button } from 'antd';
import styled from 'styled-components';

type TimePriceInputWrapperProps = {
  error: boolean;
};

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TimePriceInputWrapper = styled.div<TimePriceInputWrapperProps>`
  border: 1px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red5 : theme.colors.gray5};
  border-radius: 8px;
  min-height: 38px;
  padding: 4px;
  width: 100%;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

export const TimePriceInputChip = styled.span`
  background-color: ${({ theme }) => theme.colors.gray3};
  padding: 4px 8px;
  border-radius: 4px;
  user-select: none;
`;

export const TimePriceButton = styled(Button)`
  margin-left: 1rem;
`;
