import { DoubleRightOutlined } from '@ant-design/icons';
import { Form, InputNumber, Typography } from 'antd';
import {
  AddFixedBookingDiscountField,
  AddFixedBookingDiscountFormValues,
} from 'features/bookings';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethod } from 'types';
import { formatPrice } from 'utils/currency';
import { paymentMethodTranslationsMap } from 'utils/transaction';

import { PaginatedRecurringBookings } from './fixed-booking-invoice';
import {
  DiscountInputWrapper,
  SectionLabel,
  TotalAmountRow,
  TotalAmountWrapper,
  Wrapper,
} from './fixed-booking-invoice.styles';
import {
  InvoiceGridColumn,
  InvoiceGridItem,
  InvoiceGridWrapper,
} from './invoice-grid';

type InvoicePaymentInfoProps = {
  bookings: PaginatedRecurringBookings;
  paymentMethod?: PaymentMethod;
  setDiscount?: Dispatch<SetStateAction<number>>;
  isAddBooking?: boolean;
};

export const InvoicePaymentInfo = ({
  bookings,
  paymentMethod,
  setDiscount,
  isAddBooking,
}: InvoicePaymentInfoProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<AddFixedBookingDiscountFormValues>();
  const { t } = useTranslation();

  const sumTotalPrice =
    bookings &&
    bookings?.items
      .map((booking) => booking?.transaction[0]?.total_cost)
      .reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);

  const sumDiscount =
    bookings &&
    bookings?.items
      .map((booking) => booking.transaction[0]?.discount)
      .reduce((accumulator, value) => {
        return accumulator + value;
      }, 0);

  const getInitialValues = {
    [AddFixedBookingDiscountField.Discount]: 0,
  };

  const watchDiscount = Form.useWatch(
    AddFixedBookingDiscountField.Discount,
    form,
  );

  useEffect(() => {
    return setDiscount && setDiscount(watchDiscount);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDiscount]);

  return (
    <Wrapper>
      <SectionLabel>
        <Typography.Text strong>
          {t('bookings.invoice.labels.paymentInfo')}
        </Typography.Text>
      </SectionLabel>
      <InvoiceGridWrapper>
        <InvoiceGridColumn>
          {paymentMethod ? (
            <InvoiceGridItem
              label={t('bookings.invoice.labels.paymentMode')}
              value={paymentMethodTranslationsMap[paymentMethod]}
            />
          ) : null}
          <InvoiceGridItem
            label={t('bookings.invoice.labels.totalPrice')}
            value={sumTotalPrice}
          />
          <InvoiceGridItem
            label={t('bookings.invoice.labels.totalDiscount')}
            value={sumDiscount}
          />
        </InvoiceGridColumn>
        {isAddBooking ? (
          <InvoiceGridColumn>
            <Form
              id='add-fixed-booking-discount-form'
              name='add-fixed-booking-discount-form'
              form={form}
              initialValues={getInitialValues}
              onFinishFailed={() => {
                setValidateTrigger(['onChange']);
              }}
              validateTrigger={validateTrigger}
              requiredMark='optional'
              layout='vertical'
            >
              <DiscountInputWrapper>
                <Form.Item<AddFixedBookingDiscountField>
                  label={t('createBooking.formFields.discountPerBooking.label')}
                  name={AddFixedBookingDiscountField.Discount}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    placeholder={t(
                      'createBooking.formFields.discountPerBooking.placeholder',
                    )}
                    prefix={
                      <DoubleRightOutlined
                        rotate={90}
                        className='input-prefix-icon'
                      />
                    }
                    min={0}
                    max={bookings.items[0]?.transaction[0]?.total_cost}
                    size='large'
                  />
                </Form.Item>
              </DiscountInputWrapper>
            </Form>
          </InvoiceGridColumn>
        ) : null}
      </InvoiceGridWrapper>

      <TotalAmountRow>
        <Typography.Text strong>
          {t('bookings.invoice.labels.totalAmount')}
        </Typography.Text>
        <TotalAmountWrapper>
          <Typography.Title level={3} style={{ color: 'inherit', margin: 0 }}>
            {formatPrice({
              value: sumTotalPrice - sumDiscount,
              currency: 'KWD',
            })}
          </Typography.Title>
        </TotalAmountWrapper>
      </TotalAmountRow>
    </Wrapper>
  );
};
