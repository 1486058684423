import { Space } from 'antd';
import { Button, ContentTopBar } from 'components';
import { StyledMenu } from 'components/side-bar/side-bar.styles';
import { getActiveKeys } from 'components/side-bar/utils';
import { CancelAllModal } from 'features/bookings/components/cancel-all/cancel-all';
import { EditDiscountModal } from 'features/bookings/components/edit-discount/edit-discount';
import { useGetRecurringBooking } from 'features/bookings/use-cases/use-get-recurring-booking';
import { getUserFullName } from 'features/users';
import i18n from 'locales/i18n';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet, useLocation, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const RecurringBookingDetailsView = () => {
  const [showCancelAllModal, setShowCancelAllModal] = useState(false);
  const [showEditDiscountModal, setShowEditDiscountModal] = useState(false);
  const { t } = useTranslation();
  const [detailsTitle, setDetailsTitle] = useState('');
  const { id } = useParams();

  const { data: bookingData, isFetching: isFetchingBooking } =
    useGetRecurringBooking(id || '', { enabled: Boolean(id) });

  const maxDiscount = bookingData
    ? bookingData?.total_cost / bookingData?.bookings_count
    : 0;

  useEffect(() => {
    if (isFetchingBooking) {
      setDetailsTitle('Loading...');
    }
    if (bookingData) {
      const fieldName = bookingData.field.name;
      const userName = getUserFullName({
        first_name: bookingData.first_name,
        last_name: bookingData.last_name,
      });
      const companyName = bookingData.field.facility.name;

      setDetailsTitle(`${fieldName} (${userName}) - ${companyName}`);
    }
  }, [bookingData, isFetchingBooking]);

  const location = useLocation();

  const activeKeys = useMemo(() => {
    return getActiveKeys(location.pathname);
  }, [location.pathname]);

  if (!id) return null;

  return (
    <>
      <ContentTopBar
        title={detailsTitle}
        hasBackButton={ROUTES.FIXED_BOOKINGS}
        renderButtons={
          <Space>
            <Button danger onClick={() => setShowCancelAllModal(true)}>
              {t('bookings.buttons.cancelAll')}
            </Button>
            <Button onClick={() => setShowEditDiscountModal(true)}>
              {t('bookings.buttons.editDiscount')}
            </Button>
          </Space>
        }
      />
      <StyledMenu
        selectedKeys={activeKeys}
        mode='horizontal'
        style={{
          background: 'transparent',
        }}
        items={[
          {
            key: `${ROUTES.FIXED_BOOKINGS}/${id}/bookings`,
            label: (
              <NavLink to={`${ROUTES.FIXED_BOOKINGS}/${id}/bookings`}>
                {i18n.t('sideBar.menu.bookings.bookings')}
              </NavLink>
            ),
          },
          {
            key: `${ROUTES.FIXED_BOOKINGS}/${id}/invoice`,
            label: (
              <NavLink to={`${ROUTES.FIXED_BOOKINGS}/${id}/invoice`}>
                {i18n.t('sideBar.menu.bookings.invoice')}
              </NavLink>
            ),
          },
          {
            key: `${ROUTES.FIXED_BOOKINGS}/${id}/comments`,
            label: (
              <NavLink to={`${ROUTES.FIXED_BOOKINGS}/${id}/comments`}>
                {i18n.t('sideBar.menu.bookings.comments')}
              </NavLink>
            ),
          },
        ]}
      />
      <Outlet />
      {showCancelAllModal && (
        <CancelAllModal
          onCancel={() => setShowCancelAllModal(false)}
          onSuccess={() => setShowCancelAllModal(false)}
        />
      )}
      {showEditDiscountModal && (
        <EditDiscountModal
          onCancel={() => setShowEditDiscountModal(false)}
          onSuccess={() => setShowEditDiscountModal(false)}
          maxDiscount={maxDiscount}
        />
      )}
    </>
  );
};
