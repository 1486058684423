import { Card, Row } from 'antd';
import Background from 'assets/patterned-background.svg';
import styled from 'styled-components';

export const StyledBackground = styled(Row)`
  min-height: 100vh;
  padding: 8px;
  background-image: url(${Background});
  background-position: center center;
  background-size: cover;
`;

export const StyledCard = styled(Card)`
  padding: 8px;
  width: 100%;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 48px;
`;
