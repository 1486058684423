export enum RecurringBookingsKey {
  GameScheduleDate = 'gameScheduleDate',
  GameScheduleTime = 'gameScheduleTime',
  Price = 'price',
}

export type RecurringBookingsValues = {
  [RecurringBookingsKey.GameScheduleDate]: string;
  [RecurringBookingsKey.GameScheduleTime]: string;
  [RecurringBookingsKey.Price]: string;
};
