import { DownOutlined } from '@ant-design/icons';
import { Layout, Typography } from 'antd';
import { Button } from 'components';
import styled, { css } from 'styled-components';
import { BREAKPOINTS } from 'styles/constants/breakpoints';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  &.app-header {
    z-index: 100;
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: ${({ theme }) => theme.constants.headerHeight};
    padding: 16px;
  }

  .app-header-logo-wrapper {
    cursor: pointer;

    & > .ant-space-item {
      display: flex;
    }
  }

  .app-header-hamburger-mobile {
    color: ${({ theme }) => theme.colors.pureWhite};
    font-size: 2rem;
    padding-right: 0.8rem;

    ${BREAKPOINTS.mobile} {
      display: none;
    }
  }

  .app-header-avatar {
    flex-shrink: 0;
    background: ${({ theme }) => theme.colors.gray7};
  }

  .app-header-user-wrapper {
    display: flex;
    width: fit-content;
    max-width: 50%;

    & .app-header-user {
      width: 100%;
      border: none;

      &:hover,
      &:active {
        color: ${({ theme }) => theme.colors.pureWhite};
      }
    }

    & > .ant-space-item {
      width: 100%;
    }
  }
`;

interface StyledUserMenuButtonProps {
  $avatarSize: number;
}

export const StyledUserMenuButton = styled(Button)<StyledUserMenuButtonProps>`
  display: flex;
  align-items: center;

  .avatar-user-placeholder-icon {
    font-size: 1.6rem;
  }

  ${({ $avatarSize }) => css`
    .app-header-space {
      gap: 0 !important;
      margin-left: 0.8rem;
      display: flex;
      flex-direction: column;
      width: calc(
        100% - ${$avatarSize / 10}rem - 0.8rem
      ); //box width - avatar width - margin

      & > .ant-space-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }
    }
  `}
`;

export const StyledDropdownIcon = styled(DownOutlined)`
  width: 100%;
`;

export const StyledLogoWrapper = styled.div`
  display: flex;
`;

export const StyledUserInfo = styled(Typography.Text)`
  width: 100%;
  font-size: inherit;
  color: inherit;
  text-align: left;

  &.app-header-user-role {
    color: ${({ theme }) => theme.colors.li3ibgreen5};
    font-size: 1.2rem;
    vertical-align: top;
  }
`;
