import { Form, message, Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { UpdateTermDTO } from 'features/community/api/community-api.service';
import { TermFormDataFields } from 'features/community/components/interface/type';
import { useGetCompanyTcdetails } from 'features/companies/use-cases/get-termbyid';
import { useUpdateTermComapny } from 'features/companies/use-cases/update-termcompany';
import { StyledUserModalWrapper } from 'features/users/components/modals/user-modal.styles';
import { useEffect, useState } from 'react';

import { TermCompanyInfoFormItems } from './term-comapny-form-items';

export const EditTermCompanyModal = ({
  onClose,
  onSuccess,
  id,
}: {
  onClose: () => void;
  onSuccess: () => void;
  id: string;
}) => {
  const [form] = Form.useForm();
  const [validateTrigger] = useState(['onSubmit']);
  const formName = 'edit-user-form';
  //   const { id } = useParams();
  //   const userQueryResult = useGetGroupTermList('' as any);
  const userQueryResult = useGetCompanyTcdetails(`id=${id}`, {
    cacheTime: 0,
  });
  const {
    isLoading: isLoadingGet,
    isFetching: isFetchingGet,
    data: termdata,
    isError: isErrorQuestion,
  } = userQueryResult;
  const { mutate: mutateUpdateTerm, isLoading: isLoadingUpdateQuestion } =
    useUpdateTermComapny();
  const isLoading = {
    loading: isLoadingGet || isFetchingGet || isLoadingUpdateQuestion,
    isFetchingGet,
    isLoadingUpdateQuestion,
  };
  const handleUpdate = (formData: TermFormDataFields) => {
    if (
      typeof formData.content_english !== 'string' ||
      !formData.content_english.trim().replace(/<[^>]*>?/gm, '').length
    ) {
      message.warning('Please enter content in English.');

      return;
    }

    // Check if content_arabic is a string and has meaningful content
    if (
      typeof formData.content_arabic !== 'string' ||
      !formData.content_arabic.trim().replace(/<[^>]*>?/gm, '').length
    ) {
      message.warning('Please enter content in Arabic.');

      return;
    }
    const payload: UpdateTermDTO = {
      id: id,
      content_english: formData.content_english,
      content_arabic: formData.content_arabic,
    };
    mutateUpdateTerm(payload as any, { onSuccess });
  };
  useEffect(() => {
    form.setFieldValue('content_english', termdata?.items[0]?.content_english);
    form.setFieldValue('content_arabic', termdata?.items[0]?.content_arabic);
    form.setFieldValue('typename', termdata?.items[0]?.typename);
  }, [termdata]);

  return (
    <StyledUserModalWrapper>
      <Modal
        open
        centered
        className='right-modal editer_text'
        title={'Update Terms & Conditions'}
        onCancel={() => {
          document.body.style.overflow = 'auto';
          onClose(); // Call onClose function
        }}
        getContainer={false}
        onOk={form.submit}
        okText={'Update'}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{
          form: formName,
        }}
      >
        {isFetchingGet ? (
          <Loader />
        ) : isErrorQuestion ? (
          <Result404 />
        ) : (
          <Spin spinning={isLoading.loading}>
            <Form
              id={formName}
              name={formName}
              form={form}
              layout='vertical'
              //   initialValues={getInitialValues}
              // onFinishFailed={onFinishFailed}
              validateTrigger={validateTrigger}
              disabled={isLoading.loading}
              onFinish={handleUpdate}
            >
              <TermCompanyInfoFormItems form={form} />
              {/* <PasswordFormItems title={t('adminUsers.accountInfo')} /> */}
            </Form>
          </Spin>
        )}
      </Modal>
    </StyledUserModalWrapper>
  );
};
