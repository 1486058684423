import { Dropdown as AntDropdown } from 'antd';
import styled, { css } from 'styled-components';

export const StyledDropdown = styled(AntDropdown)`
  display: flex;
  gap: 6px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.greenDark};

  &:hover {
    color: ${({ theme }) => theme.colors.greenLight};
  }

  .anticon {
    font-size: 12px;
  }
`;

export const dropdownMenu = css`
  .ant-dropdown-menu {
    overflow-y: auto;
    max-height: 200px;
  }
`;
