import { Facility, Option } from 'types';

export const facilitiesFilterOptionsMap = (
  facilities?: Facility[],
  predefinedOptions: Option[] = [],
) => {
  if (!facilities) return [...predefinedOptions];

  const companiesOptions = facilities.map((facility) => ({
    label: facility.name,
    value: facility.id,
  }));

  return [...predefinedOptions, ...companiesOptions];
};
