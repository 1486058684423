import { Empty, Pagination } from 'antd';
import {
  ContentFilters,
  ContentTopBar,
  Loader,
  RecordCountBadge,
  StyledPaginationWrapper,
} from 'components';
import { ResultError } from 'components/errors';
import {
  MainFilters,
  MainFiltersParams,
} from 'components/main-filters/main-filters';
import {
  DayViewFiltersForm,
  DayViewFiltersFormValues,
  EmptyStateWrapper,
  FieldsTimetable,
  StyledTimetableBackground,
  TimetableContainer,
  TimetableLegend,
  TimetableMetadataWrapper,
} from 'features/bookings/components';
import { useGetFieldsBookings } from 'features/bookings/use-cases';
import { FieldsBookingsParams } from 'features/fields';
import {
  canViewDayViewClientStats,
  usePermissions,
  useUserContext,
} from 'features/users';
import { usePaginationParams } from 'hooks';
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';

import DayViewStatsComponent from './day-view-stats-component';
const initialParams: FieldsBookingsParams = {
  page: 1,
  size: 10,
  date: formatDate({
    date: new Date(),
    format: 'isoDate',
  }),
};

export const DayView = () => {
  const { t } = useTranslation();
  const timetableRef = useRef<HTMLDivElement>(null);
  const [componentTopOffset, setComponentTopOffset] = useState(0);
  const permissions = usePermissions();

  const { params, setParams, setPage } =
    usePaginationParams<FieldsBookingsParams>(initialParams);

  const { data, isError, isFetching, isLoading, isSuccess, status } =
    useGetFieldsBookings(params);
  const hasResults = isSuccess && !isFetching && data.total > 0;

  useLayoutEffect(() => {
    if (timetableRef.current) {
      setComponentTopOffset(timetableRef.current.offsetTop);
    }
  }, []);

  const handleFiltersChange = (values: DayViewFiltersFormValues) => {
    setParams((prevParams) => ({
      ...prevParams,
      ...values,
      page: 1,
      date: formatDate({
        date: values.date,
        format: 'isoDate',
      }),
    }));
  };

  const handleMainFiltersChange = (mainParams: MainFiltersParams) => {
    setParams((prevParams) => ({
      ...prevParams,
      company_type: permissions?.companyType.select
        ? mainParams?.company_type
        : undefined,
      facility_id: permissions?.facility.select
        ? mainParams?.facility_id
        : undefined,
      company_id: permissions?.company.select
        ? mainParams?.company_id
        : undefined,
      page: 1,
    }));
  };

  const formId = 'day-view-filters-form';
  const ndate = params?.date;
  const company_id = params?.company_id;
  const facility_id = params?.facility_id;
  const { user } = useUserContext();

  return (
    <>
      <ContentTopBar
        title={t('dayView.heading')}
        ctFilter={
          <ContentFilters formId={formId}>
            <DayViewFiltersForm
              formId={formId}
              handleFilter={handleFiltersChange}
              initialValues={initialParams}
            />
          </ContentFilters>
        }
        renderHeadingContent={
          <MainFilters onChange={handleMainFiltersChange} />
        }
      />
      {canViewDayViewClientStats(user) && (
        <DayViewStatsComponent
          date={ndate}
          company_id={company_id}
          facility_id={facility_id}
        />
      )}
      {/* <ContentFilters formId={formId}>
        <DayViewFiltersForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialParams}
        />
      </ContentFilters> */}

      <TimetableMetadataWrapper>
        <RecordCountBadge
          text={t('dayView.fieldsFound')}
          count={data?.total || 0}
        />

        <TimetableLegend />
      </TimetableMetadataWrapper>

      <StyledTimetableBackground className='slot_wrap day_view_clg'>
        <TimetableContainer
          ref={timetableRef}
          $offset={componentTopOffset}
          status={!hasResults ? 'empty' : status}
          headerHeight={215}
        >
          {isLoading || isFetching ? (
            <Loader />
          ) : isError ? (
            <ResultError />
          ) : !hasResults ? (
            <EmptyStateWrapper>
              <Empty description={<>{t('dayView.empty')}</>} />
            </EmptyStateWrapper>
          ) : (
            <FieldsTimetable
              data={data}
              date={formatDate({ date: params.date, format: 'isoDate' })}
              filteredSportId={params.sport_id}
            />
          )}
        </TimetableContainer>
      </StyledTimetableBackground>

      <StyledPaginationWrapper>
        <Pagination
          current={data?.page}
          total={data?.total}
          onChange={setPage}
        />
      </StyledPaginationWrapper>
    </>
  );
};
