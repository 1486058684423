export enum LoginFormField {
  PhoneNumber = 'phone_number',
  Password = 'password',
  Remember = 'remember',
}

export type LoginFormValues = {
  [LoginFormField.PhoneNumber]: string;
  [LoginFormField.Password]: string;
  [LoginFormField.Remember]: boolean;
};
