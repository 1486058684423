import { theme as antDesignTheme } from 'antd';
import { ReactNode } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { theme } from 'styles/constants/theme';
import { GlobalStyle } from 'styles/global-style.styles';

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { token } = antDesignTheme.useToken();

  return (
    <StyledComponentsThemeProvider theme={{ ...theme, antd: token }}>
      <GlobalStyle />
      {children}
    </StyledComponentsThemeProvider>
  );
};
