import { DownOutlined, InboxOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  Image,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import Upload, { RcFile } from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';
import dayjs from 'dayjs';
import { CompanySelect } from 'features/companies/components/company-select/company-select';
import { FacilitySelect } from 'features/facilities/components';
import { useGetFacility } from 'features/facilities/use-cases/get-facility';
import { FieldDetailsField } from 'features/fields';
import {
  ImageGroupWrapper,
  ImageWrapper,
} from 'features/fields/components/field-details-form/field-details-form.styles';
import { SportSelect } from 'features/sports';
import { useUploadProps } from 'hooks/use-upload-props';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import { FieldDetailsPackage, PackageDetailsFormValues, week } from './type';
const { useBreakpoint } = Grid;
type AddCompanyFormProps = {
  handleUpdate: (
    object: PackageDetailsFormValues,
    FileList: RcFile[],
    galleryFileList: RcFile[],
  ) => void;
  disabled: boolean;
};
export const AddPackageForm = ({
  handleUpdate,
  disabled,
}: AddCompanyFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<PackageDetailsFormValues>();
  const screens = useBreakpoint();
  const { fileList: galleryFileList, uploadProps: uploadGalleryProps } =
    useUploadProps();
  const { fileList, uploadProps } = useUploadProps();
  const onFinish = (formValues: any) => {
    handleUpdate(formValues, fileList as RcFile[], galleryFileList as RcFile[]);
  };
  const companyId = Form.useWatch(FieldDetailsPackage.Company, form);
  const [endDate, setEndDate] = useState<any>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const handleStartDateChange = (date: any) => {
    setStartDate(date);
    if (date && endDate && date.isAfter(endDate, 'day')) {
      setEndDate(null); // Reset end date if it is before the new start date
    }
  };
  const handleEndDateChange = (date: any) => {
    setEndDate(date);
    if (date && startDate && date.isBefore(startDate, 'day')) {
      setStartDate(null); // Reset start date if it is after the new end date
    }
  };
  const disabledStartDate = (current: any) => {
    // Disable all dates before today and dates before or equal to the end date
    return (
      current &&
      (current < dayjs().startOf('day') ||
        (endDate && current <= endDate.endOf('day')))
    );
  };
  const disabledEndDate = (current: any) => {
    // Disable all dates before today and dates before or equal to the start date
    return (
      current &&
      (current < dayjs().startOf('day') ||
        (startDate && current <= startDate.endOf('day')))
    );
  };
  const facilityId = Form.useWatch(FieldDetailsField.Facility, form);
  const { data: facilityData } = useGetFacility(facilityId, {
    enabled: !!facilityId,
    retry: false,
    cacheTime: 0, //workaround to invalidate initialValues of client data form
  });
  useEffect(() => {
    const composeGeolocation = `${facilityData?.geolocation.lat}, ${facilityData?.geolocation.lon}`;

    return (
      facilityData &&
      form.setFieldsValue({
        area: facilityData?.area.name,
        geolocation: composeGeolocation,
      } as any)
    );
  }, [facilityData]);
  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;

  return (
    <>
      <div className='packForm'>
        <div className='warp_inner'>
          <Form
            {...formItemLayout}
            id='company-details-form'
            name='company-details-form'
            form={form}
            layout={screens.lg ? 'horizontal' : 'vertical'}
            onFinish={(values) => onFinish(values)}
            onFinishFailed={() => {
              setValidateTrigger(['onChange']);
            }}
            validateTrigger={validateTrigger}
            disabled={disabled}
          >
            <Row>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={t('fields.formFields.company.label')}
                  name={FieldDetailsPackage.Company}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <CompanySelect allowClear={false} size='large' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={t('fields.formFields.facility.label')}
                  name={FieldDetailsPackage.Facility}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <FacilitySelect
                    allowClear={false}
                    allowPullAll={false}
                    disabled={!companyId}
                    companyId={companyId ? companyId : undefined}
                    size='large'
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={t('fields.formFields.sport.label')}
                  name={FieldDetailsPackage.Sports}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <SportSelect
                    mode='multiple'
                    size='large'
                    customParams={{ is_active: true }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label='Google Map location' name='latlang'>
                  <Input placeholder='Latitude,Longitude' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={'Package Type'}
                  name={FieldDetailsPackage.Packagetypeid}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    defaultValue='Select'
                    style={{ width: '100%' }}
                    options={[
                      { value: '1', label: 'Monthly' },
                      { value: '2', label: 'Session' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name='month_session_count'
                  label='Monthly/Session Number'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder='Enter Number'
                    onKeyPress={(e) => {
                      const isNumber = /^[0-9]$/.test(e.key);
                      const isBackspace = e.key === 'Backspace';
                      if (!isNumber && !isBackspace) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={6}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label='Total Seat'
                  name={FieldDetailsPackage.Totalseat}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder='Enter Seat'
                    onKeyPress={(e) => {
                      const isNumber = /^[0-9]$/.test(e.key);
                      const isBackspace = e.key === 'Backspace';
                      if (!isNumber && !isBackspace) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={6}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label='Package Name'
                  name={FieldDetailsPackage.PackageName}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder='Enter Package Name' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label='Package Name Arabic'
                  name={FieldDetailsPackage.Packagenamearabic}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder='Enter Package Name' dir='rtl' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='Price'
                  name='price'
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    placeholder='Enter Price'
                    prefix={'KD'}
                    onKeyPress={(e) => {
                      const inputElement = e.target as HTMLInputElement;
                      const isNumber = /^[0-9]$/.test(e.key);
                      const isBackspace = e.key === 'Backspace';
                      const isDecimalPoint = e.key === '.';

                      // Get the current value of the input field
                      const inputValue = inputElement.value;

                      // Check if the decimal point is already present in the input field
                      const hasDecimalPoint = inputValue.includes('.');

                      // Allow numbers, backspace, and one-time use of the decimal point
                      if (
                        !isNumber &&
                        !isBackspace &&
                        !(isDecimalPoint && !hasDecimalPoint)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    maxLength={6}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={'Age'}
                  name={'age'}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Input placeholder='Enter age' />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={'Gender'}
                  name={'gender'}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <Select
                    placeholder='Select Gender'
                    style={{ width: '100%' }}
                    options={[
                      { value: 'All', label: 'All' },
                      { value: 'Male', label: 'Male' },
                      { value: 'Female', label: 'Female' },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item<FieldDetailsPackage>
                  label={'Days'}
                  name={'package_days'}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    className='has-prefix-icon'
                    placeholder={t('bookings.formFields.days.placeholder')}
                    options={week}
                    mode='multiple'
                    allowClear
                    size='large'
                    suffixIcon={
                      <>
                        <DownOutlined className='suffix-icon' />
                      </>
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <label className='lab_bx' htmlFor='Package Start Date & Time'>
                  Package Start Date & Time
                </label>
                <div className='pack_date'>
                  <Form.Item name='package_time_start'>
                    <DatePicker
                      disabledDate={disabledStartDate}
                      onChange={handleStartDateChange}
                      value={startDate}
                    />
                  </Form.Item>
                  <Form.Item name='packagestart_time'>
                    <TimePicker use12Hours format='h:mm a' />
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <label className='lab_bx' htmlFor='Package End Date & Time'>
                  Package End Date & Time
                </label>
                <div className='pack_date'>
                  <Form.Item name='package_time_end'>
                    <DatePicker
                      disabledDate={disabledEndDate}
                      onChange={handleEndDateChange}
                      value={endDate}
                    />
                  </Form.Item>
                  <Form.Item name='packageend_time'>
                    <TimePicker use12Hours format='h:mm a' />
                  </Form.Item>
                </div>
              </Col>
              <Col span={8}>
                <label>Package Cancellation</label>
                <Form.Item name='is_user_can_cancel'>
                  <Radio.Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item<FieldDetailsPackage>
                  label='About Package'
                  name={'about_package'}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea placeholder='Decribe Package' />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item<FieldDetailsPackage>
                  label='About Package Arabic'
                  name={'about_package_arabic'}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                    },
                  ]}
                >
                  <TextArea placeholder='Decribe Package' dir='rtl' />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={'Package Cover'}
                  name={'package_cover'}
                  getValueFromEvent={(event) => event?.fileList}
                >
                  {fileList.length ? (
                    <Upload
                      {...uploadProps}
                      name='file'
                      accept='.png, .jpg, .jpeg'
                      maxCount={1}
                      listType='picture-card'
                    />
                  ) : (
                    <ImgCrop rotationSlider aspect={2}>
                      <Dragger {...uploadProps} multiple={false}>
                        <p className='ant-upload-drag-icon'>
                          <InboxOutlined />
                        </p>
                        <p className='ant-upload-text'>
                          {t('upload.dragOrClick')}
                        </p>
                        <p className='ant-upload-hint'>
                          <Trans
                            i18nKey='upload.hint'
                            components={{ br: <br /> }}
                          />
                        </p>
                      </Dragger>
                    </ImgCrop>
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  label={t('fields.formFields.gallery.label')}
                  getValueFromEvent={(event) => event?.fileList}
                >
                  <Image.PreviewGroup>
                    <ImageGroupWrapper>
                      <ImageWrapper key={'uploader'}>
                        <ImgCrop rotationSlider aspect={2}>
                          <Upload
                            {...uploadGalleryProps}
                            name='file'
                            accept='.png, .jpg, .jpeg'
                            maxCount={12}
                            listType='picture-card'
                          >
                            {galleryFileList.length < 12 && '+ Upload'}
                          </Upload>
                        </ImgCrop>
                      </ImageWrapper>
                    </ImageGroupWrapper>
                  </Image.PreviewGroup>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button disabled={disabled} type='primary' htmlType='submit'>
                  Add Package
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
};
