import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { Amenity } from 'types/amenity';

export type GetAmenitiesParams = {
  name?: string;
  chargeable?: boolean;
  is_active?: boolean;
} & PaginatedRequestParams;

export type AmenitiesResponse = PaginatedResponse<Amenity>;

export type UpdateAmenityDTO = {
  id: string;
  is_active?: boolean;
};
export const AmenitiesApiService = () => {
  const getAmenities = async (
    params: GetAmenitiesParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<AmenitiesResponse>({
      ...callConfig,
      endpoint: `/admin/amenities`,
      params,
    });

    return response.data;
  };

  const updateAmenity = async (
    payload: UpdateAmenityDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<Amenity>({
      ...callConfig,
      endpoint: `/admin/amenities/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  return {
    getAmenities,
    updateAmenity,
  };
};
