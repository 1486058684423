import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AmenitiesApiService,
  amenitiesQueryKeys,
  UpdateAmenityDTO,
} from 'features/amenities';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

const { updateAmenity } = AmenitiesApiService();

export const useUpdateAmenity = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateAmenityDTO) => {
      return updateAmenity(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: amenitiesQueryKeys.lists(),
          }),
        ]);
        message.success(i18n.t('amenities.details.successfulUpdate'));
      },
    },
  );
};
