import { Row } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  gap: 24px;
`;

export const SectionLabel = styled(Row)`
  background: ${({ theme }) => theme.antd.colorPrimaryBg};
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

export const TotalAmountRow = styled(Row)`
  background: ${({ theme }) => theme.antd.colorBgLayout};
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
`;

export const TotalAmountWrapper = styled.div`
  background: ${({ theme }) => theme.antd.colorPrimaryBg};
  color: ${({ theme }) => theme.antd.colorPrimaryActive};
  padding: 8px 16px;
  border-radius: 4px;
`;

export const DiscountInputWrapper = styled.div`
  padding: 0 8px;
  margin-top: 8px;

  ${BREAKPOINTS.laptop} {
    width: 75%;
    margin-left: auto;
    margin-top: initial;
  }
`;

export const AddRecurringBookingButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 42px;
  border-top: 1px solid ${({ theme }) => theme.antd.colorBorderSecondary};
  padding-top: 16px;

  ${BREAKPOINTS.laptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
