import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  FieldsApiService,
  FieldsParams,
  FieldsResponse,
} from 'features/fields/api/fields-api.service';

import { fieldsQueryKeys } from './query-keys';

const { getFields } = FieldsApiService();

const getFieldsQuery = (
  params: FieldsParams,
  options?: UseQueryOptions<FieldsResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<FieldsResponse>;
  options?: UseQueryOptions<FieldsResponse>;
} => ({
  queryKey: fieldsQueryKeys.list(params),
  queryFn: ({ signal }) => getFields(params, { config: { signal } }),
  ...options,
});

export const useGetFields = (
  params: FieldsParams,
  options?: UseQueryOptions<FieldsResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<FieldsResponse, Error>(
    getFieldsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

export const useGetFieldsInfiniteQuery = (
  params: FieldsParams,
  options?: UseInfiniteQueryOptions<FieldsResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: fieldsQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getFields(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
