import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TransactionDetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ItemLabel = styled.div`
  color: ${({ theme }) => theme.antd.colorTextDescription};
`;

export const ItemValue = styled.div``;
