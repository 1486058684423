import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError, PaginatedRequestParams } from 'api';
import {
  WalletApiService,
  WalletTransactionsHistoryResponse,
} from 'features/wallet';

import { walletQueryKeys } from './query-keys';

const { getWalletTransactionsHistory } = WalletApiService();

const getUsersWithWalletQuery = (
  id: string,
  params: PaginatedRequestParams,
  options?: UseQueryOptions<WalletTransactionsHistoryResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<WalletTransactionsHistoryResponse>;
  options?: UseQueryOptions<WalletTransactionsHistoryResponse>;
} => ({
  queryKey: walletQueryKeys.detail(id, params),
  queryFn: ({ signal }) =>
    getWalletTransactionsHistory(id, params, { config: { signal } }),
  ...options,
});

export const useGetWalletTransactionsHistory = (
  id: string,
  params: PaginatedRequestParams,
  options?: UseQueryOptions<WalletTransactionsHistoryResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<WalletTransactionsHistoryResponse, Error>(
    getUsersWithWalletQuery(id, params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
