import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CompaniesFilterFormField, CompaniesFilterFormValues } from './types';

const { useBreakpoint } = Grid;

type CompaniesFilterFormProps = {
  formId: string;
  handleFilter: (values: CompaniesFilterFormValues) => void;
  initialValues: CompaniesFilterFormValues;
  closeModal?: () => void;
};

export const CompaniesFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: CompaniesFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<CompaniesFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
  };

  const handleOnFinish = (values: CompaniesFilterFormValues) => {
    handleFilter(values);
    closeModal?.();
  };

  return (
    <Form<CompaniesFilterFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish}
      initialValues={initialValues}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<CompaniesFilterFormValues>
            label={t('companies.formFields.companyName.label')}
            name={CompaniesFilterFormField.CompanyName}
          >
            <Input
              placeholder={t('companies.formFields.companyName.placeholder')}
            />
          </Form.Item>
          <Form.Item<CompaniesFilterFormValues>
            label={t('companies.formFields.phoneNumber.label')}
            name={CompaniesFilterFormField.PhoneNumber}
          >
            <Input
              type='tel'
              placeholder={t('companies.formFields.phoneNumber.placeholder')}
            />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('companies.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('companies.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
