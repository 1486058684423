import { Button, Form, Grid, Input } from 'antd';
import { ContentFilters } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import {
  UsersWithWalletFilterFormField,
  UsersWithWalletFilterFormValues,
} from 'features/wallet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;

type UsersWithWalletFilterFormProps = {
  formId: string;
  handleFilter: (values: UsersWithWalletFilterFormValues) => void;
  initialValues: UsersWithWalletFilterFormValues;
  closeModal?: () => void;
};

export const UsersWithWalletFilterForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: UsersWithWalletFilterFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<UsersWithWalletFilterFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
  };

  const handleOnFinish = (values: UsersWithWalletFilterFormValues) => {
    handleFilter(values);
    closeModal?.();
  };

  return (
    <>
      <Form<UsersWithWalletFilterFormValues>
        id={formId}
        name={formId}
        form={form}
        layout='vertical'
        onFinish={handleOnFinish}
        initialValues={initialValues}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        validateTrigger={validateTrigger}
      >
        <ContentFilters.FormItemsWrapper>
          <StandardItemsWrapper>
            <Form.Item<UsersWithWalletFilterFormValues>
              label={t('wallet.formFields.userName.label')}
              name={UsersWithWalletFilterFormField.FullUserName}
            >
              <Input
                placeholder={t('wallet.formFields.userName.placeholder')}
              />
            </Form.Item>
            <Form.Item<UsersWithWalletFilterFormValues>
              label={t('wallet.formFields.phoneNumber.label')}
              name={UsersWithWalletFilterFormField.PhoneNumber}
            >
              <Input
                placeholder={t('wallet.formFields.phoneNumber.placeholder')}
              />
            </Form.Item>
          </StandardItemsWrapper>
        </ContentFilters.FormItemsWrapper>

        {screens.lg && (
          <ContentFilters.ButtonsWrapper>
            <Button type='primary' htmlType='submit'>
              {t('wallet.buttons.filter', { count: 1 })}
            </Button>
            <Button onClick={onReset}>{t('wallet.buttons.reset')}</Button>
          </ContentFilters.ButtonsWrapper>
        )}
      </Form>
    </>
  );
};
