import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  FacilitiesApiService,
  UpdateFacilityDTO,
} from 'features/facilities/api/facilities-api.service';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { facilitiesQueryKeys } from './query-keys';

const { updateFacility } = FacilitiesApiService();

export const useUpdateFacility = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateFacilityDTO) => {
      return updateFacility(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: facilitiesQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: facilitiesQueryKeys.detail(variables.id),
          }),
        ]);
        message.success(i18n.t('facilities.update.successfulUpdate'));
      },
    },
  );
};
