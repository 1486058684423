import { Typography } from 'antd';
import { FieldsBookingsResponse } from 'features/fields';
import { useUserContext } from 'features/users';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { theme } from 'styles';
import { getLocalizedFullHours } from 'utils/date';

import { FieldHeader } from './field-header';
import {
  AxisColumn,
  EmptyCell,
  HeaderRow,
  ScaleHourBlock,
  StyledHeadersWrapper,
  StyledTimetableContent,
} from './fields-timetable.styles';
import { TimetableContent } from './timetable-content';
import { getFieldData, getTimelineHours, getTimeRange } from './utils';

type FieldsTimetableProps = {
  data: FieldsBookingsResponse;
  date: string;
  filteredSportId?: string;
};

const HOUR_BLOCK_HEIGHT_IN_PIXELS =
  theme.constants.fieldsTimetable.oneHourSlotHeight;

export const FieldsTimetable = ({
  data,
  date,
  filteredSportId,
}: FieldsTimetableProps) => {
  let preventEvent = false;
  let contentLastScrollTop = 0;
  let contentLastScrollLeft = 0;
  const headerRef = useRef<HTMLDivElement>(null);
  const axisRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { user } = useUserContext();
  const currentHour = new Date().getHours();

  const timelineHours = getTimelineHours(data.items);
  const [state] = useState('30');
  const timelineRange = getTimeRange(timelineHours);
  const timelineRangeLabels = getLocalizedFullHours(timelineRange);
  useLayoutEffect(() => {
    const currentHourIndex = timelineRange.indexOf(currentHour);
    const lastOpenHourIndex = timelineRange.length - 1;
    const scrollContentTo = (hourIndex: number) => {
      if (contentRef.current) {
        contentRef.current.scrollTop = hourIndex * HOUR_BLOCK_HEIGHT_IN_PIXELS;
      }
    };

    if (currentHourIndex > 0) {
      scrollContentTo(currentHourIndex);
    } else if (currentHour > timelineRange[lastOpenHourIndex]) {
      scrollContentTo(lastOpenHourIndex);
    }
  }, [currentHour, timelineRange]);

  if (!user) return null;

  // TODO: improve performance of all scrolling functions with requestAnimationFrame
  const onHeaderScroll = (event: React.UIEvent<HTMLElement>) => {
    if (preventEvent) {
      preventEvent = false;

      return;
    }

    preventEvent = true;

    if (contentRef.current) {
      contentRef.current.scrollLeft = event.currentTarget.scrollLeft;
    }
  };

  const onAxisScroll = (event: React.UIEvent<HTMLElement>) => {
    if (preventEvent) {
      preventEvent = false;
      
return;
    }
    preventEvent = true;

    if (contentRef.current) {
      contentRef.current.scrollTop = event.currentTarget.scrollTop;
    }
  };

  const onContentScroll = (event: React.UIEvent<HTMLElement>) => {
    if (preventEvent) {
      preventEvent = false;
      
return;
    }

    if (event.currentTarget.scrollTop !== contentLastScrollTop) {
      preventEvent = true;
      if (axisRef.current) {
        axisRef.current.scrollTop = event.currentTarget.scrollTop;
        contentLastScrollTop = event.currentTarget.scrollTop;
      }
    }
    if (event.currentTarget.scrollLeft !== contentLastScrollLeft) {
      preventEvent = true;
      if (headerRef.current) {
        headerRef.current.scrollLeft = event.currentTarget.scrollLeft;
        contentLastScrollLeft = event.currentTarget.scrollLeft;
      }
    }
  };

  return (
    <>
      <EmptyCell />
      <HeaderRow ref={headerRef} onScroll={onHeaderScroll}>
        <StyledHeadersWrapper>
          {data.items.map((field) => (
            <FieldHeader key={field.id} field={getFieldData(field)} />
          ))}
        </StyledHeadersWrapper>
      </HeaderRow>

      <AxisColumn ref={axisRef} onScroll={onAxisScroll}>
        {timelineRangeLabels.map((hourLabel) => (
          <ScaleHourBlock
            className={state === '30' ? 'oneandhelf' : ('' as any)}
            key={hourLabel}
          >
            <Typography.Text>{hourLabel}</Typography.Text>
          </ScaleHourBlock>
        ))}
      </AxisColumn>

      <StyledTimetableContent ref={contentRef} onScroll={onContentScroll}>
        <TimetableContent
          fields={data.items}
          date={date}
          user={user}
          timelineHours={timelineHours}
          filteredSportId={filteredSportId}
        />
      </StyledTimetableContent>
    </>
  );
};
