import { PaginatedRequestParams } from 'api';
import { Dispatch, SetStateAction, useState } from 'react';

type SetPage = (page: number, pageSize: number) => void;
type SetSortOrder = (newOrder: string) => void;
type ResetPage = () => void;
type UpdateParams = <TParams>(newParams: TParams) => void;

type Result<TParams> = {
  params: TParams;
  setParams: Dispatch<SetStateAction<TParams>>;
  setPage: SetPage;
  updateParams: UpdateParams;
  setSortOrder: SetSortOrder;
  resetPage: ResetPage;
};

export const usePaginationParams = <TParams extends PaginatedRequestParams>(
  initialState: TParams | (() => TParams),
): Result<TParams> => {
  const [params, setParams] = useState<TParams>(initialState);

  const setPage: SetPage = (page, pageSize) => {
    setParams((prevParams) => ({
      ...prevParams,
      size: pageSize,
      page,
    }));
  };

  const setSortOrder: SetSortOrder = (newOrder) => {
    setParams((prevParams) => ({
      ...prevParams,
      order_by: newOrder,
      page: 1,
    }));
  };

  const resetPage: ResetPage = () => {
    setParams((prevParams) => ({
      ...prevParams,
      page: 1,
    }));
  };

  const updateParams: UpdateParams = (newParams) => {
    setParams((prevParams) => ({
      ...prevParams,
      ...newParams,
    }));
  };

  return { params, setParams, setPage, setSortOrder, resetPage, updateParams };
};
