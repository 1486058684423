import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  BookingApiService,
  recurringBookingsQueryKeys,
} from 'features/bookings';
import { RecurringBooking } from 'types';

const { getRecurringBooking } = BookingApiService();

const getBookingsQuery = (
  params: string,
  options?: UseQueryOptions<RecurringBooking>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<RecurringBooking>;
  options?: UseQueryOptions<RecurringBooking>;
} => ({
  queryKey: recurringBookingsQueryKeys.detail(params),
  queryFn: ({ signal }) => getRecurringBooking(params, { config: { signal } }),
  ...options,
});

export const useGetRecurringBooking = (
  params: string,
  options?: UseQueryOptions<RecurringBooking>,
) => {
  const { message } = App.useApp();

  return useQuery<RecurringBooking, Error>(
    getBookingsQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
