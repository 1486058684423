import i18n from 'locales/i18n';
import { Role } from 'types';

export const RoleTranslationsMap: { [key in Role]: string } = {
  [Role.SuperAdmin]: i18n.t('role.SuperAdmin'),
  [Role.Li3ibAdmin]: i18n.t('role.Li3ibAdmin'),
  [Role.Li3ibAccountant]: i18n.t('role.Li3ibAccountant'),
  [Role.ClientAdmin]: i18n.t('role.ClientAdmin'),
  [Role.Accountant]: i18n.t('role.Accountant'),
  [Role.Coordinator]: i18n.t('role.Coordinator'),
  [Role.CallCenterOperative]: i18n.t('role.CallCenterOperative'),
  [Role.User]: i18n.t('role.User'),
};
