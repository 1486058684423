import { PlusOutlined } from '@ant-design/icons';
import { Grid } from 'antd';
import { Button, ContentFilters, ContentTopBar } from 'components';
import { AddAdminUserModal } from 'features/users/components/modals/add-admin-user-modal';
import { UserFilterFormValues } from 'features/users/components/users-filter-form/types';
import { UsersFilterForm } from 'features/users/components/users-filter-form/users-filter-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyType } from 'types';

const { useBreakpoint } = Grid;

type UsersTopBarProps = {
  getInitialFilterValues: UserFilterFormValues;
  handleFilter: (values: UserFilterFormValues) => void;
};

export const UsersTopBar = ({
  getInitialFilterValues,
  handleFilter,
}: UsersTopBarProps) => {
  useState<CompanyType>();
  const { t } = useTranslation();
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const screens = useBreakpoint();
  const formId = 'users-filters-form';

  return (
    <>
      <ContentTopBar
        title={t('adminUsers.heading.admin')}
        renderButtons={
          <>
            <Button
              icon={!screens.xs ? <PlusOutlined /> : undefined}
              onClick={() => setShowAddUserModal(true)}
            >
              {t('adminUsers.addNewUser')}
            </Button>
            {showAddUserModal && (
              <AddAdminUserModal onClose={() => setShowAddUserModal(false)} />
            )}
          </>
        }
      />
      <ContentFilters formId={formId}>
        <UsersFilterForm
          formId={formId}
          handleFilter={handleFilter}
          initialValues={getInitialFilterValues}
        />
      </ContentFilters>
    </>
  );
};
