import { DynamicImportIcon, FallbackIcon } from 'components';
import styled from 'styled-components';
import { Sport } from 'types';

const SportsStyle = styled.div`
  display: inline-flex;
  gap: 12px;

  border-radius: 8px;
  padding: 8px;
  border: 1px solid var(--color-bg-text-active, rgba(0, 0, 0, 0.15));

  background: ${({ theme }) => theme.colors.pureWhite};

  svg {
    width: 20px;
    height: 20px;
  }
`;

const SportStyle = styled.div`
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

type SportIconsListProps = {
  sports: Pick<Sport, 'id' | 'name' | 'icon'>[];
};

export const SportIconsList = ({ sports }: SportIconsListProps) => {
  return (
    <SportsStyle>
      {sports.map((sport) => (
        <SportStyle key={sport.icon}>
          <DynamicImportIcon
            filePath={`sports/${sport.icon}`}
            label={sport.name}
            fallback={<FallbackIcon label={sport.name} />}
          />
        </SportStyle>
      ))}
    </SportsStyle>
  );
};
