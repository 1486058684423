import styled, { css } from 'styled-components';
import { mapSourceColorToToken, SourceColor } from 'utils/source';

export type SlotProps = {
  $color: SourceColor;
  isSelectable: boolean;
};

const ONE_HOUR_IN_MINUTES = 60;
const darkBackgrounds: SlotProps['$color'][] = ['green', 'darkGreen', 'blue'];

export const StyledSlotWrapper = styled.div<{ duration: number }>`
  height: ${({ theme, duration }) =>
    `${
      (theme.constants.fieldsTimetable.oneHourSlotHeight * duration) /
      ONE_HOUR_IN_MINUTES
    }px`};
  width: ${({ theme }) => theme.constants.fieldsTimetable.columnWidth};
  background-color: ${({ theme }) => theme.antd.colorBgLayout};
  margin: ${({ theme }) => `0 ${theme.constants.fieldsTimetable.columnMargin}`};
  padding: 2px 8px;

  &:last-of-type {
    border-radius: 0 0 8px 8px;
  }
`;

const commonSlotStyles = css`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  border-radius: 6px;
  border: ${({ theme }) => `1px solid ${theme.antd.colorBorder}`};
`;

const commonUnavailableSlotStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ClosedSlot = styled.div`
  ${commonSlotStyles}
  ${commonUnavailableSlotStyles}
  background-color: ${({ theme }) => theme.antd['red-1']};
`;

export const MaintenanceSlot = styled.div`
  ${commonSlotStyles}
  ${commonUnavailableSlotStyles}
  background-color: ${({ theme }) => theme.antd.colorBgContainerDisabled};
`;

export const BookingSlot = styled.div<SlotProps>`
  ${commonSlotStyles}

  position: relative;
  cursor: ${({ isSelectable }) => (isSelectable ? 'pointer' : 'not-allowed')};
  background-color: ${({ theme, $color }) =>
    mapSourceColorToToken(theme, $color)};

  & .ant-typography,
  & .anticon {
    color: ${({ theme, $color: color }) =>
      darkBackgrounds.includes(color) ? theme.colors.pureWhite : undefined};
  }
`;

export const DurationRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SlotContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DurationWrapper = styled.div`
  min-width: 50%;
`;

export const SlotFooter = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  bottom: 4px;
  left: 8px;
  right: 8px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  gap: 4px;
  padding-left: 8px;
`;
