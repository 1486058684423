import { SelectProps, Spin } from 'antd';
import { Select } from 'components';
import { GetSportsParams } from 'features/sports/api/sports-api.service';
import { useGetSportsInfiniteQuery } from 'features/sports/use-cases';
import { usePaginationParams } from 'hooks';
import debounce from 'lodash/debounce';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Sport } from 'types';

const initialParams = {
  page: 1,
  size: 10,
  // is_active: true,
};

type SportSelectProps = {
  customParams?: GetSportsParams;
};

export const SportSelect = ({
  customParams,
  ...props
}: SelectProps & SportSelectProps) => {
  const { t } = useTranslation();

  const { params, setParams } =
    usePaginationParams<GetSportsParams>(initialParams);

  const { data, isFetching, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useGetSportsInfiniteQuery(
      { ...params, ...customParams },
      {
        keepPreviousData: true,
        cacheTime: 2000,
      },
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearch = useCallback(
    debounce(
      (sportName?: string) =>
        setParams((prevParams) => ({ ...prevParams, ...{ name: sportName } })),
      500,
    ),
    [],
  );

  const handleClear = () => setParams(initialParams);

  return (
    <Select<Sport>
      placeholder={t('dayView.formFields.sport.placeholder')}
      onSearch={debouncedOnSearch}
      onClear={handleClear}
      loading={isFetching}
      dropdownMatchSelectWidth={200}
      notFoundContent={
        isFetching ? (
          <Spin
            size='small'
            aria-label={t('dayView.formFields.sport.loading')}
          />
        ) : null
      }
      options={data?.pages
        .map((page) =>
          page.items.map((sportItem) => ({
            value: sportItem.id,
            label: sportItem.name,
          })),
        )
        .flat()}
      onPopupScroll={(e) => {
        if (!e.target) return;

        const target = e.target as HTMLDivElement;
        const isScrolledToBottom =
          target.scrollTop + target.offsetHeight === target.scrollHeight;

        if (!isScrolledToBottom) return;

        if (hasNextPage || !isFetchingNextPage) {
          fetchNextPage();
        }
      }}
      allowClear
      {...props}
    />
  );
};
