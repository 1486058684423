import { Button, Form, Grid, Input, Spin, Typography } from 'antd';
import { Loader, Result404 } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import { useAddFixedBookingComment } from 'features/bookings/use-cases/add-fixed-booking-comment';
import { useGetFixedBookingCommentsInfiniteQuery } from 'features/bookings/use-cases/get-fixed-booking-comments';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingComment } from 'types';
import { formatDate } from 'utils/date';

import {
  ButtonWrapper,
  CommentAdditionalDetails,
  CommentListWrapper,
  CommentWrapper,
  ContentHeaderWrapper,
  ContentWrapper,
  StyledButton,
  StyledText,
  Wrapper,
} from './fixed-booking-details-comments.styles';
import {
  FixedBookingCommentsField,
  FixedBookingCommentsFormValues,
} from './types';

const { useBreakpoint } = Grid;

type FixedBookingDetailsCommentsProps = {
  bookingId: string;
};

export const FixedBookingDetailsComments = ({
  bookingId,
}: FixedBookingDetailsCommentsProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<FixedBookingCommentsFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();

  const bookingCommentsQueryResult = useGetFixedBookingCommentsInfiniteQuery(
    { id: bookingId },
    {
      keepPreviousData: true,
      enabled: true,
      retry: false,
      cacheTime: 0, //workaround to invalidate initialValues of client data form
    },
  );

  const {
    isLoading: isLoadingBookingComments,
    isError: isErrorBookingComments,
    isFetching: isFetchingBookingComments,
    data: bookingCommentsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = bookingCommentsQueryResult;

  const {
    mutate: mutateAddBookingComment,
    isLoading: isLoadingAddBookingComment,
  } = useAddFixedBookingComment();

  const getInitialValues = {
    [FixedBookingCommentsField.Comment]: undefined,
  };

  const handleUpdate = (formValues: FixedBookingCommentsFormValues) => {
    mutateAddBookingComment(
      { id: bookingId, text: formValues.comment },
      {
        onSuccess: () => {
          form.resetFields();
        },
      },
    );
  };

  const getPages = () => {
    const pages = bookingCommentsData?.pages;

    return Array.isArray(pages) ? pages : [];
  };
  const mapComments = () =>
    getPages()
      .map((page) => page.items)
      .flat();

  return (
    <>
      {isLoadingBookingComments ? (
        <Loader />
      ) : isErrorBookingComments ? (
        <Result404 />
      ) : (
        <Spin spinning={isFetchingBookingComments || isFetchingNextPage}>
          <Wrapper>
            <StyledContentBackground>
              <ContentWrapper>
                <ContentHeaderWrapper>
                  {t('bookings.fixedBookingComments.title')}
                </ContentHeaderWrapper>

                <Form<FixedBookingCommentsFormValues>
                  id='fixed-booking-comments-form'
                  name='fixed-booking-comments-form'
                  form={form}
                  layout='vertical'
                  initialValues={getInitialValues}
                  onFinish={handleUpdate}
                  onFinishFailed={() => {
                    setValidateTrigger(['onChange']);
                  }}
                  validateTrigger={validateTrigger}
                >
                  <Form.Item<FixedBookingCommentsField>
                    label={t('bookings.formFields.comment.label')}
                    name={FixedBookingCommentsField.Comment}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                      },
                    ]}
                  >
                    <Input.TextArea
                      showCount
                      rows={2}
                      placeholder={`${t(
                        'bookings.formFields.comment.placeholder',
                      )}`}
                      maxLength={1000}
                      size='large'
                    />
                  </Form.Item>

                  <StyledButton
                    disabled={isLoadingAddBookingComment}
                    type='primary'
                    htmlType='submit'
                    size={screens.lg ? 'middle' : 'large'}
                  >
                    {t('bookings.buttons.addComment')}
                  </StyledButton>
                </Form>

                <CommentListWrapper>
                  {bookingCommentsData
                    ? mapComments().map((comment: BookingComment) => (
                        <CommentWrapper key={comment.id}>
                          <CommentAdditionalDetails>
                            {formatDate({
                              date: comment.created_at,
                              format: 'date',
                            })}
                            <StyledText
                              ellipsis={{ tooltip: comment.creator.fullname }}
                            >
                              {comment.creator.fullname}
                            </StyledText>
                          </CommentAdditionalDetails>
                          <Typography>{comment.text}</Typography>
                        </CommentWrapper>
                      ))
                    : null}
                </CommentListWrapper>

                {hasNextPage ? (
                  <ButtonWrapper>
                    <Button type='dashed' onClick={() => fetchNextPage()}>
                      {t('bookings.buttons.loadMoreComments')}
                    </Button>
                  </ButtonWrapper>
                ) : null}
              </ContentWrapper>
            </StyledContentBackground>
          </Wrapper>
        </Spin>
      )}
    </>
  );
};
