import { Button } from 'components';
import { useTranslation } from 'react-i18next';
import { reload } from 'routes/utils';

import { StyledResult } from './result-error.styles';

export const ResultError = () => {
  const { t } = useTranslation();

  return (
    <StyledResult
      status='500'
      title={t('errors.generic.title')}
      subTitle={t('errors.generic.subtitle')}
      extra={
        <Button type='primary' onClick={reload}>
          {t('errors.generic.action')}
        </Button>
      }
    />
  );
};
