import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

type RouteLeavingGuardProps = {
  blocked: boolean;
};

export const RouteLeavingGuard = ({ blocked }: RouteLeavingGuardProps) => {
  const { t } = useTranslation();

  const blocker = useBlocker(blocked);
  const isBlocked = blocker.state === 'blocked';

  const handleConfirmation = () => {
    if (isBlocked) blocker.proceed();
  };

  const handleCancel = () => {
    if (isBlocked) blocker.reset();
  };

  return (
    <Modal
      open={isBlocked}
      title={t('confirmModal.routeLeavingGuard.title')}
      getContainer={false}
      onOk={handleConfirmation}
      onCancel={handleCancel}
      okText={t('confirmModal.routeLeavingGuard.okButton')}
      cancelText={t('confirmModal.routeLeavingGuard.cancelButton')}
      okButtonProps={{
        danger: true,
      }}
      closable={false}
    >
      {t('confirmModal.routeLeavingGuard.text')}
    </Modal>
  );
};
