import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CreateFieldDTO, FieldsApiService } from 'features/fields';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { fieldsQueryKeys } from './query-keys';

const { createField } = FieldsApiService();

export const useCreateField = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: CreateFieldDTO) => {
      return createField(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: fieldsQueryKeys.all,
        });
        message.success(i18n.t('fields.create.successfulCreate'));
      },
    },
  );
};
