import { Button, Grid } from 'antd';
import dayjs from 'dayjs';
import {
  FixedBookingInvoice,
  PaginatedRecurringBookings,
} from 'features/bookings';
import { AddRecurringBookingButtonWrapper } from 'features/bookings/components/fixed-booking-invoice/fixed-booking-invoice.styles';
import { useWizardContext } from 'features/bookings/hooks';
import { useCreateRecurringBooking } from 'features/bookings/use-cases/create-recurring-booking';
import { useGetCompany } from 'features/companies';
import { useGetFacility } from 'features/facilities/use-cases/get-facility';
import { useGetField } from 'features/fields';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useWizard } from 'react-use-wizard';
import { ROUTES } from 'routes/constants';
import {
  BookingType,
  PaymentStatus,
  RecurringBooking,
  RecurringBookingSingleBookingListItem,
  Source,
} from 'types';
import { formatDate, zeroOutSeconds } from 'utils/date';

const { useBreakpoint } = Grid;

type RecurringBookingList = {
  end_date: string;
  start_date: string;
  total_cost: number;
};

export const AddFixedBookingStepThree = () => {
  const { goToStep } = useWizard();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { wizardState, setWizardState } = useWizardContext();
  const [recurringBooking, setRecurringBooking] = useState<RecurringBooking>();
  const [recurringBookingList, setRecurringBookingList] =
    useState<PaginatedRecurringBookings>();
  const [discount, setDiscount] = useState(0);
  const screens = useBreakpoint();

  const { data: companyData } = useGetCompany(
    wizardState?.formValues?.company,
    {
      enabled: !!wizardState?.formValues?.company,
      retry: false,
    },
  );

  const { data: facilityData } = useGetFacility(
    wizardState?.formValues?.facility_id,
    {
      enabled: !!wizardState?.formValues?.facility_id,
      retry: false,
    },
  );

  const { data: fieldData } = useGetField(wizardState?.formValues?.field_id, {
    enabled: !!wizardState?.formValues?.field_id,
    retry: false,
  });

  const sumTotalPrice = wizardState?.prediction?.items
    .map((booking: RecurringBookingSingleBookingListItem) => booking.total_cost)
    .reduce((accumulator: number, value: number) => {
      return accumulator + value;
    }, 0);

  useEffect(() => {
    setRecurringBookingList({
      ...wizardState?.prediction,
      items: wizardState?.prediction.items.map((item: RecurringBookingList) => {
        return {
          id: item.start_date, // for key purposes
          start_date: item.start_date,
          end_date: item.end_date,
          is_cancelled: false,
          client_showed_up: false,
          transaction: [
            {
              id: '',
              total_cost: item.total_cost,
              discount,
              payment_status: PaymentStatus.Created,
            },
          ],
        };
      }),
    });

    setRecurringBooking({
      created_at: dayjs(),
      first_name: wizardState?.formValues.first_name || '',
      last_name: wizardState?.formValues.last_name || '',
      phone_number: wizardState?.formValues.phone_number || t('notApplicable'),
      field: {
        id: '',
        name: fieldData?.name || '',
        max_players: fieldData?.max_players || 0,
        facility: {
          id: '',
          name: facilityData?.name || '',
          company: {
            id: '',
            name: companyData?.name || '',
            image_url: companyData?.image_url || '',
          },
        },
      },
      date_from: wizardState?.formValues.date?.[0],
      date_to: wizardState?.formValues.date?.[1],
      weekdays_list: wizardState?.formValues.days,
      start_time: wizardState?.formValues.time?.[0],
      end_time: wizardState?.formValues.time?.[1],
      bookings_count: wizardState?.prediction.total,
      type: wizardState?.formValues.booking_type,
      source: Source.InternalAdminPortal,
      id: '',
      total_cost: 0,
    });

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardState, companyData, facilityData, fieldData, discount]);

  const { mutate } = useCreateRecurringBooking();

  const handleSubmit = () => {
    const sharedPayload = {
      date_from: wizardState?.formValues.date?.[0]
        ? formatDate({
            date: dayjs(wizardState?.formValues.date?.[0]).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      date_to: wizardState?.formValues.date?.[1]
        ? formatDate({
            date: dayjs(wizardState?.formValues.date?.[1]).endOf('day'),
            format: 'isoDate',
          })
        : undefined,
      weekdays_list: wizardState?.formValues.days,
      start_time: wizardState?.formValues.time?.[0]
        ? formatDate({
            date: zeroOutSeconds(wizardState?.formValues.time?.[0]),
            format: 'timeMilitary',
          })
        : undefined,
      end_time: wizardState?.formValues.time?.[1]
        ? formatDate({
            date: zeroOutSeconds(wizardState?.formValues.time?.[1]),
            format: 'timeMilitary',
          })
        : undefined,
      field_id: fieldData?.id,
      type: wizardState?.formValues.booking_type,
      text: wizardState?.formValues.comment,
    };

    if (wizardState?.formValues.booking_type === BookingType.Fixed) {
      const payload = {
        ...sharedPayload,
        sport_id: wizardState?.formValues.sport_id,
        first_name: wizardState?.formValues.first_name || '',
        last_name: wizardState?.formValues.last_name || '',
        phone_number: wizardState?.formValues.phone_number || '',
        payment_method: wizardState?.formValues.payment_method,
        total_cost: sumTotalPrice,
        discount_per_booking: discount,
      };

      return mutate(payload, {
        onSuccess: () => {
          return navigate(ROUTES.FIXED_BOOKINGS);
        },
        onError: ({ response }) => {
          if (response?.status === 409) {
            setWizardState({
              ...wizardState,
              prediction: response?.data,
            });

            return goToStep(1);
          }
        },
      });
    }

    return mutate(sharedPayload, {
      onSuccess: () => {
        return navigate(ROUTES.FIXED_BOOKINGS);
      },
      onError: ({ response }) => {
        if (response?.status === 409) {
          setWizardState({
            ...wizardState,
            prediction: response?.data,
          });

          return goToStep(1);
        }
      },
    });
  };

  return (
    <>
      {recurringBooking ? (
        <>
          <FixedBookingInvoice
            data={recurringBooking}
            bookings={recurringBookingList as PaginatedRecurringBookings}
            paymentMethod={wizardState?.formValues.payment_method}
            setDiscount={setDiscount}
            isAddBooking={true}
            footer={
              <AddRecurringBookingButtonWrapper>
                <Button
                  onClick={() => goToStep(0)}
                  size={screens.lg ? 'middle' : 'large'}
                >
                  {t('bookings.buttons.changeSlot')}
                </Button>
                <Button
                  onClick={handleSubmit}
                  size={screens.lg ? 'middle' : 'large'}
                  type='primary'
                >
                  {t('bookings.buttons.create')}
                </Button>
              </AddRecurringBookingButtonWrapper>
            }
          />
        </>
      ) : null}
    </>
  );
};
