import { Button, Space, Switch, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { useUpdateStatusSocial } from 'features/social-match/use-cases/update-status';
import { usePermissions } from 'features/users';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { BookingListItem, User } from 'types';
import { Social } from 'types/social';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';
type UsersTableProps = {
  isSocialListLoading: boolean;
  socialList: any;
  tableParams: any;
  setTableParams: any;
};

export const SocialListTable = ({
  socialList,
  isSocialListLoading,
  tableParams,
  setTableParams,
}: UsersTableProps) => {
  const { t } = useTranslation();
  const permissions = usePermissions();
  const navigate = useNavigate();
  const { mutate: mutateUpdateSocial, isLoading: isLoadingUpdateSocial } =
    useUpdateStatusSocial();
  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<BookingListItem> | SorterResult<BookingListItem>[],
  ) => {
    setTableParams({
      pagination,
      ...sorter,
    });

    return {
      pagination,
      filters,
      ...sorter,
    };
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t(`social.foundSocial`)}
          count={socialList?.total as any}
        />
      </StyledTableHeader>
      <div className='table_list_dta'>
        <Table<User>
          loading={isSocialListLoading}
          dataSource={socialList?.items as any}
          rowKey='id'
          onChange={handleTableChange as any}
          pagination={{
            current: tableParams.pagination?.current,
            defaultPageSize: tableParams.pagination?.pageSize,
            total: socialList?.total,
          }}
          onRow={(record: any) => {
            return {
              onClick: () => {
                const selection = window?.getSelection()?.toString();
                if (!selection?.length) {
                  navigate(getRoute(ROUTES.SOCIAL_DETAILS, record.match_id));
                }
              },
            };
          }}
        >
          <Column<Social>
            title={t('social.table.matchtype')}
            dataIndex={'match_type'}
            render={(_, record) => record.match_type}
          />
          <Column<Social>
            title={t('social.table.date')}
            dataIndex={'start_date'}
            render={(_, record) => (
              <>
                {formatDate({
                  date: record.start_date,
                  format: 'date',
                  timeZone: DEFAULT_TIMEZONE,
                } as any)}
                <span style={{ marginLeft: '10px' }}>
                  {formatDate({
                    date: record.start_date,
                    format: 'time',
                    timeZone: DEFAULT_TIMEZONE,
                  } as any)}
                </span>
              </>
            )}
          />
          <Column<Social>
            title={t('social.table.field')}
            dataIndex={'field_name'}
            render={(_, record) => record.field_name}
          />
          <Column<Social>
            title={t('social.table.Price')}
            dataIndex={'price'}
            render={(_, record) => `${record.price} KD`}
          />
          <Column<Social>
            title={t('social.table.matchstatus')}
            dataIndex={'match_status'}
            render={(_, record: any) => record.match_status}
          />
          <Column<Social>
            title={t('social.table.action')}
            render={(_, record: any) => (
              <Space>
                <Switch
                  defaultChecked={record.is_active}
                  checked={record.is_active}
                  loading={isLoadingUpdateSocial}
                  onChange={(isChecked) => {
                    mutateUpdateSocial({
                      match_id: record.match_id,
                      is_active: isChecked,
                    });
                    record.is_active = isChecked;
                  }}
                  onClick={(checked, event) => {
                    event.stopPropagation();
                    event.preventDefault();
                  }}
                />
                <Button
                  type='primary'
                  onClick={() =>
                    navigate(getRoute(ROUTES.SOCIAL_DETAILS, record.match_id))
                  }
                >
                  View
                </Button>
                {/* <Button
                onClick={() =>
                  navigate(getRoute(ROUTES.SOCIAL_EDIT, record.match_id))
                }
              >
                Update Score
              </Button> */}
              </Space>
            )}
          />
        </Table>
      </div>
    </StyledTableWrapper>
  );
};
