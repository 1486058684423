import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { FacilitiesApiService } from 'features/facilities';
import { Facility } from 'types';

import { facilitiesQueryKeys } from './query-keys';

const { getFacility } = FacilitiesApiService();

const getFacilityQuery = (
  id: string,
  options?: UseQueryOptions<Facility>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Facility>;
  options?: UseQueryOptions<Facility>;
} => ({
  queryKey: facilitiesQueryKeys.detail(id),
  queryFn: ({ signal }) => getFacility(id, { config: { signal } }),
  ...options,
});

export const useGetFacility = (
  id: string,
  options?: UseQueryOptions<Facility>,
) => {
  const { message } = App.useApp();

  return useQuery<Facility, Error>(
    getFacilityQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
