import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  BookingApiService,
  BookingCommentResponse,
  GetBookingCommentParams,
} from 'features/bookings';

import { fixedBookingCommentsQueryKeys } from './query-keys';

const { getFixedBookingComments } = BookingApiService();

export const useGetFixedBookingCommentsInfiniteQuery = (
  params: GetBookingCommentParams,
  options?: UseInfiniteQueryOptions<BookingCommentResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: fixedBookingCommentsQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getFixedBookingComments(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
