import styled from 'styled-components';

export const StyledWrapper = styled.div<{ isHighlighted?: boolean }>`
  width: ${({ theme }) => theme.constants.fieldsTimetable.columnWidth};
  display: flex;
  background-color: ${({ theme }) => theme.antd.colorBgLayout};
  border-radius: ${({ theme }) => {
    const radius = theme.constants.fieldsTimetable.columnBorderRadius;

    return `${radius} ${radius} 0 0`;
  }};
  margin: ${({ theme }) => `0 ${theme.constants.fieldsTimetable.columnMargin}`};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) =>
    `8px ${theme.constants.fieldsTimetable.columnPadding}`};

  ${({ isHighlighted }) =>
    isHighlighted &&
    `
      border: 1px solid var(--color-primary-base, #53a869);
      border-bottom: none;
    `};
`;

export const SportsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: center;
  margin: 8px 0;
`;

export const FieldInfoWrapper = styled.div`
  color: ${({ theme }) => theme.antd.colorIcon};
  border: ${({ theme }) => `1px solid ${theme.antd.colorBorder}`};
  border-radius: 4px;
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
`;

export const FieldPropertyWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const StyledImageWrapper = styled.div`
  height: 96px;
  width: 100%;
  margin: 8px 0;
  border-radius: 4px;
  overflow: hidden;

  .ant-image {
    width: 100%;
    height: 100%;

    .ant-image-img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledImagePlaceholder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.greenExtraLight};
  align-items: center;
  justify-content: center;
`;
