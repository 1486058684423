import { AddField } from 'features/fields/components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const AddFieldView = () => {
  const navigate = useNavigate();

  const navigateToList = () => {
    navigate(ROUTES.FIELDS);
  };

  return <AddField onCancel={navigateToList} onSuccess={navigateToList} />;
};
