import { DoubleRightOutlined } from '@ant-design/icons';
import { Form, Grid, InputNumber, Space, Typography } from 'antd';
import { Modal } from 'components/modal/modal';
import { useUpdateRecurringBooking } from 'features/bookings/use-cases/use-update-recurring-booking';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EditDiscountFormField, EditDiscountFormValues } from './types';

const { useBreakpoint } = Grid;

type EditDiscountModalProps = {
  onSuccess: () => void;
  onCancel: () => void;
  maxDiscount: number;
};

export const EditDiscountModal = ({
  onCancel,
  onSuccess,
  maxDiscount,
}: EditDiscountModalProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onChange']);
  const formName = 'edit-discount';
  const [form] = Form.useForm<EditDiscountFormValues>();
  const { id } = useParams();

  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { mutate, isLoading } = useUpdateRecurringBooking();

  const handleUpdate = (formData: EditDiscountFormValues) => {
    if (id) {
      mutate(
        {
          discount: formData[EditDiscountFormField.DiscountPerBooking],
          id,
        },
        {
          onSuccess,
        },
      );
    }
  };

  return (
    <Modal
      open
      title={t('bookings.modal.editDiscount.title')}
      onCancel={onCancel}
      onOk={form.submit}
      getContainer={false}
      width={screens.lg ? '480px' : '100%'}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{
        loading: isLoading,
        type: 'primary',
      }}
      okText={<>{t('bookings.buttons.updateDiscount')}</>}
    >
      <Space direction={'vertical'}>
        <Typography.Text>
          {t('bookings.modal.editDiscount.subtitle')}
        </Typography.Text>
        <Form<EditDiscountFormValues>
          id={formName}
          name={formName}
          form={form}
          layout='vertical'
          initialValues={{
            [EditDiscountFormField.DiscountPerBooking]: undefined,
          }}
          onFinish={handleUpdate}
          requiredMark={'optional'}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          disabled={isLoading}
        >
          <Form.Item<EditDiscountFormField>
            label={t('bookings.formFields.discountPerBooking.label')}
            name={EditDiscountFormField.DiscountPerBooking}
            rules={[
              {
                required: true,
                min: 0,
                max: maxDiscount,
                type: 'number',
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder={t(
                'bookings.formFields.discountPerBooking.placeholder',
              )}
              prefix={
                <DoubleRightOutlined
                  rotate={90}
                  className='input-prefix-icon'
                />
              }
              size='large'
            />
          </Form.Item>
        </Form>
      </Space>
    </Modal>
  );
};
