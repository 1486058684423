import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  FacilitiesApiService,
  FacilitiesParams,
  FacilitiesResponse,
} from 'features/facilities';

import { facilitiesQueryKeys } from './query-keys';

const { getFacilities } = FacilitiesApiService();

const getFacilitiesQuery = (
  params: FacilitiesParams,
  options?: UseQueryOptions<FacilitiesResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<FacilitiesResponse>;
  options?: UseQueryOptions<FacilitiesResponse>;
} => ({
  queryKey: facilitiesQueryKeys.list(params),
  queryFn: ({ signal }) => getFacilities(params, { config: { signal } }),
  ...options,
});

export const useGetFacilities = (
  params: FacilitiesParams,
  options?: UseQueryOptions<FacilitiesResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<FacilitiesResponse, Error>(
    getFacilitiesQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

export const useGetFacilitiesInfiniteQuery = (
  params: FacilitiesParams,
  options?: UseInfiniteQueryOptions<FacilitiesResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: facilitiesQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getFacilities(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
