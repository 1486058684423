import { FilterOutlined } from '@ant-design/icons';
import { Button, Grid } from 'antd';
import { Modal } from 'components/modal/modal';
import { cloneElement, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledButtonsWrapper,
  StyledFiltersModalWrapper,
  StyledFiltersWrapper,
  StyledFooterWrapper,
  StyledFormItemsWrapper,
} from './content-filters.styles';

type ContentFiltersProps = {
  formId?: string;
  children: ReactElement;
};

const { useBreakpoint } = Grid;

export const ContentFilters = ({ formId, children }: ContentFiltersProps) => {
  const screens = useBreakpoint();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (formId === 'week-view-filters-form') {
      setIsModalOpen(true);
    }
  }, []);

  return (
    <>
      {screens.lg ? (
        <StyledFiltersWrapper>{children}</StyledFiltersWrapper>
      ) : (
        <StyledFiltersModalWrapper>
          <Button onClick={showModal}>
            <FilterOutlined /> {t('bookings.buttons.filter', { count: 0 })}
          </Button>

          <Modal
            open={isModalOpen}
            onCancel={closeModal}
            title={t('bookings.heading.filters')}
            getContainer={false}
            width='100%'
            footer={[
              <StyledFooterWrapper key='footer'>
                <StyledButtonsWrapper>
                  <Button
                    form={formId}
                    key='modal-filters-reset'
                    htmlType='reset'
                    size={screens.lg ? 'middle' : 'large'}
                    className='modal-footer-button'
                  >
                    {t('bookings.buttons.reset')}
                  </Button>
                  <Button
                    form={formId}
                    key='modal-filters-submit'
                    htmlType='submit'
                    size={screens.lg ? 'middle' : 'large'}
                    type='primary'
                    className='modal-footer-button'
                  >
                    {t('bookings.buttons.filter', { count: 1 })}
                  </Button>
                </StyledButtonsWrapper>
              </StyledFooterWrapper>,
            ]}
          >
            {cloneElement(children, { closeModal })}
          </Modal>
        </StyledFiltersModalWrapper>
      )}
    </>
  );
};

ContentFilters.ButtonsWrapper = StyledButtonsWrapper;
ContentFilters.FormItemsWrapper = StyledFormItemsWrapper;
