import dayjs from 'dayjs';
import {
  AddFixedBookingForm,
  AddFixedBookingFormValues,
  useRecurringBookingPrediction,
  useWizardContext,
} from 'features/bookings';
import { useWizard } from 'react-use-wizard';
import { formatDate, zeroOutSeconds } from 'utils/date';

export const AddFixedBookingStepOne = () => {
  const { setWizardState } = useWizardContext();
  const { goToStep } = useWizard();

  const {
    // isLoading,
    mutate,
  } = useRecurringBookingPrediction();

  const handleFindFixedBooking = (formValues: AddFixedBookingFormValues) => {
    const fromDate = formValues.date?.[0];
    const toDate = formValues.date?.[1];
    const fromTime = formValues.time?.[0];
    const toTime = formValues.time?.[1];

    const payload = {
      field_id: formValues.field_id,
      date_from: fromDate
        ? formatDate({
            date: dayjs(fromDate).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      date_to: toDate
        ? formatDate({
            date: dayjs(toDate).endOf('day'),
            format: 'isoDate',
          })
        : undefined,
      time_from: fromTime
        ? formatDate({
            date: zeroOutSeconds(fromTime),
            format: 'timeMilitary',
          })
        : undefined,
      time_to: toTime
        ? formatDate({
            date: zeroOutSeconds(toTime),
            format: 'timeMilitary',
          })
        : undefined,
      weekdays_list: formValues.days,
      type: formValues.booking_type,
    };

    mutate(payload, {
      onSuccess: (res) => {
        setWizardState({
          formValues,
          predictionPayload: payload,
          prediction: res,
        });

        return goToStep(2);
      },
      onError: ({ response }) => {
        if (response?.status === 409) {
          setWizardState({
            formValues,
            predictionPayload: payload,
            prediction: response?.data,
          });

          return goToStep(1);
        }
      },
    });
  };

  return (
    <AddFixedBookingForm handleFindFixedBooking={handleFindFixedBooking} />
  );
};
