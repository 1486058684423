import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const StyledContentBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  padding: 32px 16px 24px;

  ${BREAKPOINTS.tablet} {
    padding: 64px 64px 56px;
  }
`;
