import { Button, Grid, Typography } from 'antd';
import {
  ButtonWrapper,
  CommentAdditionalDetails,
  CommentListWrapper,
  CommentWrapper,
  StyledText,
} from 'features/bookings';
import { useTranslation } from 'react-i18next';
import { BookingComment } from 'types';
import { formatDate } from 'utils/date';

const { useBreakpoint } = Grid;

type BookingDetailsCommentsProps = {
  bookingCommentsData: BookingComment[];
  loadMoreComments: () => void;
  hasNextPage?: boolean;
};

export const BookingDetailsComments = ({
  bookingCommentsData,
  loadMoreComments,
  hasNextPage,
}: BookingDetailsCommentsProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <div>
      <Typography.Title level={5}>
        {t('bookings.details.bookingComments')}
      </Typography.Title>

      <CommentListWrapper>
        {bookingCommentsData.map((comment: BookingComment) => (
          <CommentWrapper key={comment.id}>
            <CommentAdditionalDetails>
              {formatDate({
                date: comment.created_at,
                format: 'date',
              })}
              <StyledText ellipsis={{ tooltip: comment.creator.fullname }}>
                {comment.creator.fullname}
              </StyledText>
            </CommentAdditionalDetails>
            <Typography>{comment.text}</Typography>
          </CommentWrapper>
        ))}
      </CommentListWrapper>

      {!screens.lg && hasNextPage ? (
        <ButtonWrapper>
          <Button type='dashed' onClick={loadMoreComments}>
            {t('bookings.buttons.loadMoreComments')}
          </Button>
        </ButtonWrapper>
      ) : null}
    </div>
  );
};
