import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { BookingApiService } from 'features/bookings';
import { Booking } from 'types';

import { bookingsQueryKeys } from './query-keys';

const { getBooking } = BookingApiService();

const getBookingQuery = (
  id: string,
  options?: UseQueryOptions<Booking>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Booking>;
  options?: UseQueryOptions<Booking>;
} => ({
  queryKey: bookingsQueryKeys.detail(id),
  queryFn: ({ signal }) => getBooking(id, { config: { signal } }),
  ...options,
});

export const useGetBooking = (
  id: string,
  options?: UseQueryOptions<Booking>,
) => {
  const { message } = App.useApp();

  return useQuery<Booking, Error>(
    getBookingQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
