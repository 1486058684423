import { Form, FormInstance } from 'antd';
import {
  AddBookingFormField,
  AddBookingFormValues,
  ClientInfo,
} from 'features/bookings/components';
import { getUserFullName } from 'features/users';
import { useTranslation } from 'react-i18next';

export const AddBookingClientInfo = ({ form }: { form: FormInstance }) => {
  const { t } = useTranslation();

  const firstName = Form.useWatch<AddBookingFormValues['first_name']>(
    AddBookingFormField.FirstName,
    form,
  );
  const lastName = Form.useWatch<AddBookingFormValues['last_name']>(
    AddBookingFormField.LastName,
    form,
  );

  const clientNameText =
    firstName || lastName
      ? getUserFullName({ first_name: firstName, last_name: lastName })
      : t('createBooking.clientName');

  return <ClientInfo>{clientNameText}</ClientInfo>;
};
