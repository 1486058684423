import Icon, {
  ApartmentOutlined,
  CalendarOutlined,
  DownOutlined,
  InboxOutlined,
  PhoneOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  DatePicker,
  Form,
  Grid,
  Input,
  Row,
  TimePicker,
} from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import { ReactComponent as FieldIcon } from 'assets/icons/icon-field.svg';
import { Select } from 'components';
import { RouteLeavingGuard } from 'components/route-leaving-guard/route-leaving-guard';
import dayjs from 'dayjs';
import {
  getDaysOptions,
  // getFacilityDisabledTimeRange,
  paymentOptions,
  typeOptions,
  useWizardContext,
} from 'features/bookings';
import { CompanySelect } from 'features/companies/components/company-select/company-select';
import { FacilitySelect } from 'features/facilities/components';
// import { FacilitySelect } from 'features/facilities/components';
// import { useGetFacility } from 'features/facilities/use-cases/get-facility';
import { useGetField } from 'features/fields';
import { FieldsSelect } from 'features/fields/components/fields-select/fields-select';
import { SportSelect } from 'features/sports';
import { sportOptionsWithinFacility } from 'features/sports/utils';
import { canViewCompany, useUserContext } from 'features/users';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';
import { BookingType } from 'types';
import { DATE_FORMATS, getDisabledMinutes } from 'utils/date';
import { phoneNumberPattern } from 'utils/validations';

import { AddFixedBookingField, AddFixedBookingFormValues } from './types';

const { useBreakpoint } = Grid;
const { RangePicker: DateRangePicker } = DatePicker;
const { RangePicker: TimeRangePicker } = TimePicker;

type AddFixedBookingFormProps = {
  handleFindFixedBooking: (formValues: AddFixedBookingFormValues) => void;
};

export const AddFixedBookingForm = ({
  handleFindFixedBooking,
}: AddFixedBookingFormProps) => {
  const { wizardState } = useWizardContext();
  const { user } = useUserContext();
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm<AddFixedBookingFormValues>();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const [isFormDirty, setIsFormDirty] = useState(false);
  const maxSize = Number.MAX_SAFE_INTEGER;

  const getInitialValues = {
    [AddFixedBookingField.Company]:
      wizardState?.formValues?.company || undefined,
    [AddFixedBookingField.Facility]:
      wizardState?.formValues?.facility_id || undefined,
    [AddFixedBookingField.Field]:
      wizardState?.formValues?.field_id || undefined,
    [AddFixedBookingField.Date]: wizardState?.formValues?.date || undefined,
    [AddFixedBookingField.Days]: wizardState?.formValues?.days || undefined,
    [AddFixedBookingField.Sport]:
      wizardState?.formValues?.sport_id || undefined,
    [AddFixedBookingField.Time]: wizardState?.formValues?.time || undefined,
    [AddFixedBookingField.Type]:
      wizardState?.formValues?.booking_type || undefined,
    [AddFixedBookingField.Comment]:
      wizardState?.formValues?.comment || undefined,
    [AddFixedBookingField.FirstName]:
      wizardState?.formValues?.first_name || undefined,
    [AddFixedBookingField.LastName]:
      wizardState?.formValues?.last_name || undefined,
    [AddFixedBookingField.PhoneNumber]:
      wizardState?.formValues?.phone_number || undefined,
    [AddFixedBookingField.PaymentMethod]:
      wizardState?.formValues?.payment_method || undefined,
  };

  const formItemLayout = screens.lg
    ? { labelCol: { span: 7 }, wrapperCol: { span: 10 } }
    : null;

  const handleSubmit = (formValues: AddFixedBookingFormValues) => {
    setIsFormDirty(false);
    handleFindFixedBooking(formValues);
  };

  const companyId = Form.useWatch(AddFixedBookingField.Company, form);
  const facilityId = Form.useWatch(AddFixedBookingField.Facility, form);
  const fieldId = Form.useWatch(AddFixedBookingField.Field, form);
  const bookingType = Form.useWatch(AddFixedBookingField.Type, form);
  const date = Form.useWatch(AddFixedBookingField.Date, form);

  /*   const { data: facilityData } = useGetFacility(facilityId, {
    enabled: !!facilityId || !!wizardState?.facility_id,
    retry: false,
  });
 */
  const { data: fieldData } = useGetField(fieldId, {
    enabled: !!fieldId || !!wizardState?.field_id,
    retry: false,
  });

  const getSportOptions = fieldData && sportOptionsWithinFacility([fieldData]);

  if (!user) return null;

  const getCompanyId = () => {
    if (user && user.company_id) return user.company_id;
    if (companyId) return companyId;

    return undefined;
  };

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today
    return current.subtract(-1, 'day') < dayjs().endOf('day');
  };

  // TODO: uncomment this after updating the utility function
  /*   const disabledRangeTime: RangePickerProps['disabledTime'] = () => ({
    disabledHours: () => getFacilityDisabledTimeRange(facilityData),
  }); */

  return (
    <>
      <Form
        {...formItemLayout}
        id='add-fixed-booking-form'
        name='add-fixed-booking-form'
        form={form}
        layout={screens.lg ? 'horizontal' : 'vertical'}
        initialValues={getInitialValues}
        onFinish={(formValues) => handleSubmit(formValues)}
        onFinishFailed={() => {
          setValidateTrigger(['onChange']);
        }}
        onFieldsChange={() => setIsFormDirty(true)}
        validateTrigger={validateTrigger}
        requiredMark='optional'
      >
        {canViewCompany(user) ? (
          <Form.Item<AddFixedBookingField>
            label={t('bookings.formFields.company.label')}
            name={AddFixedBookingField.Company}
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <CompanySelect
              className='has-prefix-icon'
              allowClear={false}
              customParams={wizardState?.formValues ? { size: maxSize } : {}}
              onChange={() => {
                form.setFieldsValue({
                  [AddFixedBookingField.Facility]: undefined,
                  [AddFixedBookingField.Field]: undefined,
                  [AddFixedBookingField.Sport]: undefined,
                  [AddFixedBookingField.Time]: undefined,
                });
              }}
              size='large'
              suffixIcon={
                <>
                  <ApartmentOutlined className='prefix-icon' />
                  <DownOutlined className='suffix-icon' />
                </>
              }
            />
          </Form.Item>
        ) : null}
        <Form.Item<AddFixedBookingField>
          label={t('bookings.formFields.facility.label')}
          name={AddFixedBookingField.Facility}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <FacilitySelect
            className='has-prefix-icon'
            allowClear={false}
            allowPullAll={false}
            disabled={!getCompanyId()}
            companyId={getCompanyId()}
            customParams={
              wizardState?.formValues
                ? {
                    is_active: true,
                    size: maxSize,
                  }
                : { is_active: true }
            }
            size='large'
            placeholder={t('bookings.formFields.facility.placeholder')}
            suffixIcon={
              <>
                <ApartmentOutlined className='prefix-icon' />
                <DownOutlined className='suffix-icon' />
              </>
            }
            onChange={() => {
              form.setFieldsValue({
                [AddFixedBookingField.Field]: undefined,
                [AddFixedBookingField.Sport]: undefined,
                [AddFixedBookingField.Time]: undefined,
              });
            }}
          />
        </Form.Item>
        <Form.Item<AddFixedBookingField>
          label={t('bookings.formFields.field.label')}
          name={AddFixedBookingField.Field}
          rules={[
            {
              required: true,
              whitespace: true,
            },
          ]}
        >
          <FieldsSelect
            className='has-prefix-icon'
            disabled={!facilityId}
            customParams={
              wizardState?.formValues
                ? {
                    is_active: true,
                    facility_id: facilityId,
                    size: maxSize,
                  }
                : { is_active: true, facility_id: facilityId }
            }
            size='large'
            placeholder={t('bookings.formFields.field.placeholder')}
            suffixIcon={
              <>
                <Icon component={FieldIcon} className='prefix-icon' />
                <DownOutlined className='suffix-icon' />
              </>
            }
          />
        </Form.Item>
        <Form.Item<AddFixedBookingField>
          label={t('bookings.formFields.date.label')}
          name={AddFixedBookingField.Date}
          wrapperCol={{ lg: 10, xs: 24 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DateRangePicker
            allowEmpty={[true, true]}
            format={DATE_FORMATS.date}
            disabledDate={disabledDate}
            presets={[
              {
                label: t('form.formFields.date.presets.today'),
                value: [dayjs(), dayjs()],
              },
              {
                label: t('form.formFields.date.presets.fromTomorrow'),
                value: [dayjs().add(1, 'day'), null],
              },
            ]}
            size='large'
            style={{ width: '100%' }}
            onChange={() => {
              form.setFieldsValue({
                [AddFixedBookingField.Days]: undefined,
              });
            }}
            placement='bottomLeft'
          />
        </Form.Item>
        <Form.Item<AddFixedBookingField>
          label={t('bookings.formFields.days.label')}
          name={AddFixedBookingField.Days}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            className='has-prefix-icon'
            placeholder={t('bookings.formFields.days.placeholder')}
            options={getDaysOptions(date)}
            mode='multiple'
            allowClear
            size='large'
            suffixIcon={
              <>
                <CalendarOutlined className='prefix-icon' />
                <DownOutlined className='suffix-icon' />
              </>
            }
          />
        </Form.Item>
        <Form.Item<AddFixedBookingField>
          label={
            <>
              {t('bookings.formFields.startTime.label')}
              <span style={{ color: colors.gray7, marginLeft: '4px' }}>
                {fieldData
                  ? t('bookings.formFields.startTime.additionalInfo', {
                      timeSlot: fieldData.time_slot,
                    })
                  : ''}
              </span>
            </>
          }
          name={AddFixedBookingField.Time}
          wrapperCol={{ lg: 10, xs: 24 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimeRangePicker
            format='h mm A'
            size='large'
            style={{ width: '100%' }}
            showSecond={false}
            inputReadOnly={true}
            disabledTime={getDisabledMinutes}
            hideDisabledOptions={true}
            disabled={!fieldData}
          />
        </Form.Item>
        <Form.Item<AddFixedBookingField>
          label={t('bookings.formFields.bookingType.label')}
          name={AddFixedBookingField.Type}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            className='has-prefix-icon'
            placeholder={t('bookings.formFields.bookingType.placeholder')}
            options={typeOptions}
            allowClear
            size='large'
            suffixIcon={
              <>
                <InboxOutlined className='prefix-icon' />
                <DownOutlined className='suffix-icon' />
              </>
            }
          />
        </Form.Item>

        {/* ++is fixed */}

        {bookingType === BookingType.Fixed ? (
          <>
            <Form.Item<AddFixedBookingField>
              label={t('bookings.formFields.sport.label')}
              name={AddFixedBookingField.Sport}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <SportSelect
                className='has-prefix-icon'
                size='large'
                customParams={{ is_active: true }}
                suffixIcon={
                  <>
                    <CalendarOutlined className='prefix-icon' />
                    <DownOutlined className='suffix-icon' />
                  </>
                }
                options={getSportOptions}
              />
            </Form.Item>

            <Form.Item<AddFixedBookingField>
              label={t('bookings.formFields.phoneNumber.label')}
              name={AddFixedBookingField.PhoneNumber}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: phoneNumberPattern,
                  message: t('validateMessages.custom.phoneNumber'),
                },
              ]}
            >
              <Input
                placeholder={t('bookings.formFields.phoneNumber.placeholder')}
                size='large'
                prefix={<PhoneOutlined className='input-prefix-icon' />}
                maxLength={17}
                onKeyPress={(e) => {
                  // Check if the entered key is a number, "+", or Backspace (to allow deletion)
                  const isNumberOrPlus = /^[0-9+]*$/.test(e.key);
                  const isBackspace = e.key === 'Backspace';

                  // Check if the total length after the new character insertion will be <= 15
                  const inputValue = (e.target as HTMLInputElement).value || '';
                  const totalLength =
                    inputValue.length + (isBackspace ? -1 : 1);

                  if (!isNumberOrPlus || totalLength > 17) {
                    e.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item<AddFixedBookingField>
              label={t('bookings.formFields.firstName.label')}
              name={AddFixedBookingField.FirstName}
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder={t('bookings.formFields.firstName.placeholder')}
                size='large'
                prefix={<UserOutlined className='input-prefix-icon' />}
              />
            </Form.Item>

            <Form.Item<AddFixedBookingField>
              label={t('bookings.formFields.lastName.label')}
              name={AddFixedBookingField.LastName}
              rules={[
                {
                  required: true,
                  whitespace: true,
                },
              ]}
            >
              <Input
                placeholder={t('bookings.formFields.lastName.placeholder')}
                size='large'
                prefix={<UserOutlined className='input-prefix-icon' />}
              />
            </Form.Item>

            <Form.Item<AddFixedBookingField>
              label={t('bookings.formFields.paymentMethod.label')}
              name={AddFixedBookingField.PaymentMethod}
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                className='has-prefix-icon'
                placeholder={t('bookings.formFields.paymentMethod.placeholder')}
                options={paymentOptions}
                allowClear
                size='large'
                suffixIcon={
                  <>
                    <WalletOutlined className='prefix-icon' />
                    <DownOutlined className='suffix-icon' />
                  </>
                }
              />
            </Form.Item>
          </>
        ) : null}

        {/* --is fixed */}

        <Form.Item<AddFixedBookingField>
          label={t('bookings.formFields.comment.label')}
          name={AddFixedBookingField.Comment}
        >
          <Input.TextArea
            showCount
            rows={2}
            placeholder={`${t('bookings.formFields.comment.placeholder')}`}
            maxLength={1000}
            size='large'
          />
        </Form.Item>

        <Col span={screens.lg ? 10 : 'auto'} offset={screens.lg ? 7 : 0}>
          <Row justify='end'>
            <Button
              type='primary'
              style={{ marginTop: '24px' }}
              htmlType='submit'
            >
              {t('bookings.buttons.findFixedBookings')}
            </Button>
          </Row>
        </Col>
      </Form>

      <RouteLeavingGuard blocked={isFormDirty} />
    </>
  );
};
