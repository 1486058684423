import {
  DoubleRightOutlined,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Form, FormProps, Input, InputNumber } from 'antd';
import { Select } from 'components';
import { FieldWithBookings } from 'features/fields';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { phoneNumberPattern } from 'utils/validations';

import { AddBookingFormField, AddBookingFormValues } from './types';

type AddBookingFormProps = {
  sports: FieldWithBookings['sports'];
  filteredSportId?: string;
  form: FormProps<AddBookingFormValues>['form'];
  totalCost: number;
  disabled: boolean;
  onSubmit: (values: AddBookingFormValues) => void;
};

export const AddBookingForm = ({
  sports,
  filteredSportId,
  form,
  totalCost,
  disabled,
  onSubmit,
}: AddBookingFormProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const { t } = useTranslation();

  const getSportInitialValue = () => {
    if (
      filteredSportId &&
      sports.find((sport) => sport.id === filteredSportId)
    ) {
      return filteredSportId;
    }

    if (sports.length === 1) {
      return sports[0].id;
    }
  };

  const initialValues = {
    [AddBookingFormField.PhoneNumber]: '',
    [AddBookingFormField.FirstName]: '',
    [AddBookingFormField.LastName]: '',
    [AddBookingFormField.Sport]: getSportInitialValue(),
    [AddBookingFormField.Discount]: 0,
    [AddBookingFormField.Comment]: '',
  };

  const sportOptions = sports.map((sport) => ({
    label: sport.name,
    value: sport.id,
  }));

  return (
    <Form<AddBookingFormValues>
      id='add-booking-form'
      name='add-booking-form'
      form={form}
      layout='vertical'
      initialValues={initialValues}
      onFinish={onSubmit}
      onFinishFailed={() => {
        setValidateTrigger(['onChange']);
      }}
      validateTrigger={validateTrigger}
      requiredMark='optional'
      disabled={disabled}
    >
      <Form.Item<AddBookingFormField>
        label={t('createBooking.formFields.phoneNumber.label')}
        name={AddBookingFormField.PhoneNumber}
        rules={[
          {
            required: true,
          },
          {
            pattern: phoneNumberPattern,
            message: t('validateMessages.custom.phoneNumber'),
          },
        ]}
      >
        <Input
          type='tel'
          placeholder={t('createBooking.formFields.phoneNumber.placeholder')}
          prefix={<PhoneOutlined className='input-prefix-icon' />}
          size='large'
          maxLength={17}
          onKeyPress={(e) => {
            // Check if the entered key is a number, "+", or Backspace (to allow deletion)
            const isNumberOrPlus = /^[0-9+]*$/.test(e.key);
            const isBackspace = e.key === 'Backspace';

            // Check if the total length after the new character insertion will be <= 15
            const inputValue = (e.target as HTMLInputElement).value || '';
            const totalLength = inputValue.length + (isBackspace ? -1 : 1);

            if (!isNumberOrPlus || totalLength > 17) {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>

      <Form.Item<AddBookingFormField>
        label={t('createBooking.formFields.firstName.label')}
        name={AddBookingFormField.FirstName}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('createBooking.formFields.firstName.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
          size='large'
        />
      </Form.Item>

      <Form.Item<AddBookingFormField>
        label={t('createBooking.formFields.lastName.label')}
        name={AddBookingFormField.LastName}
        rules={[
          {
            required: true,
            whitespace: true,
          },
        ]}
      >
        <Input
          placeholder={t('createBooking.formFields.lastName.placeholder')}
          prefix={<UserOutlined className='input-prefix-icon' />}
          size='large'
        />
      </Form.Item>

      <Form.Item<AddBookingFormField>
        label={t('createBooking.formFields.sport.label')}
        name={AddBookingFormField.Sport}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder={t('createBooking.formFields.sport.placeholder')}
          options={sportOptions}
          size='large'
        />
      </Form.Item>

      <Form.Item<AddBookingFormField>
        label={t('createBooking.formFields.discount.label')}
        name={AddBookingFormField.Discount}
      >
        <InputNumber
          style={{ width: '100%' }}
          placeholder={t('createBooking.formFields.discount.placeholder')}
          prefix={
            <DoubleRightOutlined rotate={90} className='input-prefix-icon' />
          }
          min={0}
          max={totalCost}
          size='large'
        />
      </Form.Item>

      <Form.Item<AddBookingFormField>
        label={t('createBooking.formFields.comment.label')}
        name={AddBookingFormField.Comment}
      >
        <Input.TextArea
          showCount
          rows={2}
          placeholder={`${t('createBooking.formFields.comment.placeholder')}`}
          maxLength={1000}
          size='large'
        />
      </Form.Item>
    </Form>
  );
};
