import { Spin } from 'antd';
import { Loader, Result404 } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
//import dayjs from 'dayjs';
import {
  FixedBookingInvoice,
  useGetRecurringBookingSingleBookings,
} from 'features/bookings';
import { useGetRecurringBooking } from 'features/bookings/use-cases/use-get-recurring-booking';

import { Wrapper } from './fixed-booking-details-invoice.styles';

type FixedBookingDetailsInvoiceProps = {
  bookingId: string;
};

export const FixedBookingDetailsInvoice = ({
  bookingId,
}: FixedBookingDetailsInvoiceProps) => {
  const maxSize = Number.MAX_SAFE_INTEGER;

  const {
    data: recurringbookingData,
    isError: isErrorRecurringBooking,
    isLoading: isLoadingRecurringBooking,
    isFetching: isFetchingRecurringBooking,
  } = useGetRecurringBooking(bookingId, { enabled: !!bookingId });

  const {
    data: recurringBookingsData,
    isError: isErrorRecurringBookings,
    isLoading: isLoadingRecurringBookings,
    isFetching: isFetchingRecurringBookings,
  } = useGetRecurringBookingSingleBookings(
    {
      fixed_booking_id: bookingId,
      size: maxSize,
    },
    {
      enabled: !!bookingId,
    },
  );

  return (
    <>
      {isLoadingRecurringBooking || isLoadingRecurringBookings ? (
        <Loader />
      ) : isErrorRecurringBooking || isErrorRecurringBookings ? (
        <Result404 />
      ) : (
        <Spin
          spinning={isFetchingRecurringBooking || isFetchingRecurringBookings}
        >
          <Wrapper>
            <StyledContentBackground>
              {recurringbookingData ? (
                <FixedBookingInvoice
                  data={{
                    ...recurringbookingData,
                    start_time: convertToAMPM(recurringbookingData?.start_time),
                    end_time: convertToAMPM(recurringbookingData?.end_time),
                  }}
                  bookings={recurringBookingsData}
                />
              ) : null}
            </StyledContentBackground>
          </Wrapper>
        </Spin>
      )}
    </>
  );

  function convertToAMPM(time24: any) {
    const [hours, minutes] = time24.split(':');
    let period = 'AM';

    if (hours >= 12) {
      period = 'PM';
    }

    const hours12 = hours % 12 || 12;
    const formattedTime = `${hours12}:${minutes} ${period}`;

    return formattedTime;
  }
};
