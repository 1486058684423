import {
  StyledMinimalSelect,
  StyledSelect,
} from './main-filters-select.styles';

export const getSelectStyle = (theme: 'standard' | 'minimal') => {
  switch (theme) {
    case 'minimal':
      return StyledMinimalSelect;
    case 'standard':
      return StyledSelect;
    default:
      return StyledSelect;
  }
};
