import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CreateFacilityDTO,
  FacilitiesApiService,
} from 'features/facilities/api/facilities-api.service';
import i18n from 'locales/i18n';

import { facilitiesQueryKeys } from './query-keys';

const { createFacility } = FacilitiesApiService();

export const useCreateFacility = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateFacilityDTO) => {
      return createFacility(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: facilitiesQueryKeys.all,
        });
        message.success(i18n.t('facilities.update.successCreate'));
      },
    },
  );
};
