import {
  apiClient,
  ApiClientConfig,
  PaginatedRequestParams,
  PaginatedResponse,
} from 'api';
import { Gender, User } from 'types';

export interface UserListParams extends PaginatedRequestParams {
  full_user_name?: string;
  phone_number?: string;
  roles?: string[];
  company_id?: string;
  created_at_from?: string; // ISO8601_FORMAT
  created_at_to?: string; // ISO8601_FORMAT
}

export type CreateUserDTO = {
  company_id?: string;
  email?: string | null;
  first_name: string;
  gender?: Gender | null;
  last_name: string;
  password: string;
  phone_number: string;
  role: string;
};

export type UpdateUserDTO = {
  company_id?: string;
  email?: string | null;
  first_name?: string;
  gender?: Gender | null;
  id: string;
  last_name?: string;
  password?: string;
  phone_number?: string;
  is_active?: boolean;
  role?: string;
};

export type UserResponse = PaginatedResponse<User>;

export const UserApiService = () => {
  const createUser = async (
    payload: CreateUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/admin/users`,
      method: 'POST',
      data: payload,
    });

    return response.data;
  };

  const getUser = async (id: string, callConfig?: Partial<ApiClientConfig>) => {
    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/admin/users/${id}`,
    });

    return response.data;
  };

  const getUsers = async (
    params: PaginatedRequestParams,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const response = await apiClient<UserResponse>({
      ...callConfig,
      endpoint: `/admin/users`,
      params,
    });

    return response.data;
  };

  const updateUser = async (
    payload: UpdateUserDTO,
    callConfig?: Partial<ApiClientConfig>,
  ) => {
    const { id, ...data } = payload;

    const response = await apiClient<User>({
      ...callConfig,
      endpoint: `/admin/users/${id}`,
      method: 'PATCH',
      data,
    });

    return response.data;
  };

  return {
    createUser,
    getUser,
    getUsers,
    updateUser,
  };
};
