import { BookingDetailsModal } from 'features/bookings/components';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const BookingDetailsView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.BOOKINGS);
  };

  return (
    <BookingDetailsModal
      bookingId={id}
      onCancel={closeModal}
      onSuccess={closeModal}
    />
  );
};
