import { uniqBy } from 'lodash';
import { Field } from 'types';

export const sportOptionsWithinFacility = (fields?: Field[]) => {
  if (!fields) return [];

  const sportOptions = fields
    .map((field) => field.sports)
    .flat()
    .map((sport) => ({ label: sport.name, value: sport.id }));

  const uniqSports = uniqBy(sportOptions, (sport) => sport.value);

  return uniqSports;
};
