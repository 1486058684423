import { Form, Grid, InputNumber, Modal } from 'antd';
import dayjs from 'dayjs';
import { getUserFullName, useUserContext } from 'features/users';
import {
  StyledAdditionalInfoWrapper,
  StyledLabel,
  StyledTopUpModalWrapper,
} from 'features/wallet';
import { useDeductWallet } from 'features/wallet/use-cases/deduct-wallet';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/currency';
import { formatDate } from 'utils/date';

import { DeductFormField, DeductFormValues } from './types';

const { useBreakpoint } = Grid;

type DeductModalProps = {
  walletId: string;
  currentBalance: number | undefined;
  onSuccess: () => void;
  onCancel: () => void;
};

export const DeductModal = ({
  walletId,
  currentBalance,
  onSuccess,
  onCancel,
}: DeductModalProps) => {
  const [validateTrigger, setValidateTrigger] = useState(['onSubmit']);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const { user } = useUserContext();

  const { isLoading, mutate } = useDeductWallet();

  const initialValues = {
    [DeductFormField.Amount]: undefined,
  };

  const handleSubmit = (formValues: DeductFormValues) => {
    const payload = {
      wallet_id: walletId,
      currency: 'KWD',
      ...formValues,
    };

    mutate(payload, {
      onSuccess,
    });
  };

  return (
    <Modal
      open
      title={t('wallet.modal.deduct')}
      onCancel={onCancel}
      getContainer={false}
      width={screens.lg ? '480px' : '100%'}
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{
        form: 'wallet-deduct',
        htmlType: 'submit',
        loading: isLoading,
      }}
      okText={<>{t('wallet.buttons.deductWallet')}</>}
    >
      <StyledTopUpModalWrapper>
        <Form<DeductFormValues>
          id='wallet-deduct'
          name='wallet-deduct'
          form={form}
          layout='vertical'
          initialValues={initialValues}
          onFinish={handleSubmit}
          onFinishFailed={() => {
            setValidateTrigger(['onChange']);
          }}
          validateTrigger={validateTrigger}
          requiredMark='optional'
          disabled={isLoading}
        >
          <Form.Item<DeductFormField>
            label={t('wallet.formFields.amount.label')}
            name={DeductFormField.Amount}
            extra={t('wallet.formFields.amount.extra', {
              currency: 'KD',
              value: currentBalance || 0,
            })}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber
              style={{ width: '100%' }}
              placeholder={t('wallet.formFields.amount.placeholder')}
              size='large'
              prefix='KD'
              max={currentBalance || 0}
            />
          </Form.Item>
        </Form>

        <StyledAdditionalInfoWrapper>
          <div>
            <StyledLabel>{t('wallet.modal.label.currentBalance')}</StyledLabel>
            {formatPrice({
              value: currentBalance || 0,
              currency: 'KWD',
            })}
          </div>

          <div>
            <StyledLabel>{t('wallet.modal.label.comment')}</StyledLabel>
            {t('wallet.modal.value.updatedByAdmin')}
          </div>

          <div>
            <StyledLabel>{t('wallet.modal.label.transactionDate')}</StyledLabel>
            {formatDate({
              date: dayjs(),
              format: 'dateTime',
            })}
          </div>

          <div>
            <StyledLabel>{t('wallet.modal.label.actionBy')}</StyledLabel>
            {user ? getUserFullName(user) : '-'}
          </div>
        </StyledAdditionalInfoWrapper>
      </StyledTopUpModalWrapper>
    </Modal>
  );
};
