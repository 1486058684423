import i18n from 'locales/i18n';

type FormatPriceArguments = {
  value: number;
  currency: string;
};

export const formatPrice = ({ value, currency }: FormatPriceArguments) => {
  const locale = i18n.language;
  const parts = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: 0,
  }).formatToParts(value);

  return parts
    .map(({ value: partValue }) => (partValue === 'KWD' ? 'KD' : partValue))
    .join('');
};
