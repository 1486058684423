import { useAllCompanies } from 'features/companies/hooks';
import { companiesFilterOptionsMap } from 'features/companies/utils';
import i18n from 'locales/i18n';
import { useMemo } from 'react';

export const useCompanyOptions = () => {
  const allCompanies = useAllCompanies();

  return useMemo(
    () =>
      companiesFilterOptionsMap(allCompanies, [
        {
          value: null,
          label: i18n.t('adminUsers.li3ib'),
        },
      ]),
    [allCompanies],
  );
};
