export enum TimePriceModalFields {
  AllDays = 'all_days',
  AllDaysRange = 'all_days_range',
  AllDaysSlot = 'all_days_slot_price',
  ExceptionDays = 'exception_days',
  ExceptionDaysList = 'exception_days_list',
  ExceptionDaysRange = 'exception_days_range',
  ExceptionDaysSlot = 'exception_days_slot_price',
  ExceptionWeekDays = 'exception_week_days',
}
