import { getUserPermissions, useUserContext } from 'features/users';

export const usePermissions = () => {
  const { user } = useUserContext();

  if (!user) return null;

  const permissions = getUserPermissions(user);

  return permissions;
};
