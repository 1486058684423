import { ContentFilters, ContentTopBar } from 'components';
import { SportsFilterForm, SportsTable } from 'features/sports/components';
import {
  SportsFilterFormPaginated,
  SportsFilterFormValues,
} from 'features/sports/components/sports-filter-form/types';
import { useGetSports } from 'features/sports/use-cases';
import { usePaginationParams } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const initialFilterValues: SportsFilterFormValues = {
  name: undefined,
};

export const Sports = () => {
  const { t } = useTranslation();
  const { params, updateParams, resetPage } =
    usePaginationParams<SportsFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by: 'name',
      ...initialFilterValues,
    });

  const { data, isFetching } = useGetSports(params, { retry: false });

  const handleFiltersChange = (values: SportsFilterFormValues) => {
    updateParams(values);
    resetPage();
  };

  const formId = 'sports-filters-form';

  return (
    <>
      <ContentTopBar title={t('sports.heading')} />

      <ContentFilters formId={formId}>
        <SportsFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>

      <SportsTable
        data={data}
        isLoading={isFetching}
        tableParams={params}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
