import { TablePaginationConfig } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import { MainFiltersParams } from 'components/main-filters/main-filters';
import dayjs from 'dayjs';
import {
  RecurringBookingFilterFormValues,
  RecurringBookingsTopBar,
} from 'features/bookings/components';
import { RecurringBookingsTable } from 'features/bookings/components/bookings/recurring-bookings-table';
import { useGetRecurringBookings } from 'features/bookings/use-cases';
import { usePermissions } from 'features/users';
import { useCallback, useState } from 'react';
import { formatDate } from 'utils/date';

interface TableParams {
  pagination?: TablePaginationConfig;
  field?: string;
  order?: string;
  filters?: Record<string, FilterValue>;
}

const initialFilterValues: RecurringBookingFilterFormValues = {
  full_user_name: undefined,
  phone_number: undefined,
  date: [dayjs(), dayjs()],
  field: undefined,
  bookings: undefined,
};

export const RecurringBookingsView = () => {
  const [queryParams, setQueryParams] = useState<MainFiltersParams>();
  const [queryFilters, setQueryFilters] =
    useState<RecurringBookingFilterFormValues>(initialFilterValues);
  const permissions = usePermissions();
  const fromDate = queryFilters.date?.[0];
  const toDate = queryFilters.date?.[1];

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const { data, isFetching } = useGetRecurringBookings(
    {
      date_from: fromDate
        ? formatDate({
            date: dayjs(fromDate).startOf('day'),
            format: 'isoDate',
          })
        : undefined,
      date_to: toDate
        ? formatDate({
            date: dayjs(toDate).endOf('day'),
            format: 'isoDate',
          })
        : undefined,
      full_user_name: queryFilters.full_user_name,
      phone_number: queryFilters.phone_number,
      company_type: permissions?.companyType.select
        ? queryParams?.company_type
        : undefined,
      facility_id: permissions?.facility.select
        ? queryParams?.facility_id
        : undefined,
      company_id: permissions?.company.select
        ? queryParams?.company_id
        : undefined,
      field_id: queryFilters.field,
      size: tableParams.pagination?.pageSize,
      page: tableParams.pagination?.current,
      order_by: tableParams.order
        ? tableParams.order === 'ascend'
          ? tableParams.field
          : `-${tableParams.field}`
        : undefined,
      fixed_booking_type: queryFilters?.bookings,
    },
    {
      retry: false,
    },
  );

  const resetTableCurrentPage = useCallback(() => {
    setTableParams((prevTableParams) => ({
      ...prevTableParams,
      pagination: {
        ...prevTableParams.pagination,
        current: 1,
      },
    }));
  }, []);

  return (
    <>
      <RecurringBookingsTopBar
        setQueryParams={setQueryParams}
        setQueryFilters={setQueryFilters}
        initialFilterValues={initialFilterValues}
        resetTableCurrentPage={resetTableCurrentPage}
      />
      <RecurringBookingsTable
        isBookingListLoading={isFetching}
        data={data}
        tableParams={tableParams}
        setTableParams={setTableParams}
      />
    </>
  );
};
