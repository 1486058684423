import dayjs from 'dayjs';
import i18n from 'locales/i18n';
import { range } from 'lodash';
import { TimeZoneIdentifier } from 'types';

export type DateFormat = keyof typeof DATE_FORMATS;

const INVALID_DATE = 'Invalid date';

/**
 * @example `12:48 PM`
 */
const TIME_FORMAT = 'h:mm A';

/**
 * @example `12:48:00`
 */
const TIME_FORMAT_MILITARY = 'HH:mm:ss';

const DATE_FORMAT = 'DD-MM-YYYY';

/**
 * @example `Friday, 24-03-2023`
 */
const DATE_WITH_DAY_NAME = `dddd, ${DATE_FORMAT}` as const;

const ISO_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Default format of dayjs().format()
 * @example `2023-03-24T13:45:59+01:00`
 */
const ISO8601_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

export const DATE_FORMATS = {
  time: TIME_FORMAT,
  timeMilitary: TIME_FORMAT_MILITARY,
  date: DATE_FORMAT,
  dateWithDayName: DATE_WITH_DAY_NAME,
  dateTime: `${DATE_FORMAT} ${TIME_FORMAT}`,
  isoDate: ISO_DATE_FORMAT,
  dbDate: ISO8601_FORMAT,
} as const;

// TODO: replace with value from API responses
export const DEFAULT_TIMEZONE: TimeZoneIdentifier = 'Asia/Kuwait';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isDateValid = (date: any) => {
  const isValid = dayjs(date).isValid();

  if (!isValid) {
    console.error(`Invalid date returned while trying to parse: ${date}`);
  }

  return isValid;
};

const convertToDate = (date: Date | string) =>
  typeof date === 'string' ? new Date(date) : date;

/**
 * @example locale 'en' => ['12 AM', '1 AM', ..., '11 PM']
 * @example locale 'de' => ['00 Uhr', '01 Uhr', ..., '23 Uhr']
 */
export const getLocalizedFullHours = (hoursRange?: number[]) => {
  const locale = i18n.language;
  const hourFormatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
  });
  const hours = hoursRange || Array.from(Array(24).keys());
  const fullHoursDates = hours.map((hour) => new Date(2023, 0, 1, hour));
  const localizedHours = fullHoursDates.map((date) =>
    hourFormatter.format(date),
  );

  return localizedHours;
};

interface FormatDateArguments {
  date: Date | dayjs.Dayjs | string;
  format: DateFormat;
  timeZone?: TimeZoneIdentifier;
}

/**
 * Currently date and time formats are enforced according to business requirements, regardless of locale.
 * This function is intended to be extended (for example, with `Intl` API) when requirements for new locales are known.
 * Hopefully the API-agnostic `format` argument can prevent call signature changes much later,
 * as it can be mapped to specific `Intl.DateTimeFormatOptions` as well.
 */
export const formatDate = ({ date, format, timeZone }: FormatDateArguments) => {
  if (!isDateValid(date)) return INVALID_DATE;

  return timeZone
    ? dayjs(date).tz(timeZone).format(DATE_FORMATS[format])
    : dayjs(date).format(DATE_FORMATS[format]);
};

type FormatDateRangeArguments = {
  startDate: Date | string;
  endDate: Date | string;
  timeZone?: TimeZoneIdentifier;
};

/**
 * @example '8:00 AM - 12:30 PM'
 * @example '10:00 - 11:00 AM'
 * @todo handle multi-day cases '10-01-1906, 11:24 AM – 20-01-1906, 11:24 AM' when such use case appears
 */
export const formatDateRange = ({
  startDate,
  endDate,
  timeZone = DEFAULT_TIMEZONE,
}: FormatDateRangeArguments) => {
  if (!isDateValid(startDate) || !isDateValid(endDate)) {
    return INVALID_DATE;
  }

  const start = convertToDate(startDate);
  const end = convertToDate(endDate);
  const locale = i18n.language;

  return new Intl.DateTimeFormat(locale, {
    timeStyle: 'short',
    timeZone,
  }).formatRange(start, end);
};

export const getDuration = (
  startDate: Date | dayjs.Dayjs | string,
  endDate: Date | dayjs.Dayjs | string,
) => {
  return dayjs(endDate).diff(startDate, 'minute');
};
export const getDuration1 = (startDate: Date | dayjs.Dayjs | string) => {
  return dayjs(startDate);
};

export const zeroOutSeconds = (date: string) => {
  const dayjsDate = dayjs(date).second(0);

  return dayjsDate.toDate();
};

export const getDisabledMinutes = () => {
  const range1 = range(1, 30);
  const range2 = range(31, 59);

  const ranges = [...range1, ...range2];

  return {
    disabledMinutes: () => ranges,
  };
};
