import { Spin } from 'antd';
import { Loader, Modal, Result404 } from 'components';
import { getUserFullName } from 'features/users';
import { StyledAdditionalInfoWrapper, StyledLabel } from 'features/wallet';
import { useGetWalletTransactionDetails } from 'features/wallet/use-cases';
import {
  formatWalletTransactionAmount,
  mapWalletTransactionComment,
} from 'features/wallet/utils';
import { useTranslation } from 'react-i18next';
import { formatPrice } from 'utils/currency';
import { formatDate } from 'utils/date';

type WalletTransactionDetailsProps = {
  onCancel: () => void;
  walletId: string;
  transactionId: string;
};

export const WalletTransactionDetails = ({
  onCancel,
  walletId,
  transactionId,
}: WalletTransactionDetailsProps) => {
  const { t } = useTranslation();

  const transactionDetailsResult = useGetWalletTransactionDetails(
    walletId,
    transactionId,
    {
      cacheTime: 0, //workaround to invalidate initialValues of client data form
    },
  );

  const {
    isLoading: isLoadingTransactionDetails,
    isError: isErrorTransactionDetails,
    isFetching: isFetchingTransactionDetails,
    data: transactionDetailsData,
  } = transactionDetailsResult;

  return (
    <Modal
      open
      title={t('wallet.transactionDetails.title')}
      onCancel={onCancel}
      getContainer={false}
      footer={false}
    >
      {isLoadingTransactionDetails ? (
        <Loader />
      ) : isErrorTransactionDetails ? (
        <Result404 />
      ) : (
        <Spin spinning={isFetchingTransactionDetails}>
          <StyledAdditionalInfoWrapper>
            <div>
              <StyledLabel>
                {t('wallet.transactionDetails.label.amount')}
              </StyledLabel>
              {formatWalletTransactionAmount({
                value: transactionDetailsData.amount || 0,
                currency: 'KWD',
                transactionType: transactionDetailsData.type,
              })}
            </div>

            <div>
              <StyledLabel>
                {t('wallet.transactionDetails.label.balance')}
              </StyledLabel>
              {formatPrice({
                value: transactionDetailsData.balance_after,
                currency: 'KWD',
              })}
            </div>

            <div>
              <StyledLabel>
                {t('wallet.transactionDetails.label.transactionDate')}
              </StyledLabel>
              {formatDate({
                date: transactionDetailsData.created_at,
                format: 'dateTime',
              })}
            </div>

            <div>
              <StyledLabel>
                {t('wallet.transactionDetails.label.comment')}
              </StyledLabel>
              {mapWalletTransactionComment({
                source: transactionDetailsData.source,
                transactionType: transactionDetailsData.type,
              })}
            </div>

            <div>
              <StyledLabel>
                {t('wallet.transactionDetails.label.actionBy')}
              </StyledLabel>
              {getUserFullName(transactionDetailsData.created_by)}
            </div>

            {transactionDetailsData?.transaction[0].booking ? (
              <>
                <div>
                  <StyledLabel>
                    {t('wallet.transactionDetails.label.fieldName')}
                  </StyledLabel>
                  {transactionDetailsData?.transaction[0].booking.field.name}
                </div>
                <div>
                  <StyledLabel>
                    {t('wallet.transactionDetails.label.scheduleTime')}
                  </StyledLabel>
                  {formatDate({
                    date: transactionDetailsData?.transaction[0]?.booking
                      ?.start_date,
                    format: 'dateTime',
                  })}
                </div>
                <div>
                  <StyledLabel>
                    {t('wallet.transactionDetails.label.price')}
                  </StyledLabel>
                  {formatPrice({
                    value: transactionDetailsData?.amount,
                    currency: 'KWD',
                  })}
                </div>
              </>
            ) : null}

            {transactionDetailsData?.transaction[0].ottu_transaction ? (
              <>
                {transactionDetailsData?.transaction[0]?.ottu_transaction
                  ?.tranid ? (
                  <div>
                    <StyledLabel>
                      {t('wallet.transactionDetails.label.transactionId')}
                    </StyledLabel>
                    {
                      transactionDetailsData.transaction[0].ottu_transaction
                        .tranid
                    }
                  </div>
                ) : null}
                {transactionDetailsData?.transaction[0]?.ottu_transaction
                  ?.ref ? (
                  <div>
                    <StyledLabel>
                      {t('wallet.transactionDetails.label.referenceNumber')}
                    </StyledLabel>
                    {transactionDetailsData.transaction[0].ottu_transaction.ref}
                  </div>
                ) : null}
                {transactionDetailsData?.transaction[0]?.ottu_transaction
                  ?.auth ? (
                  <div>
                    <StyledLabel>
                      {t('wallet.transactionDetails.label.authId')}
                    </StyledLabel>
                    {
                      transactionDetailsData.transaction[0].ottu_transaction
                        .auth
                    }
                  </div>
                ) : null}
              </>
            ) : null}
          </StyledAdditionalInfoWrapper>
        </Spin>
      )}
    </Modal>
  );
};
