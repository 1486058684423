import { css } from 'styled-components';

/**
 * Hide scrollbar of a scrollable container
 * @see https://blog.logrocket.com/hide-scrollbar-without-impacting-scrolling-css/
 */
export const hideScrollbar = css`
  /* Firefox */
  scrollbar-width: none;
  /* IE/Edge */
  -ms-overflow-style: none;
  /* WebKit (Chrome, Safari, Opera etc.) */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;
