import { WalletTransactionHistory } from 'features/wallet/components';
import { useParams } from 'react-router-dom';

export const WalletTransactionHistoryView = () => {
  const { id } = useParams();

  if (!id) return null;

  return <WalletTransactionHistory walletTransactionId={id} />;
};
