// import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { Grid, Switch, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import {
  Column,
  DynamicImportIcon,
  FallbackIcon,
  RecordCountBadge,
  Table,
} from 'components';
// import { StyledStatus } from 'components/status/status.styles';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { AmenitiesResponse, useUpdateAmenity } from 'features/amenities';
import { AmenitiesFilterFormPaginated } from 'features/amenities/components/amenities-filter-form/types';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Amenity } from 'types/amenity';

const { useBreakpoint } = Grid;

type AmenitiesTableProps = {
  isLoading: boolean;
  data?: AmenitiesResponse;
  tableParams: AmenitiesFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<AmenitiesFilterFormPaginated>>;
};

export const AmenitiesTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: AmenitiesTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const permissions = usePermissions();

  const { mutate: mutateUpdateAmenities, isLoading: isLoadingUpdateAmenities } =
    useUpdateAmenity();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Amenity> | SorterResult<Amenity>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('amenities.foundAmenities')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Amenity>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        scroll={{ x: 1440 }}
      >
        <Column<Amenity>
          dataIndex={'icon_name'}
          width={screens.xxl ? 'auto' : 60}
          render={(_, record) => (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DynamicImportIcon
                filePath={`amenities/${record.icon_name}.svg`}
                label={record.name}
                fallback={<FallbackIcon label={record.name} />}
              />
            </div>
          )}
        />
        <Column<Amenity>
          title={t('amenities.table.name')}
          dataIndex={'name'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']} // workaround to remove null as an option
          defaultSortOrder={'ascend'}
          width={screens.xxl ? 'auto' : 360}
        />
        <Column<Amenity>
          title={t('amenities.table.nameArabic')}
          dataIndex={'name_arabic'}
          width={screens.xxl ? 'auto' : 300}
          render={(_, record) => record.name_arabic}
        />
        {/* <Column<Amenity>
          title={t('amenities.table.chargeable')}
          dataIndex={'chargeable'}
          width={screens.xxl ? 'auto' : 120}
          align={'center'}
          render={(_, record) =>
            record.chargeable ? (
              <StyledStatus className={'center'}>
                <CheckCircleFilled className='status-icon success' />
              </StyledStatus>
            ) : (
              <StyledStatus className={'center'}>
                <CloseCircleFilled className='status-icon error' />
              </StyledStatus>
            )
          }
        /> */}
        <Column<Amenity>
          title={t('amenities.table.enableDisable')}
          width={screens.xxl ? 'auto' : 136}
          dataIndex={'is_active'}
          align={'center'}
          render={(_, record) => (
            <Switch
              disabled={!permissions?.amenities.edit}
              defaultChecked={record.is_active}
              checked={record.is_active}
              loading={isLoadingUpdateAmenities}
              onChange={(isChecked) =>
                mutateUpdateAmenities({
                  id: record.id,
                  is_active: isChecked,
                })
              }
              onClick={(checked, event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
