import { EditOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Button, Column, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
// import { usePermissions } from 'features/users';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';

type BadgeTableProps = {
  isLoading: boolean;
  data?: any;
};
export const CompanyTermViewTable = ({ isLoading, data }: BadgeTableProps) => {
  // const permissions = usePermissions();
  const navigate = useNavigate();
  // if (!permissions) return null;
  // const columns = [
  //     {
  //         title: 'Content in English',
  //         dataIndex: 'content_english',
  //         key: 'title',
  //         render: (text: any) => {
  //             const stripHtmlTags = (html: any) => {
  //                 if (!html) return '';

  //                 return html.replace(/<[^>]*>?/gm, '');
  //             };

  //             const strippedText = stripHtmlTags(text);

  //             return (
  //                 <span>
  //                     {strippedText.length > 20
  //                         ? strippedText.slice(0, 20) + '...'
  //                         : strippedText}
  //                 </span>
  //             );
  //         },
  //     },
  //     {
  //         title: 'Content in Arabic',
  //         dataIndex: 'content_arabic',
  //         key: 'title',
  //         render: (text: any) => {
  //             const stripHtmlTags = (html: any) => {
  //                 if (!html) return '';

  //                 return html.replace(/<[^>]*>?/gm, '');
  //             };

  //             const strippedText = stripHtmlTags(text);

  //             return (
  //                 <span>
  //                     {strippedText.length > 20
  //                         ? strippedText.slice(0, 20) + '...'
  //                         : strippedText}
  //                 </span>
  //             );
  //         },
  //     },
  //     {
  //         title: 'Action',
  //         dataIndex: 'Action',
  //         key: 'description',
  //         render: (record: any) => (
  //             <Button
  //                 type='primary'
  //                 onClick={() => navigate(getRoute(ROUTES.BADGE_EDIT, record.id))}
  //             >
  //                 <EditOutlined />
  //             </Button>
  //         ),
  //     },
  // ];

  return (
    <StyledTableWrapper>
      <StyledTableHeader></StyledTableHeader>
      <Table
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        className='companyTNC'
      >
        <Column title={'Type'} dataIndex={'typename'} />
        <Column
          title={'Content in English'}
          dataIndex={'content_english'}
          render={(_, record: any) => (
            <div dangerouslySetInnerHTML={{ __html: record.content_english }} />
          )}
        />
        <Column
          title={'Content in Arabic'}
          dataIndex={'content_arabic'}
          render={(_, record: any) => (
            <div dangerouslySetInnerHTML={{ __html: record.content_arabic }} />
          )}
        />
        <Column
          title={t('package.table.action')}
          render={(_, record: any) => (
            <Space>
              <Button
                type='primary'
                onClick={() =>
                  navigate(getRoute(ROUTES.COMPANY_TC_EDIT, record.id))
                }
              >
                <EditOutlined />
              </Button>
            </Space>
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
