import { useGetCompanies } from '..';

export const useAllCompanies = () => {
  const companiesQueryResult = useGetCompanies(
    {
      is_active: true,
      size: 999999,
      order_by: 'name',
    },
    {
      enabled: true,
      retry: false,
    },
  );

  return companiesQueryResult.data?.items || [];
};
