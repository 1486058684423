import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import { createContext, ReactNode, useContext } from 'react';

import { StyledStatus } from './status.styles';

export enum StatusVariant {
  success = 'success',
  error = 'error',
  default = 'default',
}

type StatusContextValue = {
  variant: StatusVariant;
};

interface StatusProps {
  children: ReactNode;
  variant: StatusVariant;
}

const StatusContext = createContext<StatusContextValue | null>(null);

export const Status = ({ children, variant }: StatusProps) => {
  const value = { variant };

  return (
    <StatusContext.Provider value={value}>
      <StyledStatus>{children}</StyledStatus>
    </StatusContext.Provider>
  );
};

interface StatusMap {
  [key: string]: ReactNode;
}

export const StatusIcon = () => {
  const value = useContext(StatusContext);

  const statusMap: StatusMap = {
    [StatusVariant.success]: (
      <CheckCircleOutlined className='status-icon success' />
    ),
    [StatusVariant.error]: (
      <CloseCircleOutlined className='status-icon error' />
    ),
    [StatusVariant.default]: (
      <MinusCircleOutlined className='status-icon default' />
    ),
  };

  return <>{value && statusMap[value.variant]}</>;
};

const StatusBadgeWithText = ({ text }: { text: string }) => {
  const value = useContext(StatusContext);

  const statusMap: StatusMap = {
    [StatusVariant.success]: <Badge status='success' text={text} />,
    [StatusVariant.error]: <Badge status='error' text={text} />,
    [StatusVariant.default]: <Badge status='default' text={text} />,
  };

  return <>{value && statusMap[value.variant]}</>;
};

Status.Icon = StatusIcon;
Status.BadgeWithText = StatusBadgeWithText;
