import { Col, Grid, Image } from 'antd';
import { ReactComponent as LogoSvg } from 'assets/li3ib-logo.svg';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';

import { ImageWrapper } from './company-logo.styles';

const { useBreakpoint } = Grid;

type CompanyLogoProps = {
  hasCompany: boolean;
  companyImageUrl?: string;
};

export const CompanyLogo = ({
  hasCompany,
  companyImageUrl,
}: CompanyLogoProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();

  return (
    <>
      <Col span={screens.lg ? 10 : 'auto'} offset={screens.lg ? 7 : 0}>
        <ImageWrapper className='prflbx'>
          {!hasCompany ? (
            <LogoSvg
              title={t('li3ibLogo')}
              color={colors.greenDark}
              width={104}
              height={32}
            />
          ) : (
            <Image
              src={companyImageUrl}
              placeholder={true}
              preview={false}
              style={{ objectFit: 'cover' }}
            />
          )}
        </ImageWrapper>
      </Col>
    </>
  );
};
