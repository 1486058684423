import { PaginatedRequestParams } from 'api';
import { Dayjs } from 'dayjs';
import { Gender as GenderType } from 'types';

export enum UserFilterFormField {
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
  Role = 'role',
  Company = 'company_id',
  Email = 'email',
}

export type UserFilterFormValues = {
  [UserFilterFormField.FullUserName]: string | undefined;
  [UserFilterFormField.PhoneNumber]: string | undefined;
  [UserFilterFormField.Email]: string | undefined;
  [UserFilterFormField.Role]: string | undefined;
  [UserFilterFormField.Company]: string | undefined;
};

export type UserFilterFormValuesPaginated = UserFilterFormValues &
  PaginatedRequestParams;

export enum EndUserFilterFormField {
  Date = 'date',
  FullUserName = 'full_user_name',
  PhoneNumber = 'phone_number',
  Email = 'email',
}

export type EndUserFilterFormValues = {
  [EndUserFilterFormField.Date]: [Dayjs | undefined, Dayjs | undefined] | null;
  [EndUserFilterFormField.FullUserName]: string | undefined;
  [EndUserFilterFormField.PhoneNumber]: string | undefined;
  [EndUserFilterFormField.Email]: string | undefined;
};

export type EndUserFilterFormValuesPaginated = EndUserFilterFormValues &
  PaginatedRequestParams;

export enum UserFormField {
  Date = 'date',
  Company = 'company_id',
  Role = 'role',
  FirstName = 'first_name',
  LastName = 'last_name',
  Gender = 'gender',
  Email = 'email',
  PhoneNumber = 'phone_number',
  Password = 'password',
  ConfirmPassword = 'confirm_password',
}
export enum UserFormFieldData {
  user = 'User',
  Li3ibAdmin = 'Li3ibAdmin',
}

export type UserFormValues = {
  [UserFormField.Date]: string;
  [UserFormField.Company]: string;
  [UserFormField.Role]: string;
  [UserFormField.FirstName]: string;
  [UserFormField.LastName]: string;
  [UserFormField.Gender]: GenderType | null;
  [UserFormField.Email]: string;
  [UserFormField.PhoneNumber]: string;
  [UserFormField.Password]: string;
  [UserFormField.ConfirmPassword]: string;
};

export enum EndUserFormField {
  FirstName = 'first_name',
  LastName = 'last_name',
  Gender = 'gender',
  Email = 'email',
  PhoneNumber = 'phone_number',
}

export type EndUserFormValues = {
  [UserFormField.FirstName]: string;
  [UserFormField.LastName]: string;
  [UserFormField.Gender]: GenderType | null;
  [UserFormField.Email]: string;
  [UserFormField.PhoneNumber]: string;
};
