import { Select as AntSelect } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const StyledSelect = styled(AntSelect)`
  &.filter {
    ${BREAKPOINTS.laptop} {
      max-width: 200px;
    }
  }

  &.has-prefix-icon {
    &.ant-select-single {
      & .ant-select-selector {
        padding: 0 11px 0 40px;
      }
    }

    &.ant-select-multiple {
      & .ant-select-selector {
        padding: 0 11px 0 34px;
      }

      & .ant-select-selection-placeholder {
        padding: 0 11px 0 30px;
      }
    }

    & .ant-select-arrow {
      width: 100%;
      left: 0;

      .prefix-icon {
        position: absolute;
        left: 14px;
        font-size: 14px;
        color: ${({ theme }) => theme.antd.colorIcon};
      }

      .suffix-icon {
        position: absolute;
        right: 11px;
      }
    }

    &.ant-select-open {
      &.ant-select-single {
        & .ant-select-selector {
          padding: 0 11px;
        }
      }

      &.ant-select-multiple {
        & .ant-select-selector {
          padding: 0 0 0 2px;
        }

        & .ant-select-selection-placeholder {
          padding: 0;
        }
      }

      .prefix-icon {
        display: none;
      }
    }
  }
` as typeof AntSelect;
