import {
  ClockCircleOutlined,
  DoubleRightOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { BookingDetailsModal } from 'features/bookings/components';
import { paymentMethodIconMap } from 'features/bookings/utils';
import { getUserFullName } from 'features/users';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookingType, Currency, PaymentMethod, Source, User } from 'types';
import { formatPrice } from 'utils/currency';
import { getDuration } from 'utils/date';
import { getSourceColor } from 'utils/source';
import { paymentMethodTranslationsMap } from 'utils/transaction';

import {
  BookingSlot,
  DurationRow,
  DurationWrapper,
  IconsWrapper,
  SlotContent,
  SlotFooter,
  StyledSlotWrapper,
} from './time-slot.styles';

export type BookingSlotData = {
  id: string;
  type: BookingType.Standard | BookingType.Fixed;
  price: number;
  currency: Currency;
  discount: number;
  startDate: string;
  endDate: string;
  firstName: string;
  lastName: string;
  source: Source;
  paymentMethod: PaymentMethod;
};

export const BookingTimeSlot = ({
  bookingData,
  userRole,
}: {
  bookingData: BookingSlotData;
  userRole: User['role'];
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { startDate, endDate } = bookingData;
  const duration = getDuration(startDate, endDate);

  const isCompact = duration <= 45;
  const belongsToFixedBooking = bookingData.type === BookingType.Fixed;
  const hasDiscount = bookingData.discount > 0;
  const slotColor = getSourceColor(bookingData.source, userRole);

  const formattedPrice = formatPrice({
    value: bookingData.price,
    currency: bookingData.currency,
  });
  const discountCopy = t('tooltips.discount', {
    value: formatPrice({
      value: bookingData.discount,
      currency: bookingData.currency,
    }),
  });
  const priceCopy = t('tooltips.price', {
    value: formattedPrice,
  });
  const paymentMethodCopy = t('tooltips.paymentMethod', {
    value: paymentMethodTranslationsMap[bookingData.paymentMethod],
  });
  const fixedBookingCopy = t('tooltips.fixedBooking');

  const renderPrice = (showExtendedTooltip?: boolean) => {
    if (showExtendedTooltip) {
      return (
        <Tooltip
          title={
            <>
              <div>{priceCopy}</div>
              {hasDiscount ? <div>{discountCopy}</div> : null}
              <div>{paymentMethodCopy}</div>
              {belongsToFixedBooking ? <div>{fixedBookingCopy}</div> : null}
            </>
          }
        >
          <Typography.Text strong ellipsis>
            {formattedPrice}
          </Typography.Text>
        </Tooltip>
      );
    }

    return (
      <Typography.Text
        strong
        ellipsis={{
          tooltip: true,
        }}
      >
        {formattedPrice}
      </Typography.Text>
    );
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <StyledSlotWrapper duration={duration}>
        <BookingSlot
          className='bokd_slot'
          $color={slotColor}
          onClick={toggleModal}
          isSelectable
        >
          <Typography.Text strong ellipsis={{ tooltip: true }}>
            {getUserFullName({
              first_name: bookingData.firstName,
              last_name: bookingData.lastName,
            })}
          </Typography.Text>

          <SlotContent>
            <DurationRow>
              <DurationWrapper>
                <Typography.Text type='secondary'>
                  <ClockCircleOutlined />{' '}
                  {t('common.minutes.short', { value: duration })}
                </Typography.Text>
              </DurationWrapper>

              {isCompact ? renderPrice(true) : null}
            </DurationRow>
            {!isCompact ? (
              <SlotFooter>
                {renderPrice()}

                <IconsWrapper>
                  {belongsToFixedBooking ? (
                    <Tooltip title={fixedBookingCopy}>
                      <RetweetOutlined />
                    </Tooltip>
                  ) : null}

                  <Tooltip title={paymentMethodCopy}>
                    {paymentMethodIconMap[bookingData.paymentMethod]}
                  </Tooltip>

                  {hasDiscount ? (
                    <Tooltip title={discountCopy}>
                      <DoubleRightOutlined rotate={90} />
                    </Tooltip>
                  ) : null}
                </IconsWrapper>
              </SlotFooter>
            ) : null}
          </SlotContent>
        </BookingSlot>
      </StyledSlotWrapper>

      {isModalOpen ? (
        <BookingDetailsModal
          onSuccess={toggleModal}
          onCancel={toggleModal}
          bookingId={bookingData.id}
        />
      ) : null}
    </>
  );
};

export const MemoizedBookingTimeSlot = React.memo(BookingTimeSlot);
