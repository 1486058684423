import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BookingType } from 'types';
import { getDuration } from 'utils/date';

import { ClosedSlot, StyledSlotWrapper } from './time-slot.styles';

export type ClosedSlotData = {
  id: string;
  type: BookingType.Closed;
  startDate: string;
  endDate: string;
};

export const ClosedTimeSlot = ({ data }: { data: ClosedSlotData }) => {
  const { t } = useTranslation();

  const { startDate, endDate } = data;
  const duration = getDuration(startDate, endDate);

  return (
    <StyledSlotWrapper duration={duration}>
      <ClosedSlot>
        <Typography.Text type='danger' strong>
          {t('bookingTypes.closed')}
        </Typography.Text>
      </ClosedSlot>
    </StyledSlotWrapper>
  );
};

export const MemoizedClosedTimeSlot = React.memo(ClosedTimeSlot);
