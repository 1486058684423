import { Grid, Switch, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Column, RecordCountBadge, Table } from 'components';
import {
  StyledTableHeader,
  StyledTableWrapper,
} from 'components/table/table.styled';
import { CompaniesFilterFormPaginated } from 'features/companies';
import { CompaniesResponse } from 'features/companies/api/companies-api.service';
import { useUpdateCompany } from 'features/companies/use-cases/update-companies';
import { usePermissions } from 'features/users';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { getRoute } from 'routes/utils';
import { Company } from 'types';
import { companyTypeTranslationsMap } from 'utils/company-type';

const { useBreakpoint } = Grid;

type CompaniesTableProps = {
  isLoading: boolean;
  data?: CompaniesResponse;
  tableParams: CompaniesFilterFormPaginated;
  updateParams: Dispatch<SetStateAction<CompaniesFilterFormPaginated>>;
};

export const CompaniesTable = ({
  data,
  isLoading,
  tableParams,
  updateParams,
}: CompaniesTableProps) => {
  const { t } = useTranslation();
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const permissions = usePermissions();

  const { mutate: mutateUpdateCompany, isLoading: isLoadingUpdateCompany } =
    useUpdateCompany();

  if (!permissions) return null;

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<Company> | SorterResult<Company>[],
  ) => {
    updateParams({
      ...tableParams,
      page: pagination.current,
      size: pagination.pageSize,
      order_by: Array.isArray(sorter)
        ? undefined
        : sorter.order === 'ascend'
        ? 'name'
        : '-name',
    });
  };

  const tableWidth = screens.xxl ? { x: 1440 } : { x: 1200 };

  return (
    <StyledTableWrapper>
      <StyledTableHeader>
        <RecordCountBadge
          text={t('companies.foundCompanies')}
          count={data?.total || 0}
        />
      </StyledTableHeader>
      <Table<Company>
        loading={isLoading}
        dataSource={data?.items}
        rowKey='id'
        onChange={handleTableChange}
        pagination={{
          current: tableParams.page,
          defaultPageSize: tableParams.size,
          total: data?.total,
        }}
        scroll={tableWidth}
        onRow={(record) => {
          return {
            onClick: () => {
              const selection = window?.getSelection()?.toString();

              if (!selection?.length) {
                navigate(getRoute(ROUTES.COMPANIES_EDIT, record.id));
              }
            },
          };
        }}
      >
        <Column<Company>
          title={t('companies.table.clientType')}
          dataIndex={'type'}
          width={screens.xxl ? 'auto' : 170}
          render={(_, record) => <>{companyTypeTranslationsMap(record.type)}</>}
          ellipsis
        />
        <Column<Company>
          title={t('companies.table.companyName')}
          dataIndex={'name'}
          sorter={true}
          sortDirections={['ascend', 'descend', 'ascend']} // workaround to remove null as an option
          defaultSortOrder={'ascend'}
          width={screens.xxl ? 'auto' : 300}
          ellipsis
        />
        <Column<Company>
          title={t('companies.table.companyNameArabic')}
          dataIndex={'name_arabic'}
          width={screens.xxl ? 'auto' : 240}
          className='cell-direction-rtl'
          ellipsis
        />
        <Column<Company>
          title={t('companies.table.email')}
          dataIndex={'email'}
          width={screens.xxl ? 'auto' : 200}
          ellipsis
        />
        <Column<Company>
          title={t('companies.table.phone')}
          dataIndex={'phone_number'}
          width={screens.xxl ? 'auto' : 200}
        />
        <Column<Company>
          title={t('companies.table.enableDisable')}
          width={screens.xxl ? 'auto' : 162}
          dataIndex={'is_active'}
          render={(_, record) => (
            <Switch
              defaultChecked={record.is_active}
              checked={record.is_active}
              loading={isLoadingUpdateCompany}
              onChange={(isChecked) =>
                mutateUpdateCompany({
                  id: record.id,
                  is_active: isChecked,
                })
              }
              onClick={(checked, event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            />
          )}
        />
      </Table>
    </StyledTableWrapper>
  );
};
