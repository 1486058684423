import { useCallback, useEffect, useState } from 'react';
import { BREAKPOINTS_RESOLUTIONS } from 'styles/constants/breakpoints';

export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => {
    return {
      width: isClient && window.innerWidth,
      height: isClient && window.innerHeight,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return () => false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]);

  return {
    windowSize,
    isMobile: windowSize.width <= BREAKPOINTS_RESOLUTIONS.mobile,
    isTablet: windowSize.width >= BREAKPOINTS_RESOLUTIONS.tablet,
    isLaptop: windowSize.width >= BREAKPOINTS_RESOLUTIONS.laptop,
  };
};
