import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { UseMutateFunction } from '@tanstack/react-query';
import { Modal, Typography } from 'antd';
import { UpdateBookingDTO } from 'features/bookings/api/bookings-api.service';
import { useUserContext } from 'features/users';
import { canEditBooking } from 'features/users/permissions';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from 'styles';
import { Booking, PaymentMethod, PaymentStatus } from 'types';

import {
  StyledButton,
  StyledButtonsWrapper,
  StyledConfirmationItemWrapper,
  StyledWrapper,
} from './booking-details-confirmation-form.styles';

enum Presence {
  Present = 'present',
  Absent = 'absent',
}

type BookingDetailsConfirmationFormProps = {
  bookingData: Booking;
  onSuccess: () => void;
  mutateUpdateBooking: UseMutateFunction<
    Booking,
    unknown,
    UpdateBookingDTO,
    unknown
  >;
  isLoading: boolean;
};

export const BookingDetailsConfirmationForm = ({
  bookingData,
  onSuccess,
  mutateUpdateBooking,
  isLoading,
}: BookingDetailsConfirmationFormProps) => {
  const [modal, contextHolder] = Modal.useModal();
  const { t } = useTranslation();
  const { user } = useUserContext();
  const [propertyBeingUpdated, setPropertyBeingUpdated] = useState<
    PaymentStatus | Presence | undefined
  >();
  const transactionStatus = bookingData?.transaction?.payment_status || '';
  const presenceStatus = bookingData.client_showed_up;

  if (!user) return null;

  const canEdit = canEditBooking(user, bookingData);
  const discount = bookingData.transaction.discount;

  const handlePayment = (paymentStatus: PaymentStatus) => {
    if (!bookingData) return;

    const paymentMethod = bookingData.transaction.payment_method;
    const payload: UpdateBookingDTO = {
      id: bookingData.id,
      payment_status: paymentStatus,
      discount: discount,
    };
    const clientShowedUp = bookingData.client_showed_up;

    if (
      paymentMethod === PaymentMethod.Cash &&
      paymentStatus === PaymentStatus.Paid &&
      clientShowedUp === null
    ) {
      payload.client_showed_up = true;
    }

    setPropertyBeingUpdated(paymentStatus);

    mutateUpdateBooking(payload, {
      onSettled: () => setPropertyBeingUpdated(undefined),
      onSuccess,
    });
  };

  const handlePresence = (presence: Presence) => {
    if (!bookingData) return;

    const clientShowedUp =
      presence === Presence.Present
        ? true
        : presence === Presence.Absent
        ? false
        : undefined;

    const payload: UpdateBookingDTO = {
      id: bookingData.id,
      client_showed_up: clientShowedUp,
      discount: discount,
    };

    setPropertyBeingUpdated(presence);

    mutateUpdateBooking(payload, {
      onSettled: () => setPropertyBeingUpdated(undefined),
      onSuccess,
    });
  };

  return (
    <StyledWrapper>
      <StyledConfirmationItemWrapper>
        <Typography.Text disabled={!canEdit.payment}>
          {t('bookings.details.paymentConfirmation')}
        </Typography.Text>
        <StyledButtonsWrapper>
          <StyledButton
            disabled={!canEdit.payment || isLoading}
            loading={propertyBeingUpdated === PaymentStatus.Unpaid}
            onClick={() =>
              modal.confirm({
                centered: true,
                title: t('confirmModal.confirmTitle'),
                content: (
                  <>
                    {t('confirmModal.booking.unpaid.text')}
                    <br />
                    <Typography.Text strong>
                      {t('confirmModal.irreversable')}
                    </Typography.Text>
                  </>
                ),
                okText: t('confirmModal.booking.unpaid.button'),
                cancelText: t('bookings.buttons.back'),
                okButtonProps: {
                  danger: true,
                },
                onOk: () => handlePayment(PaymentStatus.Unpaid),
              })
            }
            className='confirmation-button confirmation-negative'
            $isActive={transactionStatus === PaymentStatus.Unpaid}
            icon={<CloseCircleOutlined className='confirmation-icon' />}
          />
          <StyledButton
            disabled={!canEdit.payment || isLoading}
            loading={propertyBeingUpdated === PaymentStatus.Paid}
            onClick={() =>
              modal.confirm({
                centered: true,
                title: t('confirmModal.confirmTitle'),
                content: (
                  <>
                    {t('confirmModal.booking.paid.text')}
                    <br />
                    <Typography.Text strong>
                      {t('confirmModal.irreversable')}
                    </Typography.Text>
                  </>
                ),
                okText: t('confirmModal.booking.paid.button'),
                cancelText: t('bookings.buttons.back'),
                okButtonProps: {
                  // tried using token but it doesn't work
                  style: { backgroundColor: colors.colorPrimary },
                },
                onOk: () => handlePayment(PaymentStatus.Paid),
              })
            }
            className='confirmation-button confirmation-positive'
            $isActive={transactionStatus === PaymentStatus.Paid}
            icon={<CheckCircleOutlined className='confirmation-icon' />}
          />
        </StyledButtonsWrapper>
      </StyledConfirmationItemWrapper>
      <StyledConfirmationItemWrapper>
        <Typography.Text disabled={!canEdit.presence}>
          {t('bookings.details.clientShowsUp')}
        </Typography.Text>
        <StyledButtonsWrapper>
          <StyledButton
            disabled={!canEdit.presence || isLoading}
            loading={propertyBeingUpdated === Presence.Absent}
            onClick={() =>
              modal.confirm({
                centered: true,
                title: t('confirmModal.confirmTitle'),
                content: (
                  <>
                    {t('confirmModal.booking.absence.text')}
                    <br />
                    <Typography.Text strong>
                      {t('confirmModal.irreversable')}
                    </Typography.Text>
                  </>
                ),
                okText: t('confirmModal.booking.absence.button'),
                cancelText: t('bookings.buttons.back'),
                okButtonProps: {
                  danger: true,
                },
                onOk: () => handlePresence(Presence.Absent),
              })
            }
            className='confirmation-button confirmation-negative'
            $isActive={presenceStatus === false}
            icon={<CloseCircleOutlined className='confirmation-icon' />}
          />
          <StyledButton
            disabled={!canEdit.presence || isLoading}
            loading={propertyBeingUpdated === Presence.Present}
            onClick={() =>
              modal.confirm({
                centered: true,
                title: t('confirmModal.confirmTitle'),
                content: (
                  <>
                    {t('confirmModal.booking.presence.text')}
                    <br />
                    <Typography.Text strong>
                      {t('confirmModal.irreversable')}
                    </Typography.Text>
                  </>
                ),
                okText: t('confirmModal.booking.presence.button'),
                cancelText: t('bookings.buttons.back'),
                okButtonProps: {
                  // tried using token but it doesn't work
                  style: { backgroundColor: colors.colorPrimary },
                },
                onOk: () => handlePresence(Presence.Present),
              })
            }
            className='confirmation-button confirmation-positive'
            $isActive={presenceStatus === true}
            icon={<CheckCircleOutlined className='confirmation-icon' />}
          />
          {contextHolder}
        </StyledButtonsWrapper>
      </StyledConfirmationItemWrapper>
    </StyledWrapper>
  );
};
