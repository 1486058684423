import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  AddBookingCommentDTO,
  BookingApiService,
  fixedBookingCommentsQueryKeys,
  recurringBookingsQueryKeys,
} from 'features/bookings';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

const { addFixedBookingComment } = BookingApiService();

export const useAddFixedBookingComment = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: AddBookingCommentDTO) => {
      return addFixedBookingComment(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (_, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: fixedBookingCommentsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: fixedBookingCommentsQueryKeys.details(),
          }),
          queryClient.invalidateQueries({
            queryKey: recurringBookingsQueryKeys.details(),
          }),
          queryClient.invalidateQueries({
            queryKey: recurringBookingsQueryKeys.detail(variables.id),
          }),
        ]);
        message.success(i18n.t('bookings.details.successfulCommentUpdate'));
      },
    },
  );
};
