import { Button, DatePicker, Form, Grid } from 'antd';
import { ContentFilters, Select } from 'components';
import { StandardItemsWrapper } from 'components/content-filters/content-filters.styles';
import dayjs, { Dayjs } from 'dayjs';
import { getTeamSizeOptions } from 'features/fields/utils';
import { SportSelect } from 'features/sports';
import { useTranslation } from 'react-i18next';
import { DATE_FORMATS } from 'utils/date';

type DayViewFiltersFormProps = {
  formId: string;
  handleFilter: (values: DayViewFiltersFormValues) => void;
  initialValues: Partial<DayViewFiltersFormValues>;
  closeModal?: () => void;
};

export enum DayViewFiltersFormField {
  Date = 'date',
  Sport = 'sport_id',
  TeamSize = 'players',
}

export type DayViewFiltersFormValues = {
  [DayViewFiltersFormField.Date]: Dayjs | string;
  [DayViewFiltersFormField.Sport]: string | undefined;
  [DayViewFiltersFormField.TeamSize]: number | undefined;
};

const { useBreakpoint } = Grid;

export const DayViewFiltersForm = ({
  formId,
  handleFilter,
  initialValues,
  closeModal,
}: DayViewFiltersFormProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<DayViewFiltersFormValues>();
  const screens = useBreakpoint();

  const onReset = () => {
    form.resetFields();
  };

  const handleOnFinish = (values: DayViewFiltersFormValues) => {
    handleFilter(values);
    closeModal?.();
  };

  return (
    <Form<DayViewFiltersFormValues>
      id={formId}
      name={formId}
      form={form}
      layout='vertical'
      onFinish={handleOnFinish}
      initialValues={{ ...initialValues, date: dayjs(initialValues.date) }}
    >
      <ContentFilters.FormItemsWrapper>
        <StandardItemsWrapper>
          <Form.Item<DayViewFiltersFormValues>
            label={t('dayView.formFields.date.label')}
            name={DayViewFiltersFormField.Date}
          >
            <DatePicker allowClear={false} format={DATE_FORMATS.date} />
          </Form.Item>

          <Form.Item<DayViewFiltersFormValues>
            label={t('dayView.formFields.sport.label')}
            name={DayViewFiltersFormField.Sport}
          >
            <SportSelect className='filter' />
          </Form.Item>

          <Form.Item<DayViewFiltersFormValues>
            label={t('dayView.formFields.teamSize.label')}
            name={DayViewFiltersFormField.TeamSize}
          >
            <Select
              placeholder={t('dayView.formFields.teamSize.placeholder')}
              bordered
              options={getTeamSizeOptions()}
              className='filter'
              allowClear
            />
          </Form.Item>
        </StandardItemsWrapper>
      </ContentFilters.FormItemsWrapper>

      {screens.lg && (
        <ContentFilters.ButtonsWrapper>
          <Button type='primary' htmlType='submit'>
            {t('bookings.buttons.filter', { count: 1 })}
          </Button>
          <Button onClick={onReset}>{t('bookings.buttons.reset')}</Button>
        </ContentFilters.ButtonsWrapper>
      )}
    </Form>
  );
};
