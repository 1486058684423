import { Typography } from 'antd';
import { ReactComponent as MarkerIcon } from 'assets/icons/icon-google-marker.svg';
import { ReactComponent as TriangleIcon } from 'assets/icons/icon-triangle.svg';
import { ReactComponent as LogoSvg } from 'assets/li3ib-logo.svg';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
  MarkerIconStyle,
  MarkerTriangleStyle,
  MarkerWrapper,
} from './marker.styles';

const LogoStyle = styled.div`
  transform: scale(0.7);
`;

type MarkerProps = { lat: number; lng: number };

export const Marker: React.FunctionComponent<MarkerProps> = () => {
  const { t } = useTranslation();

  return (
    <MarkerWrapper>
      <LogoStyle>
        <LogoSvg title={t('li3ibLogo')} color='#FF2A00' />
      </LogoStyle>
      <Typography.Text strong>
        {t('companyInfo.contactUs.markerTitle')}
      </Typography.Text>
      <a
        href={
          'https://www.google.com/maps/dir/?api=1&destination=29.370825,47.977860'
        }
        target='_blank'
        rel='noreferrer'
      >
        <Typography.Text type={'secondary'}>
          {t('companyInfo.contactUs.address')}
        </Typography.Text>
      </a>
      <MarkerIconStyle>
        <MarkerIcon />
      </MarkerIconStyle>
      <MarkerTriangleStyle>
        <TriangleIcon />
      </MarkerTriangleStyle>
    </MarkerWrapper>
  );
};
