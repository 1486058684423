import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  UpdateUserDTO,
  UserApiService,
} from 'features/users/api/user-api.service';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { userQueryKeys } from './query-keys';

const { updateUser } = UserApiService();

export const useUpdateUser = () => {
  const { message } = App.useApp();

  return useMutation(
    (payload: UpdateUserDTO) => {
      return updateUser(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (data, variables) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: userQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: userQueryKeys.detail(variables.id),
          }),
        ]);
        message.success(i18n.t('adminUsers.update.successfulUpdate'));
      },
    },
  );
};
