import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CompaniesApiService, CreateCompanyDTO } from 'features/companies';
import i18n from 'locales/i18n';

import { companiesQueryKeys } from './query-keys';

const { createCompany } = CompaniesApiService();

export const useCreateCompany = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: CreateCompanyDTO) => {
      return createCompany(payload);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: () => {
        // TODO: invalidate week view endpoint as well, when ready
        queryClient.invalidateQueries({
          queryKey: companiesQueryKeys.all,
        });

        message.success(i18n.t('companies.create.successfulUpdate'));
      },
    },
  );
};
