import { Button, Grid } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

const { useBreakpoint } = Grid;

const StyledConfirmationBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
  z-index: 9; // just below side menu z-index
  background-color: ${({ theme }) => theme.colors.li3ibgreen2};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 0 24px;

  ${BREAKPOINTS.mobile} {
    padding: 0 32px 0 64px;
  }
`;

type ConfirmationBarProps = {
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmationBar = ({
  onCancel,
  onConfirm,
}: ConfirmationBarProps) => {
  const screens = useBreakpoint();
  const { t } = useTranslation();

  return (
    <StyledConfirmationBar>
      <Button block={screens.xs} onClick={onCancel}>
        {t('dayView.confirmationBar.cancel')}
      </Button>
      <Button type='primary' block={screens.xs} onClick={onConfirm}>
        {t('dayView.confirmationBar.addBooking')}
      </Button>
    </StyledConfirmationBar>
  );
};
