/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs, { Dayjs } from 'dayjs';
import { TFunction } from 'i18next';

export const firstRangeValidator = (
  rule: any,
  value: any,
  openingHours: Date[],
  timeSlot: number,
  t: TFunction,
) => {
  if (!value) {
    //|| !openingHours
    return Promise.reject(t('fields.modal.errors.rangeRequired'));
  }

  const [startTime, endTime] = value;
  // const [openingTime, closingTime] = openingHours;

  if (
    startTime.isSame(endTime, 'hour') &&
    startTime.isSame(endTime, 'minute')
  ) {
    return Promise.reject(t('fields.modal.errors.noGap'));
  }

  // if (
  //   startTime.isBefore(openingTime, 'minute') ||
  //   startTime.isAfter(openingTime, 'minute')
  // ) {
  //   // return Promise.reject(t('fields.modal.errors.firstRangeStartTime'));
  // }

  // if (startTime.isAfter(closingTime, 'minute')) {
  //   return Promise.reject(t('fields.modal.errors.startTime'));
  // }

  // if (endTime.isBefore(openingTime, 'minute')) {
  //   return Promise.reject('The end time should come after the opening hours.');
  // }

  // if (endTime.isAfter(closingTime, 'minute')) {
  //   return Promise.reject(t('fields.modal.errors.firstRangeEndTime'));
  // }

  const moddedEndTime = getEndTime(endTime);
  if (
    !(moddedEndTime.diff(startTime, 'minute') % Number(timeSlot) === 0) &&
    !isEndOfDay(endTime)
  ) {
    //return Promise.reject(t('fields.modal.errors.timeSlot', { timeSlot }));
  }
};

export const rangeValidator = (
  rule: any,
  value: any,
  openingHours: Date[],
  timeSlot: number,
  firstRangeValue: Date[],
  t: TFunction,
) => {
  if (!value) {
    return Promise.reject(t('fields.modal.errors.rangeRequired'));
  }
  // alert(timeSlot);
  if (timeSlot === undefined || timeSlot === null) {
    return Promise.reject(t('fields.modal.errors.emptyTimeSlot'));
  }
  if (!firstRangeValue) {
    return Promise.reject(t('fields.modal.errors.firstRangeRequired'));
  }

  const [startTime, endTime] = value;
  const [, closingTime] = openingHours;
  // const [, firstRangeEndTime] = firstRangeValue;

  if (
    startTime.isSame(endTime, 'hour') &&
    startTime.isSame(endTime, 'minute')
  ) {
    return Promise.reject('Time should not be of the same');
  }

  // if (startTime.isBefore(firstRangeEndTime, 'minute')) {
  //   return Promise.reject(t('fields.modal.errors.secondRangeStartTime'));
  // }

  if (
    endTime.isAfter(closingTime, 'minute') ||
    endTime.isBefore(closingTime, 'minute')
  ) {
    // return Promise.reject(t('fields.modal.errors.secondRangeEndTime'));
  }

  const moddedEndTime = getEndTime(endTime);
  if (
    !(moddedEndTime.diff(startTime, 'minute') % Number(timeSlot) === 0) &&
    !isEndOfDay(endTime)
  ) {
    // return Promise.reject(t('fields.modal.errors.timeSlot', { timeSlot }));
  }
};

const isEndOfDay = (endTime: Dayjs): boolean => {
  if (dayjs(endTime).hour() === 23 && dayjs(endTime).minute() === 59) {
    return true;
  }

  return false;
};

const getEndTime = (endTime: Dayjs): Dayjs => {
  if (dayjs(endTime).hour() === 23 && dayjs(endTime).minute() === 59) {
    return dayjs(endTime).add(1, 'minute');
  }

  return endTime;
};

// export const exceptionRangeValidator = (
//   rule: any,
//   value: any,
//   timeSlot: number,
//   firstRangeValue: Date[],
//   t: TFunction,
// ) => {
//   if (!value) {
//     return Promise.reject(t('fields.modal.errors.rangeRequired'));
//   }

//   if (!firstRangeValue) {
//     return Promise.reject(t('fields.modal.errors.firstRangeRequired'));
//   }

//   const [startTime, endTime] = value;
//   const [, firstRangeEndTime] = firstRangeValue;

//   if (
//     startTime.isSame(endTime, 'hour') &&
//     startTime.isSame(endTime, 'minute')
//   ) {
//     return Promise.reject('Time should not be of the same');
//   }

//   if (startTime.isBefore(firstRangeEndTime, 'minute')) {
//     return Promise.reject(t('fields.modal.errors.secondRangeStartTime'));
//   }

//   const moddedEndTime = getEndTime(endTime);
//   if (!(moddedEndTime.diff(startTime, 'minute') % Number(timeSlot) === 0)) {
//     return Promise.reject(t('fields.modal.errors.timeSlot', { timeSlot }));
//   }
// };
export const exceptionRangeValidator = (
  rule: any,
  value: any,
  openingHours: Date[],
  timeSlot: number,
  t: TFunction,
) => {
  if (!value) {
    // || !openingHours
    return Promise.reject(t('fields.modal.errors.rangeRequired'));
  }

  const [startTime, endTime] = value;
  // const [openingTime, closingTime] = openingHours;

  if (
    startTime.isSame(endTime, 'hour') &&
    startTime.isSame(endTime, 'minute')
  ) {
    return Promise.reject(t('fields.modal.errors.noGap'));
  }

  // if (
  //   startTime.isBefore(openingTime, 'minute') ||
  //   startTime.isAfter(openingTime, 'minute')
  // ) {
  //   return Promise.reject(t('fields.modal.errors.firstRangeStartTime'));
  // }

  // if (startTime.isAfter(closingTime, 'minute')) {
  //   return Promise.reject(t('fields.modal.errors.startTime'));
  // }

  // if (endTime.isBefore(openingTime, 'minute')) {
  //   return Promise.reject('The end time should come after the opening hours.');
  // }

  // if (endTime.isAfter(closingTime, 'minute')) {
  //  return Promise.reject(t('fields.modal.errors.firstRangeEndTime'));
  // }

  const moddedEndTime = getEndTime(endTime);
  if (
    !(moddedEndTime.diff(startTime, 'minute') % Number(timeSlot) === 0) &&
    !isEndOfDay(endTime)
  ) {
    //return Promise.reject(t('fields.modal.errors.timeSlot', { timeSlot }));
  }
};
