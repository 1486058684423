import './module.css';

import { Form, FormInstance } from 'antd';
import {
  TermFormDataFields,
  TermFormFields,
} from 'features/community/components/interface/type';
import { FormSectionsSeparator } from 'features/users/components/modals/form-sections-separator';
import { useEffect } from 'react';
import ReactQuill from 'react-quill';
// ../interface/type
export const TermCancellationInfoFormItems = ({
  form,
}: {
  form: FormInstance<TermFormDataFields>;
}) => {
  useEffect(() => {
    form.getFieldValue('label');
  });
  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'align',
    'color',
    'background',
  ];

  // Define the modules configuration to include the bold button in the toolbar
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, false] }], // Select dropdown for heading
      [{ font: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['clean'],
    ],
  };

  return (
    <FormSectionsSeparator title={''}>
      <Form.Item
        label={'Content in English'}
        name={TermFormFields.content_english}
        rules={[{ required: true, message: 'Please enter description' }]}
      >
        <ReactQuill
          className='editor-large'
          theme='snow'
          modules={modules}
          formats={formats}
        />
        {/* <Input/> */}
      </Form.Item>
      <div style={{ marginTop: '15px' }} className='editorrrr arr_frm '>
        <Form.Item
          label={'Content in Arabic'}
          name={TermFormFields.content_arabic}
          rules={[{ required: true, message: 'Please enter description' }]}
        >
          <ReactQuill
            className='editor-large'
            theme='snow'
            modules={modules}
            formats={formats}
            style={{ direction: 'rtl', textAlign: 'right' }}
          />
        </Form.Item>
      </div>
    </FormSectionsSeparator>
  );
};
