import { Col, Grid, Row, Typography } from 'antd';
import { ReactNode } from 'react';

const { useBreakpoint } = Grid;

export const InvoiceGridItem = ({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) => {
  const screens = useBreakpoint();

  const labelSettings = {
    wideScreen: { span: 10 },
    desktop: { span: 10 },
    tablet: { span: 8 },
    mobile: { span: 24 },
  };

  const valueSettings = {
    wideScreen: { span: 14 },
    desktop: { span: 14 },
    tablet: { span: 16 },
    mobile: { span: 24 },
  };

  const getLayout = () => ({
    label: screens.xxl
      ? labelSettings.wideScreen
      : screens.xl
      ? labelSettings.desktop
      : screens.lg
      ? labelSettings.desktop
      : screens.md
      ? labelSettings.tablet
      : screens.sm
      ? labelSettings.tablet
      : screens.xs
      ? labelSettings.mobile
      : undefined,
    value: screens.xxl
      ? valueSettings.wideScreen
      : screens.xl
      ? valueSettings.desktop
      : screens.lg
      ? valueSettings.desktop
      : screens.md
      ? valueSettings.tablet
      : screens.sm
      ? valueSettings.tablet
      : screens.xs
      ? valueSettings.mobile
      : undefined,
  });

  return (
    <Row wrap={screens.md ? false : true} style={{ padding: '0 0 0 8px' }}>
      <Col {...getLayout().label}>
        <Typography.Text strong>{label}</Typography.Text>:
      </Col>
      <Col {...getLayout().value}>
        <Typography.Text>{value}</Typography.Text>
      </Col>
    </Row>
  );
};

export const InvoiceGridWrapper = ({ children }: { children: ReactNode }) => (
  <Row gutter={12} className='booking_dtls' style={{ rowGap: '12px' }}>
    {children}
  </Row>
);

export const InvoiceGridColumn = ({ children }: { children: ReactNode }) => {
  const screens = useBreakpoint();

  return (
    <Col
      span={screens.lg ? 12 : 24}
      style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
    >
      {children}
    </Col>
  );
};
