import { FileImageOutlined, TeamOutlined } from '@ant-design/icons';
import { Image, Tooltip, Typography } from 'antd';
import { ReactComponent as FieldIcon } from 'assets/icons/icon-field.svg';
import { DynamicImportIcon, FallbackIcon, Icon } from 'components';
import { FieldWithBookings } from 'features/fields';
import { formatMaxPlayersToTeamSize } from 'features/fields/utils';
import { useTranslation } from 'react-i18next';

import {
  FieldInfoWrapper,
  FieldPropertyWrapper,
  SportsWrapper,
  StyledImagePlaceholder,
  StyledImageWrapper,
  StyledWrapper,
} from './field-header.styles';

type FieldHeaderProps = {
  field: Omit<FieldWithBookings, 'bookings' | 'facility'>;
};

export const FieldHeader = ({ field }: FieldHeaderProps) => {
  const { t } = useTranslation();

  return (
    <StyledWrapper className='field_mbl'>
      <Typography.Text strong ellipsis={{ tooltip: true }}>
        {field.name}
      </Typography.Text>

      <StyledImageWrapper>
        {field.cover_image_url ? (
          <Image
            src={field.cover_image_url}
            placeholder={true}
            preview={false}
            style={{ objectFit: 'cover' }}
          />
        ) : (
          <StyledImagePlaceholder>
            <FileImageOutlined />
          </StyledImagePlaceholder>
        )}
      </StyledImageWrapper>

      <SportsWrapper>
        {field.sports.map((sport) => {
          return (
            <Tooltip key={sport.id} title={sport.name}>
              {/* Custom (non-Ant) components have to
                  either be wrapped in an HTML tag,
                  or accept spread of props,
                  to make the Tooltip work.
                  See https://github.com/ant-design/ant-design/issues/15909 */}
              <div style={{ display: 'inline-flex' }}>
                <DynamicImportIcon
                  filePath={`sports/${sport.icon}`}
                  label={sport.name}
                  fallback={<FallbackIcon label={sport.name} />}
                />
              </div>
            </Tooltip>
          );
        })}
      </SportsWrapper>

      <FieldInfoWrapper>
        <FieldPropertyWrapper>
          <TeamOutlined />
          <Typography.Text type='secondary' strong>
            {formatMaxPlayersToTeamSize(field.max_players)}
          </Typography.Text>
        </FieldPropertyWrapper>

        <FieldPropertyWrapper>
          <Icon component={FieldIcon} />
          <Typography.Text type='secondary' strong>
            {t('common.fieldDimensions', {
              length: field.length,
              width: field.width,
            })}
          </Typography.Text>
        </FieldPropertyWrapper>
      </FieldInfoWrapper>
    </StyledWrapper>
  );
};
