import { FieldsBookingsParams } from 'features/fields';

/**
 * @see https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
 */
export const bookingsQueryKeys = {
  all: ['bookings'] as const,
  lists: () => [...bookingsQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...bookingsQueryKeys.lists(), { filters }] as const,
  details: () => [...bookingsQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...bookingsQueryKeys.details(), id] as const,
};

export const bookingCommentsQueryKeys = {
  all: ['booking-comments'] as const,
  lists: () => [...bookingCommentsQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...bookingCommentsQueryKeys.lists(), { filters }] as const,
  details: () => [...bookingCommentsQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...bookingCommentsQueryKeys.details(), id] as const,
};

export const fixedBookingCommentsQueryKeys = {
  all: ['fixed-booking-comments'] as const,
  lists: () => [...fixedBookingCommentsQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...fixedBookingCommentsQueryKeys.lists(), { filters }] as const,
  details: () => [...fixedBookingCommentsQueryKeys.all, 'detail'] as const,
  detail: (id: string) =>
    [...fixedBookingCommentsQueryKeys.details(), id] as const,
};

export const recurringBookingsQueryKeys = {
  all: ['recurring-bookings'] as const,
  lists: () => [...recurringBookingsQueryKeys.all, 'list'] as const,
  list: (filters: object) =>
    [...recurringBookingsQueryKeys.lists(), { filters }] as const,
  details: () => [...recurringBookingsQueryKeys.all, 'detail'] as const,
  detail: (id: string) =>
    [...recurringBookingsQueryKeys.details(), id] as const,
};

export const fieldsBookingsQueryKeys = {
  all: ['fields-bookings'] as const,
  lists: () => [...fieldsBookingsQueryKeys.all, 'list'] as const,
  list: (filters: FieldsBookingsParams) =>
    [...fieldsBookingsQueryKeys.lists(), filters] as const,
  details: () => [...fieldsBookingsQueryKeys.all, 'detail'] as const,
  detail: (id: string) => [...fieldsBookingsQueryKeys.details(), id] as const,
};
