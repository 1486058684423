import { Typography } from 'antd';
import dayjs from 'dayjs';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';

import { StyledWrapper } from './field-header.styles';

type WeekHeaderProps = {
  date: Date;
};

export const WeekHeader = ({ date }: WeekHeaderProps) => {
  // const isToday = dayjs(date).isToday();
  const isToday = dayjs(date).tz(DEFAULT_TIMEZONE).isToday();

  const formattedDate = isToday
    ? `Today  ${formatDate({
        date: date,
        format: 'date',
        timeZone: DEFAULT_TIMEZONE,
      })}`
    : formatDate({
        date: date,
        format: 'dateWithDayName',
        timeZone: DEFAULT_TIMEZONE,
      });

  return (
    <StyledWrapper isHighlighted={isToday} className='week_nme'>
      <Typography.Text
        type={isToday ? 'success' : undefined}
        strong
        ellipsis={{ tooltip: true }}
      >
        {formattedDate}
      </Typography.Text>
    </StyledWrapper>
  );
};
