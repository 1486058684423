import { FormProps } from 'antd';
import i18n from 'locales/i18n';

export const validateMessages: FormProps['validateMessages'] = {
  default: i18n.t('validateMessages.default'),
  required: i18n.t('validateMessages.required'),
  enum: i18n.t('validateMessages.enum'),
  whitespace: i18n.t('validateMessages.whitespace'),
  date: {
    format: i18n.t('validateMessages.date.format'),
    parse: i18n.t('validateMessages.date.parse'),
    invalid: i18n.t('validateMessages.date.invalid'),
  },
  types: {
    string: i18n.t('validateMessages.types.common'),
    method: i18n.t('validateMessages.types.common'),
    array: i18n.t('validateMessages.types.common'),
    object: i18n.t('validateMessages.types.common'),
    number: i18n.t('validateMessages.types.common'),
    date: i18n.t('validateMessages.types.common'),
    boolean: i18n.t('validateMessages.types.common'),
    integer: i18n.t('validateMessages.types.common'),
    float: i18n.t('validateMessages.types.common'),
    regexp: i18n.t('validateMessages.types.common'),
    email: i18n.t('validateMessages.types.common'),
    url: i18n.t('validateMessages.types.common'),
    hex: i18n.t('validateMessages.types.common'),
  },
  string: {
    len: i18n.t('validateMessages.string.len'),
    min: i18n.t('validateMessages.string.min'),
    max: i18n.t('validateMessages.string.max'),
    range: i18n.t('validateMessages.string.range'),
  },
  number: {
    len: i18n.t('validateMessages.number.len'),
    min: i18n.t('validateMessages.number.min'),
    max: i18n.t('validateMessages.number.max'),
    range: i18n.t('validateMessages.number.range'),
  },
  array: {
    len: i18n.t('validateMessages.array.len'),
    min: i18n.t('validateMessages.array.min'),
    max: i18n.t('validateMessages.array.max'),
    range: i18n.t('validateMessages.array.range'),
  },
  pattern: {
    mismatch: i18n.t('validateMessages.pattern.mismatch'),
  },
};
