import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import { CompaniesApiService } from 'features/companies';
import { Company } from 'types';

import { companiesQueryKeys } from './query-keys';

const { getCompany } = CompaniesApiService();

const getCompanyQuery = (
  id: string,
  options?: UseQueryOptions<Company>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<Company>;
  options?: UseQueryOptions<Company>;
} => ({
  queryKey: companiesQueryKeys.detail(id),
  queryFn: ({ signal }) => getCompany(id, { config: { signal } }),
  ...options,
});

export const useGetCompany = (
  id: string,
  options?: UseQueryOptions<Company>,
) => {
  const { message } = App.useApp();

  return useQuery<Company, Error>(
    getCompanyQuery(id, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};
