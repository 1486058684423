import { UserOutlined } from '@ant-design/icons';
import { AvatarProps } from 'antd';

import { StyledAvatar } from './avatar.styles';

export const Avatar = ({
  size,
  src,
  style,
  className,
  children,
  ...props
}: AvatarProps) => {
  return (
    <StyledAvatar
      size={size}
      src={src}
      className={className}
      icon={
        src || children ? null : (
          <UserOutlined className='avatar-user-placeholder-icon' />
        )
      }
      style={style}
      {...props}
    >
      {children}
    </StyledAvatar>
  );
};
