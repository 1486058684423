import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  CompaniesApiService,
  CompaniesParams,
  CompaniesResponse,
} from 'features/companies';

import { companiesQueryKeys } from './query-keys';

const { getCompanies } = CompaniesApiService();

const getCompaniesQuery = (
  params: CompaniesParams,
  options?: UseQueryOptions<CompaniesResponse>,
): {
  queryKey: QueryKey;
  queryFn: QueryFunction<CompaniesResponse>;
  options?: UseQueryOptions<CompaniesResponse>;
} => ({
  queryKey: companiesQueryKeys.list(params),
  queryFn: ({ signal }) => getCompanies(params, { config: { signal } }),
  ...options,
});

export const useGetCompanies = (
  params: CompaniesParams,
  options?: UseQueryOptions<CompaniesResponse>,
) => {
  const { message } = App.useApp();

  return useQuery<CompaniesResponse, Error>(
    getCompaniesQuery(params, {
      onError: (error: unknown) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      ...options,
    }),
  );
};

export const useGetCompaniesInfiniteQuery = (
  params: CompaniesParams,
  options?: UseInfiniteQueryOptions<CompaniesResponse>,
) => {
  const { message } = App.useApp();

  return useInfiniteQuery({
    queryKey: companiesQueryKeys.list(params),
    queryFn: ({ signal, pageParam }) =>
      getCompanies(
        { ...params, page: pageParam || params.page },
        { config: { signal } },
      ),
    getNextPageParam: (lastPage) => {
      const hasMorePages = lastPage.pages > lastPage.page;

      return hasMorePages ? lastPage.page + 1 : undefined;
    },
    onError: (error: unknown) => {
      const uiMessage = handleError(error);

      message.error(uiMessage);
    },
    ...options,
  });
};
