import { UpdateFacility } from 'features/facilities/components/update-facility/update-facility';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const UpdateFacilityView = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  if (!id) return null;

  const closeModal = () => {
    navigate(ROUTES.FACILITIES);
  };

  return (
    <UpdateFacility
      facilityId={id}
      onCancel={closeModal}
      onSuccess={closeModal}
    />
  );
};
