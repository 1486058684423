import { Col, Grid, Row } from 'antd';
import { PaginatedResponse } from 'api';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import {
  BookingType,
  PaymentMethod,
  RecurringBooking,
  RecurringBookingSingleBookingListItem,
} from 'types';
import { Weekdays } from 'types/days';

import { InvoiceBookingDetails } from './invoice-booking-details';
import { InvoiceBookingList } from './invoice-booking-list';
import { InvoicePaymentInfo } from './invoice-booking-payment-info';
import { InvoiceCompanyLogo } from './invoice-company-logo';
import { InvoiceCustomerBookingInfo } from './invoice-customer-booking-info';

const { useBreakpoint } = Grid;

export type PaginatedRecurringBookings =
  PaginatedResponse<RecurringBookingSingleBookingListItem>;

interface RecurringBookingExtended extends RecurringBooking {
  weekday?: Weekdays;
}

type FixedBookingInvoiceProps = {
  data: RecurringBookingExtended;
  bookings: PaginatedRecurringBookings;
  paymentMethod?: PaymentMethod;
  isAddBooking?: boolean;
  setDiscount?: Dispatch<SetStateAction<number>>;
  footer?: ReactNode;
};

export const FixedBookingInvoice = ({
  data,
  bookings,
  paymentMethod,
  isAddBooking,
  setDiscount,
  footer,
}: FixedBookingInvoiceProps) => {
  const screens = useBreakpoint();

  const layoutSettings = {
    wideScreen: { span: 12, offset: 6 },
    desktop: { span: 20, offset: 2 },
    mobile: { span: 24 },
  };

  const getLayout = () =>
    screens.xxl
      ? layoutSettings.wideScreen
      : screens.xl
      ? layoutSettings.desktop
      : screens.sm
      ? layoutSettings.mobile
      : undefined;

  const {
    created_at,
    first_name,
    last_name,
    phone_number,
    field: {
      name: field_name,
      max_players,
      facility: {
        name: facility_name,
        company: { name: company_name, image_url },
      },
    },
    date_from,
    date_to,
    weekdays_list,
    weekday,
    start_time,
    end_time,
    bookings_count,
    type,
    source,
  } = data;

  const customerAndBookingInfoData = {
    created_at,
    first_name,
    last_name,
    phone_number,
    source,
  };

  const bookingDetailsData = {
    company_name,
    facility_name,
    field_name,
    date_from,
    date_to,
    weekdays_list,
    weekday,
    start_time,
    end_time,
    max_players,
    bookings_count,
    type,
  };

  return (
    <Row className='fixed_bk_in'>
      <Col
        {...getLayout()}
        style={{ gap: '48px', display: 'flex', flexDirection: 'column' }}
      >
        <InvoiceCompanyLogo imageUrl={image_url} />
        <InvoiceCustomerBookingInfo data={customerAndBookingInfoData} />
        <InvoiceBookingDetails data={bookingDetailsData} />
        <InvoiceBookingList bookings={bookings} />
        {data.type === BookingType.Fixed ? (
          <InvoicePaymentInfo
            bookings={bookings}
            paymentMethod={paymentMethod}
            setDiscount={setDiscount}
            isAddBooking={isAddBooking}
          />
        ) : null}

        {footer}
      </Col>
    </Row>
  );
};
