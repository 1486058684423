export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  NotSpecified = 'NOT_SPECIFIED',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Li3ibAdmin = 'Li3ibAdmin',
  Li3ibAccountant = 'Li3ibAccountant',
  ClientAdmin = 'ClientAdmin',
  Accountant = 'Accountant',
  Coordinator = 'Coordinator',
  CallCenterOperative = 'CallCenterOperative',
  User = 'User',
}

export type User = {
  created_at: string;
  email: string | null;
  first_name: string;
  gender: Gender | null;
  id: string;
  is_active: boolean;
  last_name: string;
  phone_number: string;
  role: Role;
  /** `null` indicates Li3ib user */
  company_id: string | null;
  company_name: string | null;
};
