import {
  BookingType,
  PaymentMethod as PaymentMethodType,
  SportType,
} from 'types';
import { Weekdays } from 'types/days';

export enum AddFixedBookingField {
  Company = 'company',
  Facility = 'facility_id',
  Field = 'field_id',
  Date = 'date',
  Days = 'days',
  Sport = 'sport_id',
  Time = 'time',
  Type = 'booking_type',
  Comment = 'comment',
  FirstName = 'first_name',
  LastName = 'last_name',
  PhoneNumber = 'phone_number',
  TotalCost = 'total_cost',
  PaymentMethod = 'payment_method',
}

export type AddFixedBookingFormValues = {
  [AddFixedBookingField.Company]: string;
  [AddFixedBookingField.Facility]: string;
  [AddFixedBookingField.Field]: string;
  [AddFixedBookingField.Date]: string;
  [AddFixedBookingField.Days]: Weekdays;
  [AddFixedBookingField.Sport]: SportType;
  [AddFixedBookingField.Time]: string;
  [AddFixedBookingField.Type]: BookingType;
  [AddFixedBookingField.Comment]: string;
  [AddFixedBookingField.FirstName]: string;
  [AddFixedBookingField.LastName]: string;
  [AddFixedBookingField.PhoneNumber]: string;
  [AddFixedBookingField.TotalCost]: number;
  [AddFixedBookingField.PaymentMethod]: PaymentMethodType;
};

export enum AddFixedBookingDiscountField {
  Discount = 'discount',
}

export type AddFixedBookingDiscountFormValues = {
  [AddFixedBookingDiscountField.Discount]: number;
};
