import dayjs from 'dayjs';
import { AddBookingModal } from 'features/bookings/components';
import { FieldWithBookings } from 'features/fields';
import { useCallback, useEffect, useState } from 'react';
import { User } from 'types';
import { DEFAULT_TIMEZONE, formatDate } from 'utils/date';

import { AvailableSlotData } from './available-time-slot';
import { ConfirmationBar } from './confirmation-bar';
import { FieldSchedule } from './field-schedule';
import { TimelineHours } from './utils';

type TimetableContentProps = {
  date: string;
  dates: Date[];
  user: User;
  timelineHours: TimelineHours;
  filteredSportId?: string;
  fieldWithBookings: FieldWithBookings;
};

export type SelectedSlots = Map<
  string,
  AvailableSlotData & { fieldId: string; index: number }
>;

export type SelectSlot = (
  fieldId: string,
  index: number,
  value: AvailableSlotData,
) => void;

export const BookingTimetableContent = ({
  date,
  dates,
  user,
  timelineHours,
  filteredSportId,
  fieldWithBookings,
}: TimetableContentProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSlots, setSelectedSlots] = useState<SelectedSlots>(new Map());

  const selectSlot = useCallback<SelectSlot>((bookingId, index, value) => {
    const key = `${bookingId}_${index}`;

    setSelectedSlots((prevSlots) => {
      const slots = new Map(prevSlots);

      if (slots.has(key)) {
        slots.delete(key);

        return slots;
      }

      // Clear the collection when user selects a slot from a different booking
      if (
        slots.size > 0 &&
        Array.from(slots.values())[0].fieldId !== bookingId
      ) {
        slots.clear();
      }

      return slots.set(key, { ...value, fieldId: bookingId, index });
    });
  }, []);

  useEffect(() => {
    clearSelectedSlots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const clearSelectedSlots = () => {
    setSelectedSlots(() => new Map());
  };

  const toggleAddBookingModal = () => setIsModalOpen(!isModalOpen);

  const handleCloseModal = () => {
    toggleAddBookingModal();
    clearSelectedSlots();
  };

  // const convertTZ = (dateC: string, tzString: string): any => {
  //   return new Date(
  //     (typeof dateC === 'string' ? new Date(dateC) : dateC).toLocaleString('en-US', {
  //       timeZone: tzString,
  //     }),
  //   );
  // };

  // // Function to parse DateTimeOffset string and convert to dayjs object
  // const parseDateTimeOffset = (dateTimeOffsetString: string): any => {
  //     const dateTime = convertTZ(dateTimeOffsetString, DEFAULT_TIMEZONE);

  //   return dateTime;
  // };

  const fullFields = dates.map((currentDate) => {
    const currentDayBookings = fieldWithBookings.bookings.filter((booking) => {
      return (
        formatDate({
          date: dayjs(booking.start_date),
          format: 'isoDate',
          timeZone: DEFAULT_TIMEZONE,
        }) ===
        formatDate({
          date: dayjs(currentDate),
          format: 'isoDate',
          timeZone: DEFAULT_TIMEZONE,
        })
      );
    });

    return {
      ...fieldWithBookings,
      id: formatDate({
        date: dayjs(currentDate),
        format: 'isoDate',
        timeZone: DEFAULT_TIMEZONE,
      }),
      bookings: currentDayBookings,
    };
  });

  const currentBookingId =
    selectedSlots.size > 0
      ? Array.from(selectedSlots.values())[0].fieldId
      : null;

  const currentBooking = fullFields.find(
    (field) => field.id === currentBookingId,
  );

  return (
    <>
      {fullFields.map((field, idx) => {
        const mydate = field.bookings[0]?.start_date;
        const goodDate = mydate
          ? formatDate({
              date: dayjs(mydate),
              format: 'isoDate',
              timeZone: DEFAULT_TIMEZONE,
            })
          : field.id;

        const isToday = dayjs(goodDate).isToday();

        return (
          <FieldSchedule
            key={idx}
            field={field}
            date={goodDate}
            user={user}
            timelineHours={timelineHours}
            selectSlot={selectSlot}
            selectedSlots={selectedSlots}
            highlightColumn={isToday}
          />
        );
      })}

      {selectedSlots.size ? (
        <ConfirmationBar
          onCancel={clearSelectedSlots}
          onConfirm={toggleAddBookingModal}
        />
      ) : null}

      {currentBooking && isModalOpen ? (
        <AddBookingModal
          selectedSlots={selectedSlots}
          field={fieldWithBookings}
          filteredSportId={filteredSportId}
          onCancel={handleCloseModal}
          onSuccess={handleCloseModal}
        />
      ) : null}
    </>
  );
};
