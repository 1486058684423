import { AddCompany } from 'features/companies/components';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const AddCompanyView = () => {
  const navigate = useNavigate();

  const navigateToList = () => {
    navigate(ROUTES.COMPANIES);
  };

  return <AddCompany onCancel={navigateToList} onSuccess={navigateToList} />;
};
