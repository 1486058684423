import { FixedBookingDetailsComments } from 'features/bookings';
import { useParams } from 'react-router-dom';

export const RecurringBookingsCommentsView = () => {
  const { id } = useParams();

  if (!id) return null;

  return <FixedBookingDetailsComments bookingId={id} />;
};
