import { Tag } from 'antd';
import styled from 'styled-components';
import { BREAKPOINTS } from 'styles';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  ${BREAKPOINTS.laptop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: wrap;
  gap: 16px;

  ${BREAKPOINTS.mobile} {
    flex-flow: initial;
    gap: 8px;
  }

  ${BREAKPOINTS.laptop} {
    width: initial;
  }

  .booking-details-footer-button {
    width: 100%;
    margin: 0 !important;

    ${BREAKPOINTS.laptop} {
      width: initial;
    }
  }
`;

export const StyledAdditionalInfo = styled.div`
  display: flex;
  flex-shrink: 0;
  color: ${({ theme }) => theme.antd.colorTextDescription};

  span {
    color: ${({ theme }) => theme.colors.pureBlack};
  }

  .hide-on-mobile {
    display: none;

    ${BREAKPOINTS.laptop} {
      display: inline-block;
      color: ${({ theme }) => theme.antd.colorTextDescription};
    }
  }
`;

export const StyledTag = styled(Tag)`
  height: fit-content;
`;

export const StyledAdditionalInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

export const StyledIsCancelledInfo = styled.div`
  color: ${({ theme }) => theme.colors.red5};

  span {
    color: inherit;
  }
`;
