import { Col, Row, Space, Typography } from 'antd';
import { Button } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from './privacy-policy.styles';

const EnglishVersion = () => {
  const { t } = useTranslation();

  return (
    <Space direction={'vertical'} size={'large'}>
      <article>
        <Typography.Title level={5}>
          {t('companyInfo.privacy.eng.title')}
        </Typography.Title>
        <Typography.Text>
          {t('companyInfo.privacy.eng.titleText')}
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5}>
          {t('companyInfo.privacy.eng.definitions')}
        </Typography.Title>
        <Typography.Text>
          {t('companyInfo.privacy.eng.definitionsText')}
          <ul>
            <li>{t('companyInfo.privacy.eng.definitionsDot1')}</li>
            <li>{t('companyInfo.privacy.eng.definitionsDot2')}</li>
            <li>{t('companyInfo.privacy.eng.definitionsDot3')}</li>
          </ul>
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5}>
          {t('companyInfo.privacy.eng.dataCollected')}
        </Typography.Title>
        <Typography.Text>
          {t('companyInfo.privacy.eng.dataCollectedText')}
        </Typography.Text>
        <Typography.Text>
          <ul>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot1')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot2')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot3')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot4')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot5')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot6')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot7')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot8')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot9')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot10')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot11')}</li>
            <li>{t('companyInfo.privacy.eng.dataCollectedDot12')}</li>
          </ul>
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5}>
          {t('companyInfo.privacy.eng.useOfData')}
        </Typography.Title>
        <Typography.Text>
          {t('companyInfo.privacy.eng.useOfDataText')}
        </Typography.Text>
        <Typography.Text>
          <ul>
            <li>{t('companyInfo.privacy.eng.useOfDataDot1')}</li>
            <li>{t('companyInfo.privacy.eng.useOfDataDot2')}</li>
          </ul>
        </Typography.Text>
        <Typography.Text>
          {t('companyInfo.privacy.eng.useOfDataText2')}
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5} direction={'rtl'}>
          {t('companyInfo.privacy.eng.arabic')}
        </Typography.Title>
        <Typography.Text direction={'rtl'}>
          <div>{t('companyInfo.privacy.eng.arabic2')}</div>
        </Typography.Text>
      </article>
    </Space>
  );
};

const ArabicVersion = () => {
  const { t } = useTranslation();

  return (
    <Space direction={'vertical'} size={'large'}>
      <article>
        <Typography.Title level={5} direction={'rtl'}>
          {t('companyInfo.privacy.arabic.arabic1')}
        </Typography.Title>
        <Typography.Text direction={'rtl'}>
          <div>{t('companyInfo.privacy.arabic.arabic2')}</div>
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5} direction={'rtl'}>
          {t('companyInfo.privacy.arabic.arabic3')}
        </Typography.Title>
        <Typography.Text direction={'rtl'}>
          <div>{t('companyInfo.privacy.arabic.arabic4')}</div>
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5} direction={'rtl'}>
          {t('companyInfo.privacy.arabic.arabic5')}
        </Typography.Title>
        <Typography.Text direction={'rtl'}>
          {t('companyInfo.privacy.arabic.arabic6')}
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5} direction={'rtl'}>
          {t('companyInfo.privacy.arabic.arabic7')}
        </Typography.Title>
        <Typography.Text direction={'rtl'}>
          <div>{t('companyInfo.privacy.arabic.arabic8')}</div>
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5} direction={'rtl'}>
          {t('companyInfo.privacy.arabic.arabic9')}
        </Typography.Title>
        <Typography.Text direction={'rtl'}>
          <div>{t('companyInfo.privacy.arabic.arabic10')}</div>
        </Typography.Text>
      </article>

      <article>
        <Typography.Title level={5} direction={'rtl'}>
          {t('companyInfo.privacy.arabic.arabic11')}
        </Typography.Title>
        <Typography.Text direction={'rtl'}>
          <div>{t('companyInfo.privacy.arabic.arabic12')}</div>
        </Typography.Text>
      </article>
    </Space>
  );
};

export const PrivacyPolicyView = () => {
  const [lang, setLang] = useState<'eng' | 'arabic'>('eng');
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Space direction={'vertical'} size={'large'}>
        <Row justify={'center'}>
          <Col span={24} lg={12}>
            <Space size={'large'}>
              <Button onClick={() => setLang('eng')}>
                {t('companyInfo.buttons.english')}
              </Button>
              <Button onClick={() => setLang('arabic')}>
                {t('companyInfo.buttons.arabic')}
              </Button>
            </Space>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Col span={24} lg={12}>
            {lang === 'eng' ? <EnglishVersion /> : <ArabicVersion />}
          </Col>
        </Row>
      </Space>
    </Wrapper>
  );
};
