import { App } from 'antd';
import { handleError } from 'api';
import { FacilitiesApiService } from 'features/facilities/api/facilities-api.service';
import i18n from 'locales/i18n';

const { uploadFacilityImage } = FacilitiesApiService();

export const useUploadFacilityImage = () => {
  const { message } = App.useApp();

  const uploadImage = async (id: string, formData: FormData) => {
    await uploadFacilityImage(id, formData)
      .then(() => {
        message.success(i18n.t('facilities.update.successfulImageUpload'));
      })
      .catch((error) => {
        const uiMessage = handleError(error);
        message.warning(i18n.t('facilities.update.errorImageUpload'));

        message.error(uiMessage);
      });
  };

  return {
    uploadImage,
  };
};
