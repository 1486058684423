import { CreateFacility } from 'features/facilities/components/create-facility/create-facility';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

export const CreateFacilityView = () => {
  const navigate = useNavigate();

  const closeModal = () => {
    navigate(ROUTES.FACILITIES);
  };

  return <CreateFacility onCancel={closeModal} onSuccess={closeModal} />;
};
