import { useMutation } from '@tanstack/react-query';
import { App } from 'antd';
import { handleError } from 'api';
import {
  BookingApiService,
  bookingsQueryKeys,
  fieldsBookingsQueryKeys,
} from 'features/bookings';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

const { cancelBooking } = BookingApiService();

export const useCancelBooking = () => {
  const { message } = App.useApp();

  return useMutation(
    (id: string) => {
      return cancelBooking(id);
    },
    {
      onError: (error) => {
        const uiMessage = handleError(error);

        message.error(uiMessage);
      },
      onSuccess: (_, id) => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: bookingsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: bookingsQueryKeys.detail(id),
          }),
          queryClient.invalidateQueries({
            queryKey: fieldsBookingsQueryKeys.all,
          }),
        ]);

        message.success(i18n.t('bookings.details.successfulCancellation'));
      },
    },
  );
};
