import { Button, ContentTopBar } from 'components';
import { StyledContentBackground } from 'components/content-full-screen/content-full-screen';
import {
  AddFixedBookingStepOne,
  AddFixedBookingStepThree,
  AddFixedBookingStepTwo,
  WizardContextProvider,
} from 'features/bookings';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Wizard } from 'react-use-wizard';
import { ROUTES } from 'routes/constants';

export const RecurringBookingsAddBookingView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const closeView = () => {
    navigate(ROUTES.FIXED_BOOKINGS);
  };

  return (
    <>
      <ContentTopBar
        title={t('bookings.heading.addFixedBooking')}
        renderButtons={
          <Button onClick={closeView}>{t('bookings.buttons.cancel')}</Button>
        }
      />
      <StyledContentBackground>
        <WizardContextProvider>
          <Wizard>
            <AddFixedBookingStepOne />
            <AddFixedBookingStepTwo />
            <AddFixedBookingStepThree />
          </Wizard>
        </WizardContextProvider>
      </StyledContentBackground>
    </>
  );
};
