import { App } from 'antd';
import { handleError } from 'api';
import { FieldsApiService } from 'features/fields';
import i18n from 'locales/i18n';
import { queryClient } from 'providers';

import { fieldsQueryKeys } from './query-keys';

const { uploadFieldCoverImage } = FieldsApiService();

export const useUploadFieldCoverImage = () => {
  const { message } = App.useApp();

  const uploadImage = async (id: string, formData: FormData) => {
    await uploadFieldCoverImage(id, formData)
      .then(() => {
        Promise.all([
          queryClient.invalidateQueries({
            queryKey: fieldsQueryKeys.lists(),
          }),
          queryClient.invalidateQueries({
            queryKey: fieldsQueryKeys.detail(id),
          }),
        ]);
        message.success(i18n.t('fields.update.successfulImageUpload'));
      })
      .catch((error) => {
        const uiMessage = handleError(error);
        console.error(uiMessage);

        message.warning(i18n.t('fields.update.errorImageUpload'));
      });
  };

  return {
    uploadImage,
  };
};
