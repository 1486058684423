import { ContentFilters, ContentTopBar } from 'components';
import {
  AmenitiesFilterForm,
  AmenitiesTable,
} from 'features/amenities/components';
import {
  AmenitiesFilterFormPaginated,
  AmenitiesFilterFormValues,
} from 'features/amenities/components/amenities-filter-form/types';
import { useGetAmenities } from 'features/amenities/use-cases';
import { usePaginationParams } from 'hooks';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

const initialFilterValues: AmenitiesFilterFormValues = {
  name: undefined,
  chargeable: undefined,
};

export const Amenities = () => {
  const { t } = useTranslation();
  const { params, updateParams, resetPage } =
    usePaginationParams<AmenitiesFilterFormPaginated>({
      page: 1,
      size: 10,
      order_by: 'name',
      ...initialFilterValues,
    });

  const { data, isFetching } = useGetAmenities(params, { retry: false });

  const handleFiltersChange = (values: AmenitiesFilterFormValues) => {
    updateParams(values);
    resetPage();
  };

  const formId = 'amenities-filters-form';

  return (
    <>
      <ContentTopBar title={t('amenities.heading')} />

      <ContentFilters formId={formId}>
        <AmenitiesFilterForm
          formId={formId}
          handleFilter={handleFiltersChange}
          initialValues={initialFilterValues}
        />
      </ContentFilters>

      <AmenitiesTable
        data={data}
        isLoading={isFetching}
        tableParams={params}
        updateParams={updateParams}
      />
      <Outlet />
    </>
  );
};
