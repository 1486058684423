import { CompanyType } from 'types';

export enum CompanyDetailsField {
  Name = 'name',
  NameArabic = 'name_arabic',
  Address = 'address',
  Country = 'country',
  PhoneNumber = 'phone_number',
  Email = 'email',
  Geolocation = 'geolocation',
  Type = 'type',
}

export type CompanyDetailsFormValues = {
  [CompanyDetailsField.Name]: string;
  [CompanyDetailsField.NameArabic]: string;
  [CompanyDetailsField.Address]: string;
  [CompanyDetailsField.Country]: string;
  [CompanyDetailsField.PhoneNumber]: string;
  [CompanyDetailsField.Email]: string;
  [CompanyDetailsField.Geolocation]: string;
  [CompanyDetailsField.Type]: CompanyType;
};
