import { Company, Option } from 'types';

export const companiesFilterOptionsMap = (
  companies?: Company[],
  predefinedOptions: Option[] = [],
): Option[] | [] => {
  if (!companies) return [];

  const companiesOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  return [...predefinedOptions, ...companiesOptions];
};
