import { apiClient } from 'api';

export type LoginDTO = {
  phone_number: string;
  password: string;
};

export type LoginResponse = {
  access_token: string;
  refresh_token: string;
  type: string;
};

export type RefreshTokenDTO = {
  grant_type: 'refresh_token';
  token: string;
};

export const AuthApiService = () => {
  const login = async (payload: LoginDTO) => {
    const response = await apiClient<LoginResponse>({
      endpoint: '/admin/login',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };

  const refreshToken = async (payload: RefreshTokenDTO) => {
    const response = await apiClient<LoginResponse>({
      endpoint: '/admin/login/refresh',
      method: 'POST',
      data: payload,
      config: {
        skipAuthRefresh: true,
      },
    });

    return response.data;
  };

  return {
    login,
    refreshToken,
  };
};
